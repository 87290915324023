import PropTypes from 'prop-types'
import React from 'react'
import { Carousel } from 'react-bootstrap'
import GoogleText from './GoogleText'

const mod = (n, m) => ((n % m) + m) % m

const GoogleTextCarousel = ({ headlines, descriptions, link }) => {
    const zipped = headlines
        .map((headline, i) => [headline, descriptions[i]])
        .filter(pair => pair[0] !== '' && pair[1] !== '')

    return (
        <Carousel indicators={false} fade style={{minWidth: '17.5rem'}}>
            {zipped.map((pair, i) => (
                <Carousel.Item key={i} className="px-5">
                    <GoogleText link={link} header={pair[0]} description={pair[1]} />
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

GoogleTextCarousel.propTypes = {
    headlines: PropTypes.arrayOf(PropTypes.string).isRequired,
    descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default GoogleTextCarousel
