import { connect } from 'react-redux'
import { selectErrors, selectOrder, orderFieldUpdated, selectReadOnly, budgetUpdated } from '../orderFormSlice'
import { selectSession } from '../../session/sessionSlice'
import DateRangeDescription from '../components/DateRangeDescription'

const mapStateToProps = state => ({
    start_datetime: selectOrder(state).start_at,
    stop_datetime: selectOrder(state).stop_at,
})


const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeDescription)
