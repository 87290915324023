import React, {useEffect, useRef} from 'react'
import {Button, Col, Container, Row} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import ConnectedOrderFilter from '../features/orders/ConnectedOrderFilter'
import ConnectedOrdersGrid from '../features/orders/ConnectedOrdersGrid'
import {fetchOrders, selectUnarchivedOrders} from '../features/orders/ordersSlice'
import Chrome from './layout/Chrome'
import OrderPagination from '../features/orders/components/OrderPagination'
import {cacheFetchRetailers, selectAllRetailers} from "../features/retailers/retailersSlice";
import {cachedFetchBrands, selectAllBrands} from "../features/brands/brandsSlice";

const AllOrders = () => {
    const status = useSelector(state => state.orders.status)
    const ordersTotal = useSelector(state => state.orders?.total)
    const ordersPerPage = useSelector(state => state.orders?.per_page)
    const currentPage = useSelector(state => state.orders?.current_page)
    const orderFilters = useSelector(state => state.orders?.filterFields)
    const pages = useSelector(state => state.orders?.pages)
    const orders = useSelector(selectUnarchivedOrders)
    const dispatch = useDispatch()
    const listTopRef = useRef(null)
    const retailers = useSelector(selectAllRetailers);
    const suppliers = useSelector(selectAllBrands);

    const organisations = [
        ...retailers.map(retailer => ({...retailer, label: `${retailer.name} (Retailer)`})),
        ...suppliers.map(supplier => ({...supplier, label: `${supplier.name} (Supplier)`})),
    ]

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchOrders({page: currentPage, filters: orderFilters}))
        }
    }, [status, dispatch])

    useEffect(() => {
        dispatch(cachedFetchBrands());
        dispatch(cacheFetchRetailers());
    }, []);
    

    const handlePaginationClick = async (val) => {
        if(status !== 'loading' && val && val !== currentPage){
            dispatch(fetchOrders({page: val, filters: orderFilters}));
            listTopRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            }) 
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Orders - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container className="pt-4" ref={listTopRef}>
                    <Row>
                        <Col>
                            <h3>Orders</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ConnectedOrderFilter organisations={organisations} />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <ConnectedOrdersGrid />
                        </Col>
                    </Row>
                    
                    <Row className="mb-3">
                        <Col><OrderPagination pages={pages} currentPage={currentPage} onClick={v => handlePaginationClick(v)} disabled={status === 'loading'} /></Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link to="/orders/archive">
                                <Button disabled={status === 'loading'} variant="outline-secondary">
                                    Archived Orders
                                </Button>
                            </Link>
                        </Col>
                    </Row>

                </Container>
            </Chrome>
        </>
    )
}

export default AllOrders
