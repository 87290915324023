import { Pagination } from 'react-bootstrap'

const OrderPagination = ({ pages, currentPage, onClick = () => {}, disabled = false }) => {
    let items = [];

    const handlePaginationClick = async (val) => {
        let idx = parseInt(val);
        onClick(idx);
    };

    items.push(<Pagination.Prev key={`Pagination.Prev of page:${currentPage}`} disabled={currentPage === 1 || disabled} onClick={e => handlePaginationClick(currentPage - 1)} />);
    items.push(
        <Pagination.Item key={`Pagination.Item:${currentPage}`} disabled={true}>Page {currentPage} of {pages}</Pagination.Item>,
    );
    items.push(<Pagination.Next key={`Pagination.Next of page:${currentPage}`} disabled={currentPage === pages || disabled} onClick={e => handlePaginationClick(currentPage + 1)} />);

    return (
        <Pagination className="d-flex justify-content-center">{items}</Pagination>
    );
}

export default OrderPagination;