import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {identifyUser} from '../../helpers/logrocket';
import {
    linkOrg,
    linkUser,
    loginAdminAs,
    loginAs,
    loginRequest,
    refreshFacebookData as refreshFacebookDataRequest,
    refreshGoogleData as refreshGoogleDataRequest,
    refreshUser,
    updateUser as updateUserRequest
} from '../../lib/api';
import {getOwnedSpacesForSupplier} from '../../lib/api/ownedSpaces';
import {setAuthTokenCookie} from '../../lib/auth';

export const loginUser = createAsyncThunk(
    'session/login',
    async ({ email, password }) => {
        const response = await loginRequest(email, password)
        return response.data.data
    }
)

export const loginAsUser = createAsyncThunk(
    'session/loginAsUser',
    async ({ email, password, login_as }, { rejectWithValue }) => {
        try {
            const response = await loginAs(email, password, login_as)
            return response.data.data
        } catch (err) {
            if (!err.response?.data) {
                throw err
            }

            return rejectWithValue({ status: err.response.status, errors: err.response.data })
        }
    }
)

export const fetchWebSpacesForSupplier = createAsyncThunk('session/webspaces/supplier', async ({retailerId, supplierId}) => {
    const response = await getOwnedSpacesForSupplier(retailerId, supplierId)
    return response.data.data
})

export const loginAdminAsUser = createAsyncThunk(
    'session/loginAdminAsUser',
    async ({ token }, { rejectWithValue }) => {
        try {
            const response = await loginAdminAs(token)
            return response.data.data
        } catch (err) {
            if (!err.response?.data) {
                throw err
            }

            return rejectWithValue({ status: err.response.status, errors: err.response.data })
        }
    }
)

export const handleLinkOrg = createAsyncThunk(
    'session/handleLinkOrg',
    async ({ token }, { rejectWithValue }) => {
        try {
            const response = await linkOrg(token)
            return response.data.data
        } catch (err) {
            if (!err.response?.data) {
                throw err
            }

            return rejectWithValue({ status: err.response.status, errors: err.response.data })
        }
    }
)

export const handleLinkUser = createAsyncThunk(
    'session/handleLinkUser',
    async ({ token }, { rejectWithValue }) => {
        try {
            const response = await linkUser(token)
            return response.data.data
        } catch (err) {
            if (!err.response?.data) {
                throw err
            }

            return rejectWithValue({ status: err.response.status, errors: err.response.data })
        }
    }
)

export const updateUserPreferences = createAsyncThunk(
    'session/updateUserPreferences',
    async (preferences_json, { getState, dispatch }) => {
        const user = selectUser(getState())
        const response = await updateUserRequest(user.id, user.first_name, user.last_name, preferences_json)
        dispatch(updateUser(response.data.data))
        return response.data.data
    }
)


export const refreshSession = createAsyncThunk(
    'session/refresh',
    async (cache = true, { getState }) => {
        const response = await refreshUser(selectSession(getState()), cache)
        return response.data.data
    }
)


export const refreshFacebookData = createAsyncThunk(
    'organisations/facebook/refresh',
    async (_, { getState }) => {
        const response = await refreshFacebookDataRequest();
        return response.data.data;
    }
)

export const refreshGoogleData = createAsyncThunk(
    'organisations/google/refresh',
    async (_, { getState }) => {
        const response = await refreshGoogleDataRequest();
        return response.data.data;
    }
)


const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        authToken: Cookies.get('authToken') || null,
        data: {},
        status: 'unauthenticated',
        refresh_status: 'idle',
        errors: {},
        registration: {},
    },
    reducers: {
        clearSession(state) {
            state.data = {}
            state.status = 'unauthenticated'
            state.refresh_status = 'idle'
            state.authToken = null;
            Cookies.remove('authToken');
            state.errors = {}
            state.registration = {}
        },
        updateUser(state, action) {
            const user = action.payload
            state.data.user = user
        },
        registrationSuccess(state) {
            state.registration.outcome = 'success'
        },
    },
    extraReducers: {
        [loginUser.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'authenticated'
                state.data = action.payload
                const user = action.payload.user;
                identifyUser(user);
                const token = action.payload.token;
                state.authToken = token;
                setAuthTokenCookie(token);
            }
        },
        [loginUser.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [loginUser.rejected]: (state, action) => {
            state.status = 'failed'
            state.errors = { _error: ['Please check your login details and try again. '] }
        },
        [loginAsUser.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'authenticated'
                state.data = action.payload
                const user = action.payload.user;
                identifyUser(user);
                const token = action.payload.token;
                state.authToken = token;
                setAuthTokenCookie(token);
            }
        },
        [loginAsUser.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [loginAsUser.rejected]: (state, action) => {
            const { status, errors } = action.payload
            state.status = 'failed'

            if (status === 403) {
                state.errors = { _error: ['Please check your login details, authorization level and try again.'] }
            } else {
                state.errors = errors
            }
        },
        [loginAdminAsUser.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'authenticated'
                state.data = action.payload
                const user = action.payload.user;
                identifyUser(user);
                const token = action.payload.token;
                state.authToken = token;
                setAuthTokenCookie(token);
            }
        },
        [loginAdminAsUser.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [loginAdminAsUser.rejected]: (state, action) => {
            const { status, errors } = action.payload
            state.status = 'failed'

            if (status === 403) {
                state.errors = { _error: ['Please check your login details'] }
            } else {
                state.errors = errors
            }
        },
        [handleLinkOrg.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'success'
                state.data = action
            }
        },
        [handleLinkOrg.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [handleLinkOrg.rejected]: (state, action) => {
            const { status, errors } = action.payload
            state.status = 'failed'

            if (status === 403) {
                state.errors = { _error: ['Linking organisation failed, please try again'] }
            } else {
                state.errors = errors
            }
        },
        [handleLinkUser.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'success'
                state.data = action
            }
        },
        [handleLinkUser.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [handleLinkUser.rejected]: (state, action) => {
            const { status, errors } = action.payload
            state.status = 'failed'

            if (status === 403) {
                state.errors = { _error: ['Linking user failed, please try again'] }
            } else {
                state.errors = errors
            }
        },
        [refreshSession.pending]: (state, action) => {
            state.refresh_status = 'loading'
        },
        [refreshSession.rejected]: (state, action) => {
            state.refresh_status = 'idle'
            state.status = 'unauthenticated'
        },
        [refreshSession.fulfilled]: (state, action) => {
            const token = state.data.token
            state.data = action.payload
            state.data.token = token
            state.refresh_status = 'idle'
        },
        [refreshFacebookData.fulfilled]: (state, { payload }) => {
            const { custom_audiences, lookalike_audiences, saved_audiences, catalogs } = payload;
            state.data.facebook_custom_audiences = custom_audiences;
            state.data.facebook_saved_audiences = saved_audiences;
            state.data.facebook_lookalike_audiences = lookalike_audiences;
            state.data.platform_catalogs.facebook = catalogs;
        },
        [refreshGoogleData.fulfilled]: (state, { payload }) => {
            const { remarketing_audiences, catalogs } = payload;
            state.data.google_remarketing_audiences = remarketing_audiences;
            state.data.platform_catalogs.google = catalogs;
        },
    }
})

export const {
    clearSession,
    updateUser,
    registrationSuccess,
} = sessionSlice.actions

export const selectSession = state => state.session.data
export const selectUser = state => selectSession(state).user
export const selectUserOrganisation = state => selectUser(state).active_organisation
export const selectSuppliers = state => selectSession(state).suppliers
export const selectRetailers = state => selectSession(state).retailers

export const selectTheme = state => selectSession(state).theme
export const selectOrderStatuses = state => selectSession(state).order_statuses
export const selectRefreshStatus = state => state.session.refresh_status
export const selectDashboardStatisticsOptions = state => selectSession(state).dashboard_statistics_options
export const isLoggedIn = (session) => session.status === 'authenticated'
export const selectPermissions = state => selectSession(state).permissions
export const selectAuthToken = state => state.session.authToken;

export const selectAdTypes = state => selectSession(state).ad_types.filter(adType => adType.disabled_at === null || new Date() < new Date(adType.disabled_at))
export const selectAdTypesIncludingDisabled = state => selectSession(state).ad_types
export const selectStandardAudienceTemplates = state => selectSession(state).standard_audience_templates

export const selectAdbox = state => selectSession(state).adbox
export const selectWebSubformats = state => selectSession(state).web_subformats
export const selectMercatoDeviceGroups = state => selectSession(state).mercato_device_groups

export const selectUserOrganisationCurrency = state => selectUser(state).active_organisation?.settings?.defaultCurrency;

export const selectFacebookPlacements = state => selectSession(state).facebook_placements

export default sessionSlice.reducer
