import React, { useMemo } from 'react'
import Select, { components } from 'react-select'

const SingleCategorySelect = ({ title, options = [], value = [], onChange = () => { } }) => {
    
    let optionsWithSelect = options

    return <Select
        isMulti={false}
        isClearable={false}
        isSearchable={false}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder={title}
        styles={selectStyles}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        closeMenuOnSelect={true}
        value={value}
        onChange={selected => {
            onChange(selected.value);
        }}
        options={optionsWithSelect}
    />
}

const selectStyles = {
    option: styles => ({
        ...styles,
        backgroundColor: 'while',
        color: 'black',
        overflow: 'wrap',
    }),
}

const Option = props => (
    <div>
        <components.Option {...props}>
            <div className="d-flex align-items-top">
                <input type="checkbox" checked={props.isSelected} className="mr-2 mt-1" onChange={() => null} />
                <label className="my-0" style={{ wordWrap: 'break-word' }}>{props.label}</label>
            </div>
        </components.Option>
    </div>
)

const MultiValue = props => (
    <components.MultiValue {...props}>
        <span>{props.data.label}</span>
    </components.MultiValue>
)

const DropdownInput = props => (
    <components.Input {...props}>
        <span>Metrics</span>
    </components.Input>
)

export default SingleCategorySelect
