import { connect } from 'react-redux'
import OrdersGrid from './components/OrdersGrid'
import { selectFilteredOrders, selectIsLoading } from './ordersSlice'

const mapStateToProps = state => ({
    orders: selectFilteredOrders(state),
    loading: selectIsLoading(state),
})

export default connect(mapStateToProps)(OrdersGrid)
