import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getOrganisation, updateSettings as updateSettingsRequest} from '../../lib/api'

export const updateSettings = createAsyncThunk('organisation_settings/updateSettings', async (arg, {
    getState,
    dispatch,
    rejectWithValue
}) => {
    try {
        const data = {
            read_permissions: selectReadPermissions(getState()),
            settings: selectSettings(getState()),
        }
        const response = await updateSettingsRequest(arg, data)
        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

export const updateOrganisation = createAsyncThunk('organisation_settings/organisation', async (organisationID, {
    getState,
    dispatch,
    rejectWithValue
}) => {
    try {
        let response = await getOrganisation(organisationID)

        dispatch(organisationUpdate(response.data.data))
        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})


const organisationSettingsSlice = createSlice({
    name: 'organisation_settings',
    initialState: {
        organisation: {
            id: undefined,
            read_permissions: {
                metrics: {},
                facebook_custom_audiences: [],
                google_remarketing_audiences: [],
                product_catalogs: [],
                product_sets: [],
            },
            settings: {
                margin: {},
                min_budget: {},
                enabled_ads: {},
            },
        },
        status: 'idle',
        errors: {},
    },
    reducers: {
        idUpdate(state, action) {
            state.organisation.id = action.payload
        },
        readPermissionsUpdate(state, action) {
            state.organisation.read_permissions = action.payload
        },
        metricsUpdate(state, action) {
            state.organisation.read_permissions.metrics = action.payload
        },
        facebookCustomAudienceUpdate(state, action) {
            state.organisation.read_permissions.facebook_custom_audiences = action.payload
        },
        facebookLookalikeAudienceUpdate(state, action) {
            state.organisation.read_permissions.facebook_lookalike_audiences = action.payload
        },
        facebookSavedAudienceUpdate(state, action) {
            state.organisation.read_permissions.facebook_saved_audiences = action.payload
        },
        googleRemarketingAudienceUpdate(state, action) {
            state.organisation.read_permissions.google_remarketing_audiences = action.payload
        },
        productsUpdate(state, action) {
            const [catalogues, productSets] = action.payload

            state.organisation.read_permissions.product_catalogs = catalogues
            state.organisation.read_permissions.product_sets = productSets
        },
        settingsUpdate(state, action) {
            state.organisation.settings = action.payload
        },
        organisationUpdate(state, action) {
            state.organisation = action.payload
        },
        marginUpdate(state, action) {
            state.organisation.settings.margin = action.payload
        },
        minBudgetsUpdate(state, action) {
            state.organisation.settings.min_budget = action.payload
        },
        enabledAdTypesUpdate(state, action) {
            state.organisation.settings.enabled_ads = action.payload
        },

    },
    extraReducers: {
        [updateSettings.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [updateSettings.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.read_permissions = action.payload.read_permissions
                state.settings = action.payload.settings
                state.status = 'success'
            }
        },
        [updateSettings.rejected]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'failed'
                state.errors = action.payload
            }
        },
        [updateOrganisation.pending]: (state, action) => {
            state.status = 'loading'
            state.errors = {}
        },
        [updateOrganisation.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.read_permissions = action.payload.read_permissions
                state.settings = action.payload.settings
                state.status = 'success'
            }
        },
        [updateOrganisation.rejected]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'failed'
                state.errors = action.payload
            }
        },
    },
})

export const {
    idUpdate,
    readPermissionsUpdate,
    metricsUpdate,
    facebookCustomAudienceUpdate,
    facebookLookalikeAudienceUpdate,
    facebookSavedAudienceUpdate,
    googleRemarketingAudienceUpdate,
    productsUpdate,
    settingsUpdate,
    minBudgetsUpdate,
    enabledAdTypesUpdate,
    organisationUpdate,
    marginUpdate,
} = organisationSettingsSlice.actions

export const selectReadPermissions = state => selectOrganisation(state).read_permissions
export const selectSettings = state => selectOrganisation(state).settings
export const selectStatus = state => state.organisation_settings.status
export const selectId = state => selectOrganisation(state).id

export const selectOrganisation = state => state.organisation_settings.organisation

export default organisationSettingsSlice.reducer
