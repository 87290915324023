import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Modal, Tab} from "react-bootstrap";
import TabContainer from './TabContainer'
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from 'uuid';
import OrderPackagePane from "../../containers/AdSelectionModal/OrderPackagePane";
import {PLATFORM_NATIVE} from "../../../../lib/platforms";
import {
  adInsert,
  selectAvailableAdTypes,
  selectFacebookProductGroup,
  selectGoogleProductGroup,
  selectImageAssets,
  selectOwnerOrganisation,
  selectVideoAssets
} from "../../orderFormSlice";
import {selectAdbox} from "../../../session/sessionSlice";
import styles from "./ad_selection_modal.module.scss";
import {fetchBespokeProducts} from '../../../bespoke/bespokeSlice';
import {createOwnedSpaceAd, createStandardAd, strToIcon} from "./helper";
import {Entrypoints} from "./Entrypoints";


const AdSelectionModal = ({
                            show = false,
                            onHide = () => {
                            }
                          }) => {
  const organisation = useSelector(selectOwnerOrganisation)

  const adTypes = useSelector(selectAvailableAdTypes);

  const bespoke = adTypes.filter(ad => ad.platform === 'bespoke_product');
  const adbox = useSelector(selectAdbox);

  const facebookProducts = useSelector(selectFacebookProductGroup);
  const googleProducts = useSelector(selectGoogleProductGroup);
  const imageAssets = useSelector(selectImageAssets);
  const videoAssets = useSelector(selectVideoAssets);
  const handleInsert = (adFormat, fields = {}) => {
    let ad;
    if (adFormat.platform !== PLATFORM_NATIVE) {
      ad = createStandardAd(
        adFormat,
        facebookProducts,
        googleProducts,
        imageAssets,
        videoAssets,
        organisation.settings,
      );
    } else if (adFormat.platform === PLATFORM_NATIVE) {
      ad = createOwnedSpaceAd(adFormat);
    }

    ad = { ...ad, ...fields }

    dispatch(adInsert(ad));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBespokeProducts())
  }, [dispatch])

  let tabs = Object.keys(adbox.entrypoints);

  return (
    <Modal
      dialogClassName={styles.modalflex}
      show={show}
      onHide={() => onHide(false)}
    >

      <Modal.Header closeButton className={styles.modal_header}>
        <Modal.Title className={styles.modal_title}>Select an ad format</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modal_fixes}>
        <TabContainer tabs={tabs.map(tab => ({
          id: tab,
          title: adbox.entrypoints[tab].title,
          icon: strToIcon(adbox.entrypoints[tab].icon),
        }))}>
          <Tab.Content>
            {tabs
              .filter(tab => tab !== 'by_package' && tab !== 'by_bespoke_product')
              .map(tab =>
                <Tab.Pane eventKey={tab} key={tab} style={{ background: '#fff' }}>
                  <Entrypoints
                    key={tab}
                    objective={adbox.entrypoints[tab].groups}
                    adtype={adTypes}
                    states={onHide}
                  />
                </Tab.Pane>
              )}
            <Tab.Pane eventKey="by_package">
              <OrderPackagePane
                  onAdd={pkg => {
                    const packageCollectionID = uuidv4()
                    pkg.ad_format_templates.forEach(adTemplate => {
                      let adFormat = adTypes.find(format => format.control_id === adTemplate.adformat_control_id)
                      if (adFormat) {
                        handleInsert(adFormat, {
                          package_id: pkg.id,
                          package_collection_id: packageCollectionID,
                          package_item_id: adTemplate.id,
                        })
                      }
                    })
                    onHide()
                  }}
                />
            </Tab.Pane>
            <Tab.Pane eventKey="by_bespoke_product" key="by_bespoke_product" style={{ background: '#fff' }}>
              <Entrypoints
                key="by_bespoke_product"
                objective={[]}
                adtype={bespoke}
                states={onHide}
                entrytype="bespoke"
              />
            </Tab.Pane>
          </Tab.Content>
        </TabContainer>
      </Modal.Body>
    </Modal>
  );
};

AdSelectionModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};


export default AdSelectionModal;
