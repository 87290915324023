import {faFacebook, faGoogle, faInstagram, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faAd, faArrowRight, faFilm, faFilter, faGlobe, faImage} from "@fortawesome/free-solid-svg-icons";
import {PLATFORM_NATIVE} from "../../../../lib/platforms";
import {
    faArrowToBottom,
    faArrowToRight,
    faArrowToTop,
    faBullseyeArrow,
    faClipboardListCheck,
    faTrafficLight,
    faTvRetro,
    faVideo
} from "@fortawesome/pro-light-svg-icons";
import {faCopyright} from "@fortawesome/free-regular-svg-icons";
import {faD, faGrid2Plus} from "@fortawesome/pro-regular-svg-icons";

const iconMappings = {
    'facebook': faFacebook,
    'youtube': faYoutube,
    'instagram': faInstagram,
    'google': faGoogle,
    'filter': faFilter,
    [PLATFORM_NATIVE]: faGlobe,
    'image': faImage,
    'media': faFilm,
    'video': faVideo,
    'brand': faCopyright,
    'traffic': faTrafficLight,
    'conversion': faArrowRight,
    'funnel': faFilter,
    'objective': faBullseyeArrow,
    'top': faArrowToTop,
    'middle': faArrowToRight,
    'bottom': faArrowToBottom,
    'channel': faTvRetro,
    'bespoke': faGrid2Plus,
    'digital': faD,
    'clipboard': faClipboardListCheck,
};

export const strToIcon = (iconString = null) => {
    if (typeof iconString === 'object') {
        // return if the icon is from fontawesome
        return iconString
    }
    if (!iconString) {
        return;
    }
    let icon = iconString;
    icon = icon.includes('-') ? icon.split('-')[1] : icon;

    icon = icon.toLowerCase();

    return iconMappings[icon] || faAd;
}
export const createStandardAd = (
    adSelection,
    facebookProducts,
    googleProducts,
    imageAssets,
    videoAssets,
    settings,
) => {
    let ad = {
        type: adSelection.control_id,
        platform: adSelection.platform,
    };

    // Apply checkbox default values
    adSelection.fields
        .filter(
            (field) =>
                field.type === "select_one_or_many" && field.default?.length > 0
        )
        .forEach((field) => {
            ad[field.id] = field.default;
        });

    // Perform default feed value
    if (adSelection.fields.some((field) => field.type === "feed")) {
        const feedField = adSelection.fields.find((field) => field.type === "feed");
        if (ad.platform === "google") {
            ad[feedField.id] = googleProducts;
        } else if (ad.platform === "facebook") {
            ad[feedField.id] = facebookProducts;
        }
    }

    // Set default cta
    if (
        ad.type === "facebook_online_conversion_dpa" ||
        ad.type === "facebook_display" || ad.type === 'facebook_carousel'
    ) {
        ad.cta = "LEARN_MORE";
    }

    return ad;
};
export const createOwnedSpaceAd = (adSelection) => {
    let ad = {
        platform: PLATFORM_NATIVE,
        type: adSelection.control_id
    };

    return ad;
};
export const createBespokeProductAd = (adSelection) => {
    let ad = {
        platform: adSelection.platform,
        type: adSelection.control_id,
        bespoke_id: adSelection.id,
    };

    return ad;
};
export const getadfromadtytpe = (lookup, adtype) => {
    return adtype.find(ad => ad.control_id == lookup)
}