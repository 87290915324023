import { connect } from 'react-redux'
import Asset from '../../components/generic/Asset'
import { selectVideoAndImageAssets } from '../../orderFormSlice'

const mapStateToProps = state => ({
    type: 'mixed',
    files: selectVideoAndImageAssets(state),
})

export default connect(mapStateToProps)(Asset)
