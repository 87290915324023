import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { selectUser } from '../features/session/sessionSlice'
import Invite from '../features/users/Invite'
import { INVITE_EMPLOYEE } from '../lib/permissions'
import Chrome from './layout/Chrome'

const InviteSupplier = () => {
    const user = useSelector(selectUser)
    const userPermissions = user?.role?.permissions
    const canInviteEmployees = userPermissions ? userPermissions.find(p => p.name === INVITE_EMPLOYEE) : false

    if (!canInviteEmployees) {
        return <Redirect to="/" />
    }

    return (
        <>
            <Helmet>
                <title>
                    Invite Employee - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container className="pt-4">
                    <Row className="mb-3">
                        <Col lg="6">
                            <Invite />
                        </Col>
                    </Row>
                </Container>
            </Chrome>
        </>
    )
}


export default InviteSupplier
