import {
    faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from 'react';
import {
    Button,
    Col,
    Container, Nav,
    Row,
    Tab
} from "react-bootstrap";
import styled from 'styled-components';

const TabNav = styled(Nav)`
  padding: 0 8px;
  overflow-x: auto;
  flex-wrap: nowrap;
`

const TabTitle = styled.h6`
  font-size: 12px;
  margin: 4px 0px;
`

const TabNavItem = styled(Nav.Item)`
  text-align: center;
  background-color: #dedede;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 2px;
  font-size: 12px;
  height: 53px;
  flex: 0 0 65px;

  ${props => props.active && `
    background-color: #fff !important;
    border-bottom: 0;
  `}

  ${props =>
    props.completed && `
    border-top: 2px solid #87F5C0;
    `}

  ${props => props.invalid && `border-top: 2px solid #EF767A;`}
`

const TabNavLink = styled(Nav.Link)`
    color: #595959;
    font-weight: normal;
    padding: 0 !important;
    position: relative;
    text-align: center;
`

const TabNavCheck = styled.span`
  right: 3px;
  top: -3px;
  position: absolute;
`

const ActiveTabTitle = styled.h4`
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    margin: 0.5rem auto 1rem;
`

const ActiveTabDescription = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d0c6ff;
    border-radius: 50px;
    font-size: 0.75rem;
    padding: 0.5rem;
    color: #3a3b4f;
    max-width: 600px;
    margin: 0 auto 1.5rem;
`

const NextButton = styled(Button)`
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem auto;
    text-align: center;
    padding: 0.25rem 2rem;
    background-color: transparent;
    border: 1px solid #7155ff;
    color: #7155ff;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      background-color: #7155ff;
      color: #fff;
    }
    &:disabled {
        opacity: 0.5;
        background-color: transparent !important;
        border: 1px solid #7155ff !important;
        color: #7155ff !important;
    }
`

const PropertiesBody = ({ readOnly = true, tabs = [], completeCheckContext = {}, children }) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const activeTabIndex = tabs.findIndex((tab) => tab.id === activeTab.id);

    const showNextTabButton = activeTabIndex !== tabs.length - 1 || activeTabIndex === 0;

    const handleNextTabButtonClick = () => {
        if (activeTabIndex === -1) {
            return;
        }

        let nextTabIndex = activeTabIndex + 1;
        if (nextTabIndex > tabs.length) {
            // this should not trigger since we are not setting back to the start position, it's one way flow
            nextTabIndex = 0;
        }

        setActiveTab(tabs[nextTabIndex]);
    }

    const handleTabSelect = key => setActiveTab(tabs.find(tab => tab.id === key))

    return <Tab.Container
        id="tab"
        activeKey={activeTab.id}
        onSelect={handleTabSelect}
    >
        <div style={{ backgroundColor: "#EDEDED" }}>
            <TabNav>
                {tabs.map((tab) => {
                    const completed = tab.completedCheck(completeCheckContext);
                    const optional = tab.hasOwnProperty('optionalCheck') ? tab.optionalCheck(completeCheckContext): tab.optional;

                    return <TabNavItem completed={completed} invalid={!optional && !completed} active={tab.id === activeTab.id}>
                        <TabNavLink eventKey={tab.id}>
                            <>
                                <FontAwesomeIcon icon={tab.icon} size="lg" color="#5995ED" />
                                <TabTitle>
                                    {tab.title}
                                </TabTitle>
                            </>
                        </TabNavLink>
                    </TabNavItem>
                })}
            </TabNav>
        </div>

        <Container>
            <Row>
                <Col sm="12">
                    <div>&nbsp;</div>
                    <ActiveTabTitle>
                        {activeTab.title}
                    </ActiveTabTitle>
                    {activeTab.description && (
                        <ActiveTabDescription>
                            {activeTab.description}
                        </ActiveTabDescription>
                    )}
                </Col>
            </Row>
        </Container>

        <Tab.Content>
            {children}
            <Container>
                <Row>
                    <Col sm="12">
                        {showNextTabButton && (
                            <NextButton
                                disabled={activeTabIndex === 0 && tabs.length === 1 || readOnly}
                                onClick={handleNextTabButtonClick}
                            >
                                Next
                            </NextButton>
                        )}
                    </Col>
                </Row>
            </Container>
        </Tab.Content>
    </Tab.Container>
}

export default PropertiesBody
