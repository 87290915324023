import {faAd} from '@fortawesome/pro-regular-svg-icons'
import React, {useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'
import PackageCard from '../../../packages/components/PackageCard'
import {Group} from "./Group";

const EmptyPackageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`

const PackageContainer = styled(Container)`
    padding-top: 25px;
`

const ColumnPane = styled(Col)`
    overflow-x: scroll;
    height: 600px;
`

const PackagePane = ({ owner = {}, groups = [], packages = [], onAdd = () => { } }) => {
    const [selectedGroup, setSelectedGroup] = useState(groups?.[0]?.id);

    if (packages.length === 0) {
        return <EmptyPackageContainer>
            No Packages available.
        </EmptyPackageContainer>
    }

    let filteredPackages = packages
    if (selectedGroup !== undefined) {
        filteredPackages = filteredPackages.filter(pkg => selectedGroup === (pkg.package_group_id ?? 0))
    }

    return <PackageContainer className="container-fluid">
        <Row>
            <ColumnPane Col xl={2} lg={3} md={3}>
                {groups.map(group =>
                    <div onClick={e => setSelectedGroup(group.id)} key={group.id}>
                        <Group
                            keyGroup={group.id}
                            icon={faAd}
                            selectstate={selectedGroup}
                            title={group.label}
                        />
                    </div>
                )}
            </ColumnPane>
            <ColumnPane xl={10} lg={9} md={9}>
                <Row>
                    {filteredPackages.map(pkg =>
                        <Col xl={6} lg={12} key={pkg.id}>
                            <PackageCard
                                image={pkg.image}
                                organisationLabel={owner.name}
                                header={pkg.name}
                                description={pkg.description}
                                outcomes={pkg.outcomes}
                                ads={pkg.ad_format_templates}
                                onAdd={e => onAdd(pkg)}
                            />
                        </Col>
                    )}
                </Row>
            </ColumnPane>
        </Row>
    </PackageContainer>
}

export default PackagePane
