import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Form, OverlayTrigger, Tooltip } from "react-bootstrap";

const Hidden = ({ controlId, value = "" }) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Control type="hidden" value={value} />
    </Form.Group>
  );
};

Text.propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default Hidden;
