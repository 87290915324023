import {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import styled from "styled-components";
import Chrome from "../app/layout/Chrome";
import ConnectedOrderFormChrome from "../features/order_form/ConnectedOrderFormChrome";
import OrderAdForm from '../features/order_form/containers/OrderAdForm';
import {
    availableAdFormats,
    availableMercatoDevices,
    availableWebSpaces,
    orderFormEditable,
    orderPackages,
    selectErrors,
    selectOrder,
    selectOrderAds,
    selectOtherOrganisation,
    updateOrderFormOrganisations
} from "../features/order_form/orderFormSlice";


const StyledContainer = styled.div`
  ${props => props.package && `:not(:empty){
    border-color: #f8f9fa;
    border: 1px solid #D4CCFF;
    margin-bottom: 60px;
    border-radius: 4px;
    section {
      border: 0;
      button.ml-auto.btn {
        display: none;
      }
    }
  }`}
`;

const CreateOrder = () => {
    const dispatch = useDispatch();
    const errors = useSelector(selectErrors);
    const order = useSelector(selectOrder);
    const selectedOrg = useSelector(selectOtherOrganisation);
    const ads = useSelector(selectOrderAds)

    useEffect(() => {
        dispatch(orderFormEditable());
        dispatch(updateOrderFormOrganisations())
        dispatch(availableAdFormats())
        dispatch(availableMercatoDevices())
        dispatch(availableWebSpaces())
        dispatch(orderPackages())
    }, [dispatch, order?.owner_id, order?.supplier_id]);

    if (!selectedOrg) {
        return <Redirect to="/"/>
    }

    return (
        <>
            <Helmet>
                <title>
                    Create Order - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>


      <Chrome>
        <Container fluid className="pt-4">
          <Row>
            <Col>
              <ConnectedOrderFormChrome>
                <OrderAdForm />
              </ConnectedOrderFormChrome>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default CreateOrder;
