import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { fetchBespokeProducts } from "../../features/bespoke/bespokeSlice";
import OrdersSchedule from "../../features/orders/OrdersSchedule";
import { fetchOrders, resetFilter } from "../../features/orders/ordersSlice";
import { organisationRetailerPackages } from "../../lib/api";
import Chrome from "../layout/Chrome";
import DashboardOrders from "./DashboardOrders";
import MetricsOverview from "./MetricsOverview";
import PackageOffers from "./PackageOffers";
import WelcomeMessage from "./WelcomeMessage";

const BannerContainer = styled.div`
  padding: 63px 120px;
  border-radius: 0px;

  background: linear-gradient(358deg, #E3E5FF 75%, #EBEDFF 25%);
`

const Dashboard = () => {
  const status = useSelector((state) => state.orders.status);
  const currentPage = useSelector(state => state.orders?.current_page)
  const onlyArchived = useSelector(state => state.orders?.archived_only)
  const [packages, setPackages] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "idle" || status === 'succeeded'){
      dispatch(fetchOrders({filters: {order_status: 'pending_approval'}}));
    }
    dispatch(fetchBespokeProducts())
    dispatch(resetFilter())
  }, [])

  useEffect(() => {
    organisationRetailerPackages()
      .then(resp => setPackages(resp.data.data))
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Dashboard - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Chrome>
        <Container fluid>
          <Row className="mb-5">
            <Col xs="12" className="p-0">
              <BannerContainer>
                <WelcomeMessage />
                <MetricsOverview />
              </BannerContainer>
            </Col>
          </Row>
          <Row className="px-5">
            <Col xs="12" xl={packages.length === 0 ? 12 : 8} className="mb-5">
              {/* <h5>Schedule</h5>
              <hr /> */}
              {/* <OrdersSchedule /> */}
              <DashboardOrders />
            </Col>
            <Col xs="12" xl="4" className={`${packages.length === 0 ? 'd-none' : ''}`}>
              <PackageOffers packages={packages} />
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default Dashboard;

