import React from 'react'
import PropTypes from 'prop-types'
import StatisticsCard from './StatisticsCard.jsx'
import styled from 'styled-components'
import devices from '../../../lib/devices.js'


const MetricsContainer = styled.div`
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: repeat(6, 1fr);

    @media screen and (max-width: ${devices.tablet}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: ${devices.mobileL}) {
        grid-template-columns: 1fr;
    }

    justify-content: space-between;
`

const OrganisationCampaignStatistics = ({ metrics = [], }) =>
    <MetricsContainer>
        {metrics
            .map(statistic => {
                if (statistic.value !== null) {
                    return <StatisticsCard
                        key={statistic.id}
                        header={statistic.label}
                        body={statistic.value}
                        unit={statistic.unit}
                    />
                }
                return ''
            })}
    </MetricsContainer>

OrganisationCampaignStatistics.propTypes = {
    statistics: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    visible: PropTypes.arrayOf(PropTypes.string),
}

export default OrganisationCampaignStatistics
