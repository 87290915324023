import React, { useEffect, useState } from 'react'
import { Button, Table, Form, InputGroup, Spinner, OverlayTrigger, Popover, Row, Col, Accordion, Card, useAccordionToggle, CarouselItem } from 'react-bootstrap'
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { selectSession, selectUser } from "../../session/sessionSlice";
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEdit, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { selectSettings } from '../organisationSettingsSlice';
import styles from './adsettings.module.scss';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import { CHANNELS_MANAGEMENT_FACEBOOK, CHANNELS_MANAGEMENT_GOOGLE } from '../../../lib/permissions';
import { fetchBespokeProducts, selectAllBespokeProducts } from '../../bespoke/bespokeSlice';
import { PLATFORM_NATIVE } from '../../../lib/platforms';

const StyledFbIcon = styled(FontAwesomeIcon)`
  color: #5995ED;
`;
const StyledYtIcon = styled(FontAwesomeIcon)`
  color: #FF0000;
`;
const StyledGoIcon = styled(FontAwesomeIcon)`
  color: #4285F4;
`;
const StyledEditIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
`
const StyledPlatformTitle = styled.h5`
  color: #000;
  padding-top: 10px;
`;

const validationRules = {
  margin: {
    required: "Margin is required",
    min: {
      value: 0,
      message: 'Margin can\'t be least than 0',
    },
    max: {
      value: 100,
      message: 'Margin can\'t be more than 100 %',
    },
  },
  min_budget: {
    required: false,
    min: {
      value: 1,
      message: 'Minimum budget can\'t be least than $1.00',
    },
  },
};

const changedFieldStyle = {
  border: '2px solid #0095ff'
};

const tdInputStyle = {
  minWidth: '250px',
  maxWidth: '250px',
};


const AdSettings = ({
  updateAllSettings,
  loading,
  adTypes = [],
  adSettings,
  isSupplierSettingsPage = false,
  onMarginChange = () => { },
  onMinBudgetChange = () => { },
  onAdTypeStatusChange = () => { },
}) => {
  const [panelIndex, setPanelIndex] = useState()
  const session = useSelector(selectSession);
  const supplierSettings = useSelector(selectSettings);
  const { control, clearErrors, setValue, register } = useFormContext();
  const { touchedFields, isSubmitted, errors } = useFormState();
  const organisationSettings = session.user.active_organisation.settings?.ad_settings;
  const organisation_id = session.user.active_organisation.id;
  const dispatch = useDispatch()
  const bespokeProducts = useSelector(selectAllBespokeProducts);
  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey)
    })

    const arrow =
      eventKey === panelIndex ? (
        <FontAwesomeIcon icon={faAngleUp} fixedWidth />
      ) : (
        <FontAwesomeIcon icon={faAngleDown} fixedWidth />
      )

    return (
      <Card.Header
        className='bg-white'
        onClick={customOnClick}
      >
        {children} <span className='float-right'>{arrow}</span>
      </Card.Header>
    )
  }

  useEffect(() => {
    adTypes.forEach(adType => {
      if(adType.platform !== PLATFORM_NATIVE){
        register(`${adType.control_id}.margin`);
        register(`${adType.control_id}.min_budget`);

        setValue(`${adType.control_id}.margin`, adSettings?.margin?.[adType.control_id]);
        setValue(`${adType.control_id}.min_budget`, adSettings?.min_budget?.[adType.control_id] || "");
      }
      register(`${adType.control_id}.enabled`);
      if (isSupplierSettingsPage) {
        setValue(`${adType.control_id}.enabled`, adSettings?.enabled_ads?.[adType.control_id]);
      } else {
        setValue(`${adType.control_id}.enabled`, !!adSettings?.enabled?.[adType.control_id]);
      }
    });
  }, [])

  useEffect(() => {
    dispatch(fetchBespokeProducts())
  }, [dispatch])

  const user = useSelector(selectUser)
  const userPermissions = user?.role?.permissions ? user?.role?.permissions?.map(p => p.name) : [];
  const canManageChannelFacebook = userPermissions.includes(CHANNELS_MANAGEMENT_FACEBOOK);
  const canManageChannelGoogle = userPermissions.includes(CHANNELS_MANAGEMENT_GOOGLE);

  function platformIcon( platform ) {
    switch (platform) {
      case 'facebook':
        return (<StyledFbIcon icon={faFacebook}/>);
      case 'youtube':
        return (<StyledYtIcon icon={faYoutube}/>);
      case 'google':
        return (<StyledGoIcon icon={faGoogle}/>);
      case PLATFORM_NATIVE:
        return (<FontAwesomeIcon icon={faWindowMaximize} />);
      default:
        return '';
    }
  }

  return (
    <>
      <Row>
        <Col md='12' className='mb-2'>
          <Accordion>
            <Card className={styles.card_border}>
              <CustomToggle eventKey='extension-channels' >
                Extension Channels
              </CustomToggle>
              <Accordion.Collapse eventKey='extension-channels'>
                <Card.Body className={styles.card_padding}>
                  <Row>
                      {adTypes.filter(a => a.platform !== PLATFORM_NATIVE && a.platform !== 'bespoke_product').filter(a => (a.platform !== 'facebook' || (a.platform === 'facebook' && canManageChannelFacebook))).filter(a => (a.platform !== 'google' || (a.platform === 'google' && canManageChannelGoogle))).map(row => {
                    const { control_id, label, platform } = row;
                    return (
                      <Col md="6">
                        <StyledPlatformTitle>{platformIcon(platform)} {label}</StyledPlatformTitle>
                        <div className={styles.tablestyle}>
                          <Table responsive >
                            <tbody>
                              <tr key={control_id}>
                                <td>Enabled</td>
                                <td>{
                                  !isSupplierSettingsPage ?
                                    <Controller
                                      name={`${control_id}.enabled`}
                                      control={control}
                                      render={({ field, formState }) => (
                                        <Form.Check
                                          className="text-left"
                                          type="checkbox"
                                          checked={field.value}
                                          {...field}
                                          onChange={(e) => {
                                            field.onChange(e.target.checked);
                                            if (isSupplierSettingsPage) {
                                              onAdTypeStatusChange(control_id, e.target.checked);
                                            }
                                          }
                                          }
                                        />
                                      )}
                                    /> :
                                    <Controller
                                      name={`${control_id}.enabled`}
                                      control={control}
                                      render={({ field, formState }) => (
                                        <Form.Control
                                          as="select"
                                          value={field.value}
                                          style={isSupplierSettingsPage && field.value && field.value !== 'default' ? changedFieldStyle : null}
                                          onChange={e => {
                                            field.onChange(e.target.value);
                                            if (isSupplierSettingsPage) {
                                              onAdTypeStatusChange(control_id, e.target.value);
                                            }
                                          }}
                                        >
                                          {['default', 'yes', 'no'].map(option => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      )}
                                    />
                                }</td>
                              </tr>
                              <tr>
                                <td>Margin</td>
                                <td><InputGroup>
                                  <InputGroup.Text>%</InputGroup.Text>
                                  <Controller
                                    name={`${control_id}.margin`}
                                    control={control}
                                    rules={{
                                      ...validationRules.margin,
                                      required: false,
                                    }}
                                    render={({ field, fieldState: { invalid } }) => (
                                      <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        max="100"
                                        aria-label="Margin"
                                        {...field}
                                        placeholder={isSupplierSettingsPage ? organisationSettings?.margin?.[control_id] : ''}
                                        style={isSupplierSettingsPage && field.value ? changedFieldStyle : null}
                                        isInvalid={
                                          (isSubmitted || touchedFields[control_id]?.margin) &&
                                          errors[control_id]?.margin?.message
                                        }
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (value !== "") {
                                            value = Number(value);
                                          } else {
                                            value = "";
                                          }

                                          field.onChange(parseFloat(value));
                                          if (isSupplierSettingsPage) {
                                            onMarginChange(control_id, parseFloat(value));
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                  {(isSubmitted || touchedFields[control_id]?.margin) && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors[control_id]?.margin?.message}
                                    </Form.Control.Feedback>
                                  )}

                                </InputGroup></td>
                              </tr>
                              <tr>
                                <td>Min. Lifetime Budget
                                </td>
                                <td><InputGroup>
                                  <InputGroup.Text>$</InputGroup.Text>
                                  <Controller
                                    name={`${control_id}.min_budget`}
                                    rules={validationRules.min_budget}
                                    control={control}
                                    render={({
                                      field,
                                      fieldState,
                                    }) => (
                                      <Form.Control
                                        type="number"
                                        step="0.01"
                                        min="1"
                                        aria-label="Min budget"
                                        placeholder={isSupplierSettingsPage ? organisationSettings?.min_budget?.[control_id] : ''}
                                        ref={field.ref}
                                        {...field}
                                        style={isSupplierSettingsPage && field.value ? changedFieldStyle : null}
                                        isInvalid={
                                          (isSubmitted || touchedFields[control_id]?.min_budget) &&
                                          errors[control_id]?.min_budget?.message
                                        }
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          if (value !== "") {
                                            value = Number(value);
                                          } else {
                                            value = "";
                                          }

                                          field.onChange(parseFloat(value));
                                          if (isSupplierSettingsPage) {
                                            onMinBudgetChange(control_id, parseFloat(value));
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                  {(isSubmitted || touchedFields?.[control_id]?.min_budget) && (
                                    <Form.Control.Feedback type="invalid">
                                      {errors[control_id]?.min_budget?.message}
                                    </Form.Control.Feedback>
                                  )}
                                </InputGroup></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    )
                  })
                  }
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        <Col md='12' className='mb-2'>
          <Accordion>
            <Card className={styles.card_border}>
              <CustomToggle eventKey='owned-channels' >
                Owned Channels
              </CustomToggle>
              <Accordion.Collapse eventKey='owned-channels'>
                <Card.Body className={styles.card_padding}>
                  <Row>
                    {adTypes.filter(a => a.platform === PLATFORM_NATIVE).map(row => {
                      const { control_id, label, platform } = row;

                      return (
                        <Col md="6">
                          <StyledPlatformTitle>{platformIcon(platform)} {label}</StyledPlatformTitle>
                          <div className={styles.tablestyle}>
                            <Table responsive>
                              <tbody>
                                <tr key={control_id}>
                                  <td>Enabled</td>
                                  <td>{
                                    !isSupplierSettingsPage ?
                                      <Controller
                                        name={`${control_id}.enabled`}
                                        control={control}
                                        render={({ field, formState }) => (
                                          <Form.Check
                                            className="text-left"
                                            type="checkbox"
                                            checked={field.value}
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e.target.checked);
                                              if (isSupplierSettingsPage) {
                                                onAdTypeStatusChange(control_id, e.target.checked);
                                              }
                                            }
                                            }
                                          />
                                        )}
                                      /> :
                                      <Controller
                                        name={`${control_id}.enabled`}
                                        control={control}
                                        render={({ field, formState }) => (
                                          <Form.Control
                                            as="select"
                                            value={field.value}
                                            style={isSupplierSettingsPage && field.value && field.value !== 'default' ? changedFieldStyle : null}
                                            onChange={e => {
                                              field.onChange(e.target.value);
                                              if (isSupplierSettingsPage) {
                                                onAdTypeStatusChange(control_id, e.target.value);
                                              }
                                            }}
                                          >
                                            {['default', 'yes', 'no'].map(option => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        )}
                                      />
                                  }</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
        {adTypes.filter(a => a.platform === "bespoke_product") && <Col md='12' className='mb-2'>
          <Accordion>
            <Card className={styles.card_border}>
              <CustomToggle eventKey='extension-channels' >
                Bespoke Products
              </CustomToggle>
              <Accordion.Collapse eventKey='extension-channels'>
                <Card.Body className={styles.card_padding}>
                  <Row>
                    {bespokeProducts.filter(a => a.organisation_id === organisation_id).map(row => {
                      const { control_id, label, platform } = row;
                      return (
                        <Col md="6">
                          <StyledPlatformTitle>{platformIcon(platform)} {label}</StyledPlatformTitle>
                          <div className={styles.tablestyle}>
                            <Table responsive >
                              <tbody>
                                <tr key={control_id}>
                                  <td>Enabled</td>
                                  <td>{
                                    !isSupplierSettingsPage ?
                                      <Controller
                                        name={`${control_id}.enabled`}
                                        control={control}
                                        render={({ field, formState }) => (
                                          <Form.Check
                                            className="text-left"
                                            type="checkbox"
                                            checked={field.value}
                                            {...field}
                                            onChange={(e) => {
                                              field.onChange(e.target.checked);
                                              if (isSupplierSettingsPage) {
                                                onAdTypeStatusChange(control_id, e.target.checked);
                                              }
                                            }
                                            }
                                          />
                                        )}
                                      /> :
                                      <Controller
                                        name={`${control_id}.enabled`}
                                        control={control}
                                        render={({ field, formState }) => (
                                          <Form.Control
                                            as="select"
                                            value={field.value}
                                            style={isSupplierSettingsPage && field.value && field.value !== 'default' ? changedFieldStyle : null}
                                            onChange={e => {
                                              field.onChange(e.target.value);
                                              if (isSupplierSettingsPage) {
                                                onAdTypeStatusChange(control_id, e.target.value);
                                              }
                                            }}
                                          >
                                            {['default', 'yes', 'no'].map(option => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </Form.Control>
                                        )}
                                      />
                                  }</td>
                                </tr>
                                <tr>
                                  <td>Margin</td>
                                  <td><InputGroup>
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Controller
                                      name={`${control_id}.margin`}
                                      control={control}
                                      rules={{
                                        ...validationRules.margin,
                                        required: false,
                                      }}
                                      render={({ field, fieldState: { invalid } }) => (
                                        <Form.Control
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          max="100"
                                          aria-label="Margin"
                                          {...field}
                                          placeholder={isSupplierSettingsPage ? organisationSettings?.margin?.[control_id] : ''}
                                          style={isSupplierSettingsPage && field.value ? changedFieldStyle : null}
                                          isInvalid={
                                            (isSubmitted || touchedFields[control_id]?.margin) &&
                                            errors[control_id]?.margin?.message
                                          }
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (value !== "") {
                                              value = Number(value);
                                            } else {
                                              value = "";
                                            }

                                            field.onChange(parseFloat(value));
                                            if (isSupplierSettingsPage) {
                                              onMarginChange(control_id, parseFloat(value));
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                    {(isSubmitted || touchedFields[control_id]?.margin) && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors[control_id]?.margin?.message}
                                      </Form.Control.Feedback>
                                    )}

                                  </InputGroup></td>
                                </tr>
                                <tr>
                                  <td>Min. Lifetime Budget
                                  </td>
                                  <td><InputGroup>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Controller
                                      name={`${control_id}.min_budget`}
                                      rules={validationRules.min_budget}
                                      control={control}
                                      render={({
                                        field,
                                        fieldState,
                                      }) => (
                                        <Form.Control
                                          type="number"
                                          step="0.01"
                                          min="1"
                                          aria-label="Min budget"
                                          placeholder={isSupplierSettingsPage ? organisationSettings?.min_budget?.[control_id] : ''}
                                          ref={field.ref}
                                          {...field}
                                          style={isSupplierSettingsPage && field.value ? changedFieldStyle : null}
                                          isInvalid={
                                            (isSubmitted || touchedFields[control_id]?.min_budget) &&
                                            errors[control_id]?.min_budget?.message
                                          }
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (value !== "") {
                                              value = Number(value);
                                            } else {
                                              value = "";
                                            }

                                            field.onChange(parseFloat(value));
                                            if (isSupplierSettingsPage) {
                                              onMinBudgetChange(control_id, parseFloat(value));
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                    {(isSubmitted || touchedFields?.[control_id]?.min_budget) && (
                                      <Form.Control.Feedback type="invalid">
                                        {errors[control_id]?.min_budget?.message}
                                      </Form.Control.Feedback>
                                    )}
                                  </InputGroup></td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )
                    })
                    }
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>}
      </Row>
      <div>&nbsp;</div>
    </>
  );
}

export default AdSettings;
