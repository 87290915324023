import React from 'react'
import PropTypes from 'prop-types'
import styles from './feed_view.module.scss'
import { Form } from 'react-bootstrap'
import Errors from '../Errors'

const ProductSquare = ({ src, alt }) => (
    <div className={`mx-auto rounded ${styles.container}`}>
        {src ? <img
            className={`w-100 h-100 rounded ${styles.product_square}`}
            alt={alt}
            src={src}
        /> : <div className={`p-1 w-100 h-100 rounded ${styles.text_square}`}>
            {alt}
        </div>}
    </div>
)

const FeedView = ({ header, readOnly = false, errors = [], required = false, group = {}, products = [] }) => (
    <Form.Group>
        <Form.Label>
            {header} {required && '*'}
        </Form.Label>
        <div>
            <h6>{group.name}</h6>
            {products.length === 0 ?
                <div className="text-center" style={{ lineHeight: '4rem' }}>
          No {group.platform} products selected.
                </div>
                :
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem'}}>
                    {products.map(product =>
                        <ProductSquare src={product.image_url} alt={product.name} />
                    )}
                </div>
            }
        </div>
        <Form.Control.Feedback type="invalid" className={`${errors.length > 0 ? 'd-block' : ''}`}>
          <Errors errors={errors} />
        </Form.Control.Feedback>
    </Form.Group>
)


FeedView.propTypes = {
    header: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    group: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    products: PropTypes.arrayOf(PropTypes.shape({
        image_url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })),
}

export default FeedView
