import {useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import Chrome from '../app/layout/Chrome'
import {fetchBespokeProducts} from '../features/bespoke/bespokeSlice'
import {selectOrderById} from '../features/orders/ordersSlice'
import ConnectedOrderFormChrome from '../features/order_form/ConnectedOrderFormChrome'
import OrderAdForm from '../features/order_form/containers/OrderAdForm'
import {
    availableAdFormats,
    availableMercatoDevices,
    availableWebSpaces,
    clearOrder,
    orderPackages,
    orderUpdated,
    updateOrderFormOrganisations
} from '../features/order_form/orderFormSlice'

const ViewOrder = () => {
    const dispatch = useDispatch()
    const { order_id } = useParams()
    const order = useSelector(state => selectOrderById(state, order_id))

    useEffect(() => {
        dispatch(clearOrder())
        dispatch(orderUpdated(order))
        dispatch(updateOrderFormOrganisations())
        dispatch(availableAdFormats())
        dispatch(availableMercatoDevices())
        dispatch(availableWebSpaces())
        dispatch(orderPackages())
        dispatch(fetchBespokeProducts())
    }, [dispatch, order?.owner_id, order?.supplier_id])

    return (
        <>
            <Helmet>
                <title>
                    View Order - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container fluid className="pt-4">
                    <Row>
                        <Col>
                            <ConnectedOrderFormChrome>
                                <OrderAdForm readOnly />
                            </ConnectedOrderFormChrome>
                        </Col>
                    </Row>
                </Container>
            </Chrome>
        </>
    )
}

export default ViewOrder
