import { React, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import propTypes from "prop-types";
import { DevTool } from "@hookform/devtools";
import { submitWalletTransaction } from "../../lib/api/wallets";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchWalletsForSupplier } from "../session/sessionSlice";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import {
  faWallet,
  faMinusSquare,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";

import {
  formatWalletNumber,
  getCurrencySymbol,
  TRANSACTIONS_TYPE_CREDIT,
  TRANSACTIONS_TYPE_DEBIT,
} from "../../lib/wallets";
import CurrencyBadge from "./CurrencyBadge";
import PopoverHint from "./components/form/PopoverHint";

const StyledWalletName = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: 12px;
  width: 20px;
  height: 20px;
`;

const StyledRemarkWarning = styled(Alert)`
  background-color: #e7f9f9;
  padding: 0.75rem;
  margin-top: 1rem;
`;

const StyledModalHeader = styled(Modal.Header)`
  background-color: #f8f8f8;
  border: none;
`;

const StyledModalBody = styled(Modal.Body)`
  padding: 2.5rem;
  padding-bottom: 1rem;
`;

const StyledBtn = styled(Button)`
  width: 130px;
`;

const StyledCurrencyBadge = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  margin-bottom: 1rem;
`;

const AddCreditDebitNoteDialog = ({
  wallet,
  type,
  onHideHandler = () => {},
  onSubmitHandler = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    type,
    amount: "",
    remark: "",
    wallet_balance: wallet?.balance,
  };

  const { handleSubmit, control, formState, setError, reset } = useForm({
    defaultValues,
  });

  const { errors, isSubmitted, touchedFields, dirtyFields } = formState;

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const res = await submitWalletTransaction(wallet?.id, data);
      reset(defaultValues);
      toast.success(res?.data?.message ?? `${type} note created`);
      onSubmitHandler();
    } catch (e) {
      const errors = e.response?.data?.errors;
      if (e.response?.status !== 422) {
        toast.error("Something went wrong, please try again");
      } else {
        const keys = Object.keys(errors);
        keys.forEach((errorKey) => {
          if (
            errorKey === "wallet_balance" &&
            errors[errorKey][0]?.length > 0
          ) {
            toast.error(errors[errorKey][0]);
          } else {
            setError(errorKey, {
              type: "manual",
              message: errors[errorKey][0],
            });
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === "true" && (
        <DevTool control={control} />
      )}

      <Modal
        size="md"
        show={true}
        enforceFocus={false}
        onHide={onHideHandler}
        backdrop="static"
      >
        <StyledModalHeader closeButton className="d-flex align-items-center">
          {type === "credit" ? (
            <>
              <FontAwesomeIcon
                icon={faPlusSquare}
                size="md"
                className="mr-2"
                color="#4FAD80"
              />
              <span>Add Credit Note</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faMinusSquare}
                size="md"
                className="mr-2"
                color="#EF767A"
              />
              <span>Add Debit Note</span>
            </>
          )}
        </StyledModalHeader>
        <StyledModalBody>
          <div>
            <div class="d-flex align-items-center mb-3">
              <FontAwesomeIcon
                icon={faWallet}
                size="lg"
                color="#000"
                className="mr-2"
              />
              <StyledWalletName>{wallet.name}</StyledWalletName>
            </div>

            <div className="mb-4">
              <p>
                Available Balance:{" "}
                {formatWalletNumber(wallet.available_balance, wallet.currency)}
              </p>
            </div>
          </div>
          <Form>
            <Row>
              <Col>
                <Form.Group className="position-relative">
                  <StyledCurrencyBadge>
                    <CurrencyBadge currency={wallet?.currency} />
                  </StyledCurrencyBadge>
                  <Form.Label>Amount *</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      {getCurrencySymbol(wallet?.currency)}
                    </InputGroup.Text>
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          aria-label="Amount"
                          isInvalid={
                            (isSubmitted || touchedFields.amount) &&
                            errors.amount?.message
                          }
                          {...field}
                        />
                      )}
                    />
                    {(isSubmitted || touchedFields.amount) && (
                      <Form.Control.Feedback type="invalid">
                        {errors.amount?.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <span>Description *</span>
                    <PopoverHint popoverText="Description will be visible to the supplier" />
                  </Form.Label>
                  <Controller
                    name="remark"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        isInvalid={
                          (isSubmitted || touchedFields.remark) &&
                          errors.remark?.message
                        }
                        {...field}
                      />
                    )}
                  />
                  {(isSubmitted || touchedFields.remark) && (
                    <Form.Control.Feedback type="invalid">
                      {errors.remark?.message}
                    </Form.Control.Feedback>
                  )}

                  {
                    <StyledRemarkWarning>
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        size="md"
                        color="#000"
                        className="mr-2"
                      />
                      <span>Once added, this transaction cannot be deleted</span>
                    </StyledRemarkWarning>
                  }
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="d-flex justify-content-end">
              <StyledBtn
                type="submit"
                disabled={loading || !!Object.keys(errors).length}
                onClick={handleSubmit(onSubmit)}
              >
                Add
                {loading && (
                  <StyledSpinner animation="border" variant="light" size="md" />
                )}
              </StyledBtn>
            </Form.Group>
          </Form>
        </StyledModalBody>
      </Modal>
    </>
  );
};

AddCreditDebitNoteDialog.propTypes = {
  wallet: propTypes.shape({
    id: propTypes.isRequired,
    name: propTypes.string.isRequired,
    currency: propTypes.string.isRequired,
    balance: propTypes.number.isRequired,
  }),
  type: propTypes.oneOf([TRANSACTIONS_TYPE_DEBIT, TRANSACTIONS_TYPE_CREDIT]),
  onHideHandler: propTypes.func.isRequired,
  onSubmitHandler: propTypes.func.isRequired,
};

export default AddCreditDebitNoteDialog;
