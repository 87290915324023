import {
  faAngleDown, faSync
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { getSupplierWallets, getUserWallets } from "../../../lib/api/wallets";
import {useRequest} from "../../../lib/hooks/useRequest";
import { formatWalletNumber } from "../../../lib/wallets";
import { selectUser } from "../../session/sessionSlice";
import DataTable from "../../wallets/components/tables/DataTable";
import QueryResult from "../../wallets/QueryResult";
import {
  orderFieldUpdated,
  selectErrors,
  selectOrder
} from "../orderFormSlice";

const StyledSelection = styled.div`
  ${({ selected }) =>
    selected &&
    `
    border-bottom: 2px solid #7155FF;
  `}
  cursor: pointer;
  color: #7155FF;
  font-size: 1rem;
  ${({ hasError }) =>
    hasError &&
    `
    color: #EF767A;
    border-bottom: 2px solid #EF767A;
  `}
`;

const StyledSelectBtn = styled(Button)`
  width: 150px;
`;

const StyledHeading = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

const StyledRefreshIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const StyledWalletsWrapper = styled.div`
  border: 1px solid #777;
  border-radius: 3px;
  width: 100%;
  border-color: #b9b9c0;
  background-color: #fff;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;

  table tbody td {
    cursor: pointer;
    padding: 0.5rem;
  }

  .table thead th:not(:nth-of-type(2)),
  .table tbody td:not(:nth-of-type(2)) {
    max-width: 115px;
    text-align: right;
  }

  .table thead th {
    padding: 0.5rem;
  }
`;

const StyledPopover = styled(Popover)`
  min-width: 300px;
  max-width: 500px;
`;

const StyledSelectionArrow = styled(FontAwesomeIcon)`
  ${({ hasError }) => hasError && "color: #EF767A"}
`;

const StyledWalletErrorText = styled.p`
  color: #ef767a;
`;

const StyledRefreshText = styled.span`
  span {
    color: #5995ed;
    cursor: pointer;
  }
`;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    max-width: 400px;
    text-align: left;
  }
`;

const StyledSelectionRequiredText = styled.span`
  ${({ hasError }) => hasError && "color: #EF767A"}
`;

const StyledWalletRow = styled.div`
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? "#F3F0FF" : "transparent")};
`;

const OrderWallets = ({ readOnly = false }) => {
  const dispatch = useDispatch();
  const order = useSelector(selectOrder);
  const user = useSelector(selectUser);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(order?.wallet);
  const errors = useSelector(selectErrors);
  const [walletErrors, setWalletErrors] = useState(errors?.wallet ?? []);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const isOwner = order.owner_id === user.active_organisation_id;

  const overlayRef = useRef(null);

  const { response, loading, error, refresh } = useRequest(() =>
    isOwner
      ? getSupplierWallets(order?.supplier_id)
      : getUserWallets(order?.owner_id)
  );

  const handleSelectBtnClick = useCallback(() => {
    dispatch(
      orderFieldUpdated({
        field: "wallet",
        value: {
          id: selectedWallet?.id,
          available_balance: selectedWallet?.available_balance,
          balance: parseFloat(selectedWallet?.balance),
          allow_overdraw: selectedWallet?.allow_overdraw,
        },
      })
    );
    setWalletErrors([]);
    setOverlayOpen(false);
  }, [selectedWallet]);

  const filteredWallets = useMemo(
    () =>
      readOnly
        ? wallets.filter((wallet) => !wallet.is_disable && wallet.is_active)
        : wallets,
    [wallets, order?.status, readOnly]
  );

  const orderWallet = useMemo(
    () => filteredWallets.find((wallet) => wallet.id === order?.wallet?.id),
    [order, filteredWallets]
  );

  useEffect(() => {
    if (errors?.wallet?.length > 0) {
      toast.error(errors?.wallet[0]);
      setWalletErrors(errors?.wallet);
    }
  }, [errors]);

  useEffect(() => {
    if (response !== null) {
      setWallets(response?.data);
    }
  }, [response]);

  const rowEvents = {
    onClick: (e, row, rowIndex) => !readOnly && setSelectedWallet(row),
  };

  const columns = [
    {
      dataField: "action",
      text: "",
      formatExtraData: {
        selectedWallet,
      },
      formatter: (cell, row, rowIndex, { selectedWallet }) => {
        const wallet = row;
        return (
          <input
            type="radio"
            name="wallet_id"
            checked={selectedWallet?.id === row.id}
          />
        );
      },
    },
    {
      dataField: "name",
      text: "Wallet",
      formatter: (cell, row) => {
        const wallet = row;
        return (
          <div className="d-flex flex-column flex-grow">
            <div className="d-flex align-items-center">
              <p className="m-0 mr-2">{wallet.name}</p>
            </div>
            {wallet?.remark.length > 0 && <small>{wallet.remark}</small>}
          </div>
        );
      },
    },
    {
      dataField: "available_balance",
      text: "Available Balance",
      formatter: (cell, row) => (
        <span>
          {row.allow_overdraw
            ? "∞"
            : formatWalletNumber(row?.available_balance, row?.currency)}
        </span>
      ),
    },
    {
      dataField: "balance",
      text: "Net Position",
      formatter: (cell, row) => (
        <span>{formatWalletNumber(row?.balance, row?.currency)}</span>
      ),
    },
  ];

  return (
    <OverlayTrigger
      trigger="toggle"
      placement="bottom"
      target={overlayRef.current}
      show={overlayOpen}
      ref={overlayRef}
      rootClose
      onEnter={() => refresh()}
      onToggle={() => setOverlayOpen(false)}
      overlay={
        <StyledPopover>
          <Popover.Content>
            <QueryResult loading={loading} error={error}>
              {!filteredWallets?.length ? (
                <>
                  <StyledHeading>
                    Select Wallet
                    <StyledRefreshIcon
                      className="ml-2"
                      icon={faSync}
                      size="1x"
                      onClick={refresh}
                    />
                  </StyledHeading>
                  <p className="mb-2">
                    Sorry, there is no available wallet assigned to you by the
                    retailer
                  </p>
                </>
              ) : (
                <>
                  {walletErrors?.length > 0 && (
                    <StyledWalletErrorText>
                      {walletErrors[0]}
                    </StyledWalletErrorText>
                  )}
                  <StyledHeading>
                    Select Wallet
                    <StyledRefreshIcon
                      className="ml-2"
                      icon={faSync}
                      size="1x"
                      onClick={refresh}
                    />
                  </StyledHeading>

                  <StyledWalletsWrapper>
                    <DataTable
                      data={filteredWallets}
                      columns={columns}
                      rowEvents={rowEvents}
                    />
                  </StyledWalletsWrapper>

                  <div className="d-flex justify-content-end mt-3 mb-2">
                    <StyledSelectBtn
                      onClick={handleSelectBtnClick}
                      disabled={readOnly}
                    >
                      Select
                    </StyledSelectBtn>
                  </div>
                </>
              )}
            </QueryResult>
          </Popover.Content>
        </StyledPopover>
      }
    >
      <div className="flex row">
        <span>Wallet: </span>
        <StyledSelection
          hasError={walletErrors.length > 0}
          className="ml-1"
          selected={!!orderWallet}
          ref={overlayRef}
          onClick={() => readOnly || setOverlayOpen(!overlayOpen)}
        >
          {orderWallet ? (
            <span>
              {orderWallet.name} (
              {orderWallet.allow_overdraw
                ? "∞"
                : formatWalletNumber(
                  orderWallet?.available_balance,
                  orderWallet?.currency
                )}
              )
            </span>
          ) : (
            <StyledSelectionRequiredText hasError={walletErrors.length > 0}>
              Selection required
            </StyledSelectionRequiredText>
          )}
          <StyledSelectionArrow
            icon={faAngleDown}
            size="1x"
            className="ml-1"
            hasError={walletErrors.length > 0}
          />
        </StyledSelection>
      </div>
    </OverlayTrigger>
  );
};

OrderWallets.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default OrderWallets;
