import Dropdown from "../../controls/Dropdown";
import Search from "../../controls/Search";
import ControlsContainer from "../../controls/ControlsContainer";
import React, {useEffect, useState} from "react";
import {Product, ProductFeed, ProductSet, RequestStatus} from "../../types";
import {getProducts} from "../../../../lib/api";
import ProductSelectorBody from "./ProductSelectorBody";
import {NoProductSets} from "./OtherStates";
import ProductRectangle from "../ProductRectangle";
import {useInfiniteScroll} from "../../../../lib/hooks/useInfiniteScroll";
import {DEFAULT_DEBOUNCE_TIME} from "../../../../lib/defaults";
import useDebouncedRequest from "../../../../lib/hooks/useDebouncedRequest";


type ProductSelectorProps = SelectorProps & {
    value?: ProductFeed,
};

const ProductSelector = (props: ProductSelectorProps) => {
    const {
        productSets = [],
        value,
        onChange,
    } = props;


    if (productSets.length === 0) {
        return <NoProductSets/>;
    }

    if (!value) {
        onChange({
            ...value,
            group: productSets[0],
            products: [],
            deleted_products: [],
        });
        return <></>;
    }

    return <Selector {...props} />
};

export default ProductSelector;


type SelectorProps = {
    productSets: ProductSet[],
    value: ProductFeed,
    onChange: (ProductFeed) => void,
    errors?: string[],
    readOnly?: boolean,
    disabled?: boolean,
};

const Selector = ({
    productSets = [],
    value,
    onChange,
    readOnly,
    errors = [],
}: SelectorProps) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [filter, setFilter] = useState<string>('');
    const [status, setStatus] = useState<RequestStatus>('idle');
    const [count, setCount] = useState<number>(0);
    const [after, setAfter] = useState<object | undefined>(undefined);
    const limit = 25;

    const options = productSets.map(productSet => ({value: productSet.id, label: productSet.name}));
    const isSelected = (product: Product): boolean => value.products.some(p => p.id === product.id);

    const resetProducts = () => {
        setProducts([]);
        setAfter(undefined);
        setCount(0);
    };
    const handleFilterChange = e => setFilter(e.target.value);
    const handleProductSetChange = e => {
        onChange({
            group: productSets.find(set => set.id === e.target.value),
            products: [],
            deleted_products: [],
        });
        resetProducts();
    }
    const handleProductSelected = (product: Product) => {
        // Remove product
        if (isSelected(product)) {
            onChange({
                ...value,
                products: value.products.filter(p => p.id !== product.id),
            })
            return
        }

        // Add Product
        onChange({
            ...value,
            products: [...value.products, product],
        })
    }

    const debouncedProducts = useDebouncedRequest((groupId, filter) => {
        setStatus('loading');
        resetProducts();
        getProducts(groupId, filter, undefined, limit)
            .then(resp => {
                setProducts(resp.data.data);
                setAfter(resp.data.after)
                setCount(resp.data.count)
                setStatus('succeeded');
            })
            .catch(() => setStatus('failed'));
    }, DEFAULT_DEBOUNCE_TIME);

    useEffect(() => {
        if (status !== 'idle') {
            debouncedProducts(value.group.id, filter)
        }
    }, [filter]);

    // Filter search
    useEffect(() => {
        setStatus('loading');

        getProducts(value.group.id, filter, after, limit)
            .then(resp => {
                setProducts(resp.data.data);
                setAfter(resp.data.after)
                setCount(resp.data.count)
                setStatus('succeeded');
            })
            .catch(() => setStatus('failed'));
    }, [value.group.id]);


    const ref = useInfiniteScroll(() => {
        if (products.length < count && status !== 'loading') {
            setStatus('loading');
            getProducts(value.group.id, filter, after, 5)
                .then(resp => {
                    setProducts([...products, ...resp.data.data]);
                    setAfter(resp.data.after)
                    setCount(resp.data.count)
                    setStatus('succeeded');
                })
                .catch(() => {
                });
        }
    });

    return <div>
        <ControlsContainer>
            <Dropdown
                label="Produt Set"
                width="15rem"
                value={value.group?.id || ''}
                options={options}
                onChange={handleProductSetChange}
            />
            <Search
                value={filter}
                onChange={handleFilterChange}
            />
        </ControlsContainer>
        <ProductSelectorBody ref={ref} status={status} productsCount={products.length}>
            {products.map(product => (
                <ProductRectangle
                    key={product.id}
                    src={product.image_url}
                    name={product.title}
                    price={product.price}
                    currency={product.currency}
                    checked={isSelected(product)}
                    onClick={() => handleProductSelected(product)}
                />
            ))}
        </ProductSelectorBody>
    </div>
};

