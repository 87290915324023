import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { createPackage as createPackageRequest, createPackageGroup as createPackageGroupRequest, listPackages, showPackage, updatePackage as updatePackageRequest } from '../../lib/api';
import { refreshSession } from '../session/sessionSlice';

const initialState = {
    package: {
        name: '',
        description: '',
        image: '',
        outcomes: '',
        package_group_id: undefined,
        stock_availability: undefined,
        min_budget: 0,
        max_budget: undefined,
        organisation_whitelist: [],
        ad_format_templates: [],
    },
    available_package_groups: [],
    status: 'idle',
    request_type: 'none',
    read_only: true,
    errors: {},
};


export const upsertPackage = createAsyncThunk('package_form/upsertPackage', async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
        let response;
        if (selectPackage(getState()).id) {
            response = await updatePackageRequest(selectPackage(getState()).id, selectPackage(getState()))
        } else {
            response = await createPackageRequest(selectPackage(getState()))
        }

        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }

        throw rejectWithValue(err.response.data)
    }
})

export const fetchPackage = createAsyncThunk('package_form/fetchPackage', async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
        const response = await showPackage(arg)

        dispatch(updatePackage(response.data.data))
        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        dispatch(updateErrors(err.response.data.errors))

        throw rejectWithValue(err.response.data)
    }
})

export const fetchPackageGroups = createAsyncThunk('package_form/fetchPackageGroups', async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
        const response = await listPackages()

        dispatch(updateAvailablePackageGroups(response.data.data))
        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        dispatch(updateErrors(err.response.data.errors))

        throw rejectWithValue(err.response.data)
    }
})

export const createPackageGroup =  createAsyncThunk('package_form/createPackageGroup', async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
        const response = await createPackageGroupRequest(arg)

        dispatch(addAvailablePackageGroup(response.data.data))
        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }

        const errors = { package_group_id: Object.values(err.response.data.errors).flat() }
        dispatch(updateErrors(errors))

        throw rejectWithValue(err.response.data)
    }
})


const packageFormSlice = createSlice({
    name: 'package_form',
    initialState,
    reducers: {
        updatePackage(state, action) {
            state.package = action.payload
        },
        updateName(state, action) {
            state.package.name = action.payload
        },
        updateDescription(state, action) {
            state.package.description = action.payload
        },
        updateOutcomes(state, action) {
            state.package.outcomes = action.payload
        },
        updateGroup(state, action) {
            state.package.package_group_id = action.payload
        },
        updateImage(state, action) {
            state.package.image = action.payload
        },
        updateStockSize(state, action) {
            state.package.stock_availability = action.payload
        },
        updateMinBudget(state, action) {
            state.package.min_budget = action.payload
        },
        updateMaxBudget(state, action) {
            state.package.max_budget = action.payload
        },
        updateOrganisationWhiteList(state, action) {
            state.package.organisation_whitelist = action.payload
        },
        addAdFormat(state, action) {
            state.package.ad_format_templates.push({
                id: uuidv4(),
                adformat_control_id: action.payload.control_id,
                min_budget: 0,
                start_date: undefined,
                end_date: undefined,
                percentage_discount: 0,
                flat_discount: 0,
            })
        },
        updateAdFormats(state, action) {
            state.package.ad_format_templates = action.payload
        },
        updateErrors(state, action) {
            state.errors = action.payload || {}
        },
        updateAvailablePackageGroups(state, action) {
            state.available_package_groups = action.payload
        },
        addAvailablePackageGroup(state, action) {
            state.available_package_groups.push(action.payload)
        },
        clearPackage: () => initialState,
    },
    extraReducers: {
        [fetchPackage.pending]: (state, action) => {
            state.status = 'loading'
            state.request_type = 'fetch'
        },
        [fetchPackage.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'success'
            }
        },
        [fetchPackage.rejected]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'failed'
            }
        },
        [upsertPackage.pending]: (state, action) => {
            state.status = 'loading'
            state.request_type = 'upsert'
        },
        [upsertPackage.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'success'
            }
        },
        [upsertPackage.rejected]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'failed'
                state.errors = action.payload.errors
            }
        },
    },
})

export const selectPackage = (state) => state.package_form.package
export const selectPackageName = (state) => state.package_form.package.name
export const selectPackageDescription = (state) => state.package_form.package.description
export const selectPackageOutcomes = (state) => state.package_form.package.outcomes
export const selectPackageGroup = (state) => state.package_form.package.package_group_id
export const selectPackageImage = (state) => state.package_form.package.image
export const selectPackageStockAvailability = (state) => state.package_form.package.stock_availability
export const selectPackageMinBudget = (state) => state.package_form.package.min_budget
export const selectPackageMaxBudget = (state) => state.package_form.package.max_budget
export const selectOrganisationWhiteList = (state) => state.package_form.package.organisation_whitelist
export const selectAdFormatTemplates = (state) => state.package_form.package.ad_format_templates
export const selectAvaiablePackageGroups = state => state.package_form.available_package_groups

export const selectCanFullEdit = state => selectPackage(state).can_full_edit

export const selectErrors = state => state.package_form.errors
export const selectFormErrors = state => selectErrors(state)._errors
export const selectPackageNameErrors = state => selectErrors(state).name
export const selectPackageDescriptionErrors = state => selectErrors(state).description
export const selectPackageOutcomesErrors = state => selectErrors(state).outcomes
export const selectPackageGroupErrors = state => selectErrors(state).package_group_id
export const selectPackageImageErrors = (state) => selectErrors(state).image
export const selectPackageStockAvailabilityErrors = state => selectErrors(state).stock_availability
export const selectPackageMinBudgetErrors = state => selectErrors(state).min_budget
export const selectPackageMaxBudgetErrors = state => selectErrors(state).max_budget
export const selectPackageOrganisationWhitelistErrors = state => selectErrors(state).organisation_whitelist
export const selectPackageAdFormatTemplatesErrors = state => selectErrors(state).ad_format_templates

export const selectStatus = state => state.package_form.status
export const selectRequestType = state => state.package_form.request_type

export const {
    updatePackage,
    updateName,
    updateDescription,
    updateOutcomes,
    updateGroup,
    updateImage,
    updateStockSize,
    updateMinBudget,
    updateMaxBudget,
    updateDiscountPercentage,
    updateDiscountPrice,
    updateOrganisationWhiteList,
    addAdFormat,
    updateAdFormats,
    updateErrors,
    updateAvailablePackageGroups,
    addAvailablePackageGroup,
    clearPackage,
} = packageFormSlice.actions

export default packageFormSlice.reducer
