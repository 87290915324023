/* Template Fields */

const TEMPLATE_FIELD_TYPE_IMAGE = 'image';
const TEMPLATE_FIELD_TYPE_LINK = 'link';
const TEMPLATE_FIELD_TYPE_TEXTAREA = 'textarea';
const TEMPLATE_FIELD_TYPE_COLOR = 'color';
const TEMPLATE_FIELD_TYPE_VIDEO = 'video';
const TEMPLATE_FIELD_TYPE_TEXT = 'text';
const TEMPLATE_FIELD_TYPE_CURRENCY = 'currency';

const BESPOKE_PHYSICAL = 'physical';
const BESPOKE_DIGITAL = 'digital';

export {
  TEMPLATE_FIELD_TYPE_TEXT,
  TEMPLATE_FIELD_TYPE_CURRENCY,
  TEMPLATE_FIELD_TYPE_COLOR,
  TEMPLATE_FIELD_TYPE_IMAGE,
  TEMPLATE_FIELD_TYPE_LINK,
  TEMPLATE_FIELD_TYPE_TEXTAREA,
  TEMPLATE_FIELD_TYPE_VIDEO,
  BESPOKE_PHYSICAL,
  BESPOKE_DIGITAL,
}