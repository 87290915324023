import PropTypes from "prop-types";
import React from "react";
import PropType from "prop-types";
import { Alert, Form, InputGroup } from "react-bootstrap";
import Errors from "../../order_form/components/Errors";
import Select from "react-select";
import { useEffect } from "react";
import Chart from "react-google-charts";
import moment from "moment/moment";

const options = {
  legend: { position: 'top' },
  chartArea: { 'width': '80%', 'height': '80%' },
  animation: {
    startup: true,
    easing: 'linear',
    duration: 300,
  },
  hAxix: {
    title: 'Period',
  },
  vAxis: {
    title: 'Utilisation (%)',
    viewWindow: {
      min: 0,
      max: 100
    }
  },
  isStacked: true,
  seriesType: 'steppedArea',
  series: {
    0: { type: 'bars', visibleInLegend: false, color: '#777785' },
    1: { color: '#FAD1D3' },
    2: { color: '#FFF5CC' },
    3: { color: '#E3FDF0' },
  },
  axes: {
    y: {
      all: {
        range: {
          max: 100,
          min: 0
        }
      }
    }
  },
};

const InventoryAudiencePerformance = ({ performance, errors = {} }) => {
  let graph = [["", "Utilisation (%)", 'Low', 'Medium', 'High']];
  if ((performance?.data ?? []).length === 0) {
    graph = [];
  } else {
    (performance?.data).forEach(d => graph.push([moment(d?.start_at).format("D MMM"), parseFloat(d?.percent), 30, 30, 40]))
  }

  return (
    <div className="border rounded p-3">
      <article>
        <div className="mb-3">
          <h5>Performance Over Time</h5>
        </div>
        <div>
          <div>
            <Chart
              chartType="ComboChart"
              data={graph}
              width="100%"
              height="450px"
              options={options}
              legendToggle
            />
          </div>
        </div>
      </article>
    </div>
  );
};

InventoryAudiencePerformance.propTypes = {
  ownedSpace: PropTypes.object,
  errors: PropTypes.object,
};

export default InventoryAudiencePerformance;
