import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Form, InputGroup, Spinner, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap'
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { useSelector } from 'react-redux';
import { selectSession, selectUserOrganisation } from '../../session/sessionSlice';
import { updateIf } from 'typescript';
import { propTypes } from 'react-bootstrap/esm/Image';
import styles from './metric_permissions.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const changedFieldStyle = {
  border: '2px solid #0095ff',
}

export const financialMetricKeys = [
  'cpc',
  'cpm',
  'cpv',
  'ctr',
  'cpv',
  'spend',
  'lifetime_budget',
  'daily_budget',
  'total_roas',
  'total_conversion_value',
  'webads_total_conversions_value',
  'direct_conversions_value',
  'associated_conversions_value',
  'cost_per_inline_link_click',
]

export const performanceMetricKeys = [
  'clicks',
  'view_rate',
  'views',
  'impressions',
  'reach',
  'online_sales',
  'offline_sales',
  'inline_link_clicks',
];

const PermissionsSelectMenu = ({
  name,
  isGeneralSettings,
  formid,
}) => {
  const {control, register } = useFormContext();

  return <Controller
    control={control}
    name={name}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState,
    }) => (
      <Form.Control
        id={formid}
        as="select"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        style={!isGeneralSettings && !!value && value !== 'default' ? changedFieldStyle : null}
      >{['default', 'yes', 'no'].map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
      </Form.Control>
    )}
  />
}

const PermissionsCheckbox = ({
  name,
  formid,
}) => {
  const {control, register } = useFormContext();

  return <Controller
    control={control}
    name={name}
    render={({
      field: { onChange, onBlur, value, name, ref },
      fieldState: { invalid, isTouched, isDirty, error },
      formState,
    }) => (
      <Form.Check
        id={formid}
        onBlur={onBlur}
        onChange={onChange}
        checked={value}
      />
    )}
  />
}

const PopupHint = ({
  text,
  popoverText
}) => {
  return (
    <span>{text}{popoverText && <OverlayTrigger
        placement="right"
        trigger="hover"
        overlay={(
          <Popover>
            <Popover.Content className='text-justify'>
              {popoverText}
            </Popover.Content>
          </Popover>
        )}>
        <a className={styles.ml_5px}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </a>
      </OverlayTrigger>}
    </span>
  )
}

const PermissionsTable = ({
  data,
  title,
  isMetrics = false,
  isGeneralSettings = false,
}) => {
  
  const SelectedComponent = isGeneralSettings ? PermissionsCheckbox: PermissionsSelectMenu
  function TableModifications( tableInfo ) {
    if (tableInfo.name === 'budget' && isGeneralSettings)
        return 'Allow suppliers to set budget';
    else if (tableInfo.name === 'direct_conversions_value')
        return 'Direct Conversion Value (Web Ads)';
    else if (tableInfo.name === 'webads_total_conversions_value')
        return 'Total Conversion Value (Web Ads)';
    else if (tableInfo.name === 'associated_conversions_value')
        return 'Assocated Conversion Value (Web Ads)';
    else if (tableInfo.name == 'skew_metrics')
        return <PopupHint text={tableInfo.title} popoverText={"If you select this option, calculated metrics visible to your suppliers (ex. CPC, ROAS) will be based on budget allocated by the supplier vs actual spend"}></PopupHint>;
    else if (tableInfo.name === 'require_order_wallet')
      return <PopupHint text={tableInfo.title} popoverText="If you select this option, suppliers are required to have and select a wallet when they create an order"></PopupHint>;

    return tableInfo.title;
    
  }
  return (
    <>
       <div className="d-sm-flex justify-content-between align-items-center mb-3">
          <h5 className="text-dark mb-0">{ title }</h5>
        </div>        
        {!isMetrics && 
        <>
          <hr />
          <Row>
          {data?.length > 0 && data.map((permission, index) => (
            <Col md="6">
            <Table responsive className={styles.tablestyle}>
              <tbody>
                <tr>
                    <td><Form.Label className='mb-0' htmlFor={`${title}-${index}`}>{TableModifications(permission)}</Form.Label></td>
                  <td>
                    <InputGroup>
                        <SelectedComponent isGeneralSettings={isGeneralSettings} name={permission.name} formid={`${title}-${index}`} />
                    </InputGroup> 
                  </td>
                </tr>
              </tbody>
            </Table>
            </Col>
          ))}
          </Row>
        </>
        }

        {isMetrics &&
        <Table responsive className={styles.tablestyle}>
          <tbody>
            {data?.length > 0 && data.map((permission, index) => (
              <tr>
                <td><Form.Label className='mb-0' htmlFor={`${title}-${index}`}>{TableModifications(permission)}</Form.Label></td>
                <td>
                  <InputGroup>
                    <SelectedComponent isGeneralSettings={isGeneralSettings} name={permission.name} formid={`${title}-${index}`} />
                  </InputGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        }
    </>
  );
}

const MetricPermissions = ({ isGeneralSettings = false, permissionsData, controlId, value = {}, onChange = () => { } }) => {
    const permissions = useSelector(selectSession)?.metric_read_permissions || [];
    const fieldPermissions = permissions
      .filter(p => p.type === 'field')
      // don't show require order wallet in individual supplier settings
      .filter(p => isGeneralSettings || (!isGeneralSettings && p.name !== 'require_order_wallet'));
    const groupPermissions = permissions.find(p => p.type === 'group')
    const financialMetrics = groupPermissions ? groupPermissions?.fields.filter(p => financialMetricKeys.includes(p.name)): [];
    const performanceMetrics = groupPermissions ? groupPermissions?.fields.filter(p => performanceMetricKeys.includes(p.name)): [];

    const { register, setValue } = useFormContext();

    useEffect(() => {
      [...financialMetrics, ...performanceMetrics].map(p => p.name).forEach(key => {
        if(isGeneralSettings) {
          setValue(key,  !!permissionsData?.metrics?.insights?.[key]);
        }else {
          let defaultValue = 'default';
          if(permissionsData?.metrics?.insights?.[key] === "yes") {
            defaultValue = 'yes';
          }else if(permissionsData?.metrics?.insights?.[key] === "no") {
            defaultValue = 'no';
          }
          setValue(key,  defaultValue);
        }
      });

      fieldPermissions.map(p => p.name).forEach(key => {
        register(key);
        if(isGeneralSettings) {
          setValue(key,  !!permissionsData?.metrics?.[key]);
        }else {
          let defaultValue = 'default';
          if(permissionsData?.metrics?.[key] === "yes") {
            defaultValue = 'yes';
          }else if(permissionsData?.metrics?.[key] === "no") {
            defaultValue = 'no';
          }
          setValue(key,  defaultValue);
        }
      });

    }, []);

    return (
      <>
      <Row>
        <Col md={12} className="my-2">
            <PermissionsTable isGeneralSettings={isGeneralSettings} data={fieldPermissions} title="Permissions" />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
            <h5 className="text-dark mb-0">Metrics</h5>
            <hr />
        </Col>
        <Col md="6" sm="12" className="my-2">
          <PermissionsTable isGeneralSettings={isGeneralSettings} data={financialMetrics.filter(f => f.name !== "ctr" && f.name !== "cpv")} title="Financial" isMetrics={true} />
        </Col>
        <Col md="6" sm="12" className="my-2">
          <PermissionsTable isGeneralSettings={isGeneralSettings} data={performanceMetrics.filter(f => f.name !== "view_rate" && f.name !== "views")} title="Performance" isMetrics={true}  />
        </Col>
      </Row>

    </>

    );
}

MetricPermissions.propTypes = {
  isGeneralSettings: PropTypes.bool,
  permissionsData: PropTypes.array.isRequired,
}


export default MetricPermissions
