import styled from 'styled-components'
import PackageDescription from '../../containers/PackageForm/PackageDescription'
import PackageGroup from '../../containers/PackageForm/PackageGroup'
import PackageImage from '../../containers/PackageForm/PackageImage'
import PackageMaximumBudget from '../../containers/PackageForm/PackageMaximumBudget'
import PackageMinimumBudget from '../../containers/PackageForm/PackageMinimumBudget'
import PackageName from '../../containers/PackageForm/PackageName'
import PackageOrganisationWhitelist from '../../containers/PackageForm/PackageOrganisationWhitelist'
import PackageOutcomes from '../../containers/PackageForm/PackageOutcomes'
import PackageStock from '../../containers/PackageForm/PackageStock'
import PropTypes from 'prop-types'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {availableAdFormats} from '../../../order_form/orderFormSlice'
import {cachedFetchBrands, selectAllBrands} from "../../../brands/brandsSlice";


const DetailContainer = styled.section`
    border: 1px solid #ECECEC;
    border-radius: 8px;
    padding: 41px 16px;
`

const DetailsForm = ({ canFullEdit = true }) => {

    const suppliers = useSelector(selectAllBrands);

    useEffect(() => {
        dispatch(cachedFetchBrands());
    }, [])

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(availableAdFormats());
    }, [dispatch]);

    let options = suppliers.map(supplier => ({
        id: supplier.id,
        label: supplier.name,
        value: supplier.id
    }))

    return (
        <DetailContainer>
            <PackageName />
            <PackageDescription />
            <PackageOutcomes readOnly={!canFullEdit} />
            <PackageGroup />
            <PackageImage />
            <PackageStock readOnly={!canFullEdit} />
            <PackageMinimumBudget readOnly={!canFullEdit} />
            <PackageMaximumBudget readOnly={!canFullEdit} />
            <PackageOrganisationWhitelist options={options} />
        </DetailContainer>
    );
}

DetailsForm.propTypes = {
    canFullEdit: PropTypes.bool,
}

export default DetailsForm
