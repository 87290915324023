import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Chrome from "../app/layout/Chrome";
import { selectRetailers } from "../features/session/sessionSlice";
import WalletsView from "../features/wallets/WalletsView";
import TransactionsView from "../features/wallets/TransactionsView";
import QueryResult from "../features/wallets/QueryResult";
import { getSingleWallet } from "../lib/api/wallets";
import {useRequest} from "../lib/hooks/useRequest";

const SingleWalletPage = () => {
  const [wallet, setWallet] = useState(null);
  const { wallet_id } = useParams();
  const history = useHistory();

  const { response, loading, error } = useRequest(() =>
    getSingleWallet(wallet_id)
  );

  useEffect(() => {
    if (response !== null) {
      setWallet(response?.data);
    }
  }, [response]);

  return (
    <>
      <Helmet>
        <title>
          View Wallet - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Chrome>
        <Container>
          <QueryResult loading={loading} error={error}>
            <Row>
              <Col>
                {wallet && (
                  <>
                    <TransactionsView
                      walletID={wallet.id}
                      isSupplier={true}
                      changeView={() => {
                        history.push("/wallets");
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>
          </QueryResult>
        </Container>
      </Chrome>
    </>
  );
};

export default SingleWalletPage;
