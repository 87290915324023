import {
  faAt,
  faBuilding,
  faKey,
  faTicketAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LogRocket from 'logrocket';
import PropType from 'prop-types'
import React, {useEffect} from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import { useLocation } from 'react-router-dom'
import Errors from '../order_form/components/Errors'

const RegisterForm = React.forwardRef(
  ({ form = {}, onChange, onSubmit, errors = {} }, ref) => {
    let location = useLocation()
    let params = new URLSearchParams(location.search)

    const handleChange = field => onChange({ ...form, ...field })

    const userId = params.has('id') ? params.get('id'): null;

    useEffect(() => {
      LogRocket.identify(userId, {
        email: form.email,
        first_name: form.first_name,
        is_registering: true,
      });
    }, [])

    return (
      <Form ref={ref}>
        {errors?._error?.length > 0 && (
          <Alert variant="danger">{errors._error.join('\n')}</Alert>
        )}
        {errors?._success?.length > 0 && (
          <Alert variant="success">{errors?._success.join('\n')}</Alert>
        )}

        <Form.Group controlId="token">
          <Row>
            {/* <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                Invitation Code <FontAwesomeIcon icon={faTicketAlt} />
              </Form.Label>
            </Col> */}
            <Col md={6}>
              <Form.Control
                type="text"
                required
                hidden
                readOnly={params.has('token')}
                value={form.token}
                isInvalid={errors?.token?.length > 0}
                onChange={e => handleChange({ token: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                <Errors errors={errors.token} />
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="first_name">
          <Row>
            <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                First Name <FontAwesomeIcon icon={faUser} />
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Control
                type="text"
                required
                value={form.first_name}
                isInvalid={errors?.first_name?.length > 0}
                onChange={e => handleChange({ first_name: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                <Errors errors={errors.first_name} />
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="last_name">
          <Row>
            <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                Last Name
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Control
                type="text"
                required
                value={form.last_name}
                isInvalid={errors?.last_name?.length > 0}
                onChange={e => handleChange({ last_name: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                <Errors errors={errors.last_name} />
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="jobtitle">
          <Row>
            <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                Job Title
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Control
                type="text"
                required
                value={form.jobtitle}
                isInvalid={errors?.jobtitle?.length > 0}
                onChange={e => handleChange({ jobtitle: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                <Errors errors={errors.jobtitle} />
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="email">
          <Row>
            <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                E-Mail Address <FontAwesomeIcon icon={faAt} />
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Control
                type="email"
                required
                isInvalid={errors?.email?.length > 0}
                value={form.email}
                onChange={e => handleChange({ email: e.target.value })}
              />
              <Form.Control.Feedback type="invalid">
                <Errors errors={errors.email} />
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="password">
          <Row>
            <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                Password <FontAwesomeIcon icon={faKey} />
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Control
                type="password"
                isInvalid={errors?.password?.length > 0}
                autoComplete="off"
                value={form.password}
                autoComplete="off"
                required
                onChange={e => handleChange({ password: e.target.value })}
              />
               <div className="mr-2">
                Password should be at least 8 characters
              </div>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group controlId="password_confirmation">
          <Row>
            <Col md={4} className="text-md-right">
              <Form.Label className="col-form-label font-weight-bold">
                Confirm Password
              </Form.Label>
            </Col>
            <Col md={6}>
              <Form.Control
                type="password"
                isInvalid={errors?.password?.length > 0}
                required
                value={form.password_confirmation}
                autoComplete="off"
                onChange={e =>
                  handleChange({ password_confirmation: e.target.value })
                }
              />
              <Form.Control.Feedback type="invalid">
                <Errors errors={errors.password} />
              </Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>
        <Row>
          <Col className="text-center">
            <Button type="submit" onClick={onSubmit}>
              Register
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
)

RegisterForm.propTypes = {
  form: PropType.shape({
    token: PropType.string,
    organisation: PropType.string,
    first_name: PropType.string,
    last_name: PropType.string,
    email: PropType.string,
    password: PropType.string,
    password_confirmation: PropType.string,
  }),
  errors: PropType.object,
  onChange: PropType.func.isRequired,
  onSubmit: PropType.func.isRequired,
}

export default RegisterForm
