import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { ReactBootstrapSlider } from "react-bootstrap-slider";
import Errors from "../Errors";

const maxAge = 65;
const minAge = 18;

const FacebookAge = ({
  readOnly = false,
  required = false,
  value = {},
  errors = [],
  onChange = () => {},
}) => {
  const [state, setState] = useState({
    value: value,
  });

  useEffect(() => {
    if (value?.min_age === undefined && value?.max_age === undefined) {
      onChange({
        min_age: minAge,
      });
    }
  }, []);

  const [isDirty, setIsDirty] = useState(false);

  setTimeout(() => {
    if (isDirty) {
      onChange({
        min_age: state?.value?.min_age,
        max_age: state?.value?.max_age,
      });

      setIsDirty(false);
    }
  }, 1000);

  const handleChange = (obj) => {
    obj = { ...value, ...obj };

    if (obj.min_age) {
      if (obj.min_age < minAge) {
        obj.min_age = minAge;
      }
      if (obj.min_age > maxAge) {
        obj.min_age = maxAge;
      }
    }

    if (obj.max_age) {
      if (obj.max_age > maxAge) {
        obj.max_age = maxAge;
      }
      if (obj.max_age < minAge) {
        obj.max_age = minAge;
      }
    }

    setState({
      value: obj,
    });

    onChange(obj);
  };

  return (
    <Form.Group controlId="age-group">
      <Form.Label>Age</Form.Label>
      <Form.Row>
        <Col lg="6">
          <div
            style={{ marginLeft: "10px", width: "100%", paddingBottom: "15px" }}
          >
            <span
              style={{
                display: "inline",
                marginRight: "13px",
                color: "#6d767e",
              }}
            >
              {minAge}
            </span>
            <ReactBootstrapSlider
              value={[
                state?.value?.min_age ? parseInt(state.value.min_age) : minAge,
                state?.value?.max_age ? parseInt(state.value.max_age) : maxAge,
              ]}
              change={(e) => {
                setState({
                  value: {
                    min_age: e.target.value[0],
                    max_age: e.target.value[1],
                  },
                });
                setIsDirty(true);
              }}
              slideStop={15}
              range={true}
              step={1}
              max={maxAge}
              min={minAge}
              orientation="horizontal"
              reversed={false}
              disabled={readOnly}
            />
            <span
              style={{
                display: "inline",
                marginLeft: "13px",
                color: "#6d767e",
              }}
            >
              {maxAge}+
            </span>
          </div>
        </Col>
      </Form.Row>
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

FacebookAge.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.shape({
    min_age: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max_age: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default FacebookAge;
