import { faCheckCircle, faMapMarker, faMarker } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Button, Col, Container, Figure, Form, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { OWNED_SPACE_BANNERS, OWNED_SPACE_PRODUCTS, NATIVE_BANNER, NATIVE_PRODUCT } from '../../../../lib/webads'

const SelectionContainer = styled.div`
  border: 1px solid #D0C6FF;
  padding: 12px 15px;
  border-radius: 4px;
`
const SelectionHeader = styled.h6`
  font-weight: bold;
  margin-bottom: 0;
`

const Content = styled.div`
    margin-top: 25px;
    transition: all 0.3s ease-in-out;
    display: none;

    &[aria-expanded="true"] {
        display: block;
    }
`


const Accordian = ({ onCollapse = () => { }, open = true, header, children }) => {
    return <SelectionContainer>
        <div className="d-flex align-items-center">
            <SelectionHeader>{header}</SelectionHeader>
            {/* <span className="ml-auto" onClick={onCollapse}>
                <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
            </span> */}
        </div>
        <Content aria-expanded={open ? 'true' : 'false'}>
            {children}
        </Content>
    </SelectionContainer>
}


const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Placement = ({ ad = {}, mercatoDeviceGroups = [], ownedDevices = [], onChange = () => { }, value = [], readOnly = false }) => {
    const handleMercatoDeviceClick = (device, en) => {
        let nval = value.filter(v => v !== device)
        if (en) {
            nval.push(device)
        }
        onChange(nval)

    }

    return <Container>
        {mercatoDeviceGroups.map(g => <Row className="mb-4">
            <Col>
                <Accordian
                    header={<span>
                        {capitalizeFirstLetter(g.group)}
                    </span>}>
                    <div style={{ width: "100%" }}>

                        {ownedDevices && ownedDevices
                            .filter(device => device.group === g.group)
                            .map(device =>
                                <>
                                    <div style={{ display: "inline-block", width: "98%" }}>
                                        <Form.Check
                                            type="checkbox"
                                            label={device.name}
                                            disabled={readOnly}
                                            checked={value.find(v => v === device.id)}
                                            onChange={e => handleMercatoDeviceClick(device.id, e.target.checked)}
                                        />
                                    </div>
                                    <div style={{ display: "inline-block", width: "2%" }}>
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip" as="div">
                                                {Object.keys(device?.physical_location ?? {}).map(k => { return <li style={{ textAlign: "left", listStyleType: "none" }}>{k}: {device?.physical_location[k]}</li> })}
                                            </Tooltip>}
                                        >
                                            <FontAwesomeIcon icon={faMapMarker} />
                                        </OverlayTrigger>
                                    </div>
                                </>
                            )}


                    </div>
                </Accordian>
            </Col>
        </Row>)}
    </Container>
}

export default Placement
