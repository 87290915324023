import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import { DescriptionContainer, ErrorFeedback, LabelText, TextInput } from './Common'
import { formatNumber } from '../../../../lib/strings'
import Errors from './Errors'
import styled from 'styled-components'
import { useState } from 'react'


const RadioContainer = styled.div`
    margin-bottom: 0.5rem;
`

const ChoiceAmount = ({
    controlId,
    readOnly = false,
    required = false,
    label,
    description,
    value,
    options = [],
    defaultOption,
    onChange = () => { },
    errors = [],
}) => {
    const [selection, setSelection] = useState(defaultOption)

    const handleAmountChange = val => {
        if (readOnly) {
            return
        }

        onChange({ selection, amount: val })
    }

    const handleSelectionChange = selection => {
        setSelection(selection)
        onChange({ selection, amount: 0 })
    }

    return <Form.Group controlId={controlId}>
        <LabelText>
            {label} {required && label && "*"}{" "}
        </LabelText>
        <DescriptionContainer>
            {description}
        </DescriptionContainer>
        <RadioContainer>
            {options.map(option =>
                <Form.Check
                    type="radio"
                    name={controlId}
                    inline
                    key={option.id}
                    id={option.id}
                    checked={option.value === selection}
                    label={option.label}
                    onChange={() => handleSelectionChange(option.value)}
                />)
            }
        </RadioContainer>

        {options.map(option => {
            const OptionInput = option.input
            return <div className={`${option.value === selection ? 'd-block' : 'd-none'}`} key={option.id}>
                <OptionInput
                    controlId={`${controlId}-${option.id}`}
                    required={required}
                    readOnly={readOnly}
                    disabled={readOnly}
                    value={value}
                    isInvalid={errors.length}
                    onChange={handleAmountChange}
                />
            </div>
        })}
        <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
            <Errors errors={errors} />
        </ErrorFeedback>
    </Form.Group>
}

ChoiceAmount.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.number,
    label: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        // input: PropTypes.element.isRequired,
    })),
    defaultOption: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
}

export default ChoiceAmount
