import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faSearch} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import * as PropTypes from "prop-types";
import {Button, Spinner} from "react-bootstrap";
import styles from './inventory_controls.module.scss';
import {InventoryDropdown} from "./InventoryDropdown";

const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #777785;
`;

const DownloadCsvButton = (props) => {
    return <Button
        type="button"
        variant="outline-secondary"
        disabled={props.downloadIsProcessing || props.webSpacesLoading}
        onClick={props.onClick}
        className={`${styles.inventory_controls_button}`}
    >
        {props.downloadIsProcessing ? (
            <Spinner
                animation="border"
                variant="dark"
                size="md"
                style={{
                    marginRight: "5px",
                    width: "20px",
                    height: "20px",
                }}
            />
        ) : <><FontAwesomeIcon
            className="fa-download"
            style={{cursor: "pointer"}}
            icon={faDownload}
        /> Download CSV</>}
    </Button>;
}

const FilterResultsBar = (props) => {
    return <div className='input-group' style={{maxWidth: '15em', flexGrow: 1}}>
        <input
            type="text"
            size={20}
            className={`border-right-0 ${styles.inventory_controls_input}`}
            id="search"
            placeholder="Search"
            onChange={props.onChange
            }
        />
        <StyledSearch>
            <FontAwesomeIcon icon={faSearch}/>
        </StyledSearch>
    </div>;
}

const SortDropdown = (props) => {
    const options = [
        {value: 'none', label: 'None'},
        {value: 'revenue', label: 'Revenue'},
        {value: 'ads', label: 'Campaigns'},
        {value: 'impressions', label: 'Impressions'}
    ];
    return (
        <InventoryDropdown
            label="Sort"
            options={options}
            onChange={props.onChange}
            defaultValue="none"
            marginLeft="auto"
        />
    );
}

const DateRangeDropdown = (props) => {
    const options = [
        {value: 'last_14_days', label: 'Last 14 Days (By Days)'},
        {value: 'last_30_days', label: 'Last 30 Days (By Weeks)'},
        {value: 'last_90_days', label: 'Last 90 Days (By Months)'}
    ];
    return (
        <InventoryDropdown
            options={options}
            onChange={props.onChange}
            defaultValue="last_30_days"
        />
    );
}

const SupplierDropdown = (props) => {
    const options = [
        {value: 'all', label: 'All Suppliers'},
        ...props.suppliers.map(supplier => ({value: supplier.id, label: supplier.name}))
    ];
    return (
        <InventoryDropdown
            options={options}
            onChange={props.onChange}
            maxWidth="9em"
            defaultValue="all"
        />
    );
}

const StatusDropdown = (props) => {
    const options = [
        {value: 'all', label: 'All Status'},
        {value: 'active', label: 'Active'},
        {value: 'inactive', label: 'Inactive'}
    ];
    return (
        <InventoryDropdown
            options={options}
            onChange={props.onChange}
            defaultValue="all"
        />
    );
}

const FormatDropdown = props => {
    const options = [
        {value: 'all', label: 'All Formats'},
        {value: 'banners', label: 'Banners'},
        {value: 'product', label: 'Product'}
    ];
    return (
        <InventoryDropdown
            options={options}
            onChange={props.onChange}
            defaultValue="all"
        />
    );
}

const EntriesInfo = ({entryFrom, entryTo, totalEntries}) => {
    return <p className={`${styles.small_label_text}`}>Showing {entryFrom} - {entryTo} / {totalEntries}</p>;
}

export const InventoryControls = props => {
    return <>
        <div className={`${styles.top_controls_background}`}>
            <EntriesInfo entryFrom={props.tableEntryFrom} entryTo={props.tableEntryTo}
                         totalEntries={props.tableEntryTotal}/>
            <FormatDropdown onChange={props.onChangeFormatDropdown}/>
            <StatusDropdown onChange={props.onChangeStatusDropdown}/>
            <SupplierDropdown onChange={props.onChangeSupplierDropdown} suppliers={props.suppliers}/>
            <DateRangeDropdown onChange={props.onChangeDateRangeDropdown}/>
            <FilterResultsBar onChange={props.onChangeFilterTextBox}/>
            <SortDropdown onChange={props.onChangeSortDropdown}/>
            <DownloadCsvButton downloadIsProcessing={props.downloadIsProcessing}
                               webSpacesLoading={props.webSpacesLoading}
                               onClick={props.onClickCsvDownloadButton}/>
        </div>
    </>
        ;
}


InventoryControls.propTypes = {
    onChangeFormatDropdown: PropTypes.func,
    onChangeStatusDropdown: PropTypes.func,
    onChangeSupplierDropdown: PropTypes.func,
    suppliers: PropTypes.any,
    onChangeDateRangeDropdown: PropTypes.func,
    onChangeFilterTextBox: PropTypes.func,
    onChangeSortDropdown: PropTypes.func,
    downloadIsProcessing: PropTypes.bool,
    webSpacesLoading: PropTypes.bool,
    onClickCsvDownloadButton: PropTypes.func,
    tableEntryFrom: PropTypes.number,
    tableEntryTo: PropTypes.number,
    tableEntryTotal: PropTypes.number
};