import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Col, Form, Row, Spinner} from 'react-bootstrap'
import {getProducts} from '../../../../lib/api'
import Errors from '../Errors'
import ProductSquare from "../../../common/products/ProductSquare";
import styles from './feed.module.scss'

const Feed = ({
  controlId,
  readOnly = false,
  required = false,
  productSets = [],
  productCatalogs = [],
  value = {group: {}, products: []},
  onChange = () => {
  },
  errors = [],
}) => {
  const [products, setProducts] = useState([])
  const [filter, setFilter] = useState('')
  const [status, setStatus] = useState('idle')

  useEffect(() => {
    if (Object.keys(value.group).length === 0) {
      setProducts([])
    } else if (productSets.some(productSet => value.group.id === productSet.id)) {
      setStatus('loading')
      getProducts(
        value.group.id
      ).then(resp => setProducts(resp.data.data))
      .then(_ => setStatus('succeeded'))
    }
  }, [value.group?.id])

  let filteredProducts = products
  if (filter !== '') {
    filteredProducts = products.filter(product => product.name.toLowerCase().includes(filter.toLowerCase()))
  }

  if (!value.group?.id && productSets.length > 0) {
    onChange({
      ...value, group: productSets[0], products: [],
    })
  }

  return (
    <Form.Group controlId={controlId}>
      <Row className="mb-2">
        <Col md="6">
          <Form.Control
            as="select"
            required={required}
            readOnly={readOnly}
            disabled={readOnly}
            value={value.group?.id}
            onChange={e => {
              if (e.target.value === '') {
                onChange({ ...value, group: {}, products: [] })
                return
              }
              let set = [...productCatalogs, ...productSets]

              onChange({
                ...value,
                group: set.find(set => set.id === e.target.value),
                products: [],
              })
            }}
          >
            <option value="">None</option>
            {productCatalogs.map(set => (
              <option value={set.id} key={set.id}>
                {set.name}
              </option>
            ))}
            {productSets.map(set => (
              <option value={set.id} key={set.id}>
                {set.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col md="6" className="d-flex align-items-center">
          <Form.Control
            type="text"
            value={filter}
            inline
            placeholder="Search..."
            onChange={e => setFilter(e.target.value)}
          />
        </Col>
      </Row>
      {filteredProducts.length === 0 ?
          <div className="text-center" style={{lineHeight: '10rem'}}>{status === 'loading' ?
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner> : 'No products available'}
          </div>
          : <div
              className={`position-relative w-100 p-1 ${styles.filtered_products}`}
          >
            {filteredProducts.map(product => (
                <ProductSquare
                    key={product.id}
                    src={product.image_url}
                    name={product.title}
                    price={product.price}
                    currency={product.currency}
                    checked={value.products.some(p => p.id === product.id)}
                    onClick={e => {
                      if (readOnly) {
                    return
                  }

                  if (value.products.some(p => p.id === product.id)) {
                    onChange({
                      ...value,
                      products: value.products.filter(p => p.id !== product.id),
                    })
                    return
                  }

                      onChange({
                        ...value,
                        products: [...value.products, product],
                      })
                    }}
                />
            ))}
          </div>
        }
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  )
}

Feed.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  manualInput: PropTypes.bool,
  value: PropTypes.shape({
    group: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
}

export default Feed
