import React, { useRef, useEffect } from "react";
import StandardAdSection from "./StandardAdSection";
import PropTypes from "prop-types";
import OwnedSpaceSection from "./OwnedSpaceSection";
import BespokeProductSection from "./BespokeProductSection";
import { stripAdErrors } from "../../lib/orders";
import { useSelector } from "react-redux";
import { hasLiveOrderBeingViewed, selectLiveOrder } from "./orderFormSlice";
import moment from "moment";
import { PLATFORM_NATIVE } from "../../lib/platforms";

const AdSection = React.forwardRef((props, ref) => {
  const {
    controlId,
    readOnly = false,
    header = "",
    index,
    ad = {},
    bespoke = [],
    assets = [],
    isNew = false,
    errors = {},
    onChange = () => { },
    onDelete = () => { },
    children,
  } = props;

  const liveOrderViewed = useSelector(hasLiveOrderBeingViewed)
  const liveOrder = useSelector(selectLiveOrder)
  const adSectionRef = useRef();

  useEffect(() => {
    const errorKeys = Object.keys(errors ?? {});
    for (let key of errorKeys) {
      const keys = key.split('.');
      if (!keys || keys.length === 0) {
        continue;
      }
      const isDatajson = keys[0] === 'data_json';
      const adIndex = parseInt(keys[1]) ?? null;

      // Scroll to the adSection setup by ref when the adIndex matches the index of the ad
      if (isDatajson && adIndex === index) {
        adSectionRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
        break;
      }
    }
  }, [errors, adSectionRef])

  if (ad.platform === "bespoke_product") {
    return <BespokeProductSection ref={adSectionRef} key={ad.id} {...props} errors={stripAdErrors(errors, index)} />;
  }
  const now = moment();
  const adStop = moment(ad.stop_at);

  if (ad.platform === PLATFORM_NATIVE) {
    let makeReadOnly = readOnly
    if (liveOrderViewed) {
      const now = moment();
      const originalAd = liveOrder.data_json.find(a => a.id === ad.id)
      if (!originalAd) {
        throw 'Need original ad in old order.'
      }
      const adStop = moment(originalAd.stop_at);
      makeReadOnly = readOnly || liveOrderViewed && now.isAfter(adStop)
    }

    return <OwnedSpaceSection ref={adSectionRef} key={ad.id} {...props} errors={stripAdErrors(errors, index)} readOnly={makeReadOnly} />;
  }

  return <StandardAdSection ref={adSectionRef} key={ad.id} {...props} errors={stripAdErrors(errors, index)} readOnly={readOnly || liveOrderViewed} />;
});

AdSection.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  ad: PropTypes.shape({
    platform: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default AdSection;
