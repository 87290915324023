import {Alert, Badge, Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory, useParams} from "react-router-dom";
import OwnedSpaceAllowedOrganisations from "../features/owned_space_form/containers/OwnedSpaceAllowedOrganisations";
import OwnedSpaceCategory from "../features/owned_space_form/containers/OwnedSpaceCategory";
import OwnedSpaceMonetisation from "../features/owned_space_form/containers/OwnedSpaceMonetisation";
import OwnedSpaceName from "../features/owned_space_form/containers/OwnedSpaceName";
import OwnedSpaceSlots from "../features/owned_space_form/containers/OwnedSpaceSlots";
import OwnedTemplates from "../features/owned_space_form/containers/OwnedTemplates";
import OwnedUrls from "../features/owned_space_form/containers/OwnedUrls";
import OwnedChannels from "../features/owned_space_form/containers/OwnedChannels";
import {
  clearOwnedSpace,
  editOwnedSpace,
  ownedSpaceFormUpdated,
  selectOwnedSpace,
  toggleOwnedSpaceState,
} from "../features/owned_space_form/ownedSpaceFormSlice";
import {selectUser,} from "../features/session/sessionSlice";
import {getEmbeddableTag, getOwnedSpaceDetails} from "../lib/api/ownedSpaces";
import Breadcrumbs from "./components/Breadcrumbs";
import Chrome from "./layout/Chrome";
import styled from 'styled-components'

const SearchSpaceBadge = styled(Badge)`
  background-color: rgb(254, 242, 192);
`

const breadcrumbItems = [
  {
    path: "/organisation/settings",
    title: "Settings",
  },
  {
    path: "/organisation/channels",
    title: "Owned Channels",
  },
  {
    path: "/organisation/websettings",
    title: "Spaces & Templates",
  },
  {
    path: "/organisation/websettings",
    title: "Edit Space",
    active: true,
  },
];

const EditOwnedSpace = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const history = useHistory();
  const { owned_space_id } = useParams();
  const [ownedSpaceForm, setOwnedSpaceForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [submitMessages, setSubmitMessages] = useState(null);
  const ownedSpace = useSelector(selectOwnedSpace);
  const [getTagModal, setGetTagModal] = useState(false);
  const [getTagModalData, setGetTagModalData] = useState({ status: 'danger', text: "Not available", copyStatus: null });
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    dispatch(clearOwnedSpace());
    getOwnedSpaceDetails(user.active_organisation.id, owned_space_id).then(res => {
      var data = res.data?.data;
      if (data) {
        setOwnedSpaceForm(data);
        dispatch(ownedSpaceFormUpdated(data));
      }
    }).catch(err => {

    });
  }, []);

  const handleGetTagModalClose = e => {
    setGetTagModal(false);
  };

  const handleGetTagModalOpen = e => {
    getEmbeddableTag(ownedSpace.id).then(res => {
      if (res.data?.data) {
        setGetTagModalData({ status: 'success', text: res.data?.data });
      } else {
        setGetTagModalData({ status: 'danger', text: "Not available" });
      }
      setGetTagModal(true);
    }).catch(err => {
      setGetTagModalData({ status: 'danger', text: "Error occured while fetching data, please try again" });
      setGetTagModal(true);
    });
  }

  if (!ownedSpace) {
    return <Redirect to="/organisation/websettings" />;
  }
  return (
    <>
      <Helmet>
        <title>
          Edit Virtual Space - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>

      <Chrome>
        <Container className="pt-4">
          <Breadcrumbs items={breadcrumbItems} />

          <Row>
            <Col>
              <Form>
                {submitMessages && submitMessages?.type === 'danger' && (
                  <Alert className="mt-3" variant={submitMessages.type}>
                    {submitMessages.body}
                  </Alert>
                )}
                {submitMessages && submitMessages?.type === 'list' && (
                  <Alert className="mt-3" variant="danger">
                    <ul>{submitMessages.body.map((m) => (<li>{m}</li>))}</ul>
                  </Alert>
                )}
                <div className="mb-5 d-flex">
                  <div>
                    <div className="d-flex align-items-center mb-2">
                      <h5>{ownedSpace.name}</h5>{" "}
                      {ownedSpace.placement && (
                        <Badge className="ml-3 text-capitalize" variant="info">
                          {ownedSpace.placement} Space 
                        </Badge>
                      )}{" "}
                      {ownedSpace?.is_active && (
                        <Badge className="ml-3" variant="success">
                          Active
                        </Badge>
                      )}
                      {!ownedSpace?.is_active && (
                        <Badge className="ml-3" variant="danger">
                          Inactive
                        </Badge>
                      )}
                      {ownedSpace?.is_multitenancy && (
                        <Badge className="ml-3" variant="warning">
                          Multi-tenancy
                        </Badge>
                      )}

                    {ownedSpace?.is_active && ownedSpace?.placement === 'embedded' && (
                      <Button
                        variant="outline-success"
                        className="ml-3"
                        onClick={(e) => {
                          handleGetTagModalOpen();
                        }}
                      >
                      <span className="small d-flex">
                        <span>
                        Get embeddable tag
                        </span>
                      </span>
                      </Button>
                    )}
                  </div>
                    <div>
                      <span className="mr-5">
                        <b>ID:</b> {ownedSpace.id ? ownedSpace.id : "-"}
                      </span>
                      <span>
                        <b>Format:</b>{" "}
                        {ownedSpace.format ? ownedSpace.format : "-"}
                      </span>
                      <span className="ml-2">
                        <b>Subformat:</b>{" "}
                        {ownedSpace.subformat ? ownedSpace.subformat : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="ml-auto">
                    {ownedSpace?.is_active && (
                      <Button
                        variant="outline-danger"
                        className="ml-3"
                        disabled={isSaving}
                        onClick={(e) => {
                          setIsSaving(true);
                          dispatch(toggleOwnedSpaceState()).then((action) => {
                            if (!action.error) {
                              history.push(`/organisation/websettings`);
                            } else {
                              setIsSaving(false);
                              const errors = action?.payload?.errors;
                              if (errors ) {
                                setSubmitMessages({type: "danger", body: errors});
                              }
                            }
                          });
                        }}
                      >
                      <span className="small d-flex">
                        <span>Deactivate</span>
                      </span>
                      </Button>
                    )}
                    {!ownedSpace?.is_active && (
                      <Button
                        variant="outline-success"
                        className="ml-3"
                        disabled={isSaving}
                        onClick={(e) => {
                          setIsSaving(true);
                          dispatch(toggleOwnedSpaceState()).then((action) => {
                            if (!action.error) {
                              history.push(`/organisation/websettings`);
                            } else {
                              setIsSaving(false);
                              const errors = action?.payload?.errors;
                              if (errors ) {
                                setSubmitMessages({type: "danger", body: errors});
                              }
                            }
                          });
                        }}
                      >
                      <span className="small d-flex">
                        <span>Activate</span>
                      </span>
                      </Button>
                    )}

                    <Button
                      variant="primary"
                      disabled={isSaving}
                      className="ml-3"
                      onClick={(e) => {
                        setIsSaving(true);
                        setShowSpinner(true);
                        dispatch(editOwnedSpace()).then((action) => {
                          if (!action.error) {
                            history.push(`/organisation/websettings`);
                          } else {
                            setIsSaving(false);
                            setShowSpinner(false);
                            const errors = action?.payload?.errors;
                            const keys = Object.keys(errors || {});
                            if (!errors || !keys.length) {
                              setSubmitMessages({type: "danger", body: "Could not save the settings, please try again."});
                            } else {
                              var msgs = [];
                              keys.forEach((errorKey) => {
                                const key = errorKey;
                                const splitKey = key.split(".");

                                if (splitKey.length === 1) {
                                  msgs.push(errors[`${key}`][0]);
                                }
                              });
                              setSubmitMessages({
                                type: "list",
                                body: msgs,
                              });
                            }
                          }
                        });
                      }}
                    >
                      <span className="small d-flex">
                        {showSpinner && isSaving && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                          />
                        )}
                        <span>Save Changes</span>
                      </span>
                    </Button>
                  </div>
                </div>
                <div className="mb-5">
                  <h5>General Settings</h5>
                  <hr />
                  <Container fluid>
                    <Row>
                      <Col>
                        <OwnedSpaceName />
                      </Col>
                      <Col>
                        <OwnedSpaceSlots />
                      </Col>
                      {
                        ownedSpace?.subformat === 'web' &&
                        <Col>
                          <OwnedSpaceCategory />
                        </Col>
                      }
                    </Row>
                  </Container>
                </div>
                <section>
                  <h5>Space Details</h5>
                  <hr />
                  <Container fluid>
                    <Row>
                      {ownedSpace.placement !== 'embedded' &&
                        <Col>
                          <OwnedUrls />
                        </Col>}
                        <Col>
                        <OwnedChannels />
                      </Col>
                      <Col>
                        <OwnedSpaceMonetisation />
                      </Col>
                      <Col>
                        <OwnedSpaceAllowedOrganisations />
                      </Col>
                      <Col>
                        <OwnedTemplates />
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Form>
            </Col>
          </Row>
          <Modal
            show={getTagModal}
            onHide={handleGetTagModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div class="text-secondary">Get embeddable tag</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {getTagModalData?.status === 'success' && <div>
                <p>Please embed this HTML tag into your website. <small>(Click on the <FontAwesomeIcon icon={faCopy} /> button to copy the code to your clipboard)</small></p>
                <code>
                  {getTagModalData.text}
                </code>

                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="ml-3"
                  onClick={(e) => {
                    if (getTagModalData.text) {
                      navigator.clipboard.writeText(getTagModalData.text);
                      setGetTagModalData({ ...getTagModalData, copyStatus: "Code copied!" })
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
                {getTagModalData.copyStatus && <span style={{ marginLeft: "10px" }}><small style={{ color: "grey" }}>{getTagModalData.copyStatus}</small></span>}
              </div>}
              {getTagModalData?.status === 'danger' && <div>
                <Alert variant="danger">
                  {getTagModalData.text}
                </Alert>
              </div>}
            </Modal.Body>
          </Modal>
        </Container>
      </Chrome>
    </>
  );
};

export default EditOwnedSpace;
