import { useState, useEffect } from 'react';

export const useRequest = (request) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const sendRequest = async() => {
      try {
          setLoading(true);
          const result = await request();
          setResponse(result.data);
      } catch (error) {
          setError(error);
      } finally {
          setLoading(false);
      }
  };

  useEffect(() => {
      sendRequest();
  }, []);

  return { response, error, loading, refresh: sendRequest };
};

export const useRequests = (requests) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const sendRequest = async() => {
        try {
            setLoading(true);
            const result = await Promise.all(requests());
            setResponse(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        sendRequest();
    }, []);

    return { response, error, loading, refresh: sendRequest };
};

