import React, { useState, useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Spinner,
  InputGroup,
  Alert,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  ModalFooter,
  Badge,
  Pagination,
} from "react-bootstrap";
import { debounce } from "debounce";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Chrome from "./layout/Chrome";
import {
  clearSession,
  refreshSession,
  selectRefreshStatus,
  selectSession,
  selectUser,
} from "../features/session/sessionSlice";
import { makeResponsiveTemplateEditRequest, updateOrganisationSettings, uploadOrganisationLogo } from "../lib/api";
import FacebookDefaultPageID from "../features/organisation_settings/components/FacebookDefaultPage";
import OrganisationList from "../features/organisation_settings/components/List";
import OrganisationAdSettings from "../features/organisation_settings/components/OrganisationAdSettings";
import { DevTool } from "@hookform/devtools";
import { selectSettings } from "../features/organisation_settings/organisationSettingsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $modalStyles from "./web_ads_modal.module.scss";
import {
  faPlus,
  faSpinner,
  faCheck,
  faCross,
  faTimes,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import $styles from "./organisation_web_settings.module.scss";
import {
  makeVirtualSpacesRequest,
  makeStaticTemplateCreateRequest,
  makeResponsiveTemplateRequest,
  makeStaticTemplateEditRequest,
} from "../lib/api";
import Breadcrumbs from "./components/Breadcrumbs";
import { useEffect } from "react";
import { getOwnedSpaces } from "../lib/api/ownedSpaces";
import ChannelPagination from "./components/ChannelPagination";

const percLoaded = (p) => parseInt((p.loaded / p.total) * 100);

const breadcrumbItems = [
  {
    path: "/organisation/settings",
    title: "Settings",
  },
  {
    path: "/organisation/channels",
    title: "Owned Channels",
  },
  {
    path: "/organisation/websettings",
    title: "Spaces & Templates",
    active: true,
  },
];

const OrganisationWebSettings = () => {
  let history = useHistory();
  const [formMessages, setFormMessages] = useState({});
  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const refreshStatus = useSelector(selectRefreshStatus);
  //const hasWebSpaces = session?.webspaces && session.webspaces.length > 0;
  const hasWebSpaces = session?.channels_enabled;
  const hasWebCategories =
    session?.webcategories && session.webcategories.length > 0;
  const hasWebTemplates =
    session?.webtemplates && session.webtemplates.length > 0;
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState(session.user.organisation);
  const webCategories = session?.webcategories ?? [];
  const [filterByWebSpaceCategory, setFilterByWebSpaceCategory] = useState([]);
  const [filterByWebSpaceFormat, setFilterByWebSpaceFormat] = useState([]);
  const [filterByWebTemplateFormat, setFilterByWebTemplateFormat] = useState(
    []
  );
  const [filterByWebSpaceName, setFilterByWebSpaceName] = useState(null);
  const [filterByWebTemplateName, setFilterByWebTemplateName] = useState(null);
  const [embedModal, setEmbedModal] = useState(false);
  const [embedModalForm, setEmbedModalForm] = useState({
    embedded_space: "banners",
  });

  // const [errors, setErrors] = useState({});
  const [logoProg, setLogoProg] = useState(null);
  const [tabSelection, setTabSelection] = useState(0);
  const [showVirtualSpaceModal, setVirtualSpaceModal] = useState(false);
  const [showEmbeddedSpaceModal, setEmbeddedSpaceModal] = useState(false);
  const [showStaticTemplateModal, setStaticTemplateModal] = useState(false);
  const [showResponsiveTemplateModal, setResponsiveTemplateModal] =
    useState(false);
  const [virtalSpacesform, setVirtalSpacesForm] = useState({
    name: "",
    displayLocation: "",
    format: "banners",
    subformat: 'web',
    notes: "",
    mediafile: "",
  });

  const [staticTemplateForm, setStaticTemplateForm] = useState({
    id: "",
    name: "",
    width: "",
    height: "",
    markup: "1",
  });

  const [responsiveTemplateForm, setResponsiveTemplateForm] = useState({
    name: "",
    notes: "",
    description: "",
    mediafile: "",
  });

  const clearResponsiveTemplateForm = () => {
    setResponsiveTemplateForm({
      name: "",
      notes: "",
      description: "",
      mediafile: "",
    });
    setSelectedResponsiveTemplateFile(null);
  };

  const [staticTemplateFormEnabled, setStaticTemplateFormEnabled] =
    useState(true);

  const [staticTemplateFormEditMode, setStaticTemplateFormEditMode] =
    useState(false);

  const [responsiveTemplateFormEnabled, setResponsiveTemplateFormEnabled] =
    useState(true);

  const [responsiveTemplateFormEditModal, setResponsiveTemplateFormEditModal] = useState(false)

  const handleStaticTemplateSubmit = (e) => {
    e.preventDefault();

    setFormMessages({});
    setStaticTemplateFormEnabled(false);

    makeStaticTemplateCreateRequest(
      user.active_organisation.id,
      staticTemplateForm.name,
      staticTemplateForm.width,
      staticTemplateForm.height,
      staticTemplateForm.markup
    )
      .then((resp) => {
        setStaticTemplateFormEnabled(true);
        setFormMessages({ success: true });
        clearStaticTemplateForm();
        dispatch(refreshSession());
        setStaticTemplateModal(false);
      })
      .catch((err) => {
        setStaticTemplateFormEnabled(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  const handleStaticTemplateEditSubmit = (e) => {
    e.preventDefault();

    setFormMessages({});
    setStaticTemplateFormEnabled(false);

    makeStaticTemplateEditRequest(
      user.active_organisation.id,
      staticTemplateForm.id,
      staticTemplateForm.name,
      staticTemplateForm.width,
      staticTemplateForm.height,
      staticTemplateForm.markup
    )
      .then((resp) => {
        setStaticTemplateFormEnabled(true);
        setFormMessages({ success: true });
        clearStaticTemplateForm();
        dispatch(refreshSession());
        setStaticTemplateModal(false);
      })
      .catch((err) => {
        setStaticTemplateFormEnabled(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  const handleResponsiveTemplateEditSubmit = e => {
    e.preventDefault()

    makeResponsiveTemplateEditRequest(user.active_organisation_id, responsiveTemplateForm.id, responsiveTemplateForm.name, 
        responsiveTemplateForm.description)
      .then(resp => {
        setFormMessages({ success: true });
        dispatch(refreshSession());
        handleResponsiveTemplateEditClose();
      })
      .catch((err) => {
        setStaticTemplateFormEnabled(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      })
  }

  const handleStaticTemplateFormChange = (field) =>
    setStaticTemplateForm({ ...staticTemplateForm, ...field });

  const handleResponsiveTemplateFormChange = (field) =>
    setResponsiveTemplateForm({ ...responsiveTemplateForm, ...field });

  const [embeddedSpacesform, setEmbeddedSpacesForm] = useState({
    format: "banners",
    subformat: 'web',
  });

  const [virtalSpacesformEnabled, setVirtalSpacesFormEnabled] = useState(true);
  const [selectedVirtalSpacesFile, setSelectedVirtalSpacesFile] =
    useState(null);

  const [selectedResponsiveTemplateFile, setSelectedResponsiveTemplateFile] =
    useState(null);

  const handleVirtalSpacesChange = (field) =>
    setVirtalSpacesForm({ ...virtalSpacesform, ...field });

  const clearStaticTemplateForm = () => {
    setStaticTemplateForm({
      id: "",
      name: "",
      width: "",
      height: "",
      markup: "1",
    });
  };

  const clearResponsiveTemplateEditForm = () => setResponsiveTemplateForm({
    name: "",
    notes: "",
    mediafile: "",
  })

  const clearVirtualSpaceRequestForm = () => {
    setVirtalSpacesForm({
      name: "",
      displayLocation: "",
      format: "banners",
      subformat: 'web',
      notes: "",
      mediafile: "",
    });
    setSelectedVirtalSpacesFile(null);
  };

  const handleResponsiveTemplateSubmit = (e) => {
    e.preventDefault();

    setFormMessages({});
    setResponsiveTemplateFormEnabled(false);

    makeResponsiveTemplateRequest(
      user.active_organisation.id,
      responsiveTemplateForm.name,
      responsiveTemplateForm.notes,
      selectedResponsiveTemplateFile
    )
      .then((resp) => {
        setResponsiveTemplateFormEnabled(true);
        setFormMessages({ success: true });
        clearResponsiveTemplateForm();
      })
      .catch((err) => {
        setResponsiveTemplateFormEnabled(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  const handleVirtalSpacesSubmit = (e) => {
    e.preventDefault();

    setFormMessages({});
    setVirtalSpacesFormEnabled(false);

    makeVirtualSpacesRequest(
      user.active_organisation.id,
      virtalSpacesform.name,
      virtalSpacesform.displayLocation,
      virtalSpacesform.format,
      virtalSpacesform.subformat,
      virtalSpacesform.notes,
      selectedVirtalSpacesFile
    )
      .then((resp) => {
        setVirtalSpacesFormEnabled(true);
        setFormMessages({ success: true });
        clearVirtualSpaceRequestForm();
      })
      .catch((err) => {
        setVirtalSpacesFormEnabled(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [webspaceData, setWebSpaceData] = useState([]);
  const [totalWebspacesCount, setTotalWebspacesCount] = useState(0);
  const [activeWebSpacesPage, setActiveWebSpacesPage] = useState(1);
  const [webspacesPaginationItems, setWebspacesPaginationItems] = useState([]);
  const [pageCount, setPageCount] = useState({firstItem: 0, lastItem: 0, total: 0});
  const [pageItemCount, setPageItemCount] = useState(30);

  let filter = {};

  useEffect(() => {
    filter = {};
    if (filterByWebSpaceCategory.length > 0) {
      filter = { ...filter, spaceCategory: filterByWebSpaceCategory }
    }
    if (filterByWebSpaceFormat.length > 0) {
      filter = { ...filter, spaceFormat: filterByWebSpaceFormat }
    }
    if (filterByWebSpaceName && filterByWebSpaceName.length > 0) {
      filter = { ...filter, spaceName: filterByWebSpaceName }
    }
    getOwnedSpaces(user.active_organisation.id, filter, pageItemCount, pageItemCount * (activeWebSpacesPage - 1)).then(res => {
      var data = res.data?.data;
      let total = res.data?.total;
      
    let { allItems: items, firstItem, lastItem } = ChannelPagination(pageItemCount,total,activeWebSpacesPage,setActiveWebSpacesPage);
      if (data) {
            setWebspacesPaginationItems(items);
            setWebSpaceData(data)
        } 
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    })
  }, [filterByWebSpaceCategory, filterByWebSpaceFormat, filterByWebSpaceName, activeWebSpacesPage])

  const webtemplateData =
    session?.webtemplates && session.webtemplates.length > 0
      ? session.webtemplates
        .filter((template) => {
          //filter by format
          if (filterByWebTemplateFormat.length > 0) {
            return filterByWebTemplateFormat.includes(template.format);
          }

          return true;
        })
        .filter((template) => {
          //filter by name
          if (filterByWebTemplateName && filterByWebTemplateName.length > 0) {
            return (
              template.name
                .toUpperCase()
                .indexOf(filterByWebTemplateName.toUpperCase()) >= 0
            );
          }

          return true;
        })
      : [];

  const methods = useForm({});

  const { control, formState } = methods;
  

  const handleFilterByWebSpaceNameValue = debounce((val) => {
    setFilterByWebSpaceName(val);
    setActiveWebSpacesPage(1);
  }, 500)

  const filterByWebSpaceCategories = (category) => {
    if (category == "all") {
      setFilterByWebSpaceCategory([]);
    } else {
      setFilterByWebSpaceCategory([category]);
    }
  };

  const filterByWebSpaceFormats = (format) => {
    if (format == "all") {
      setFilterByWebSpaceFormat([]);
    } else {
      setFilterByWebSpaceFormat([format]);
    }
  };

  const filterByWebTemplateFormats = (format) => {
    if (format == "all") {
      setFilterByWebTemplateFormat([]);
    } else {
      setFilterByWebTemplateFormat([format]);
    }
  };

  const filterByWebSpaceNameValue = (val) => {
    handleFilterByWebSpaceNameValue(val);
  };

  const filterByWebTemplateNameValue = (val) => {
    setFilterByWebTemplateName(val);
  };

  const ucfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const webspaceColumns = [
    {
      dataField: "rowNo",
      text: "#",
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span>{pageItemCount * (activeWebSpacesPage - 1) + rowIndex + 1}</span>
        </div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => {
        return (
          <div class="d-flex justify-content-between align-items-baseline">
            <a
              href="javascript:void(0)"
              className={$styles.spaceLink}
              onClick={(e) =>
                history.push("/organisation/websettings/edit/" + row.id)
              }
            >
              {/* {!row?.is_active && (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  size="1x"
                  style={{ marginRight: "3px", color: "red" }}
                />
              )}{" "} */}

              {row.name}
            </a>
            {row?.is_active && (
              <Badge
                className={[
                  $styles.statusBadge,
                  $styles.statusBadgeActive,
                ].join(" ")}
              >
                Active
              </Badge>
            )}

            {!row?.is_active && (
              <Badge
                className={[
                  $styles.statusBadge,
                  $styles.statusBadgeInactive,
                ].join(" ")}
              >
                Inactive
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      dataField: "category",
      text: "Category",
      formatter: (cell, row) => {
        return row?.owned_category?.name;
      },
    },
    {
      dataField: "format",
      text: "Format",
      formatter: ucfirst,
    },
    {
      dataField: "subformat",
      text: "Subformat",
      formatter: ucfirst,
    },
    {
      dataField: "slots",
      text: "Slots",
    },
    {
      dataField: "templates",
      text: "Templates",
      formatter: (cell, row) => {
        return row?.owned_templates?.length;
      },
    },
    {
      dataField: "placement",
      text: "Placement",
      formatter: ucfirst,
    },
    {
      dataField: "baserate",
      text: "Base Rate",
      formatter: (cell, row) => {
        return "$ " + row?.slot_price?.toFixed(2);
      },
    },
    // {
    //   dataField: "suppliers",
    //   text: "Suppliers",
    //   formatter: (cell, row) => {
    //     if (row?.allowed_organisations.type === "whitelist") {
    //       return <a href="">View Whitelist</a>;
    //     } else if (row?.allowed_organisations.type === "all") {
    //       return <div>All</div>;
    //     } else if (row?.allowed_organisations.type === "blacklist") {
    //       return <a href="">View Blacklist</a>;
    //     }
    //     return "";
    //   },
    // },
  ];

  const webtemplateColumns = [
    {
      dataField: "rowNo",
      text: "#",
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span>{rowIndex + 1}</span>
        </div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          {row["subformat"] === "static" && (
            <span>
              <a
                href="javascript:void(0)"
                onClick={(e) => {
                  setStaticTemplateForm({
                    id: row.id,
                    name: row.name,
                    width: row?.data?.width,
                    height: row?.data?.height,
                    markup: row.markup_price,
                  });
                  setStaticTemplateFormEditMode(true);
                  setStaticTemplateModal(true);
                }}
              >
                {row["name"]}
              </a>
            </span>
          )}
          {row["subformat"] === "responsive" &&
            <a
              href="javascript:void(0)"
              onClick={e => {
                setResponsiveTemplateForm({
                  id: row.id,
                  name: row.name,
                  description: row.description
                });
                setResponsiveTemplateFormEditModal(true)
              }}
            >
              {row["name"]}
            </a>}
        </div>
      ),
    },
    {
      dataField: "format",
      text: "Format",
      formatter: ucfirst,
    },
    {
      dataField: "subformat",
      text: "Sub-Format",
      formatter: ucfirst,
    },
    // {
    //   dataField: "markup",
    //   text: "Markup",
    //   formatter: (cell, row) => {
    //     return "$ " + row?.markup_price?.toFixed(2);
    //   },
    // },
    // {
    //   dataField: "suppliers",
    //   text: "Suppliers",
    //   formatter: (cell, row) => {
    //     if (row?.allowed_organisations === "whitelist") {
    //       return <a href="">View Whitelist</a>;
    //     } else if (row?.allowed_organisations === "all") {
    //       return <div>All</div>;
    //     } else if (row?.allowed_organisations === "blacklist") {
    //       return <a href="">View Blacklist</a>;
    //     }
    //     return "";
    //   },
    // },
  ];

  const webSpaceNoDataForFilter = () => {
    return (
      <div className={$styles.webSpaceNoDataForFilter}>
        There are no owned web spaces defined for this filter
      </div>
    );
  };

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };

    return (
      <li className="page-item" style={{ float: "right" }}>
        <a
          href="#"
          onClick={handleClick}
          // className={['btn', $styles.tablePageButton, active ? 'active': ''].join(' ')}
          style={{ margin: "2px" }}
          className={
            "btn " + (active ? "btn-primary" : "btn-outline-primary")
          }
        >
          {page}
        </a>
      </li>
    );
  };

  const handleVirtualSpaceClose = () => {
    setVirtualSpaceModal(false);
    clearVirtualSpaceRequestForm();
    setFormMessages({});
    document.body.click();
  };

  const handleEmbeddedSpaceClose = () => {
    setEmbeddedSpaceModal(false);
    document.body.click();
  };

  const handleStaticTemplateClose = () => {
    setStaticTemplateFormEditMode(false);
    setStaticTemplateModal(false);
    setFormMessages({});
    clearStaticTemplateForm();
    document.body.click();
  };

  const handleResponsiveTemplateClose = () => {
    setResponsiveTemplateModal(false);
    clearResponsiveTemplateForm();
    setFormMessages({});
    document.body.click();
  };

  const handleResponsiveTemplateEditClose = () => {
    setResponsiveTemplateFormEditModal(false)
    clearResponsiveTemplateEditForm();
  }

  const options = {
    pageButtonRenderer,
  };

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === "true" && (
        <DevTool control={control} />
      )}
      <Helmet>
        <title>
          Web Settings for Organisation{" "}
          {organisation?.name ?? session.user?.active_organisation?.name} -
          {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className="pt-4">
          <Breadcrumbs items={breadcrumbItems} />
          <Form>
            <Row className="mb-3">
              <Col>
                <div className="rounded my-4 d-flex">
                  <div className="d-inline-block">
                    <h3 className={$styles.topHeading}>Web Channel Settings</h3>
                  </div>
                </div>
                <ToggleButtonGroup
                  type="radio"
                  defaultValue={tabSelection}
                  name="options"
                  className="mb-2"
                  onChange={(v) => setTabSelection(v)}
                >
                  <ToggleButton
                    id="tbg-check-1"
                    value={0}
                    className={[
                      $styles.toggleButton,
                      tabSelection === 0 ? "active" : "",
                    ].join(" ")}
                  >
                    Spaces
                  </ToggleButton>
                  <ToggleButton
                    id="tbg-check-2"
                    value={1}
                    className={[
                      $styles.toggleButton,
                      tabSelection === 1 ? "active" : "",
                    ].join(" ")}
                  >
                    Templates
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Row>
            <div style={{ display: tabSelection === 0 ? "block" : "none" }}>
              <Row>
                <Col class="md-3">
                  <form class="form-inline" style={{ float: "right" }} onSubmit={(e) => e.preventDefault()}>
                    {hasWebCategories && (
                      <select
                        class="custom-select mb-2 mr-sm-2"
                        id="inlineFormCustomSelectPref"
                        onChange={(e) =>
                          filterByWebSpaceCategories(e.target.value)
                        }
                      >
                        <option value="all" selected>
                          All Categories
                        </option>
                        {webCategories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    )}
                    <select
                      class="custom-select mb-2 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      onChange={(e) => filterByWebSpaceFormats(e.target.value)}
                    >
                      <option value="all" selected>
                        All Types
                      </option>
                      <option value="banners">Banners</option>
                      <option value="product">Product</option>
                    </select>

                    <div class="input-group mb-2 mr-sm-2">
                      <input
                        type="text"
                        class="form-control"
                        id="search"
                        placeholder="Search"
                        onChange={(e) =>
                          filterByWebSpaceNameValue(e.target.value)
                        }
                      />
                    </div>
                    <DropdownButton
                      as={ButtonGroup}
                      className={`mb-2 ${$styles.newSpaceBtn}`}
                      key={"down"}
                      id={`dropdown-button-drop-right`}
                      drop={"down"}
                      variant="outline-secondary"
                      title={
                        <span>
                          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> New
                          Space
                        </span>
                      }
                    >
                      <Dropdown.Item
                        className={"mb-2"}
                        eventKey="1"
                        onClick={(e) => setVirtualSpaceModal(true)}
                      >
                        Virtual Space
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={"mb-2"}
                        eventKey="2"
                        onClick={(e) => setEmbeddedSpaceModal(true)}
                      >
                        Embedded Space
                      </Dropdown.Item>
                    </DropdownButton>
                  </form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="d-flex my-2 justify-content-between">
                    {/* <span>Showing 1 - 10 / 214</span>
                    <span className={$styles.markedBaseRateText}>* Marked Base Rates has been inherited from Category</span> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {isLoading && (
                    <div className="text-center d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
                      <FontAwesomeIcon
                        className='fa-spin'
                        style={{ cursor: 'pointer' }}
                        icon={faSpinner} size="4x" />
                    </div>
                  )}
                  {hasWebSpaces && !isLoading && (
                    <div>
                      <BootstrapTable
                        keyField="rowNo"
                        headerWrapperClasses={$styles.webspacesTemplatesHeader}
                        data={webspaceData}
                        columns={webspaceColumns}
                        noDataIndication={webSpaceNoDataForFilter}
                      />
                      <div className="table-responsive mb-2">
                          <Pagination className="mb-0" style={{ overflowY: "auto" }}>{webspacesPaginationItems}</Pagination>
                      </div>
                    </div>
                  )}
                  {!hasWebSpaces && (
                    <p>There are no owned web spaces defined.</p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div>&nbsp;</div>
                </Col>
              </Row>
            </div>
            <div style={{ display: tabSelection === 1 ? "block" : "none" }}>
              <Row>
                <Col>
                  <form class="form-inline" style={{ float: "right" }} onSubmit={(e) => e.preventDefault()}>
                    <select
                      class="custom-select mb-2 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      onChange={(e) =>
                        filterByWebTemplateFormats(e.target.value)
                      }
                    >
                      <option value="all" selected>
                        All Formats
                      </option>
                      <option value="banners">Banners</option>
                      <option value="product">Product</option>
                    </select>

                    <div class="input-group mb-2 mr-sm-2">
                      <input
                        type="text"
                        class="form-control"
                        id="search"
                        placeholder="Search"
                        onChange={(e) =>
                          filterByWebTemplateNameValue(e.target.value)
                        }
                      />
                    </div>
                    <Button variant="outline-secondary" className={"mb-2"} key={"down"}
                      id={`dropdown-button-drop-right`}
                      onClick={(e) => setResponsiveTemplateModal(true)}>New Template</Button>
                  </form>
                </Col>
              </Row>
              <Row>
                <Col>
                  {refreshStatus === 'loading' && (
                    <div className="text-center d-flex align-items-center justify-content-center" style={{ height: "300px" }}>
                      <FontAwesomeIcon
                        className='fa-spin'
                        style={{ cursor: 'pointer' }}
                        icon={faSpinner} size="4x" />
                    </div>
                  )}
                  {hasWebTemplates && refreshStatus !== 'loading' && (
                    <div>
                      <BootstrapTable
                        keyField="rowNo"
                        headerWrapperClasses={$styles.webspacesTemplatesHeader}
                        data={webtemplateData}
                        columns={webtemplateColumns}
                        pagination={paginationFactory(options)}
                        noDataIndication={webSpaceNoDataForFilter}
                      />
                    </div>
                  )}
                  {!hasWebTemplates && (
                    <p>There are no owned web templates defined.</p>
                  )}
                </Col>
              </Row>
            </div>
          </Form>
          <Modal
            className={$modalStyles.modal}
            show={showVirtualSpaceModal}
            onHide={handleVirtualSpaceClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div class="text-secondary">Request a new virtual space</div>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleVirtalSpacesSubmit(e)}>
              <Modal.Body>
                <Container>
                  {formMessages.success && (
                    <Alert variant={"success"}>
                      Your request for a new virtual space has been sent
                      successfully.
                    </Alert>
                  )}
                  {formMessages.failed && (
                    <Alert variant={"danger"}>
                      There was an error sending this request, please try again.
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>What is the name of this space?</Form.Label>
                    <Form.Control
                      value={virtalSpacesform.name}
                      onChange={(e) =>
                        handleVirtalSpacesChange({ name: e.target.value })
                      }
                      disabled={virtalSpacesformEnabled ? false : true}
                      type="text"
                    />
                    {formMessages?.errors?.name && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.name}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Where should this ad display?</Form.Label>
                    <Form.Control
                      value={virtalSpacesform.displayLocation}
                      onChange={(e) =>
                        handleVirtalSpacesChange({
                          displayLocation: e.target.value,
                        })
                      }
                      disabled={virtalSpacesformEnabled ? false : true}
                      type="text"
                    />
                    {formMessages?.errors?.displayLocation && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.displayLocation}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFile">
                    <Form.Label>
                      Upload an image of desired look or placement of your space
                      if applicable. (Optional)
                    </Form.Label>
                    <Form.Control
                      type="file"
                      value={virtalSpacesform.mediafile}
                      disabled={virtalSpacesformEnabled ? false : true}
                      onChange={(e) => {
                        handleVirtalSpacesChange({ mediafile: e.target.value });
                        setSelectedVirtalSpacesFile(e.target.files[0]);
                      }}
                    />
                    {formMessages?.errors?.mediafile && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.mediafile}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      What is the format of the ad in this space?
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={
                          virtalSpacesform.format === "banners" ? true : false
                        }
                        onChange={(e) =>
                          handleVirtalSpacesChange({ format: e.target.value })
                        }
                        disabled={virtalSpacesformEnabled ? false : true}
                        value={"banners"}
                        inline
                        type="radio"
                        name="format"
                        label="Banners"
                      />
                      <Form.Check
                        checked={
                          virtalSpacesform.format === "products" ? true : false
                        }
                        value={"products"}
                        onChange={(e) =>
                          handleVirtalSpacesChange({ format: e.target.value })
                        }
                        disabled={virtalSpacesformEnabled ? false : true}
                        inline
                        type="radio"
                        name="format"
                        label="Products"
                      />
                    </div>
                    {formMessages?.errors?.format && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.format}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      What is the subformat of the ad in this space?
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={virtalSpacesform.subformat === 'web'}
                        onChange={(e) => handleVirtalSpacesChange({ subformat: e.target.name })}
                        disabled={virtalSpacesformEnabled ? false : true}
                        inline
                        type="radio"
                        name="web"
                        label="Web"
                      />
                      <Form.Check
                        checked={virtalSpacesform.subformat === 'search'}
                        onChange={(e) => handleVirtalSpacesChange({ subformat: e.target.name })}
                        disabled={virtalSpacesformEnabled ? false : true}
                        inline
                        type="radio"
                        name="search"
                        label="Search"
                      />
                    </div>
                    {formMessages?.errors?.subformat && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.subformat}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Additional Notes (Optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={virtalSpacesform.notes}
                      onChange={(e) =>
                        handleVirtalSpacesChange({ notes: e.target.value })
                      }
                      disabled={virtalSpacesformEnabled ? false : true}
                      rows={3}
                    />
                  </Form.Group>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  type="submit"
                  disabled={virtalSpacesformEnabled ? false : true}
                >
                  {!virtalSpacesformEnabled && (
                    <FontAwesomeIcon
                      className="fa-spin"
                      icon={faSpinner}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            className={$modalStyles.modal}
            show={showEmbeddedSpaceModal}
            onHide={handleEmbeddedSpaceClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div class="text-secondary">Create a new Embedded Space</div>
              </Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                history.push(
                  "/organisation/websettings/create/embedded/" +
                  embeddedSpacesform.format + '/' + embeddedSpacesform.subformat
                );
              }}
            >
              <Modal.Body>
                <Container>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      What is the format of the ad in this space?
                    </Form.Label>
                    <div>
                      <Form.Check
                        value={"banners"}
                        checked={embeddedSpacesform.format === "banners"}
                        inline
                        type="radio"
                        name="format"
                        label="Banners"
                        onClick={(e) =>
                          setEmbeddedSpacesForm({ ...embeddedSpacesform, format: e.target.value })
                        }
                      />
                      <Form.Check
                        value={"product"}
                        checked={embeddedSpacesform.format === "product"}
                        inline
                        type="radio"
                        name="format"
                        label="Product"
                        onClick={(e) =>
                          setEmbeddedSpacesForm({ ...embeddedSpacesform, format: e.target.value })
                        }
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      What is the subformat of the ad in this space?
                    </Form.Label>
                    <div>
                      <Form.Check
                        checked={embeddedSpacesform.subformat === 'web'}
                        onChange={(e) => setEmbeddedSpacesForm({ ...embeddedSpacesform, subformat: e.target.name })}
                        inline
                        type="radio"
                        name="web"
                        label="Web"
                      />
                      <Form.Check
                        checked={embeddedSpacesform.subformat === 'search'}
                        onChange={(e) => setEmbeddedSpacesForm({ ...embeddedSpacesform, subformat: e.target.name })}
                        inline
                        type="radio"
                        name="search"
                        label="Search"
                      />
                    </div>
                  </Form.Group>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="submit">
                  Create Space
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            className={$modalStyles.modal}
            show={showStaticTemplateModal}
            onHide={handleStaticTemplateClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {!staticTemplateFormEditMode && (
                  <div class="text-secondary">Create a New Static Template</div>
                )}
                {staticTemplateFormEditMode && (
                  <div class="text-secondary">Edit Static Template</div>
                )}
              </Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={(e) =>
                !staticTemplateFormEditMode
                  ? handleStaticTemplateSubmit(e)
                  : handleStaticTemplateEditSubmit(e)
              }
            >
              <Modal.Body>
                <Container>
                  {formMessages.failed && (
                    <Alert variant={"danger"}>
                      There was an error sending this request, please try again.
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>What is the name of this Template?</Form.Label>
                    <Form.Control
                      disabled={staticTemplateFormEnabled ? false : true}
                      value={staticTemplateForm.name}
                      onChange={(e) =>
                        handleStaticTemplateFormChange({ name: e.target.value })
                      }
                      type="text"
                    />
                    {formMessages?.errors?.name && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        {formMessages.errors.name}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Width of this template (px)</Form.Label>
                    <Form.Control
                      value={staticTemplateForm.width}
                      disabled={staticTemplateFormEnabled ? false : true}
                      onChange={(e) =>
                        handleStaticTemplateFormChange({
                          width: e.target.value,
                        })
                      }
                      type="text"
                    />
                    {formMessages?.errors?.width && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        {formMessages.errors.width}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Height of this template (px)</Form.Label>
                    <Form.Control
                      value={staticTemplateForm.height}
                      disabled={staticTemplateFormEnabled ? false : true}
                      onChange={(e) =>
                        handleStaticTemplateFormChange({
                          height: e.target.value,
                        })
                      }
                      type="text"
                    />
                    {formMessages?.errors?.height && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        {formMessages.errors.height}
                      </div>
                    )}
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Markup price</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        value={staticTemplateForm.markup}
                        disabled={staticTemplateFormEnabled ? false : true}
                        onChange={(e) =>
                          handleStaticTemplateFormChange({
                            markup: e.target.value,
                          })
                        }
                      />
                      {formMessages?.errors?.markup && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            width: "100%",
                          }}
                        >
                          {formMessages.errors.markup}
                        </div>
                      )}
                    </InputGroup>
                  </Form.Group> */}
                </Container>
              </Modal.Body>
              <Modal.Footer>
                {!staticTemplateFormEditMode && (
                  <Button variant="secondary" type="submit">
                    Create Template
                  </Button>
                )}
                {staticTemplateFormEditMode && (
                  <Button variant="secondary" type="submit">
                    Update Template
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            className={$modalStyles.modal}
            show={showResponsiveTemplateModal}
            onHide={handleResponsiveTemplateClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div class="text-secondary">
                  Request a new Responsive Template
                </div>
              </Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => handleResponsiveTemplateSubmit(e)}>
              {formMessages.success && (
                <Alert variant={"success"}>
                  Your request for a new responsive template has been sent
                  successfully.
                </Alert>
              )}
              <Modal.Body>
                <Container>
                  {formMessages.failed && (
                    <Alert variant={"danger"}>
                      There was an error sending this request, please try again.
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>What is the name of this Template?</Form.Label>
                    <Form.Control
                      value={responsiveTemplateForm.name}
                      disabled={responsiveTemplateFormEnabled ? false : true}
                      onChange={(e) =>
                        handleResponsiveTemplateFormChange({
                          name: e.target.value,
                        })
                      }
                      type="text"
                    />
                    {formMessages?.errors?.name && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.name}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      What are the key points of this banner?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="e.g. Horizontal carousel with 4 products"
                      value={responsiveTemplateForm.notes}
                      onChange={(e) =>
                        handleResponsiveTemplateFormChange({
                          notes: e.target.value,
                        })
                      }
                      disabled={responsiveTemplateFormEnabled ? false : true}
                      rows={3}
                    />
                    {formMessages?.errors?.notes && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.notes}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formFile">
                    <Form.Label>Image upload (Optional)</Form.Label>
                    <Form.Control
                      type="file"
                      value={responsiveTemplateForm.mediafile}
                      disabled={responsiveTemplateFormEnabled ? false : true}
                      onChange={(e) => {
                        handleResponsiveTemplateFormChange({
                          mediafile: e.target.value,
                        });
                        setSelectedResponsiveTemplateFile(e.target.files[0]);
                      }}
                    />
                    {formMessages?.errors?.mediafile && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formMessages.errors.mediafile}
                      </div>
                    )}
                  </Form.Group>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="submit">
                  Submit Request
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            className={$modalStyles.modal}
            show={responsiveTemplateFormEditModal}
            onHide={handleResponsiveTemplateEditClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div class="text-secondary">Edit Responsive Template</div>
              </Modal.Title>
            </Modal.Header>
            <Form
              onSubmit={(e) => {
                handleResponsiveTemplateEditSubmit(e)
              }}
            >
              <Modal.Body>
                <Container>
                  {formMessages.failed && (
                    <Alert variant={"danger"}>
                      There was an error sending this request, please try again.
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>What is the name of this Template?</Form.Label>
                    <Form.Control
                      value={responsiveTemplateForm.name}
                      onChange={(e) =>
                        handleResponsiveTemplateFormChange({ name: e.target.value })
                      }
                      type="text"
                    />
                    {formMessages?.errors?.name && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        {formMessages.errors.name}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      value={responsiveTemplateForm.description}
                      onChange={(e) =>
                        handleResponsiveTemplateFormChange({ description: e.target.value })
                      }
                      as="textarea"
                    />
                    {formMessages?.errors?.description && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          width: "100%",
                        }}
                      >
                        {formMessages.errors.description}
                      </div>
                    )}
                  </Form.Group>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="submit">
                  Update Template
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationWebSettings;
