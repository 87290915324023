import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { AsyncTypeahead, Token } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { selectSession } from "../../../../features/session/sessionSlice";
import { getAudienceInterests } from "../../../../lib/api";
import { selectOrder } from "../../orderFormSlice";

const interestLabel = (platform, int) => {
  if (int.disambiguation_category) {
    return `${int.name} (${int.disambiguation_category})`;
  }
  return int.name;
};

const InterestRow = ({
  id,
  readOnly = false,
  required = false,
  platform,
  value = { name: "" },
  onChange = () => {},
  onAddClick = () => {},
}) => {
  const session = useSelector(selectSession);
  const order = useSelector(selectOrder);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [singleSelections, setSingleSelections] = useState(
    value.name ? [value.name] : []
  );
  const handleChange = (obj) => onChange({ ...value, ...obj });

  return (
    <Row className="mb-2">
      <Col className="d-flex justify-content-around">
        <InputGroup className="mt-2">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <FontAwesomeIcon icon={faPlus} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          {readOnly ? (
            <Form.Control
              readOnly={readOnly}
              value={interestLabel(platform, value)}
            />
          ) : (
            <AsyncTypeahead
              id={id}
              isLoading={isLoading}
              disabled={readOnly}
              style={{
                flex: "1 0",
              }}
              renderMenuItemChildren={(val, props) => {
                return (
                  <Token active disabled={false}>
                    {val.name}
                  </Token>
                );
              }}
              onSearch={(query) => {
                setIsLoading(true);
                getAudienceInterests(platform, order.owner_id, query).then(
                  (resp) => {
                    setOptions(resp.data.data);
                    setIsLoading(false);
                  }
                );
              }}
              onChange={(selected) => {
                setSingleSelections(selected);
                onAddClick(selected[0]);
                setSingleSelections([]);
              }}
              options={options.map((interest, i) => ({
                id: interest.id || i,
                ...interest,
                label: interestLabel(platform, interest),
              }))}
              selected={singleSelections}
            />
          )}
        </InputGroup>
      </Col>
    </Row>
  );
};

InterestRow.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  value: PropTypes.shape({ name: PropTypes.string }),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};
export default InterestRow;
