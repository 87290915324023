import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import OrdersGrid from "../../features/orders/components/OrdersGrid";
import { selectAllOrders, selectIsLoading } from "../../features/orders/ordersSlice";
import { strcmp } from "../../lib/strings";

const sortBy = [
    { value: "status", label: "Status" },
    { value: "name", label: "Name" },
    { value: "start_date", label: "Start Date" },
    { value: "end_date", label: "End Date" },
    { value: "created_date", label: "Created Date" },
    { value: "last_updated", label: "Last Updated" },
];

const sortByFuncs = {
    status: (a, b) => strcmp(a.status.toLowerCase(), b.status.toLowerCase()),
    name: (a, b) => strcmp(a.name.toLowerCase(), b.name.toLowerCase()),
    start_date: (a, b) => new Date(b.start_at) - new Date(a.start_at),
    end_date: (a, b) => new Date(b.stop_at) - new Date(a.stop_at),
    created_date: (a, b) => new Date(b.created_at) - new Date(a.created_at),
    last_updated: (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
    campaign_type: (a, b) => strcmp(a.format, b.format),
    supplier: (a, b) => strcmp(a.supplier_id, b.supplier_id),
    retailer: (a, b) => strcmp(a.owner_id, b.owner_id),
};

const DashboardOrders = () => {
    const orders = useSelector(selectAllOrders);
    const isLoadingOrders = useSelector(selectIsLoading);
    const [filter, setFilter] = useState({
        sort_by: "start_date",
        status: "pending_approval",
        platform: "",
    });

    return (
        <div>
            <div className="d-flex align-items-center">
                <h5 className="mb-0">
                    New Orders (Up to 20) <Link to="/orders">(All Orders)</Link>
                </h5>
                <div className="ml-auto">
                    <Form.Group className="mb-0 d-flex align-items-center">
                        <Form.Label className="mb-0" style={{ width: "7rem" }}>
                            Sort by:
                        </Form.Label>
                        <Form.Control
                            as="select"
                            value={filter.sort_by}
                            onChange={(e) =>
                                setFilter({ ...filter, sort_by: e.target.value })
                            }
                        >
                            {sortBy.map((sort) => (
                                <option key={sort.value} value={sort.value}>
                                    {sort.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </div>
            </div>
            <hr />
            <OrdersGrid
                loading={isLoadingOrders}
                orders={orders
                    .filter((order) => order.status === "pending_approval")
                    .sort(sortByFuncs[filter.sort_by])}
            />
        </div>
    );
};

export default DashboardOrders
