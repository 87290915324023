import {faProductHunt} from "@fortawesome/free-brands-svg-icons";
import {faArrowDown, faBook, faPhotoVideo, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {
  faBullseye,
  faBullseyeArrow,
  faCalendar,
  faChartNetwork,
  faMoneyBill,
  faPlus
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {debounce, sortBy} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tab, Tooltip} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Spinner from "../../app/components/Spinner";
import {adPreview, updateOrder} from "../../lib/api";
import {STATUS_APPROVED, STATUS_COMPLETED, STATUS_LIVE, STATUS_PAUSED} from "../../lib/orders";
import {packageBudgetRange, packageFieldHumanDateRangeText} from "../../lib/packages";
import {PLATFORM_MERCATO} from "../../lib/platforms";
import {FACEBOOK_DISPLAY} from "../../lib/webads";
import {clearSession, selectAuthToken, selectFacebookPlacements, selectSession} from "../session/sessionSlice";
import AdContainer from "./AdContainer";
import styles from "./ad_section.module.scss";
import AdBudget from "./components/AdBudget";
import AdDateRange from "./components/AdDateRange";
import AdGrid from "./components/AdGrid";
import {Notification} from "./components/AssetsManager";
import FacebookCustomAudiences from "./components/audience/FacebookCustomAudiences";
import FacebookLookalikeAudiences from "./components/audience/FacebookLookalikeAudiences";
import FacebookSavedAudiences from "./components/audience/FacebookSavedAudiences";
import GoogleRemarketingAudiences from "./components/audience/GoogleRemarketingAudiences";
import DynamicField from "./components/DynamicField";
import Errors from "./components/Errors";
import PreviewDropdown from "./components/facebook_placements/PreviewDropdown";
import Budget from "./components/MercatoDevice/Budget";
import Preview from "./components/Preview";
import PreviewSection from "./components/PreviewSection";
import PropertiesBody from "./components/PropertiesBody";
import PropertiesSection from "./components/PropertiesSection";
import SelectAudience from "./components/SelectAudience";
import AssetsPane from "./components/standard_ads/AssetsPane";
import MercatoDevicePlacement from "./containers/MercatoDevices/MercatoDevicePlacement";
import OrderFacebookProducts from "./containers/OrderFacebookProducts";
import OrderGoogleProducts from "./containers/OrderGoogleProducts";
import ConnectedInsightsView from "./metrics/ConnectedInsightsView";
import {
  budgetUpdated,
  clearOrder,
  errorsUpdate,
  hasLiveOrderBeingViewed,
  orderUpdated,
  selectAssets,
  selectAvailableMercatoDevices,
  selectOrder,
  selectOrderAds,
  selectOrderFormPermissions,
  selectOwnerOrganisation,
  showBidAmount,
  showBudget
} from "./orderFormSlice";

/* NOTE: This is not final implementation, data eventually will come from backend like fields for each ad type  */
/* This code pushed to avoid delaying other stuff, refactor is already in place */

const propertiesTabsList = [
  {
    id: "schedule",
    title: "Schedule",
    description: "When do you want your ad to run?",
    icon: faCalendar,
    completedCheck: ({ ad, errors }) => ad.start_at && ad.stop_at && !errors.start_at && !errors.stop_at,
    optional: false,
  },
  {
    id: "ad-goals",
    title: "Objective",
    description: "What are you trying to achieve with this ad",
    icon: faBullseyeArrow,
    completedCheck: ({ ad, adType, errors }) => ad.goal != null,
    optional: false,
  },
  {
    id: "ad-details",
    title: "Content",
    description: "Add the content that best delivers your ad",
    icon: faArrowDown,
    completedCheck: ({ ad, adType, errors }) => {
      const fields = adType.fields.filter(field => field.type !== 'asset').filter(field => !field.deprecated_at || !ad.created_at || moment(field.deprecated_at).isAfter(ad.created_at))
      const fieldIDs = fields.map(field => field.id)
      const required = fields.filter(field => field.required === true)
      const dynamicFields = fields.filter(field => field.type === 'dynamic_row')
      const dynamicFieldsRequired = dynamicFields.every(field => {
        const required = field.fields_template.filter(fld => fld.required === true)
        if (required.length === 0) {
          return true;
        }
        const value = ad[field.id]
        let rowCount = field.maxrows
        if (field.match_media && ad.media?.length > 0) {
          rowCount = ad.media.length
        }

        return [...Array(field.maxrows)]
          .slice(0, rowCount)
          .every((_, i) =>
            required.every(fld => value?.[`${fld.id}-${field.index_start + i}`])
          )
      })

      return required.every(field => ad[field.id]) && dynamicFieldsRequired && !fieldIDs.some(id => errors?.[id]?.length > 0)
    },
    optional: false,
  },
  {
    id: "budget",
    title: "Budget",
    description: "How much would you like to spend?",
    icon: faMoneyBill,
    completedCheck: ({ ad, errors }) => ad.budget > 0 && !errors.budget,
    optional: false,
  },
  {
    id: "audiences",
    title: "Audiences",
    description: "How are you targeting?",
    icon: faBullseye,
    completedCheck: ({ ad }) => Object.values(ad.selected_audience || {}).some(audiences => Array.isArray(audiences) ? audiences.length > 0 : Object.keys(audiences).length > 0),
    optional: false,
  },
  {
    id: "assets",
    title: "Assets",
    description: null,
    icon: faPhotoVideo,
    completedCheck: ({ ad, adType }) => {
      const required = adType.fields.filter(field => field.required === true && field.type === 'asset')
      return required.every(field => ad[field.id]?.length > 0)
    },
    optional: false,
  },
  {
    id: "feed",
    title: "Products",
    description: null,
    icon: faProductHunt,
    completedCheck: ({ ad, adType }) => {
      const required = adType.fields.filter(field => field.required === true && field.type === 'feed')
      return required.every(field => ad[field.id]?.products?.length > 0)
    },
    optional: false,
  },
  // Currently there are no advanced fields for extension channel ads.
  // {
  //   id: "advanced",
  //   title: "Advanced",
  //   description: "Do you want to override the default dates for this ad type?",
  //   icon: faCog,
  //   completedCheck: ({ ad }) => ad.start_at && ad.stop_at,
  //   optional: true,
  // },
  {
    id: "metrics",
    title: "Metrics",
    description: "How is the performance of your ad?",
    icon: faBook,
    completedCheck: () => false,
    optional: true,
  },
  {
    id: "assets",
    title: "Execution",
    description: "Upload your assets",
    icon: faPhotoVideo,
    completedCheck: ({ ad, adType }) => {
      const required = adType.fields.filter(field => field.type === 'asset')
      return required.every(field => ad[field.id]?.length > 0)
    },
    optional: false,
  },
];

const mercatoDeviceAdTabs = [
  {
    id: "placement",
    title: "Placement",
    description: "Where do you want your ad to appear?",
    icon: faChartNetwork,
    completedCheck: ({ ad }) => ad.selectedDevices && Array.isArray(ad.selectedDevices) && ad.selectedDevices.length > 0,
    optional: false,
  }
]

const filterInsights = (ad, insights) => {

  const filtered = {};
  let filter = [];

  if (ad.format === "youtube_bumper") { filter = ['cpv', 'views', 'view_rate'] }

  Object.keys(insights ?? {}).map(insight => {
    if (!filter.includes(insight)) {
      filtered[insight] = insights[insight]
    }
  });

  return filtered;
}

const StandardAdSection = React.forwardRef((props, ref) => {
  const {
    controlId,
    readOnly = false,
    ad = {},
    assets = [],
    isNew = false,
    errors = {},
    onChange = () => {
    },
    onDelete = () => {
    },
  } = props;
  const session = useSelector(selectSession);
  const defCurrency = session.user?.active_organisation?.settings?.['defaultCurrency'] ?? 'AUD';
  const currency = (session.currencies).find(s => s.code === defCurrency)?.symbol;
  const userActiveOrganisation = session.user?.active_organisation;
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const showAdBudget = useSelector(showBudget);
  const showAdBidAmount = useSelector(showBidAmount);
  const adSelectedAudience = ad?.selected_audience;
  const selectedFacebookCustomAudiences =
      adSelectedAudience?.facebook_custom_audiences || [];
  const selectedFacebookSavedAudiences =
    adSelectedAudience?.facebook_saved_audiences || [];
  const selectedFacebookLookalikeAudiences =
    adSelectedAudience?.facebook_lookalike_audiences || [];
  const selectedGoogleRemarketingAudiences =
    adSelectedAudience?.google_remarketing_audiences || [];
  const structure = session.ad_types.find(
    (ad_type) => ad_type.control_id === ad.type
  );
  const adType = structure;
  const packageOfferField = props.packageOffer?.ad_format_templates?.find(field => field.id === ad.package_item_id)
  const packageFieldDateRangeDescription = packageFieldHumanDateRangeText(packageOfferField)
  const packageMinMaxBudget = packageBudgetRange(packageOfferField, currency);
  const liveOrderViewed = useSelector(hasLiveOrderBeingViewed)
  const [preview, setPreview] = useState(null)
  const [selectedPlacement, setSelectedPlacement] = useState(null)
  const [previewLoader, setPreviewLoader] = useState(false);

  const order = useSelector(selectOrder);
  const orderAds = useSelector(selectOrderAds);
  const selectedAds = orderAds.filter((orderAds) => orderAds.type === ad.type);
  const adIndex = (packageOfferField ? orderAds : selectedAds).findIndex(
    (selectedAds) => selectedAds.id === ad.id
  );

    const mercatoDevices = useSelector(selectAvailableMercatoDevices)

  const [selectedDevices, setSelectedDevices] = useState([]);

  const handleChange = (val) => onChange({ ...ad, ...val });
  const permissions = useSelector(selectOrderFormPermissions);
  const orderAssets = useSelector(selectAssets);
  const organisation = useSelector(selectOwnerOrganisation);

  let facebookCustomAudiences = permissions.facebook_custom_audiences
    ? permissions.facebook_custom_audiences.slice()
    : [];
  const defaultFacebookCustomAudiences =
    organisation?.settings?.ad_settings?.facebook_custom_audiences || [];
  if (defaultFacebookCustomAudiences.length > 0) {
    defaultFacebookCustomAudiences.forEach((audience) => {
      if (!facebookCustomAudiences.some((a) => a.id === audience.id)) {
        facebookCustomAudiences.push(audience);
      }
    });
  }

  if (selectedFacebookCustomAudiences?.length > 0) {
    selectedFacebookCustomAudiences.forEach((audience) => {
      if (!facebookCustomAudiences.some((a) => a.id === audience.id)) {
        facebookCustomAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let facebookLookalikeAudiences = permissions.facebook_lookalike_audiences
    ? permissions.facebook_lookalike_audiences.slice()
    : [];
  const defaultFacebookLookalikeAudiences =
    organisation?.settings?.ad_settings?.facebook_lookalike_audiences || [];

  if (defaultFacebookLookalikeAudiences.length > 0) {
    defaultFacebookLookalikeAudiences.forEach((audience) => {
      if (!facebookLookalikeAudiences.some((a) => a.id === audience.id)) {
        facebookLookalikeAudiences.push(audience);
      }
    });
  }

  if (selectedFacebookLookalikeAudiences?.length > 0) {
    selectedFacebookLookalikeAudiences.forEach((audience) => {
      if (!facebookLookalikeAudiences.some((a) => a.id === audience.id)) {
        facebookLookalikeAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let facebookSavedAudiences = permissions.facebook_saved_audiences
    ? permissions.facebook_saved_audiences.slice()
    : [];
  const defaultFacebookSavedAudience =
    organisation?.settings?.ad_settings?.facebook_saved_audiences || [];

  if (defaultFacebookSavedAudience.length > 0) {
    defaultFacebookSavedAudience.forEach((audience) => {
      if (!facebookSavedAudiences.some((a) => a.id === audience.id)) {
        facebookSavedAudiences.push(audience);
      }
    });
  }

  if (selectedFacebookSavedAudiences?.length > 0) {
    selectedFacebookSavedAudiences.forEach((audience) => {
      if (!facebookSavedAudiences.some((a) => a.id === audience.id)) {
        facebookSavedAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let googleRemarketingAudiences = permissions.google_remarketing_audiences
    ? permissions.google_remarketing_audiences.slice()
    : [];
  const defaultGoogleRemarketingAudiences =
    organisation?.settings?.ad_settings?.google_remarketing_audiences || [];

  if (defaultGoogleRemarketingAudiences.length > 0) {
    defaultGoogleRemarketingAudiences.forEach((audience) => {
      if (!googleRemarketingAudiences.some((a) => a.id === audience.id)) {
        googleRemarketingAudiences.push(audience);
      }
    });
  }

  if (selectedGoogleRemarketingAudiences?.length > 0) {
    selectedGoogleRemarketingAudiences.forEach((audience) => {
      if (!googleRemarketingAudiences.some((a) => a.id === audience.id)) {
        googleRemarketingAudiences.push({
          ...audience,
          deleted: true,
        });
      }
    });
  }

  let campaign;
  let structureFields;
  if (ad.platform === "facebook") {
    campaign = order?.facebook_campaigns?.find(
      (campaign) => campaign.order_ads_id === ad.id
    );
  } else if (ad.platform === "google" || ad.platform === "youtube") {
    campaign = order?.google_campaigns?.find(
      (campaign) => campaign.order_ads_id === ad.id
    );
  }

  const showMetrics = Boolean(
    readOnly && (order.status === STATUS_LIVE || order.status === STATUS_COMPLETED || order.status === STATUS_PAUSED)
  );

  /* This code block is not final code, pushed to not delay other changes/features */

  let propertiesTabs = propertiesTabsList.filter(p => (structure?.adtype_meta?.includes(p.id) || showMetrics && p.id === 'metrics') && ad.type === 'facebook_boosted' ? p.title !== 'Assets' : p.title !== 'Execution') ;

  if (ad.platform === PLATFORM_MERCATO) {
    propertiesTabs = mercatoDeviceAdTabs.concat(propertiesTabs);
  }

  if (!showAdBidAmount) {
    structureFields =
      structure?.fields?.filter((field) => field?.id !== "bid_amount") || [];
  } else {
    structureFields = structure.fields;
  }

  const [execution, setExecution] = useState(false);

  useEffect(() => {
    if (ad.type === 'facebook_boosted' && (order.status === STATUS_APPROVED || order.status === STATUS_LIVE || order.status === STATUS_COMPLETED || order.status === STATUS_PAUSED)) {
      setExecution(true);
    }
    else {
      setExecution(false);
    }
  }, [execution]);

  const tabs = useMemo(() => {
    let tabIDs = adType.adtype_meta.slice();
    if (showMetrics) {
      tabIDs.push('metrics')
    }

    if (adType.control_id === 'facebook_boosted' && (order.status !== STATUS_APPROVED && order.status !== STATUS_LIVE && order.status !== STATUS_COMPLETED && order.status !== STATUS_PAUSED)) {
      tabIDs = tabIDs.filter(tabID => tabID !== 'assets')
    }

    if (readOnly && (adType.control_id !== 'facebook_display' && adType.control_id !== 'facebook_boosted')) {
      tabIDs = tabIDs.filter(tabID => tabID !== 'assets')
    }

    return sortBy(propertiesTabs, (property) => tabIDs.indexOf(property.id))
      .filter((property) => tabIDs.includes(property.id));
  }, [adType.adtype_meta, adType.control_id, readOnly, propertiesTabs, showMetrics]);

  const linkAdUrl = `${process.env.REACT_APP_API_URL}/campaign/google/link?token=${authToken}&orderAdID=${ad.id}`;

  const hasAdIDLinkClick =
    (order.status === "approved" || order.status === "live") &&
    session?.user?.active_organisation?.id === order.owner_id &&
    structure.platform === "google" &&
    [
      "Noel Leeming",
      "The Pistol",
      "The Warehouse",
      "Warehouse Stationery",
    ].includes(session?.user?.active_organisation?.name);

  const handleAdIDLinkClick = useCallback(() => {
    if (hasAdIDLinkClick) {
      window.open(linkAdUrl, "_blank").focus();
    }
  }, [linkAdUrl, hasAdIDLinkClick]);

  const assetFieldStructures = structureFields.filter(
    (field) => field.type === "asset"
  );
  const assetFieldNames = assetFieldStructures.map((field) => field.id);
  const assetValues = assetFieldNames
    .flatMap((fieldID) => ad[fieldID] || [])
    .map((assetID) =>
      orderAssets.find((orderAsset) => orderAsset.id === assetID)
    )
    .filter((file) => file);
  const assetErrors = assetFieldNames.flatMap(
    (fieldName) => errors[fieldName] || []
  );

  const hasFeedField = structureFields.some((field) => field.type === "feed");
  const rejectMessage =
    order?.audits?.find((audit) => audit.activity === "order_rejected")
      ?.remarks || "";

  let allErrors = [...assetErrors];
  if (rejectMessage) {
    allErrors.push(rejectMessage);
  }
  if (errors) {
    allErrors= [...allErrors, ...Object.values(errors).flat()]
  }
  
  useEffect(() => {

    if (!showAdBidAmount) {
      handleChange({bid_amount: undefined});
    }
    if (ad?.selectedDevices) {

    }
  }, [order.id, ad.id, campaign]);

  const debounceHandler = useMemo(() =>
      debounce((owner_id, ad, placement, cancelSignal) => {
        if (ad.type === FACEBOOK_DISPLAY) {
          setPreviewLoader(true)
          adPreview(owner_id, ad, placement, {cancelSignal})
              .then(resp => {
                setPreviewLoader(false)
                // Set iframe to height to stop scroll for larger assets.
                const parser = new DOMParser()
            let dom = parser.parseFromString(resp.data.data.body, "text/html")
            let iframe = dom.body.firstChild
            // iframe.setAttribute('scrolling', 'no')
            iframe.setAttribute('height', '100%')
            // TODO: Handle small screen
            iframe.setAttribute('width', '100%')

            setPreview(iframe.outerHTML)
          })
          .catch(err => {
            setPreviewLoader(false)
            setPreview(null)
          })
      }
    }, 3000), [])

  useEffect(() => {
    const controller = new AbortController();
    debounceHandler(order.owner_id, ad, selectedPlacement, controller.signal)

    return () => {
      controller.abort()
    }
  }, [order.owner_id, selectedPlacement, ad])

  const handlePlacementPreviewChange = (placement) => {
    const controller = new AbortController();
    setSelectedPlacement(placement)
    setPreviewLoader(true)
    adPreview(order.owner_id, ad, placement, { cancelSignal: controller.singal })
      .then(resp => {
        setPreviewLoader(false)
        // Set iframe to height to stop scroll for larger assets.
        const parser = new DOMParser()
        let dom = parser.parseFromString(resp.data.data.body, "text/html")
        let iframe = dom.body.firstChild
        // iframe.setAttribute('scrolling', 'no')
        iframe.setAttribute('height', '100%')
        // TODO: Handle small screen
        iframe.setAttribute('width', '100%')

        setPreview(iframe.outerHTML)
      })
      .catch(err => {
        setPreviewLoader(false)
        setPreview(null)
      })
  }

  if (
    ad.type === FACEBOOK_DISPLAY
    && Array.isArray(ad.placements)
    && !ad.placements.includes(selectedPlacement)
    // && ad.placements[0] !== selectedPlacement
  ) {
    setSelectedPlacement(ad.placements[0])
  }
  let placements = useSelector(selectFacebookPlacements)
    .display

  if (!organisation?.settings?.['facebookDefaultInstagramActorID']) {
    placements = placements.filter(placement => placement.network !== 'Instagram')
  }

  if (ad.type === FACEBOOK_DISPLAY && Array.isArray(ad.placements)) {
    if (!ad.placements_automatic) {
      placements = placements.filter(placement => ad.placements.includes(placement.id))
    }
    const asset = assetValues?.[0];
    if (asset?.assettype) {
      placements = placements.filter(placement => placement[asset?.assettype])
    }
  }

  placements = placements
    .map(placement => ({
      id: placement.id,
      label: placement.name,
    }))

  return (
    <AdContainer
      ref={ref}
      {...props}
      header={
        <>
          <span className="font-weight-bold">
            Ad #{adIndex + 1}: {packageOfferField?.flat_discount ? `$${packageOfferField.flat_discount} OFF – ` : ''} {packageOfferField?.percentage_discount ? `${packageOfferField.percentage_discount}% OFF – ` : ''} {structure.label}&nbsp;
          </span>
          {ad.id && (
            <span
              style={{
                color: "#707070",
                cursor: hasAdIDLinkClick ? "pointer" : undefined,
              }}
              onClick={handleAdIDLinkClick}
              target="_blank"
            >
              (#{ad.id})
            </span>
          )}
          {ad && ad?.type === "facebook_carousel" && (
            <span>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip style={{ textAlign: "justify" }}>
                    Add 2 to 10 images or videos for this ad. Click on the
                    uploaded images/videos to customise the card.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
              </OverlayTrigger>
            </span>
          )}
          {ad && ad?.type === "google_responsive_search" && (
            <span>
              &nbsp;<small><a href="https://support.google.com/adspolicy/answer/6008942" target="_blank" class="text-sm">Limitations and Best Practice for Google RSA's.</a></small>
            </span>
          )}
          {packageFieldDateRangeDescription &&
            <div>
              {packageFieldDateRangeDescription}
            </div>
          }
          {packageMinMaxBudget && <div>
            <span className={styles.budget + ' text-right'}>{packageMinMaxBudget}</span>
          </div>}
          <Form.Control.Feedback
            type="invalid"
            className={allErrors.length > 0 ? "d-block" : ""}
          > 
            <Errors errors={allErrors} />
          </Form.Control.Feedback>
        </>
      }
    >
      <AdGrid>
        <PreviewSection
          header={ad.type === FACEBOOK_DISPLAY
            ? <PreviewDropdown value={selectedPlacement} disabled={previewLoader || !assetValues?.[0]} placements={placements} onChange={handlePlacementPreviewChange} />
            : ''}
        >
          <div style={{minHeight: '500px'}} className={`text-center h-100 ${preview && !previewLoader ? '' : 'd-none'}`} dangerouslySetInnerHTML={{ __html: preview }}></div>
          {preview
            ?
            <>
              {previewLoader &&
                <Spinner className="text-center" label="Loading your preview now..." />}

            </>
            :
            (previewLoader ?
              <Spinner className="text-center" label="Loading your preview now..." />
              : <Preview
                className="p-3"
                ad={ad}
                assets={assets}
                feed={ad.feed}
                newPrev={isNew}
                onAssetsChange={(assets) => handleChange({ media: assets })}
              />)}
        </PreviewSection>
        <PropertiesSection>
          <PropertiesBody readOnly={readOnly} tabs={tabs} completeCheckContext={{ ad, adType, errors }}>
            <Tab.Pane eventKey="schedule">
              <Container>
                <Row>
                  <Col>
                    <AdDateRange
                      controlIdStartAt={`${controlId}.start_at`}
                      controlIdStopAt={`${controlId}.stop_at`}
                      readOnly={readOnly}
                      required
                      placeholder={{
                        start_datetime: order.start_at,
                        stop_datetime: order.stop_at,
                      }}
                      value={{
                        start_datetime: ad.start_at,
                        stop_datetime: ad.stop_at,
                      }}
                      errors={{
                        start_datetime: errors.start_at,
                        stop_datetime: errors.stop_at,
                      }}
                      onChange={({ start_datetime, stop_datetime }) =>
                        handleChange({
                          start_at: start_datetime,
                          stop_at: stop_datetime,
                        })
                      }
                      dateTime={ad.platform !== "google"}
                    />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>

            <Tab.Pane eventKey="ad-goals">
              <div className={styles.form_section}>
                <Container>
                  <Row className="mb-5">
                    <Col>
                      <p><strong>Objective</strong></p>
                      {structure.goals.map((goal, index) =>
                        <div
                          key={goal}
                          onClick={function (e) {
                            !readOnly && handleChange({ goal: goal });
                          }}
                          className={styles.goals}>
                          <Form.Check
                            name={`goals-${ad.id}`}
                            disabled={readOnly}
                            type={'radio'}
                            label={goal}
                            value={goal}
                            id={`${goal}-${index}-${ad.id}`}
                            onChange={function (e) {
                              handleChange({ goal: e.target.value });
                            }}
                            isInvalid={errors?.goal}
                            aria-describedby={`${goal}-${index}-${ad.id}-muted`}
                            checked={ad.goal === goal}
                          />
                          <Form.Text
                            className={styles.goals_muted}
                            id={`${goal}-${index}-${ad.id}-muted`}
                            muted>
                            {(session.goals).find(each => each.id === goal).description}
                          </Form.Text>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="placement">
              <div className={styles.form_section}>
                <MercatoDevicePlacement ad={ad} readOnly={readOnly}
                  value={ad?.selectedDevices ?? []} ownedDevices={mercatoDevices} onChange={device => {
                    handleChange({ selectedDevices: device })
                    setSelectedDevices(device);
                  }} />
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="ad-details">
              <div className={styles.form_section}>
                <Container>
                  <Row>
                    <Col sm="12">
                      {structureFields
                        .filter(
                          (field) =>
                            !["hidden", "asset", "feed"].includes(
                              field.type
                            )
                        ).filter(field => {
                          if (!field.deprecated_at) {
                            return true
                          }
                          if (order.created_at) {
                            return moment(field.deprecated_at).isAfter(ad.created_at)
                          }
                          return moment(field.deprecated_at).isAfter(moment())
                        })
                        .map((field) => (
                          <div
                            key={field.id}
                            style={{
                              marginTop: 0,
                              marginBottom: "25px",
                            }}
                          >
                            <DynamicField
                              key={field.id}
                              controlId={`${controlId}.${field.id}`}
                              platform={structure.platform}
                              field={field}
                              readOnly={readOnly}
                              errors={errors[field.id]}
                              meta={{ ad: ad }}
                              value={ad[field.id]}
                              onChange={(val) =>
                                handleChange({ [field.id]: val })
                              }
                            />
                          </div>
                        ))}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="budget">
              <div className={styles.form_section}>
                <Container>
                  <Row>
                    <Col sm="12">
                      {showAdBudget && ad?.platform !== PLATFORM_MERCATO && (
                        <AdBudget
                          controlId={`${controlId}.budget`}
                          readOnly={readOnly || liveOrderViewed}
                          label="Budget"
                          adType={ad.type}
                          value={ad.budget}
                          onChange={(budget) => {
                            handleChange({ budget });
                            dispatch(budgetUpdated());
                          }}
                          errors={errors.budget}
                        />
                      )}
                      {showAdBudget && ad?.platform === PLATFORM_MERCATO && (
                        <>
                          <Budget
                            controlId={`${controlId}.budget`}
                            showBudget={showAdBudget}
                            ad={ad}
                            selectedDevices={mercatoDevices.filter(d => ad?.selectedDevices?.includes(d.id))}
                            readOnly={readOnly || liveOrderViewed}
                            startDate={ad.start_at}
                            stopDate={ad.stop_at}
                            onChange={(budgetChange) => handleChange(budgetChange)}
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="audiences">
              <div className={styles.form_section}>
                <Container>
                  <Row>
                    <Col sm="12">
                      {structure.audiences.includes(
                        "facebook_custom_audiences"
                      ) &&
                        facebookCustomAudiences.length > 0 && (
                          <div
                            className={
                              ad.selected_audience
                                ?.facebook_custom_audiences?.length > 0
                                ? styles.ad_selected_custom_audience
                                : styles.ad_custom_audience
                            }
                          >
                            <FacebookCustomAudiences
                              controlId={`${controlId}-custom-audiences`}
                              readOnly={readOnly}
                              disabled={readOnly}
                              platform={ad.platform}
                              value={
                                ad.selected_audience
                                  ?.facebook_custom_audiences
                              }
                              audiences={facebookCustomAudiences}
                              onChange={(item, checked) => {
                                if (!item) return;
                                let facebook_custom_audiences =
                                  ad.selected_audience
                                    ?.facebook_custom_audiences || [];
                                if (checked) {
                                  facebook_custom_audiences = [
                                    ...facebook_custom_audiences,
                                    item,
                                  ];
                                } else {
                                  facebook_custom_audiences =
                                    facebook_custom_audiences.filter(
                                      (e) => e.id !== item?.id
                                    );
                                }
                                handleChange({
                                  selected_audience: {
                                    ...ad.selected_audience,
                                    facebook_custom_audiences,
                                    facebook_saved_audiences: [],
                                  },
                                });
                              }}
                            />
                          </div>
                        )}

                      {structure.audiences.includes(
                        "facebook_lookalike_audiences"
                      ) &&
                        facebookLookalikeAudiences.length > 0 && (
                          <div
                            className={
                              ad.selected_audience
                                ?.facebook_lookalike_audiences?.length >
                                0
                                ? styles.ad_selected_custom_audience
                                : styles.ad_custom_audience
                            }
                          >
                            <FacebookLookalikeAudiences
                              controlId={`${controlId}-lookalike-audiences`}
                              readOnly={readOnly}
                              disabled={readOnly}
                              platform={ad.platform}
                              value={
                                ad.selected_audience
                                  ?.facebook_lookalike_audiences
                              }
                              audiences={facebookLookalikeAudiences}
                              onChange={(item, checked) => {
                                if (!item) return;
                                let facebook_lookalike_audiences =
                                  ad.selected_audience
                                    ?.facebook_lookalike_audiences ||
                                  [];
                                if (checked) {
                                  facebook_lookalike_audiences = [
                                    ...facebook_lookalike_audiences,
                                    item,
                                  ];
                                } else {
                                  facebook_lookalike_audiences =
                                    facebook_lookalike_audiences.filter(
                                      (e) => e.id !== item?.id
                                    );
                                }
                                handleChange({
                                  selected_audience: {
                                    ...ad.selected_audience,
                                    facebook_lookalike_audiences,
                                    facebook_saved_audiences: [],
                                  },
                                });
                              }}
                            />
                          </div>
                        )}

                      {structure.audiences.includes(
                        "facebook_saved_audiences"
                      ) &&
                        facebookSavedAudiences.length > 0 && (
                          <div
                            className={
                              ad.selected_audience
                                ?.facebook_saved_audiences?.length > 0
                                ? styles.ad_selected_custom_audience
                                : styles.ad_custom_audience
                            }
                          >
                            <FacebookSavedAudiences
                              controlId={`${controlId}-saved-audiences`}
                              readOnly={readOnly}
                              disabled={readOnly}
                              platform={ad.platform}
                              value={
                                ad.selected_audience
                                  ?.facebook_saved_audiences
                              }
                              audiences={facebookSavedAudiences}
                              onChange={(item, checked) => {
                                if (!item) return;
                                let facebook_saved_audiences =
                                  ad.selected_audience
                                    ?.facebook_saved_audiences || [];

                                if (checked) {
                                  facebook_saved_audiences = [item];
                                } else {
                                  facebook_saved_audiences = [];
                                }

                                handleChange({
                                  selected_audience: {
                                    ...ad.selected_audience,
                                    facebook_custom_audiences: [],
                                    facebook_lookalike_audiences: [],
                                    facebook_saved_audiences,
                                  },
                                });
                              }}
                            />
                          </div>
                        )}

                      {structure.audiences.includes(
                        "google_remarketing_audience"
                      ) &&
                        googleRemarketingAudiences.length > 0 && (
                          <GoogleRemarketingAudiences
                            controlId={`${controlId}-google-remarketing-audiences`}
                            readOnly={readOnly}
                            disabled={readOnly}
                            platform={ad.platform}
                            audiences={googleRemarketingAudiences}
                            value={
                              ad.selected_audience
                                ?.google_remarketing_audiences
                            }
                            onChange={(item, checked) => {
                              if (!item) return;
                              let google_remarketing_audiences =
                                ad.selected_audience
                                  ?.google_remarketing_audiences || [];
                              if (checked) {
                                google_remarketing_audiences = [
                                  ...google_remarketing_audiences,
                                  item,
                                ];
                              } else {
                                google_remarketing_audiences =
                                  google_remarketing_audiences.filter(
                                    (e) => e.id !== item?.id
                                  );
                              }
                              handleChange({
                                selected_audience: {
                                  ...ad.selected_audience,
                                  google_remarketing_audiences,
                                },
                              });
                            }}
                          />
                        )}

                      <SelectAudience
                        controlId={`${controlId}-select-audience`}
                        readOnly={readOnly}
                        adType={ad.type}
                        platform={ad.platform}
                        order={order}
                        value={
                          ad.selected_audience?.standard_audience
                        }
                        audience={ad.audience}
                        adAudiences={structure.audiences}
                        onChange={(audience) => {
                          handleChange({
                            audience,
                            selected_audience: {
                              ...ad.selected_audience,
                              standard_audience: audience, // Auto select the standard audience
                            },
                          });
                        }}
                        onSelect={(standard_audience) => {
                          handleChange({
                            selected_audience: {
                              ...ad.selected_audience,
                              standard_audience,
                            },
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="assets">
              <AssetsPane
                readOnly={readOnly && !execution}
                adFormat={structure}
                ad={ad}
                fieldNames={assetFieldNames}
                value={assetValues}
                onChange={handleChange}
                assetFieldStructures={assetFieldStructures}
                errors={assetErrors}
              />
              {execution &&
                  <Col>
                    {userActiveOrganisation.id === organisation.id && <Button
                        className="ml-2"
                        variant="outline-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          (updateOrder(order, order.id))
                              .then((resp) => {
                                dispatch(clearOrder());
                                dispatch(orderUpdated(resp.data.data));
                              })
                              .catch((err) => {
                                if (err.response?.status === 403) {
                                  dispatch(clearSession());
                                } else if (err.response?.data?.errors) {
                                  dispatch(errorsUpdate(err.response.data.errors));
                                }
                              });
                        }}
                    >
                      Update
                    </Button>}
                  </Col>}
            </Tab.Pane>

            {hasFeedField && !readOnly && (
              <Tab.Pane eventKey="feed">
                <div className={styles.form_section}>
                  <Container>
                    <Row>
                      <Col sm="12">
                        {ad.platform === "facebook" ? (
                          <FacebookProducts
                            feed={ad?.feed}
                            errors={errors?.feed}
                            handleInsert={(feed) =>
                              handleChange({ feed })
                            }
                          />
                        ) : (
                          <GoogleProducts
                            feed={ad?.feed}
                            handleInsert={(feed) =>
                              handleChange({ feed })
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab.Pane>
            )}
            <Tab.Pane eventKey="metrics">
              <Container>
                <Row>
                  <Col sm="12">
                    <ConnectedInsightsView
                      insights={filterInsights(ad, campaign?.insights) || {}}
                    />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
          </PropertiesBody>
        </PropertiesSection>
      </AdGrid>
    </AdContainer >
  );
});

StandardAdSection.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  ad: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default StandardAdSection;

const FacebookProducts = ({
  errors = [],
  feed = {},
  handleInsert = () => { },
}) => {
  const [facebookFeedModal, setFacebookFeedModal] = useState(false);
  const selectedFacebookProducts = feed;
  const productSize = selectedFacebookProducts.products.length;
  const [facebookProducts, setFacebookProducts] = useState(
    selectedFacebookProducts
  );

  return (
    <div className="mx-auto text-center">
      <Button
        variant="outline-secondary"
        className={`py-3 mb-2 position-relative ${errors.length > 0 ? "border-danger" : ""
          }`}
        onClick={(_) => setFacebookFeedModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} size="4x" />
        <h6 className="mt-1">Attach Products</h6>
        <Notification number={productSize} />
      </Button>
      <Modal
        size="xl"
        show={facebookFeedModal}
        onHide={(_) => {
          setFacebookFeedModal(false);
          setFacebookProducts(selectedFacebookProducts);
        }}
      >
        <Modal.Header closeButton>
          Select Facebook Catalogues you want to use.
        </Modal.Header>
        <Modal.Body>
          <OrderFacebookProducts
            value={facebookProducts}
            onChange={(value) => setFacebookProducts(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="float-right"
            variant="primary"
            onClick={(e) => {
              handleInsert(facebookProducts);
              setFacebookFeedModal(false);
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const GoogleProducts = ({
  errors = [],
  feed = {},
  handleInsert = () => { },
}) => {
  const [googleFeedModal, setGoogleFeedModal] = useState(false);
  const selectedGoogleProducts = feed;
  const productSize = selectedGoogleProducts.products.length;
  const [googleProducts, setGoogleProducts] = useState(selectedGoogleProducts);
  const [totalProducts, setTotalProducts] = useState([]);

  return (
    <div className="mx-auto text-center">
      <Button
        variant="outline-secondary"
        className={`py-3 mb-2 position-relative ${errors.length > 0 ? "border-danger" : ""
          }`}
        onClick={(_) => setGoogleFeedModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} size="4x" />
        <h6 className="mt-1">Attach Products</h6>
        <Notification number={productSize} />
      </Button>
      <Modal
        size="xl"
        show={googleFeedModal}
        enforceFocus={false}
        onHide={(_) => {
          setGoogleFeedModal(false);
          setGoogleProducts(selectedGoogleProducts);
        }}
      >
        <Modal.Header closeButton>
          Select Google products you want to use.
        </Modal.Header>
        <Modal.Body>
          <OrderGoogleProducts
            value={googleProducts}
            onChange={(value) => setGoogleProducts(value)}
            onProductsChange={(value) => setTotalProducts(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <span style={{ color: "#bfbfbf" }}>
            {new Intl.NumberFormat(undefined, {}).format(totalProducts.length)}
          </span>
          <Button
            className="ml-auto"
            variant="primary"
            onClick={(e) => {
              handleInsert(googleProducts);
              setGoogleFeedModal(false);
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
