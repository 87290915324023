import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Alert,
} from "react-bootstrap";
import {
  clearSession,
  refreshSession,
  selectSession,
  selectUser,
} from "../../../features/session/sessionSlice";
import Select from "react-select";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Errors from "../../order_form/components/Errors";
import { makeVirtualSpacesNewCategoryRequest } from "../../../lib/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const Categories = ({
  categories = [],
  value = "",
  errors = [],
  onChange = () => {},
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const slotPricingMode = [
    { value: "fixed", label: "Fixed" },
    { value: "bid", label: "Bid" },
  ];
  const slotBidMode = [
    { value: "cpm", label: "CPM" },
    { value: "cpc", label: "CPC" },
  ];
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const handleNewCategoryModalClose = () => {
    setShowNewCategoryModal(false);
    setFormMessages({});
    clearNewCategoryForm();
    document.body.click();
  };
  const [formMessages, setFormMessages] = useState({});
  const [newCategoryForm, setNewCategoryForm] = useState({
    name: "",
    slot_pricing_mode: "fixed",
    slot_price: "",
    slot_bid_mode: "cpm",
  });
  const [newCategoryFormState, setNewCategoryFormState] = useState(true);
  const handleNewCategoryChange = (field) => {
    setNewCategoryForm({ ...newCategoryForm, ...field });
  };
  const clearNewCategoryForm = () => {
    setNewCategoryForm({
      name: "",
      slot_pricing_mode: "fixed",
      slot_price: "",
      slot_bid_mode: "cpm",
    });
  };
  const handleNewCategorySubmit = (e) => {
    e.preventDefault();
    setFormMessages({});
    setNewCategoryFormState(false);

    makeVirtualSpacesNewCategoryRequest(
      user.active_organisation.id,
      newCategoryForm.name,
      newCategoryForm.slot_pricing_mode,
      newCategoryForm.slot_price,
      newCategoryForm.slot_bid_mode
    )
      .then((resp) => {
        setNewCategoryFormState(true);
        setFormMessages({});
        clearNewCategoryForm();
        handleNewCategoryModalClose();
        dispatch(refreshSession());
      })
      .catch((err) => {
        setNewCategoryFormState(true);
        if (err.response?.status === 422) {
          setFormMessages({ errors: err?.response?.data?.errors });
        } else {
          setFormMessages({ failed: true });
        }
      });
  };

  return (
    <article>
      <div className="mb-2 d-flex align-items-center">
        <div>
          <p className="mb-0">
            <b>Category</b>
          </p>
        </div>
        <Button
          variant="outline-secondary"
          className="ml-auto"
          size="sm"
          onClick={(e) => setShowNewCategoryModal(true)}
        >
          <span>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> New Category
          </span>
        </Button>
      </div>
      <Form.Control
        as="select"
        value={value}
        isInvalid={errors.length > 0}
        onChange={(e) => onChange(e.target.value)}
      >
        <option selected value>
          {" "}
          Select{" "}
        </option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </Form.Control>
      <small>Select or add category for this space.</small>
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
      <Modal
        show={showNewCategoryModal}
        onHide={handleNewCategoryModalClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div class="text-secondary">Create a New Category</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form onSubmit={(e) => handleNewCategorySubmit(e)}>
              {formMessages.failed && (
                <Alert variant={"danger"}>
                  There was an error sending this request, please try again.
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>What is the name of this Category?</Form.Label>
                <Form.Control
                  value={newCategoryForm.name}
                  onChange={(e) =>
                    handleNewCategoryChange({
                      name: e.target.value,
                    })
                  }
                  disabled={newCategoryFormState ? false : true}
                  type="text"
                />
                {formMessages?.errors?.name && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formMessages.errors.name}
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Slot pricing mode</Form.Label>
                <Select
                  value={slotPricingMode.filter(
                    (mode) => mode.value === newCategoryForm.slot_pricing_mode
                  )}
                  onChange={(e) =>
                    handleNewCategoryChange({
                      slot_pricing_mode: e.value,
                    })
                  }
                  isDisabled={newCategoryFormState ? false : true}
                  options={slotPricingMode}
                />
                {formMessages?.errors?.slot_pricing_mode && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formMessages.errors.slot_pricing_mode}
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Slot price</Form.Label>
                <Form.Control
                  value={newCategoryForm.slot_price}
                  onChange={(e) =>
                    handleNewCategoryChange({
                      slot_price: e.target.value,
                    })
                  }
                  disabled={newCategoryFormState ? false : true}
                  type="text"
                />
                {formMessages?.errors?.slot_price && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formMessages.errors.slot_price}
                  </div>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Slot bid mode</Form.Label>
                <Select
                  value={slotBidMode.filter(
                    (mode) => mode.value === newCategoryForm.slot_bid_mode
                  )}
                  onChange={(e) =>
                    handleNewCategoryChange({
                      slot_bid_mode: e.value,
                    })
                  }
                  isDisabled={newCategoryFormState ? false : true}
                  options={slotBidMode}
                />
                {formMessages?.errors?.slot_bid_mode && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formMessages.errors.slot_bid_mode}
                  </div>
                )}
              </Form.Group>
              <Button variant="secondary" type="submit">
                Create Category
              </Button>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </article>
  );
};

Categories.propTypes = {
  categories: PropType.array,
  value: PropType.string,
  errors: PropType.arrayOf(PropType.string),
  onChange: PropType.func,
};

export default Categories;
