export function calculateRoas(conversions, spend) {
    if (spend === 0) {
        return 0;
    }
    return (conversions / spend).toFixed(2)
}


export function discountDisplay(ads, currency = "$") {
    let percent = 0;
    let flat = 0;
    ads.map(ad => {
        percent += ad?.percentage_discount;
        flat += ad?.flat_discount;
    })

    if (flat > 0 && percent > 0) {
        return 'Discounts Available';
    }
    if (flat > 0 && percent === 0) {
        return `Discount: up to ${currency}${flat}`;
    }
    if (percent > 0 && flat === 0) {
        return `Discount: up to ${Math.floor(percent / ads.length)}%`;
    }
}

export function formatToCurrency(currency, value){
    return new Intl.NumberFormat(undefined, { style: 'currency', currency: currency, maximumFractionDigits: 0, currencyDisplay: 'narrowSymbol' }).format(value);
}
