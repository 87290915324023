import React from 'react'
import PropTypes from "prop-types";
import styled from 'styled-components'
import { DescriptionContainer, ErrorFeedback, LabelText } from '../../form_elements/Common'
import AdFormatCard from './AdFormatCard';
import Errors from '../../form_elements/Errors';
import { selectUserOrganisation } from '../../../../session/sessionSlice';
import { useSelector } from 'react-redux';


const Container = styled.section`
    background-color: #ECECEC;
    border-radius: 7px;
    padding: 20px;
    height: 1250px;
    overflow: auto;
`

const AdFormatContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const DescContainer = styled(DescriptionContainer)`
    margin-bottom: 15px;
`

const AdFormatSelect = ({
    readOnly = false,
    adFormats = [],
    enabledAdFormats = [],
    onAdd = () => { },
    errors = []
}) => {
    
    const orgEnabledAdFormats = useSelector(selectUserOrganisation).settings.ad_settings.enabled
    const enabledAds = Object.keys(orgEnabledAdFormats).filter(ad => orgEnabledAdFormats[ad]===true)
    const availableAdFormats = adFormats.filter(a => enabledAds.includes(a.control_id))
    
    return (
        <Container>
            <LabelText>
                Ad Type
            </LabelText>
            <DescContainer>
                Select which ads to be included in this package.
            </DescContainer>
            <ErrorFeedback type="invalid" isInvalid={errors.length > 0} className="mb-3">
                <Errors errors={errors} />
            </ErrorFeedback>
            <AdFormatContainer>
                    {availableAdFormats.map(adFormat =>
                    <AdFormatCard
                        key={adFormat.control_id}
                        readOnly={readOnly}
                        platform={'facebook'}
                        imageSrc={adFormat.placeholder_images.large}
                        label={adFormat.label}
                        onClick={e => onAdd(adFormat)}
                    />
                )}
            </AdFormatContainer>
        </Container>
    );
}

AdFormatSelect.propTypes = {
    adFormats: PropTypes.array,
    enabledAdFormats: PropTypes.array,
    onAdd: PropTypes.func,
    errors: PropTypes.array,
}

export default AdFormatSelect
