import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  Redirect,
} from 'react-router-dom'
import {
  clearSession,
  refreshSession,
  selectUser,
  selectAuthToken,
  fetchWebSpaces,
} from "../features/session/sessionSlice";
import styled from 'styled-components';


const StyledLoaderWrapper = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  p {
    font-size: 1.2em;
  }
`;

const ProtectedRoutes = ({children}) => {
  const [loading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const user = useSelector(selectUser);
  const location = useLocation();
  const isLogoutRoute = location.pathname === "/logout";

  const refresh = useCallback(async() => {
    try {
      const res = await dispatch(refreshSession());
      if(res && res.error) {
        dispatch(clearSession());
      }
      
    } catch(e) {
      dispatch(clearSession());
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if(!authToken || isLogoutRoute) {
      setIsLoading(false);
      return;
    }
    if(!user) {
      refresh();
    } else {
      setIsLoading(false);
    }
  }, []);

  if(!authToken) {
    return <Redirect to="/login" />
  }

  return (
    <>
      { loading ?
        <StyledLoaderWrapper>
          <p>The page is loading, please wait.</p>
          <FontAwesomeIcon
            className='fa-spin'
            style={{ cursor: 'pointer' }}
            icon={faSpinner} size="4x" />
        </StyledLoaderWrapper>: children 
      }
      
    </>
  )
  
};

export default ProtectedRoutes;