import { connect, useSelector } from 'react-redux'
import { postAsset, postFacebookAsset } from '../../../lib/api'
import { selectSession } from '../../session/sessionSlice'
import FileDropzone from '../components/FileDropzone'

const mapStateToProps = state => ({
    postRequest: postAsset,
})


export default connect(mapStateToProps)(FileDropzone)
