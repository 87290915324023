import { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { clearBespokeProduct, fetchBespokeProduct, selectRequestType, selectStatus } from '../features/bespoke/bespokeFormSlice'
import CreateBespokeProduct from './CreateBespokeProduct'
import Chrome from './layout/Chrome'

const Header = styled.h1`
    margin-bottom: 70px;
`

const EditBespokeProduct = () => {
    const dispatch = useDispatch()
    const { bespoke_id } = useParams()
    const requestStatus = useSelector(selectStatus)
    const requestType = useSelector(selectRequestType)

    useEffect(() => {
        dispatch(clearBespokeProduct())
        dispatch(fetchBespokeProduct(bespoke_id))
    }, [dispatch, bespoke_id])

    if (requestStatus === 'failed' && requestType === 'fetch') {
        return <Redirect to="/organisation/bespokesettings" />
    }

    if (requestStatus === 'loading' && requestType === 'fetch'){
        return <>
            <Helmet>
                <title>
                    Edit Bespoke Product - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container className="pt-4 px-5" fluid>
                    <Row className="mb-3">
                        <Col>
                            <Header>Bespoke Products Editor</Header>
                            <Row className="text-center d-flex align-items-center justify-content-center">
                                <Spinner animation="border" />&nbsp;
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Chrome>
        </>
    }
    else {
        return <CreateBespokeProduct edit={true} />
    }
    
}

export default EditBespokeProduct
