import { useEffect } from 'react'
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import PackageForm from '../features/packages/components/PackageForm'
import { clearPackage, fetchPackage, fetchPackageGroups, selectCanFullEdit, selectRequestType, selectStatus } from '../features/packages/packageFormSlice'
import Chrome from './layout/Chrome'

const Header = styled.h1`
    margin-bottom: 70px;
`

const EditPackage = () => {
    const dispatch = useDispatch()
    const { package_id } = useParams()
    const requestStatus = useSelector(selectStatus)
    const requestType = useSelector(selectRequestType)
    const canFullEdit = useSelector(selectCanFullEdit)

    useEffect(() => {
        dispatch(clearPackage())
        dispatch(fetchPackage(package_id))
        dispatch(fetchPackageGroups())
    }, [dispatch, package_id])

    if (requestStatus === 'failed' && requestType === 'fetch') {
        return <Redirect to="/packages" />
    }

    return <>
        <Helmet>
            <title>
                New Package - {process.env.REACT_APP_NAME}
            </title>
        </Helmet>

        <Chrome>
            <Container className="pt-4 px-5" fluid>
                <Row className="mb-3">
                    <Col>
                        <Header>Package Editor</Header>
                        {requestStatus === 'loading' && requestType === 'fetch'
                            ? <Row className="text-center d-flex align-items-center justify-content-center">
                                <Spinner animation="border" />&nbsp;
                            </Row>
                            : <PackageForm canFullEdit={canFullEdit} />
                        }

                    </Col>
                </Row>
            </Container>
        </Chrome>
    </>
}

export default EditPackage
