import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Form, FormCheck, OverlayTrigger, Popover } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getAudienceDescription } from '../../../../lib/api/audience'
import { selectOwnerOrganisation } from '../../../order_form/orderFormSlice'
import $style from './audience.module.scss';

const formatNumber = n => new Intl.NumberFormat(undefined, { notation: 'compact', compactDisplay: 'short' }).format(n)

const renderEstimatedRange = (lower, upper) => {
    if (upper === 1000) {
        return `Below ${formatNumber(upper)}`
    }

    return lower === upper
        ? formatNumber(lower)
        : `${formatNumber(lower)} – ${formatNumber(upper)}`
}

const renderPeopleWhoMatch = ({ content, children }) => {
    const splitChld = children.map(child => child.split(':'))

    return <div className="text-left">
        <b>{content}</b>
        <ul>
            {splitChld.map(child =>
            <li><b>{child[0]}:</b> {child[1]}</li>)}
        </ul>
    </div>
}

const FacebookAudienceCheck = ({ id, className, readOnly, disabled, audience, value, onChange }) => {
    const owner = useSelector(selectOwnerOrganisation)
    const description = useMemo(() => {
        if(audience?.targeting){
            let targeting = audience.targeting
            let locations = [];
            if(targeting?.geo_locations?.custom_locations){
                locations = targeting.geo_locations.custom_locations
                    .map(l => l.distance_unit === 'mile' ? l.name + " ("+l.radius+" mi)" : l.name + " ("+l.radius+" Km)");
            }else if(targeting?.geo_locations?.countries){
                locations = targeting.geo_locations.countries;
            }
            
            return [
                {content: "Location", children: locations}, 
                {content: "Age", children: 
                    [targeting.age_max === 65 ? targeting.age_min + "-65+" : targeting.age_min + "-" + targeting.age_max  ]}]
        }
        return [];
    }, [audience?.targeting])

    return <div className={className}>
        <FormCheck>
            <FormCheck.Input
                inline
                id={id}
                className="mr-2"
                disabled={disabled}
                readOnly={readOnly}
                checked={!!value.find(e => e.id === audience.id)}
                onChange={onChange}
            />
            <OverlayTrigger
                placement="top"
                overlay={<Popover id={`saved-audience-tooltip-${audience.id}`} style={{ maxWidth: '50rem' }}>
                    <Popover.Content>
                        <div>
                            {description.length > 0 && description.map(attr =>
                                <div className="text-left" >
                                    <b>{attr.content}</b> {attr.children.join('\n\n')}
                                </div>)}
                        </div>
                        <div className="text-left">
                            <b>Estimated Users:</b> {audience.estimated_users
                                ? renderEstimatedRange(audience.estimated_users.lower_bound, audience.estimated_users.upper_bound)
                                : 'N/A'}
                        </div>
                    </Popover.Content>
                </Popover>}
            >
                <FormCheck.Label>
                    {audience.name}
                </FormCheck.Label>
            </OverlayTrigger>
        </FormCheck>
    </div>
}

const FacebookSavedAudiences = ({
    controlId,
    value = [],
    readOnly = false,
    disabled = false,
    audiences = [],
    onChange = () => { },
}) => {
    const handleChange = (audience, checked) => {
        if (checked) {
            onChange(audience, true)
        } else {
            onChange(audience, false)
        }
    }
    return (
        <Form.Group controlId={controlId}>
            <Form.Label className="font-weight-bold">Saved Audiences (Maximum: 1)</Form.Label>
            <div>
                {audiences.length > 0 ? (
                    audiences.map(audience => <FacebookAudienceCheck
                        key={audience.id}
                        id={`${controlId}-facebook-saved-audience-${audience.id}`}
                        disabled={disabled || audience.deleted}
                        readOnly={readOnly || audience.deleted}
                        className={audience?.deleted ? $style.deleteItem: ''}
                        value={value}
                        audience={audience}
                        onChange={e => handleChange(audience, e.target.checked)}
                    />)
                ) : (
                    <p>There are no saved audiences available.</p>
                )}
            </div>
        </Form.Group >
    )
}

FacebookSavedAudiences.propTypes = {
    controlId: PropTypes.string.isRequired,
    value: PropTypes.object,
    audience: PropTypes.array,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default FacebookSavedAudiences
