import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {STATUS_APPROVED, STATUS_COMPLETED, STATUS_LIVE, STATUS_PENDING_APPROVAL,} from "../../lib/orders";
import Chart from "react-google-charts";
import styles from "./schedule.module.scss";
import {useHistory} from "react-router-dom";
import {Form} from "react-bootstrap";
import {selectSession} from "../../features/session/sessionSlice";
import moment from "moment";
import {cacheFetchRetailers, selectAllRetailers} from "../retailers/retailersSlice";
import {cachedFetchBrands, selectAllBrands} from "../brands/brandsSlice";

const Schedule = ({orders = []}) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const session = useSelector(selectSession);
  const retailers = useSelector(selectAllRetailers);
  const suppliers = useSelector(selectAllBrands);
  const isRetailer = suppliers.length > 0;
  const [hAxisProps, setHAxisProps] = useState({
    minValue: moment().startOf("year").toDate(),
    maxValue: moment().endOf("year").toDate(),
    format: "MMM dd",
  });
  const [filterBySuppliers, setFilterBySuppliers] = useState([]);
  const [filterByRetailers, setFilterByRetailers] = useState([]);
  const [filterByPlatforms, setFilterByPlatforms] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState([]);
  const [filterByDateRange, setFilterByDateRange] = useState("weekly");
  const [dataStatusMessage, setDataStatusMessage] =
    useState("Fetching data...");

  const timestamp = new Date().getTime();
  const ONE_DAY = 86400000;
  const min = timestamp - 7 * 2 * ONE_DAY;
  const max = timestamp + 7 * 2 * ONE_DAY;

  const orderStatuses = [
    STATUS_PENDING_APPROVAL,
    STATUS_LIVE,
    STATUS_APPROVED,
    STATUS_COMPLETED,
  ];

  const activeOrders = orders.filter((order) =>
    orderStatuses.includes(order.status)
  );

  const organisations = [
    ...retailers.map(org => ({...org, type: 'retailer'})),
    ...suppliers.map(org => ({...org, type: 'supplier'})),
  ];

  useEffect(() => {
    dispatch(cachedFetchBrands());
    dispatch(cacheFetchRetailers());
  }, []);

  let data = orders
    .filter((order) => orderStatuses.includes(order.status))
    .filter((order) => {
      //filter by supplier
      if (filterBySuppliers.length > 0) {
        return filterBySuppliers.includes(order?.supplier?.id);
      }

      return true;
    })
    .filter((order) => {
      //filter by supplier
      if (filterByRetailers.length > 0) {
        return filterByRetailers.includes(order?.retailer?.id);
      }

      return true;
    })
    .filter((order) => {
      //filter by platform
      if (filterByPlatforms.length > 0) {
        let found = false;
        order.platform.forEach((platform) => {
          found = filterByPlatforms.includes(platform) ? true : false;
        });

        return found;
      }

      return true;
    })
    .filter((order) => {
      //filter by order status
      if (filterByStatus.length > 0) {
        return filterByStatus.includes(order?.status);
      }

      return true;
    })
    .filter((order) => {
      if (filterByDateRange === "daily") {
        var start = moment(order.start_at);
        var end = moment(order.stop_at);
        if (start >= moment().startOf("month")) {
          return true;
        } else {
          return false;
        }
      } else if (filterByDateRange === "monthly") {
        var start = moment(order.start_at);
        var end = moment(order.stop_at);
        if (start >= moment().startOf("month")) {
          return true;
        } else {
          return false;
        }
      } else if (filterByDateRange === "weekly") {
        var start = moment(order.start_at);
        var end = moment(order.stop_at);
        if (start >= moment().startOf("isoWeek")) {
          return true;
        } else {
          return false;
        }

      } else {
        return true;
      }
    })
    .map((order) => {
      let adsLen = order.data_json?.length ?? 0;
      let orderStatus =
        order.status == STATUS_APPROVED
          ? "Approved"
          : order.status == STATUS_LIVE
            ? "Live"
            : order.status == STATUS_COMPLETED
              ? "Completed"
              : "Pending Approval";
      let platforms = Array.from(new Set(order.platform));
      platforms = platforms.map((word) => {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
      });
      var orderName =
        order.name?.trim().length <= 30
          ? order.name.trim()
          : order.name.trim().substr(0, 27) + "...";
      let allOrgs = organisations;
      if (session?.user?.active_organisation) {
        allOrgs = [...allOrgs, session.user.active_organisation]
      }

      const supplier = allOrgs.find(o => o.id === order.supplier_id)
      const retailer = allOrgs.find(o => o.id === order.owner_id)
      return {
        taskName: orderName,
        label: adsLen > 1 ? adsLen + " Campaigns" : adsLen + " Campaign",
        color:
          order.status == STATUS_APPROVED || order.status == STATUS_LIVE
            ? "#c2d5be"
            : order.status == STATUS_COMPLETED
              ? "#bdbdbd"
              : "#eedecc",
        tip:
          '<div style="padding: 10px; width: auto"><div>Order: ' +
          order?.name +
          "</div><div>Status: " +
          orderStatus +
          "</div><div>Supplier: " +
          supplier?.name +
          "</div><div>Retailer: " +
          retailer?.name +
          "</div><div>Start date: " +
          moment(order.start_at).format("DD/MM/YYYY, h:mm:ss A") +
          "</div><div>End date: " +
          moment(order.stop_at).format("DD/MM/YYYY, h:mm:ss A") +
          "</div><div>Platform(s): " +
          platforms.join(", ") +
          "</div></div>",
        start_date: moment(order.start_at).toDate(),
        end_date: moment(order.stop_at).toDate(),
        id: order.id,
        supplier,
        retailer,
      };
    })
    .reverse();

  const orderHeadings = data.map((order) =>
    order.taskName?.trim().length <= 30
      ? order.taskName.trim()
      : order.taskName.trim().substr(0, 27) + "..."
  );

  const filterBySupplier = (supplierId) => {
    if (supplierId == "all") {
      setFilterBySuppliers([]);
    } else {
      setFilterBySuppliers([supplierId]);
    }
  };

  const filterByRetailer = (retailerId) => {
    if (retailerId == "all") {
      setFilterByRetailers([]);
    } else {
      setFilterByRetailers([retailerId]);
    }
  };

  const filterByPlatform = (platform) => {
    if (platform == "all") {
      setFilterByPlatforms([]);
    } else {
      setFilterByPlatforms([platform]);
    }
  };

  const filterByOrderStatus = (status) => {
    if (status == "all") {
      setFilterByStatus([]);
    } else {
      setFilterByStatus([status]);
    }
  };

  const resetFilters = () => {
    setFilterBySuppliers([]);
    setFilterByPlatforms([]);
    setFilterByStatus([]);
  };

  const graphData = data.map((val) => Object.values(val));
  let graphHeight = graphData.length > 0 ? graphData.length * 35 + 120 : 0;
  graphHeight = graphHeight > 450 ? 450 : graphHeight;

  const columns = [
    { type: "string", id: "Task Name" },
    { type: "string", id: "Name" },
    { type: "string", id: "style", role: "style" },
    { type: "string", role: "tooltip" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
    { type: "string", role: "data" },
    { type: "string", role: "data" },
    { type: "string", role: "data" },
  ];

  const updateTimelineDateFormat = (format) => {
    setFilterByDateRange(format);
    var currentDate = moment();
    if (format === "auto") {
      setHAxisProps({
        textStyle: { fontName: "sans-serif", fontSize: "16" },
        minValue: moment().startOf("year").toDate(),
        maxValue: moment().endOf("year").toDate(),
        format: "MMM dd",
      });
    } else if (format == "daily") {
      setHAxisProps({
        minValue: new Date(new Date().getFullYear(), moment().month()),
        maxValue: new Date(new Date().getFullYear(), moment().month()),
        format: "MMM dd",
      });
    } else if (format == "weekly") {
      setHAxisProps({
        minValue: new Date(),
        maxValue: new Date(new Date().getFullYear(), 12),
        format: "MMM dd",
      });
    } else if (format == "monthly") {
      setHAxisProps({
        minValue: new Date(new Date().getFullYear(), moment().month(), 1),
        maxValue: new Date(new Date().getFullYear(), moment().month() + 1, 31),
        format: "MMM dd",
      });
    } else if (format == "quarterly") {
      setHAxisProps({
        minValue: new Date(new Date().getFullYear(), 0),
        maxValue: new Date(new Date().getFullYear() + 1, 0), //fix to show till december, this would strech the timeline upto January the follwing year
        format: "MMM yy",
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(
      () =>
        setDataStatusMessage(
          activeOrders.length === 0 ? "Currently no orders are running." : ""
        ),
      2000
    );
    setFilterByDateRange("weekly");
    setHAxisProps({
      minValue: new Date(),
      maxValue: new Date(new Date().getFullYear(), 12),
      format: "MMM dd",
    });

    return () => clearTimeout(timer)
  }, []);

  return (
    <div>
      <div className="d-flex flex-wrap align-items-center">
            <Form.Group>
              <Form.Label
                className="mb-0"
                style={{ width: "5rem", marginRight: "10px" }}
              >
                Status:
              </Form.Label>
              <Form.Control
                style={{ marginRight: "20px", width: "12rem" }}
                value={filterByStatus}
                as="select"
                onChange={(e) => filterByOrderStatus(e.target.value)}
              >
                <option value="all">All</option>
                {orderStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status == STATUS_PENDING_APPROVAL
                      ? "Pending Approval"
                      : status == STATUS_LIVE
                        ? "Live"
                        : status == STATUS_COMPLETED
                          ? "Completed"
                          : "Approved"}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label
                className="mb-0"
                style={{ width: "6rem", marginRight: "10px" }}
              >
                Platform:
              </Form.Label>
              <Form.Control
                style={{ marginRight: "20px", width: "12rem" }}
                value={filterByPlatforms}
                as="select"
                onChange={(e) => filterByPlatform(e.target.value)}
              >
                <option value="all">All</option>
                {session.platforms.map((platform) => (
                  <option key={platform.id} value={platform.id}>
                    {platform.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group style={{ display: retailers?.length > 0 ? "inline" : "none" }}>
              <Form.Label
                className="mb-0"
                style={{
                  width: "7rem",
                  marginRight: "10px",
                }}
              >
                Retailer:
              </Form.Label>
              <Form.Control
                style={{
                  marginRight: "20px",
                  width: "12rem",
                }}
                value={filterByRetailers}
                as="select"
                onChange={(e) => filterByRetailer(e.target.value)}
              >
                <option value="all">All</option>
                {retailers.map((retailer) => (
                  <option key={retailer.id} value={retailer.id}>
                    {retailer?.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group style={{ display: suppliers?.length > 0 ? "inline" : "none" }}>
              <Form.Label
                className="mb-0"
                style={{
                  width: "7rem",
                  marginRight: "10px",
                }}
              >
                Supplier:
              </Form.Label>
              <Form.Control
                style={{
                  marginRight: "20px",
                  width: "12rem",
                }}
                value={filterBySuppliers}
                as="select"
                onChange={(e) => filterBySupplier(e.target.value)}
              >
                <option value="all">All</option>
                {suppliers.map((supplier) => (
                  <option key={supplier.id} value={supplier.id}>
                    {supplier.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label
                className="mb-0"
                style={{ width: "5rem", marginRight: "10px" }}
              >
                View:
              </Form.Label>
              <Form.Control
                as="select"
                value={filterByDateRange}
                style={{ width: "10rem" }}
                onChange={(e) => updateTimelineDateFormat(e.target.value)}
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
              </Form.Control>
            </Form.Group>
      </div>
      <div>&nbsp;</div>
      {
    activeOrders.length === 0 && (
      <div className="text-center" style={{ width: "100%", margin: "10px" }}>
        <p>{dataStatusMessage}</p>
      </div>
    )
  }
  <div
    className={styles.chartContainer}
    style={{ height: graphHeight + "px" }}
  >
    <Chart
      chartType="Timeline"
      data={[columns, ...graphData]}
      labels="none"
      legendToggle
      is3D="true"
      options={{
        width: "1700",
        height: graphHeight,
        tooltip: { isHtml: true },
        allowHTML: true,
        backgroundColor: "#fff",
        alternatingRowStyle: true,
        bar: { groupWidth: "100%" },
        vAxis: { textPosition: "none", viewWindowMode: "maximized" },
        hAxis: hAxisProps,
        timeline: { groupByRowLabel: false },
      }}
      chartEvents={[
        {
          eventName: "ready",
          callback: ({ chartWrapper, google }) => {
            let container = chartWrapper.getChart().container;

            var svg = container.getElementsByTagName("svg")[0];
            var tag_g = svg.getElementsByTagName("g");

            var tag_t =
              tag_g[tag_g.length - 2].getElementsByTagName("text")[0];
            var labels = container.getElementsByTagName("text");
            var dateLabels = container
              .getElementsByTagName("svg")[0]
              .getElementsByTagName("text");

            Array.prototype.forEach.call(dateLabels, function (label) {
              label.setAttribute("font-family", "Roboto;");
            });

            var headings = Array.from(labels).filter(
              (label) =>
                label.getAttribute("text-anchor") === "end" &&
                orderHeadings.includes(
                  label?.textContent ? label.textContent : label.innerHTML
                )
            );
            Array.prototype.forEach.call(labels, function (label) {
              if (label.getAttribute("text-anchor") === "end") {
                label.setAttribute("font-family", "Roboto;");
                label.addEventListener(
                  "click",
                  (e) => {
                    for (var i = 0; i < headings.length; i++) {
                      if (headings[i].innerHTML == e.target.innerHTML) {
                        history.push(`/order/${data[i].id}`);
                      }
                    }
                  },
                  false
                );
                label.setAttribute("style", "cursor: pointer;");

              }
            });
            Array.prototype.forEach.call(headings, function (heading) {
              heading.setAttribute(
                "style",
                heading.getAttribute("style") + ";font-weight: 700;"
              );
            });

            //find the correct div
            let innerDivIdx = 2;
            for (
              var i = 0;
              i < container.getElementsByTagName("div").length;
              i++
            ) {
              if (
                i > 2 &&
                container.getElementsByTagName("div")[i].innerHTML &&
                container
                  .getElementsByTagName("div")
                [i].innerHTML.indexOf(orderHeadings[0]) > -1
              ) {
                innerDivIdx = i;
                break;
              }
            }

            var innerDiv =
              container.getElementsByTagName("div")[innerDivIdx];
            var chartDiv = container.getElementsByTagName("div")[1];
            var slist = document.createElement("div");
            var colHeadings = document.createElement("div");
            var headingSupplier = document.createElement("div");
            var headingOrder = document.createElement("div");
            var dummyHeading = document.createElement("div");
            var svg2 =
              container.getElementsByTagName("svg")[
              container.getElementsByTagName("svg").length - 1
              ];

            var tag_g2 = svg2.getElementsByTagName("g");
            var rectE = tag_g2[0].getElementsByTagName("rect")[0];

            chartDiv.setAttribute(
              "style",
              chartDiv.getAttribute("style") + ";overflow-y: hidden;"
            );

            //setting columns headers
            colHeadings.setAttribute(
              "style",
              "position: sticky;display: block; width: 100%; height: 40px; line-height: 40px;font-size: 15px;font-weight: 700;background-color: #f0f3f4;"
            );
            colHeadings.setAttribute("class", "fixed-top");
            headingSupplier.setAttribute(
              "style",
              "display: block;width: 155px; height: auto; text-align: center;float: left;"
            );
            headingOrder.setAttribute(
              "style",
              "display: block;width: 210px; height: auto; text-align: center;float: left;"
            );
            //fix to stretch heading background
            dummyHeading.setAttribute(
              "style",
              "display: block;width: 500px; height: 40px; text-align: center;right: -150px;background-color: #f0f3f4;position: absolute; z-index: 1000"
            );

            headingSupplier.innerHTML = isRetailer
              ? "Supplier"
              : "Retailer";
            headingOrder.innerHTML = "Order";
            colHeadings.append(headingSupplier);
            colHeadings.append(headingOrder);
            colHeadings.append(dummyHeading);

            slist.setAttribute(
              "style",
              "display: inline; min-width: 150px; height: auto; float: left; padding: 0; margin: 0; font-size: 13px; font-weight: 700; text-align: center; border-right: 1px solid #fff;"
            );

            //setting supplier name
            var i = 0;
            Array.prototype.forEach.call(data, function (row) {
              var temp = slist.appendChild(document.createElement("div"));
              temp.setAttribute(
                "style",
                "height: " +
                Math.ceil(rectE.getAttribute("height")) +
                "px; line-height: " +
                Math.ceil(rectE.getAttribute("height")) +
                "px; padding-left: 5px;padding-right: 5px;"
              );
              if (i % 2 == 0) {
                temp.setAttribute(
                  "style",
                  temp.getAttribute("style") + "background-color: #ffffff"
                );
              } else {
                temp.setAttribute(
                  "style",
                  temp.getAttribute("style") + "background-color: #f0f3f4"
                );
              }

              if (isRetailer) {
                temp.innerHTML =
                  row.supplier.name.trim().length <= 20
                    ? row.supplier.name.trim()
                    : row.supplier.name.trim().substr(0, 17) + "...";
              } else {
                temp.innerHTML =
                  row.retailer.name.trim().length <= 20
                    ? row.retailer.name.trim()
                    : row.retailer.name.trim().substr(0, 17) + "...";
              }

              i++;
            });

            innerDiv.prepend(slist);
            innerDiv.prepend(colHeadings);
            //innerDiv.prepend(colHeadings);

            var labels = container.getElementsByTagName("text");

            //adjust date line
            if (innerDivIdx > 2) {
              var dates = tag_g[0].getElementsByTagName("text");
              Array.prototype.forEach.call(dates, function (date) {
                var tmp = date.getAttribute("x");
                date.setAttribute("x", Number(tmp) + 150);
              });
            }

            var rects = container.getElementsByTagName("rect");
            Array.prototype.forEach.call(rects, function (rect) {
              // make sure rect is a background row
              if (
                rect.getAttribute("x") === "0" &&
                rect.getAttribute("stroke") === "none"
              ) {
                // determine existing color
                if (rect.getAttribute("fill") === "#ffffff") {
                  rect.setAttribute("fill", "#ffffff");
                } else {
                  rect.setAttribute("fill", "#f0f3f4");
                }
              }
            });

            var paths = container.getElementsByTagName("path");
            Array.prototype.forEach.call(paths, function (path) {
              path.setAttribute("stroke", "#ffffff");
              path.setAttribute("stroke-width", "1px");
            });

            //remove border around chart
            rects = container.getElementsByTagName("rect");
            Array.prototype.forEach.call(rects, function (rect) {
              // find chart <rect> element
              if (
                rect.getAttribute("x") === "0" &&
                rect.getAttribute("y") === "0"
              ) {
                // remove stroke from last <rect> element
                rect.setAttribute("stroke", "none");
                rect.setAttribute("stroke-width", "0");
              }
            });
          },
        },
      ]}
    />
  </div>
  {
    activeOrders.length > 0 && graphData == 0 && (
      <div className="text-center" style={{ width: "100%", margin: "10px" }}>
        <p>
          No rows match your query, try changing the filters or click here to{" "}
          <a href="javascript:void(0)" onClick={(e) => resetFilters()}>
            reset
          </a>
        </p>
      </div>
    )
  }
    </div >
  );
};

Schedule.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      start_at: PropTypes.string.isRequired,
      stop_at: PropTypes.string.isRequired,
      facebook_campaigns: PropTypes.array,
      google_campaigns: PropTypes.array,
    })
  ),
};

export default Schedule;
