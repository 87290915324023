import {
  faArrowCircleRight,
  faArrowCircleLeft,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Card, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { selectSession } from "../../../session/sessionSlice";
import styles from "./facebook_mixed_carousel.module.scss";
import { selectOwnerOrganisation } from '../../orderFormSlice';

const FacebookMixedCarousel = ({
  bodycopy,
  cta,
  ad,
  assets = [],
  isPreviewMode = true,
  onRightMove = () => {},
  onLeftMove = () => {},
}) => {
  const session = useSelector(selectSession);
  const fallback_logo = "/ad_preview/logo.png";
  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  return (
    <div id="ad_fb" className={styles.facebook_preview}>
      <div style={{ color: "#808080", fontSize: "10px", textAlign: "center", width: "100%" }}>Not actual display of the ad on the device</div>
      <div className={styles.ad_header}>
        <img src={(ownerOrganisation?.settings?.logo?.url || ownerOrganisation?.logo) ?? fallback_logo} alt="" className={styles.ad_logo} />
        <div className={styles.ad_titles}>
          <div className={styles.ad_title_one}>
            <a href="#">{ownerOrganisation.name}</a>
          </div>
          <div className={styles.ad_title_two}>
            <a href="#">Sponsored</a> ·{" "}
            <FontAwesomeIcon icon={faGlobeAsia} />
          </div>
        </div>
      </div>
      <div id="ad_body" className={styles.ad_body}>
        {bodycopy}
      </div>
      <div>
        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode
          className="w-100"
          afterChange={(previousSlide, { currentSlide, onMove }) => {}}
          containerClass={styles.carousel_container}
          itemClass={styles.carousel_item}
          dotListClass=""
          draggable
          focusOnSelect={true}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            largeDesktop: {
              breakpoint: {
                max: 3000, // this can be 1920 and we can add superLargeDesktop with the current max
                min: 1200,
              },
              items: 4,
              partialVisibilityGutter: 40,
            },
            desktop: {
              breakpoint: {
                max: 1200,
                min: 992,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            tablet: {
              breakpoint: {
                max: 992,
                min: 768,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
            mobile: {
              breakpoint: {
                max: 768,
                min: 468,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
            smallMobile: {
              breakpoint: {
                max: 468,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {assets.map((media, i) => (
            <div key={media.id} style={{ margin: "5px" }}>
              <div style={{ textAlign: "center" }}>
                {i > 0 && (
                  <Button
                    onClick={(e) => onLeftMove(media, i)}
                    variant={"outline-secondary"}
                    style={{ margin: "2px", padding: "2px", border: "none" }}
                  >
                    <FontAwesomeIcon icon={faArrowCircleLeft} />
                  </Button>
                )}
                {i < assets.length - 1 && (
                  <Button
                    onClick={(e) => onRightMove(media, i)}
                    variant={"outline-secondary"}
                    style={{
                      margin: "2px",
                      padding: "2px",
                      border: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Button>
                )}
              </div>
              <Card
                className={("card", "d-flex", "text-left", styles.ad_base)}
                style={{
                  padding: "0px",
                  height: "400px",
                  maxHeight: "400px",
                  marginBottom: "15px",
                }}
                key={i}
              >
                <div
                  className="card-block text-center"
                  style={{
                    width: "100%",
                    minHeight: "160px",
                    backgroundColor: "#fff",
                  }}
                >
                  {media.assettype == "image" && (
                    <Card.Img
                      variant="top"
                      style={{
                        maxHeight: "350px",
                        padding: "0",
                        height: "300px",
                        objectFit: "cover",
                      }}
                      className={isPreviewMode ? styles.ad_media_preview : ""}
                      src={media.url}
                    />
                  )}
                  {media.assettype == "video" && (
                    <div style={{ height: "160px", objectFit: "cover" }}>
                      <video
                        className={styles.ad_video}
                        key={media.url}
                        controls
                        style={{ backgroundColor: "white", objectFit: "cover" }}
                      >
                        <source src={media.url} />
                      </video>
                    </div>
                  )}
                </div>
                <Card.Body style={{ padding: "0px", margin: "5px" }}>
                  <div style={{ fontSize: "12px" }}>
                    <div
                      id="ad_link_title"
                      className={("md-6", styles.ad_link_title)}
                    >
                      <strong>
                        {ad?.dynamic_row &&
                        ad.dynamic_row["headline-" + (i + 1)]
                          ? ad.dynamic_row["headline-" + (i + 1)].length >= 27
                            ? ad.dynamic_row["headline-" + (i + 1)].substring(
                                0,
                                27 - 3
                              ) + "..."
                            : ad.dynamic_row["headline-" + (i + 1)]
                          : ad["headline"]
                          ? ad["headline"]
                          : "Headline Goes Here"}
                      </strong>
                      <div className={("md-6", styles.ad_cta_container)}>
                        {cta !== "NO_BUTTON" && (
                          <span
                            id="ad_link_cta"
                            className={styles.ad_link_cta}
                          >
                            {ad?.dynamic_row && ad.dynamic_row["cta-" + (i + 1)]
                              ? ad.dynamic_row["cta-" + (i + 1)].replace(
                                  "_",
                                  " "
                                )
                              : ad["cta"]
                              ? ad["cta"].replace("_", " ")
                              : "SIGN UP"}
                          </span>
                        )}
                      </div>
                      <div>
                        {ad?.dynamic_row &&
                        ad.dynamic_row["description-" + (i + 1)]
                          ? ad.dynamic_row["description-" + (i + 1)].length >=
                            27
                            ? ad.dynamic_row[
                                "description-" + (i + 1)
                              ].substring(0, 27 - 3) + "..."
                            : ad.dynamic_row["description-" + (i + 1)]
                          : ad["description"]
                          ? ad["description"]
                          : "Description Goes Here"}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
      <div className={styles.ad_reactions_container}>
      <img
        src="/ad_preview/reactions.png"
        style={{ width: "50%", display: "block", marginLeft: "auto", marginRight: "auto" }}
        alt=""
        className={styles.ad_reactions}
      />
      </div>
    </div>
  );
};

FacebookMixedCarousel.propTypes = {
  bodycopy: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  ad: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  isPreviewMode: PropTypes.bool,
  onRightMove: PropTypes.func.isRequired,
  onLeftMove: PropTypes.func.isRequired,
};

export default FacebookMixedCarousel;
