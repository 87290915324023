import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp, faCalendar, faCheckCircle, faClipboard, faCross, faImage, faPalette, faPlus, faTimes, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faDown, faPage, faSymbols, faTimeline, faUp } from "@fortawesome/pro-regular-svg-icons";
import { Card, Form, Col, Row } from "react-bootstrap"
import styled from "styled-components";
import TextField from "./TextField";
import YesNoField from "./YesNoField";
import ColorField from "./ColorField";
import DateField from "./DateField";
import ListField from "./ListField";
import RangeField from "./RangeField";
import VideoField from "./VideoField";
import ImageField from "./ImageField";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectBespokeProduct, updateBespokeProduct } from "../../bespokeFormSlice";
import { useSelector } from "react-redux";
import AssetField from "./AssetField";
import PropTypes from "prop-types";

const StyledCard = styled(Card)`
    margin: 10px 0;
    text-align: left;
    border-radius: 10px;
    animation: grow 0.3s ease-in-out 1;
    transition: max-height 0.3s ease-out;
    .card-title {
        background: #F8F8F8;
        text-transform: capitalize;
        padding: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .card-body {
        padding: 10px;
    }
    @keyframes grow {
        from { max-height: 0px; transform: scaleY(0) }
        to { max-height: 500px; transform: scaleY(1) }
    }

`;

const Actions = styled.div`
    svg {
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }
`

const fieldTypeLookup = (name) => {
    if (name === "text") return "Text";
    if (name === "textarea") return "Textarea";
    if (name === "select_one") return "Y/N";
    if (name === "asset") return "Image";
    if (name === "video") return "Video";
    if (name === "list") return "List";
    if (name === "color") return "Color";
    if (name === "date") return "Date";
    if (name === "range") return "Range";
    if (name === "document") return "Asset";
    return "Field";
}

const getIcon = (icon) => {
    switch (icon) {
        case 'text':
            return faSymbols
        case 'select_one':
            return faCheckCircle;
        case 'asset':
            return faImage;
        case 'document':
            return faPage;
        case 'video':
            return faVideo;
        case 'list':
            return faClipboard;
        case 'color':
            return faPalette;
        case 'date':
            return faCalendar;
        case 'range':
            return faTimeline;
        default:
            return faPage;
    }
}

const idHelper = (value) => {
    // Create a snake case id from the input
    return 'bespoke_' + (value.toLowerCase()).replace(" ", "_").replace(/^[a-z][_]+$/g, "")
}

const BespokeFields = (props) => {

    const {
        field,
        index,
        updateLayoutData,
        moveLayoutData,
        layoutData,
        errors,
    } = props;

    const dispatch = useDispatch();
    const bespoke = useSelector(selectBespokeProduct)
    useEffect(() => {
        dispatch(updateBespokeProduct({ ...bespoke, fields: layoutData }))
    },[layoutData])
    return (
        <StyledCard>
            <Card.Title>
                <FontAwesomeIcon className="mr-2" color="#007BFF" icon={getIcon(field.type)} />
                {field.title ? `${field.title} - (${fieldTypeLookup(field.type)})` : fieldTypeLookup(field.type)}
                <Actions className="float-right">
                    <FontAwesomeIcon
                        className="mx-2"
                        icon={faAngleUp} 
                        onClick={() => moveLayoutData(index, -1)}
                    />
                    <FontAwesomeIcon
                        className="mx-2"
                        icon={faAngleDown}
                        onClick={() => moveLayoutData(index, 1)}
                    />
                    <FontAwesomeIcon
                        className="mx-2"
                        icon={faTimes}
                        onClick={() => {
                            updateLayoutData(null, index, true);
                        }}
                    />
                </Actions>
            </Card.Title>
            <Card.Body>
                {(field.type === 'text' || field.type === 'textarea') && <TextField {...props} idHelper={idHelper} />}
                {field.type === 'select_one' && <YesNoField {...props} idHelper={idHelper} />}
                {field.type  === 'video' && <VideoField {...props} idHelper={idHelper} />}
                {field.type  === 'asset' && <ImageField {...props} idHelper={idHelper} />}
                {field.type  === 'list' && <ListField {...props} idHelper={idHelper} />}
                {field.type === 'color' && <ColorField {...props} idHelper={idHelper} />}
                {field.type === 'date' && <DateField {...props} idHelper={idHelper} />}
                {field.type === 'range' && <RangeField {...props} idHelper={idHelper} />}
                {field.type === 'document' && <AssetField {...props} idHelper={idHelper} />}
            </Card.Body>
        </StyledCard >
    );
}

BespokeFields.propTypes = {
    field: PropTypes.object,
    index: PropTypes.number,
    updateLayoutData: PropTypes.func,
    moveLayoutData: PropTypes.func,
    layoutData: PropTypes.func,
    errors: PropTypes.array,
}

export default BespokeFields