import moment from 'moment'
import React, { useEffect } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { budgetUpdated } from '../../orderFormSlice'
import AdBudget from '../AdBudget'

const SubHeader = styled.h6`
  font-weight: bold;
  margin-bottom: 0;
`

const PaymentModelBody = styled.p`
    font-size: 23px;
    text-transform: 'capitalize';
`

const Budget = ({ controlId = '', startDate, stopDate, ad = {}, readOnly = true, showBudget = false, selectedDevices = [], onChange = () => { }, errors = {} }) => {
    const dispatch = useDispatch()
    const start = moment.utc(startDate, "YYYY-MM-DD");
    const end = moment.utc(stopDate, "YYYY-MM-DD");
    let duration = Math.ceil(moment.duration(end.diff(start)).asDays());
    if (duration < 0) {
        duration = 0;
    }
    let totalDailyCost = 0;
    for (var i = 0; i < selectedDevices.length; i++) {
        totalDailyCost = totalDailyCost + selectedDevices[i].budget;
    }

    const slotPrice = parseFloat(totalDailyCost || 0).toFixed(2)
    const fixedPrice = duration * slotPrice
    useEffect(() => {
        if (fixedPrice !== ad.budget) {
            onChange({
                budget: fixedPrice,
            });
            dispatch(budgetUpdated());
        }
    }, [selectedDevices.length]);


    return <Container>
        <Row>
            <Col>
                <SubHeader>Payment Model</SubHeader>
                <PaymentModelBody>Fixed</PaymentModelBody>
            </Col>
        </Row>
        <Row>
            <Col className="font-weight-bold">
                Total spend: <span className="text-primary">${fixedPrice}</span> <span className="text-secondary">(${slotPrice} per day)</span>
            </Col>
        </Row>
    </Container>
}

export default Budget
