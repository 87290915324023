import { React, useCallback, useMemo, useState } from "react";
import propTypes from "prop-types";
import { formatWalletNumber } from "../../lib/wallets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faWallet } from "@fortawesome/free-solid-svg-icons";
import {
  faCircleCheck,
  faCircleXmark,
  faEllipsis,
} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import UpdateWalletDialog from "./UpdateWalletDialog";
import NewWalletDialog from "./NewWalletDialog";
import QueryResult from "./QueryResult";

import NoWallet from "./NoWallet";
import NewWalletButton from "./NewWalletButton";
import CurrencyBadge from "./CurrencyBadge";
import _ from "lodash";
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import DataTable from "./components/tables/DataTable";
import moment from "moment";
import { trimEllipses } from "../../lib/strings";
import PopoverHint from "./components/form/PopoverHint";

const StyledBalanceHeader = styled.div`
  /* ${CurrencyBadge} {
      background-color: red !important;
   } */
  div {
    display: inline-block;
    margin-left: 8px;
  }
`;

const StyledOverlayTooltip = styled(Tooltip)`
  & > .tooltip-inner {
    max-width: 100%;
  }
`;

const StyledActionsDropdown = styled(DropdownButton)`
  .dropdown-toggle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

const StyledOverdrawnAmount = styled.span`
  ${({ isOverdrawn }) => isOverdrawn && `color: #EF767A`}
`;

const StyledWalletName = styled.span`
  display: inline-block;
  cursor: pointer;
`;

const StyledBalance = styled.p`
  margin: 0;
  font-weight: bold;
  color: #7155ff;
`;

const StyledCurrencyFooter = styled.div`
  font-weight: bold;
  color: #7155ff;
  margin-top: 1rem;
`;

const StyledDataTableWrapper = styled.div`
  /* overflow: auto; */

  .table thead th:not(:first-of-type),
  .table tbody td:not(:first-of-type) {
    max-width: 115px;
    text-align: right;
    /* max-width: 115px; */
  }

  .table thead th,
  .table tbody td {

    &:last-child {
      width: 80px;
      text-align: center;
    }
  }
`;

const TableColumnOverlay = ({ hint, text }) => (
  <OverlayTrigger placement="bottom" overlay={<Tooltip>{hint}</Tooltip>}>
    <span>{text}</span>
  </OverlayTrigger>
);

const Wallets = ({
  currency,
  wallets = [],
  organisation,
  loading = false,
  error = false,
  isSupplier = false,
  refreshWallets = () => {},
  handleWalletSelection = () => {},
}) => {
  const [showNewWalletDialog, setShowNewWalletDialog] = useState(false);
  const [updateWalletID, setUpdateWalletID] = useState(null);

  const updateWallet = useMemo(
    () => wallets.find((w) => w.id === updateWalletID),
    [wallets, updateWalletID]
  );

  const totalWalletsBalance = useMemo(
    () =>
      wallets.reduce((n, { balance }) => n + parseFloat(balance), 0).toFixed(2),
    [wallets]
  );

  const totalWalletsAvailableBalance = useMemo(
    () =>
      wallets
        .reduce(
          (n, { available_balance }) => n + parseFloat(available_balance),
          0
        )
        .toFixed(2),
    [wallets]
  );

  const handleNewWalletSubmission = useCallback(() => {
    setShowNewWalletDialog(false);
    refreshWallets();
  }, []);

  const walletNames = useMemo(
    () => wallets?.map((wallet) => wallet.name.trim().toLowerCase()),
    [wallets]
  );

  const walletListColumns = [
    {
      dataField: "name",
      formatter: (cell, row) => {
        return (
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <StyledOverlayTooltip>
                <div className="d-flex flex-column text-left">
                  <span>
                    * Available Balance:{" "}
                    {row.allow_overdraw
                      ? "∞"
                      : formatWalletNumber(
                          row.available_balance,
                          row?.currency
                        )}
                  </span>

                  <span>
                    * Net Position:{" "}
                    {formatWalletNumber(row.balance, row?.currency)}
                  </span>

                  <span>
                    * Total Debits:{" "}
                    {formatWalletNumber(row.total_debit, row?.currency)}
                  </span>

                  <span>
                    * Total Credits:{" "}
                    {formatWalletNumber(row.total_credit, row?.currency)}
                  </span>

                  <span>
                    * Overdrawn Amount:{" "}
                    {formatWalletNumber(row.overdrawn_amount, row?.currency)}
                  </span>

                  <span>
                    * Minimum Balance:{" "}
                    {formatWalletNumber(row.min_balance, row?.currency)}
                  </span>

                  <span>
                    * Overdraw Allowed: {row?.allow_overdraw ? "Yes" : "No"}
                  </span>

                  <span>* Currency: {row?.currency}</span>
                  <span>* Active: {row?.is_active ? "Yes" : "No"}</span>
                  {row.remark && <span>* Additional Info: {row.remark}</span>}
                  <span>* ID: {row.id}</span>
                </div>
              </StyledOverlayTooltip>
            }
          >
            <StyledWalletName onClick={() => handleWalletSelection(row)}>
              <FontAwesomeIcon
                icon={faWallet}
                className="mr-2"
                size="md"
                color={row.is_active ? "#4FAD80" : "#EF767A"}
              />
              <span>{trimEllipses(row.name, 50)}</span>
            </StyledWalletName>
          </OverlayTrigger>
        );
      },
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="Name of the wallet"
          text="Wallet"
        />
      ),
    },
    {
      dataField: "available_balance",
      formatter: (cell, row) => (
        <span>
          {row.allow_overdraw
            ? "∞"
            : formatWalletNumber(row?.available_balance, row?.currency)}
        </span>
      ),
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="Amount available to spend,  net position, minimum balance and over draw options"
          text="Available Balance"
        />
      ),
    },
    {
      dataField: "balance",
      formatter: (cell, row) => (
        <span>{formatWalletNumber(row?.balance, row?.currency)}</span>
      ),
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="Net owing or due amount for a wallet, taking into account total debits, credits"
          text="Net Position"
        />
      ),
    },

    {
      dataField: "total_debit",
      formatter: (cell, row) => (
        <span>{formatWalletNumber(row?.total_debit, row?.currency)}</span>
      ),
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="Sum of all the debit entries in the wallet"
          text="Total Debits"
        />
      ),
    },
    {
      dataField: "total_credit",
      formatter: (cell, row) => (
        <span>{formatWalletNumber(row?.total_credit, row?.currency)}</span>
      ),
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="Sum of all the credit entries in the wallet"
          text="Total Credits"
        />
      ),
    },
    {
      dataField: "overdrawn_amount",
      formatter: (cell, row) => {
        return (
          <StyledOverdrawnAmount
            isOverdrawn={!row.allow_overdraw && row?.overdrawn_amount > 0}
          >
            {formatWalletNumber(row.overdrawn_amount, row?.currency)}
          </StyledOverdrawnAmount>
        );
      },
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="The total overdrawn amount, taking into account minimum balance and over draw options"
          text="Overdrawn Amount"
        />
      ),
    },
    {
      dataField: "min_balance",
      formatter: (cell, row) => (
        <span>{formatWalletNumber(row?.min_balance, row?.currency)}</span>
      ),
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="The balance that the wallet can't go below. This value is not applicable for wallet with overdraw enabled"
          text="Minimum Balance"
        />
      ),
    },
    {
      dataField: "end_date",
      formatter: (cell, row) => {
        if (!row.end_date) {
          return "—";
        }

        return (
          <div className="text-right">
            <div className="d-flex flex-column">
              <div> {moment.utc(row.end_date).format("DD/MM/YYYY")}</div>
            </div>
          </div>
        );
      },
      headerFormatter: (col) => (
        <TableColumnOverlay
          hint="A wallet will not be available to be linked to an order, after this date"
          text="Expiry Date"
        />
      ),
    },
    {
      dataField: "actions",
      text: "",
      formatter: (cell, row) => {
        return (
          <>
            <StyledActionsDropdown
              key={"down"}
              id={`dropdown-button-drop-right`}
              drop={"down"}
              variant="outline-secondary"
              title={
                <span>
                  <FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>
                </span>
              }
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() => handleWalletSelection(row)}
              >
                View Transactions
              </Dropdown.Item>
              {!isSupplier && (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => setUpdateWalletID(row.id)}
                >
                  Update Wallet
                </Dropdown.Item>
              )}
            </StyledActionsDropdown>
          </>
        );
      },
    },
  ];
  return (
    <QueryResult loading={loading} error={error}>
      {wallets?.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <StyledBalance>
                Net Position:{" "}
                {formatWalletNumber(totalWalletsBalance, currency)}
              </StyledBalance>
              <StyledBalance>
                <>
                  Available Balance:{" "}
                  {formatWalletNumber(totalWalletsAvailableBalance, currency)}
                  <PopoverHint popoverText="Does not include wallets with unlimited overdraw enabled" />
                </>
              </StyledBalance>
            </div>
            {!isSupplier && (
              <NewWalletButton
                handleClick={() => setShowNewWalletDialog(true)}
              />
            )}
          </div>

          <StyledDataTableWrapper>
            <DataTable columns={walletListColumns} data={wallets} />
          </StyledDataTableWrapper>

          {currency && (
            <StyledCurrencyFooter>Currency: {currency}</StyledCurrencyFooter>
          )}
        </>
      ) : (
        <NoWallet
          organisation={organisation}
          isSupplier={isSupplier}
          handleNewWalletBtn={() => setShowNewWalletDialog(true)}
        />
      )}

      {showNewWalletDialog && (
        <NewWalletDialog
          organisation={organisation}
          currency={currency}
          existingWallets={walletNames}
          onSubmitHandler={handleNewWalletSubmission}
          onHideHandler={() => setShowNewWalletDialog(false)}
        />
      )}

      {updateWallet && (
        <UpdateWalletDialog
          wallet={updateWallet}
          organisation={organisation}
          currency={currency}
          existingWallets={wallets}
          onSubmitHandler={() => refreshWallets() && setUpdateWalletID(null)}
          onHideHandler={() =>
            setUpdateWalletID(null) && setUpdateWalletID(null)
          }
        />
      )}
    </QueryResult>
  );
};

Wallets.propTypes = {
  currency: propTypes.string.isRequired,
  wallets: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string.isRequired,
      name: propTypes.string.isRequired,
      currency: propTypes.string.isRequired,
      balance: propTypes.number.isRequired,
      available_balance: propTypes.number.isRequired,
    })
  ),
  isSupplier: propTypes.bool,
  organisation: propTypes.shape({
    id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
  }),
  handleWalletSelection: propTypes.func.isRequired,
  refreshWallets: propTypes.func.isRequired,
};

export default Wallets;
