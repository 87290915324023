import { groupBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectSession } from '../../../../../session/sessionSlice'
import RatioSelect from './RatioSelect'

const Container = styled.div`
    border: 1px solid #d0c6ff;
    border-radius: 4px;
    padding: 15px;
    position: relative;
    width: 100%;
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;
`

const MediaContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: #CBD3DB;
    border-radius: 4px;

    ${props => props.ratio === '1:1'
        && `
    width: 150px;
    height: 150px;`}

    ${props => props.ratio === '1.91:1'
        && `
    width: 239px;
    height: 125px;`}

    ${props => props.ratio === '9:16'
        && `
    width: 113px;
    height: 200px;`}
`

const Image = styled.img`
    ${props => props.crop
        ?
        `object-fit: cover;
    width: 100%;
    height: 100%;`
        :
        `object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;`}
`

const NoMedia = ({ name }) =>
    <div className={`p-1 w-100 h-100 rounded`}>
        {name}
    </div>

const Video = styled.video`
    ${props => props.crop
        ?
        `object-fit: cover;
    width: 100%;
    height: 100%;`
        :
        `object-fit: scale-down;
    max-width: 100%;
    max-height: 100%;`}
`

const ImgMedia = ({ ratio, crop, src, alt }) => (
    <MediaContainer ratio={ratio}>
        <Image
            ratio={ratio}
            alt={alt}
            src={src}
            crop={crop}
        />
    </MediaContainer>
)

const VideoMedia = ({ ratio, crop, src }) => (
    <MediaContainer ratio={ratio}>
        <Video
            controls=""
            crop={crop}
        >
            <source src={src} />
        </Video>
    </MediaContainer>

)

const AssetRatiosSelector = ({
    readOnly = true,
    automaticPlacements = false,
    placements = [],
    asset = {},
    value = [],
    onChange = () => { },
    onPlacementDelete = () => { },
}) => {
  const fileFormats = useSelector(state => selectSession(state).file_formats)

    const handleChange = (originalRatio, assetRatio) => {
        const newValue = value.filter(ar => ar.ratio !== originalRatio)

        // If ratio is replaced by specified asset, insert asset actual asset
        if (assetRatio.id) {
            return onChange([...newValue, {...assetRatio, ratio: originalRatio, asset_id: asset.id }])
        }

        // Asset was removed
        if (assetRatio.ratio === undefined) {
            return onChange(newValue)
        }

        // Asset was replaced by asset ratio of current asset
        onChange([...newValue, {...assetRatio, asset_id: asset.id }])
    }

    let ratiosToPlacements = {};
    let mediaComponent = (assetRatio, ratio) => <NoMedia name={assetRatio?.name || asset.name} />
    let accept;
    if (asset.assettype === 'image') {
        mediaComponent = (assetRatio, ratio) => <ImgMedia
            src={assetRatio?.url || asset.url}
            alt={assetRatio?.name || asset.name}
            ratio={ratio}
            crop={assetRatio?.ratio === ratio}
        />
        ratiosToPlacements = groupBy(placements, (placement) => placement.image?.recommended)
        accept = fileFormats.images
    } else if (asset.assettype === 'video') {
        mediaComponent = (assetRatio, ratio) => <VideoMedia
            src={assetRatio?.url || asset.url}
            ratio={ratio}
            crop={assetRatio?.ratio === ratio}
        />
        ratiosToPlacements = groupBy(placements, (placement) => placement.video?.recommended)
        accept = fileFormats.videos
    }

    const ratios = Object.keys(ratiosToPlacements).filter(ratio => ratio !== '' && ratio !== 'undefined')

    return <Container>
        {ratios.map(ratio =>
            <RatioSelect
                controlId={`${asset.id}-${ratio}`}
                readOnly={readOnly}
                header={ratiosToPlacements[ratio].map(placement => placement.name).join(', ')}
                options={[
                    {
                        id: 'original',
                        label: 'Original',
                        value: undefined,
                    },
                    {
                        id: ratio,
                        label: ratio,
                        value: ratio,
                    },
                ]}
                ratio={ratio}
                value={value.find(assetRatio => assetRatio.ratio === ratio)}
                mediaComponent={mediaComponent(value.find(asset => asset.ratio === ratio), ratio)}
                placementRatio={value.find(asset => asset.ratio === ratio)}
                placements={placements}
                automaticPlacements={automaticPlacements}
                onChange={newRatio => handleChange(ratio, newRatio)}
                onDelete={deletedRatio => handleChange(ratio, {})}
                accept={accept}
            />
        )}
    </Container>
}

AssetRatiosSelector.propTypes = {
    readOnly: PropTypes.bool,
    placements: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            image: PropTypes.shape({
                recommended: PropTypes.string,
            }),
            video: PropTypes.shape({
                recommended: PropTypes.string,
            }),
        }),
    ),
    asset: PropTypes.object,
    value: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            asset_id: PropTypes.string.isRequired,
            ratio: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
}

export default AssetRatiosSelector
