import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { getBrandingAssets, resetPasswordExternal } from '../lib/api'
import Footer from './layout/Footer'
import styles from './resetpassword.module.scss'

const ResetPassword = () => {
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  let location = useLocation()
  let params = new URLSearchParams(location.search)

  const [theme, setTheme] = useState({})
  useEffect(() => {
    if (params.has('id')) {
      getBrandingAssets(params.get('id')).then(resp => {
        if (resp?.data?.data) {
          setTheme(resp.data.data)
        }
      })
    }
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    resetPasswordExternal(email).then(resp => setSuccess(true))
  }

  return (
    <>
      <Helmet>
        <title>
          Reset Password - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <main
        className="py-4"
        style={{ backgroundColor: theme?.styles?.background }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card>
                {theme?.logo?.url && (
                  <img
                    className={styles.logo}
                    src={theme.logo.url}
                    alt="Branding"
                  />
                )}
                <Card.Body>
                  {success && (
                    <Alert variant="success" className="text-center">
                      Please check your emails to continue reseting your
                      password.
                    </Alert>
                  )}
                  <Alert variant="primary" className="text-center">
                    Enter your account email to reset
                  </Alert>
                  <Form>
                    <Form.Group controlId="email">
                      <Row>
                        <Col lg={{ offset: 3, span: 6 }}>
                          <Form.Control
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row>
                      <Col className="text-center mb-2">
                        <Button type="submit" onClick={handleSubmit}>
                          Reset Password
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default ResetPassword
