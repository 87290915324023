import React, {useEffect, useRef} from "react";

export const useInfiniteScroll = (getData: () => void, deps?: React.DependencyList) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        const element = ref.current;
        if (
            element &&
            Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 10
        ) {
            getData()
        }
    }

    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll);
            }
        };
    }, deps);

    return ref;
}
