import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getAllOrders } from '../../lib/api'
import { isArchived } from '../../lib/orders'
import { selectSession, selectUser } from '../session/sessionSlice'
import { applyFilter, sortByFuncs } from './orderFiltering'


export const fetchOrders = createAsyncThunk('orders/fetchOrders', async (arg, { getState }) => {
    const response = await getAllOrders(arg)
    return response.data
})

const ordersAdapter = createEntityAdapter({
    // Sort chronologically
    sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
})


export const {
    selectAll: selectAllOrders,
    selectIds: selectOrderIds,
    selectById: selectOrderById,
} = ordersAdapter.getSelectors((state) => state.orders)

const initialFilter = {
    text: '',
    organisation: '',
    order_status: '',
    format: '',
    platform: '',
    sort_by: 'status',
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState: ordersAdapter.getInitialState({
        orders: [],
        archived_only: false,
        per_page: 0,
        total: 0,
        current_page: 0,
        pages: 1,
        filterFields: initialFilter,
        status: 'idle',
        error: {},
    }),
    reducers: {
        orderUpdated(state, action) {
            ordersAdapter.upsertOne(state, action.payload)
        },
        orderDeleted: ordersAdapter.removeOne,
        ordersCleared: ordersAdapter.removeAll,
        filterUpdated(state, action) {
            state.filterFields = action.payload
        },
        resetFilter(state, action) {
            state.filterFields = initialFilter
        }
    },
    extraReducers: {
        [fetchOrders.pending]: (state, action) => {
            state.status = 'loading'
            state.error = {}
        },
        [fetchOrders.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                ordersAdapter.setAll(state, action.payload.data)
                state.archived_only = action.meta?.arg?.onlyArchived ?? false
                state.total = action.payload?.total ?? 0
                state.per_page = action.payload?.per_page ?? 0
                state.current_page = action.payload?.current_page ?? 0
                state.pages = action.payload?.total > 0 && action.payload?.per_page > 0 ? Math.ceil(action.payload?.total / action.payload?.per_page) : 1
                state.status = 'succeeded'
            }
        },
        [fetchOrders.rejected]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'failed'
                state.error = action.payload
            }
        },
    },
})

export const {
    ordersLoaded,
    orderUpdated,
    orderDeleted,
    filterUpdated,
    resetFilter,
    ordersCleared,
} = ordersSlice.actions

export const selectRecentOrders = state =>
    selectAllOrders(state)
        .filter(a => new Date(a.updated_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))

export const selectArchivedOrders = state => {
    const user = selectUser(state)

    return selectAllOrders(state).filter(order => isArchived(order, user))
}

export const selectUnarchivedOrders = state => {
    const user = selectUser(state)

    return selectAllOrders(state).filter(order => !isArchived(order, user))
}

export const selectFilterFields = state => state.orders.filterFields

export const selectFilteredOrders = state => {
    const filterFields = selectFilterFields(state)
    const orders = selectUnarchivedOrders(state)
    const session = selectSession(state)
    const funcs = sortByFuncs(session)

    return applyFilter(orders, filterFields).sort(funcs[filterFields.sort_by])
}

export const selectIsLoading = state => state.orders.status === 'loading';

export const selectFilteredArchivedOrders = state => {
    const filterFields = selectFilterFields(state)
    const orders = selectArchivedOrders(state)
    const session = selectSession(state)
    const funcs = sortByFuncs(session)

    return applyFilter(orders, filterFields).sort(funcs[filterFields.sort_by])
}


export default ordersSlice.reducer

export const reloadAllOrders = () => async (dispatch) => {
    dispatch(ordersCleared())
    dispatch(fetchOrders())
}
