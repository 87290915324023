import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-bootstrap'
import { validVideo } from '../../../../lib/asset_selection'
import Errors from '../Errors'
import styles from './asset.module.scss'

const ImgMedia = ({ src, alt }) => (
    <img
        className={`w-100 h-100 rounded ${styles.media_square}`}
        alt={alt}
        src={src}
    />
)

const VideoMedia = ({ src, duration }) => (
    <>
    <video
        controls=""
        width="100%"
        className={`w-100 h-100 rounded ${styles.media_square}`}
    >
        <source src={src} />
    </video>
    {duration && <p>{duration}s</p>}
    </>
)

const FileSquare = ({ file, checked, onChange = () => { }, duration, disabled = false }) => {
    let media = ''
    if (file.assettype === 'image') {
        media = <ImgMedia src={file.url} alt={file.name} />
    } else if (file.assettype === 'video') {
        media = <VideoMedia src={file.url} duration={duration} />
    } else {
        media = (
            <div className={`p-1 w-100 h-100 rounded ${styles.text_square}`}>
                {file.name}
            </div>
        )
    }

    return (
        <div className={`m-3 rounded ${styles.container}`} style={{ opacity: disabled ? '0.4' : '1' }} onClick={e => {
            e.preventDefault()
            onChange(!checked)
        }}>
            {media}
            {checked &&
                <div
                    className={`border-0 p-0 text-success ${styles.check_button}`}
                >
                    <FontAwesomeIcon icon={faCheckCircle} />
                </div>}
        </div>
    )
}

const Asset = ({
    controlId,
    readOnly = false,
    required = false,
    value = [],
    files = [],
    onChange = () => { },
    errors = [],
    header,
    type,
    max,
    field,
    notice,
}) => (
    <Form.Group controlId={controlId}>
        <Form.Label>
            {header} {required && '*'}
        </Form.Label>
        {notice &&
        <Form.Control.Feedback className="d-block text-body mb-2">
          {notice}
      </Form.Control.Feedback>}
        <Form.Control type="hidden" />
        <div>
            {files.length === 0 ?
                <div className="text-center" style={{ lineHeight: '4rem' }}>
                    No {type} assets uploaded.
                </div>
                : <div>
                    <p>Select assets {max ? `(max: ${max})` : ''}</p>
                    {field.max_video_duration && <p>Max video duration (in seconds): {field.max_video_duration} </p>}
                    <div className="d-flex flex-wrap">
                        {files.map(file => {
                            let enabled = true
                            if (file.assettype === 'video') {
                                enabled = validVideo(field, file)
                            }
                            return <FileSquare
                                key={file.id}
                                file={file}
                                disabled={!enabled}
                                duration={file.metadata?.duration}
                                checked={value.includes(file.id)}
                                onChange={checked => {
                                    if (!readOnly && enabled) {
                                        if (max === 1 && checked) {
                                            onChange([file.id])
                                            return
                                        }
                                        if (value.length >= max && checked) {
                                            return
                                        }
                                        const fs = value.filter(fid => fid !== file.id)
                                        if (checked) {
                                            onChange([...fs, file.id])
                                        } else {
                                            onChange([...fs])
                                        }
                                    }
                                }} />
                        })}
                    </div>
                </div>
            }
        </div>

        <Form.Control.Feedback type="invalid" className={`${errors.length > 0 ? 'd-block' : ''}`}>
            <Errors errors={errors} />
        </Form.Control.Feedback>
    </Form.Group>
)


Asset.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
    header: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.number),
    files: PropTypes.array,
    max: PropTypes.number,
    type: PropTypes.oneOf(['image', 'video', 'mixed']).isRequired,
    maxVideoDuration: PropTypes.number,
    notice: PropTypes.string,
}

export default Asset
