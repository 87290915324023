import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import RegisterUserForm from '../features/users/RegisterUserForm'
import { getBrandingAssets, registerUser } from '../lib/api'
import Footer from './layout/Footer'
import styles from './login.module.scss'
import { handleLinkUser } from '../features/session/sessionSlice'

const LinkUser = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  let location = useLocation()
  let params = new URLSearchParams(location.search)

  useEffect(() => {
    if (params.has('token')) {
      handleLink(params.get('token'));
    }
  }, [])

  const handleLink = async (token) => {
    dispatch(
      handleLinkUser({ token: token })
    )
  }

  const status = useSelector(state => state.session.status)

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME}</title>
      </Helmet>

      <main
        className="py-4 mt-4"
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <Row className="justify-content-center">
                <div className="h1">We are linking you to your new organisation, please wait...</div>
              </Row>
              {status === 'loading' && (
                <Row className="text-center d-flex align-items-center justify-content-center">
                  <Spinner animation="border" />&nbsp;
                </Row>
              )}
              {status == 'success' && (
                <Redirect to="/login" />
              )}
              {status == 'failed' && (
                <Row className="justify-content-center">
                  <div className="h2">Something went wrong, please try again</div>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default LinkUser
