import PropTypes from "prop-types";
import React, { useState } from "react";
import PropType from "prop-types";
import { Alert, Form, InputGroup } from "react-bootstrap";
import Errors from "../../order_form/components/Errors";
import Select from "react-select";
import { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectSession } from "../../session/sessionSlice";

const StyledSpace = styled.div`
    width: 100%;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
    span {
        font-size: 22px;
        color: #777;
    }
    p {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }
    ${props => {
        return `background: #FAFAFA; color: #000;
            span {color: #000;}`
    }}
`;

const Space = ({title, category}) => <>
    <StyledSpace count={Number.isInteger(Math.ceil(title)-0)?title:0} >
        <div>
            <div><span>{title}</span></div>
            <p>{category}</p>
        </div>
    </StyledSpace>
</>

const capitalize = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
}


const InventorySpaceDetails = ({ ownedSpace, errors = {} }) => {
    const session = useSelector(selectSession);
    const [organisation, setOrganisation] = useState(session.user.active_organisation);
 
    return (
        <div className="border rounded p-3">
            <article>
                <div className="mb-2">
                    <h5>Space Details</h5>
                </div>
                <div className="d-flex">
                    <Space 
                        title={'Main Hero Page'}
                        category={'Position'}
                    />
                    <Space
                        title={ownedSpace?.category}
                        category={'Category'}
                    />
                    <Space
                        title={capitalize(ownedSpace?.format)}
                        category={'Format'}
                    />
                </div>
                <div className="d-flex">
                    <Space
                        title={ownedSpace?.link}
                        category={'URL Address'}
                    />
                </div>
                <div className="d-flex">
                    <Space
                        title={capitalize(ownedSpace?.monetising_mode)}
                        category={'Monetisation Model'}
                    />
                    <Space 
                        title={ownedSpace?.live_orders}
                        category={'Live Orders'}
                    />
                </div>
                <div className="d-flex">
                    <Space
                        title={ownedSpace?.active_orders}
                        category={'Active Orders'}
                    />
                    <Space 
                        title={ownedSpace?.approved_orders}
                        category={'Approved Orders'}
                    />
                </div>
                <div className="d-flex">
                    <Space
                        title={ownedSpace?.impressions}
                        category={'Impressions'}
                    />
                    <Space
                        title={ownedSpace?.ctr}
                        category={'CPM'}
                    />
                    <Space
                        title={ownedSpace?.roas}
                        category={'ROAS'}
                    />
                </div>
                <div className="d-flex">
                    <Space
                        title={ownedSpace?.conversion_count} //conversion_value
                        category={'Total Conversions'}
                    />
                    <Space 
                        title={organisation?.settings?.defaultCurrency}
                        category={'Currency'}
                    />
                </div>
                <div className="d-flex">
                    <Space
                        title={ownedSpace?.lifetime}
                        category={'Lifetime Revenue'}
                    />
                    <Space
                        title={ownedSpace?.annual}
                        category={'Annual Revenue'}
                    />
                    <Space
                        title={ownedSpace?.quarterly}
                        category={'Quarterly Revenue'}
                    />
                </div>
            </article>
        </div>
    );
};

InventorySpaceDetails.propTypes = {
    ownedSpace: PropTypes.object,
    errors: PropTypes.object,
};

export default InventorySpaceDetails;
