import { DevTool } from "@hookform/devtools";
import React, { useMemo, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
  Table
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Chrome from "./layout/Chrome";
import {
  refreshSession,
  selectSession,
} from "../features/session/sessionSlice";
import {
  updateRoleDetails,
} from "../lib/api";
import { useEffect } from "react";
import moment from "moment";
import styled from "styled-components";

function TableModifications(tableInfo) {
  return tableInfo.name.replace(/\b\w/g, l => l.toUpperCase());
}

const StyledDiv = styled.div`
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
`;

const PermissionsTable = ({
  data,
  title,
  value = [],
  disabled = false,
  onChange = () => { }
}) => {
  
  let checkedValues = value.filter(v => v.value === true).map(v => v.id)
  return (
    <>
      <div className="d-sm-flex justify-content-between align-items-center mb-3">
        <h5 className="text-dark mb-0">{title}</h5>
      </div>

      <Table responsive bordered>
        <thead>
          <tr>
            <th>Enabled?</th>
            <th>Permission</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 && data.map(permission => (
            <tr>
              <td>
                <InputGroup>
                  <input type="checkbox" checked={checkedValues.includes(permission.id)} disabled={disabled} onChange={e => onChange({ id: permission.id, value: e.target.checked })} />
                </InputGroup>
              </td>
              <td>{TableModifications(permission)}{permission?.parent_permission && <div><small>Requires <b>{permission.parent_permission.name.replace(/\b\w/g, l => l.toUpperCase())}</b> permission</small></div>}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

const OrganisationRoleEdit = () => {
  let history = useHistory();
  const { id } = useParams();
  const session = useSelector(selectSession);
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const [msg, setMsg] = useState(null);
  const [userActionMsg, setUserActionMsg] = useState(null);
  const [isSavingDetails, setIsSavingDetails] = useState();
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [selectedRole, setSelectedRole] = useState(session.available_roles.find(role => role.id === parseInt(id)));
  const [allPermissions, setAllPermissions] = useState(session.available_permissions);

  const [rolePermissionsForm, setRolePermissionsForm] = useState({
    name: selectedRole?.name,
    permissions: allPermissions.map(p => { return { id: p.id, value: false } }),
  });

  useEffect(() => {
    let rolePermissions = selectedRole.permissions.map(p => { return { id: p.id, value: true } });
    let rolePermissionsIds = rolePermissions.map(p => p.id);
    let filterdRoles = rolePermissionsForm.permissions.filter(p => !rolePermissionsIds.includes(p.id));
    filterdRoles = filterdRoles.concat(rolePermissions);
    setRolePermissionsForm({ ...rolePermissionsForm, ...{ permissions: filterdRoles } });
  }, []);

  const handleUpdateRoleDetailsSubmit = (e) => {
    e.preventDefault();
    setMsg(null);
    setUserActionMsg(null);
    setIsSavingDetails(true);

    updateRoleDetails(
      organisation.id,
      selectedRole.id,
      rolePermissionsForm.name,
      rolePermissionsForm.permissions).then(res => {
        dispatch(refreshSession());
        setMsg({});
        setIsSavingDetails(false);
        history.push(`/organisation/settings`);
        window.location.reload();
      }).catch(err => {
        setIsSavingDetails(false);
        const errors = err?.response?.data;
        const keys = Object.keys(errors || {});
        if (!errors || !keys.length || typeof errors === 'string') {
          setMsg({
            type: "danger",
            body: "Could not save data, please try again.",
          });
        } else {
          let tmp = [];
          keys.forEach((errorKey) => {
            tmp.push(errors[errorKey])
          });
          setMsg({
            type: "danger",
            body: tmp,
          });
        }
      });
  }

  return (
    <>
      <Helmet>
        <title>
          Organisation Role for {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container>
          <Row className="mb-3">
            <Col>
              <div className="rounded p-3 d-flex">
                <StyledDiv
                  className="d-inline-block">
                  <h3>Role Details for {selectedRole?.name}</h3>
                </StyledDiv>
              </div>
            </Col>
          </Row>

          <Form onSubmit={(e) => handleUpdateRoleDetailsSubmit(e)}>
            <Row className="mb-2">
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Name:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      value={rolePermissionsForm?.name}
                      style={session?.theme?.components?.form_control}
                      disabled={selectedRole?.is_undeletable}
                      onChange={e => {
                        setRolePermissionsForm({ ...rolePermissionsForm, name: e.target.value })
                      }}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Created at:</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      readOnly={true}
                      value={moment(selectedRole?.created_at).format('YYYY-MM-DD hh:mm A')}
                      style={session?.theme?.components?.form_control}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="6">
                <PermissionsTable disabled={selectedRole?.has_all_permissions} data={allPermissions} value={rolePermissionsForm.permissions} title="Permissions" onChange={d => {
                  let p = rolePermissionsForm.permissions;
                  p = p.filter(p => p.id !== d.id)
                  p = p.concat(d);
                  setRolePermissionsForm({ ...rolePermissionsForm, ...{ permissions: p } })
                }} />
              </Col>
            </Row>
            {!selectedRole?.has_all_permissions && <Row className="mb-3">
              <Col>
                <Button
                  variant="primary"
                  className="mr-auto px-3"
                  type="submit"
                  size="md"
                  disabled={isSavingDetails}
                >
                  <span className="small d-flex">
                    {isSavingDetails && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />
                    )}
                    <span>Save</span>
                  </span>
                </Button>
              </Col>
            </Row>}
            {selectedRole?.has_all_permissions && <Row>
              <Col>
                <Alert variant="warning">Permissions for this role cannot be updated.</Alert>
              </Col>
            </Row>}
            <Row>
              <Col>
                {msg && !Array.isArray(msg.body) && (
                  <Alert className="mt-3" variant={msg.type}>
                    {msg.body}
                  </Alert>
                )}
                {msg && Array.isArray(msg.body) && (
                  <Alert className="mt-3" variant={msg.type}>
                    {msg.body.map((key, i) =>
                      <li>{msg.body[i]}</li>
                    )}
                  </Alert>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationRoleEdit;
export {PermissionsTable}