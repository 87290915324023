import { DevTool } from "@hookform/devtools";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ProgressBar,
  Row,
  Spinner,
  Modal,
  Table
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Chrome from "../app/layout/Chrome";
import OrganisationList from "../features/organisation_settings/components/List";
import styles from "./organisation_web_settings.module.scss";
import {
  clearSession,
  refreshSession,
  selectSession,
  selectUser
} from "../features/session/sessionSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserGraduate, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { faShield, faFilter, faSearch } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import _ from 'lodash';
import { createRole, refreshUser, updateRoleDetails } from "../lib/api";
import { INVITE_EMPLOYEE, USER_MANAGEMENT } from "../lib/permissions";
import { PermissionsTable } from "./OrganisationRoleEdit";

const StyledTitle = styled.div`
  font-weight: bold;
  h3 {
    font-size: 22px;
  }
  color: #000;
  text-align: left;
  width: 100%;
`;

const StyledButton = styled(Button)`
  & ,
  &:hover,
  &:focus,
  &:active,
  &::selection{
    border-color: #644BF8 !important;
    background-color: #644BF8 !important;
  }
`;

const StyledDiv = styled.div`
  font-size: 1.25rem;
  text-align: center;
  width: 100%;
`;

// const PermissionsCheckbox = ({
//   name,
// }) => {
//   const { control, register } = useForm();

//   return <Controller
//     control={control}
//     name={name}
//     render={({
//       field: { onChange, onBlur, value, name, ref },
//       fieldState: { invalid, isTouched, isDirty, error },
//       formState,
//     }) => (
//       <Form.Check
//         onBlur={onBlur}
//         onChange={onChange}
//         checked={value}
//       />
//     )}
//   />
// }

// const PermissionsTable = ({
//   data,
//   title,
//   value = [],
//   onChange = () => { }
// }) => {
//   const SelectedComponent = PermissionsCheckbox;
//   function TableModifications(tableInfo) {
//     return tableInfo.name.replace(/\b\w/g, l => l.toUpperCase());
//   }
//   let checkedValues = value.filter(v => v.value === true).map(v => v.id)
//   return (
//     <>
//       <div className="d-sm-flex justify-content-between align-items-center mb-3">
//         <h5 className="text-dark mb-0">{title}</h5>
//       </div>

//       <Table responsive bordered>
//         <thead>
//           <tr>
//             <th>Enabled</th>
//             <th>Permission</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data?.length > 0 && data.map(permission => (
//             <tr>
//               <td>
//                 <InputGroup>
//                   <input name="acceptTerms" type="checkbox" checked={checkedValues.includes(permission.id)} onChange={e => onChange({ id: permission.id, value: e.target.checked })} />
//                 </InputGroup>
//               </td>
//               <td>{TableModifications(permission)}{permission?.parent_permission && <div><small>Requires <b>{permission.parent_permission.name.replace(/\b\w/g, l => l.toUpperCase())}</b> permission</small></div>}</td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </>
//   );rounded p-3 d-flex
// }


const OrganisationUserManagement = () => {
  let history = useHistory();
  const session = useSelector(selectSession);
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const [usersList, setUsersList] = useState(session.user.active_organisation.users);
  const [filters, setFilters] = useState({ searchTerm: null });
  const handleFilterChange = filterType => setFilters({ ...filters, ...filterType });
  const [rolesList, setRolesList] = useState(session?.available_roles);
  const [createRoleModal, setCreateRoleModal] = useState(false);
  const [allPermissions, setAllPermissions] = useState(session.available_permissions);
  const [msg, setMsg] = useState(null);
  const [userActionMsg, setUserActionMsg] = useState(null);
  const [isSavingDetails, setIsSavingDetails] = useState();
  const user = useSelector(selectUser);
  const userPermissions = user?.role?.permissions ? user?.role?.permissions?.map(p => p.name) : [];
  const canManageUsers = userPermissions.includes(USER_MANAGEMENT);
  const canInviteEmployees = userPermissions.includes(INVITE_EMPLOYEE);

  const [rolePermissionsForm, setRolePermissionsForm] = useState({
    name: '',
    permissions: allPermissions.map(p => { return { id: p.id, value: false } }),
  });

  const applyFilters = (users, filter) => {
    return users.filter(user => !filter.searchTerm || (`${user.first_name} ${user.last_name}`).toLowerCase().includes(filter.searchTerm.toLowerCase()));
  }

  const filteredUsers = applyFilters(_.orderBy(usersList, 'name', 'asc'), filters);


  useEffect(() => {
    refreshUser().then(res => {
      dispatch(refreshSession());
    })
  }, [])

  const handleCreateRoleModalClose = (e) => {
    setCreateRoleModal(false);
    setMsg(null);
    setUserActionMsg(null);
    setRolePermissionsForm({
      name: '',
      permissions: allPermissions.map(p => { return { id: p.id, value: false } }),
    });
  }

  const handleCreateRoleSubmit = (e) => {
    e.preventDefault();
    setMsg(null);
    setUserActionMsg(null);
    setIsSavingDetails(true);

    createRole(
      organisation.id,
      rolePermissionsForm.name,
      rolePermissionsForm.permissions).then(res => {
        dispatch(refreshSession());
        setMsg({});
        setIsSavingDetails(false);
        setCreateRoleModal(false);
        window.location.reload();
      }).catch(err => {
        setIsSavingDetails(false);
        const errors = err?.response?.data;
        const keys = Object.keys(errors || {});
        if (!errors || !keys.length || typeof errors === 'string') {
          setMsg({
            type: "danger",
            body: "Could not save data, please try again.",
          });
        } else {
          let tmp = [];
          keys.forEach((errorKey) => {
            tmp.push(errors[errorKey])
          });
          setMsg({
            type: "danger",
            body: tmp,
          });
        }
      });
  }

  const userDataColumns = [
    {
      dataField: "first_name",
      text: "User Name",
      formatter: (cell, row) => {
        return (
          <div class="d-flex justify-content-between align-items-baseline">
            <a
              href="javascript:void(0)"
              className={styles.spaceLink}
              onClick={(e) =>
                history.push("/organisation/settings/" + row.id)
              }
            >{row?.first_name + " " + row?.last_name}</a>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      formatter: (cell, row) => {
        return row?.email;
      },
    },
    {
      dataField: "jobtitle",
      text: "Job Title",
      formatter: (cell, row) => {
        return row?.jobtitle;
      },
    },
    {
      dataField: "role",
      text: "Role",
      formatter: (cell, row) => {
        return row?.role?.name;
      },
    },
    {
      dataField: "created_at",
      text: "Account Created",
      formatter: (cell, row) => {
        return moment(row?.created_at).format('YYYY-MM-DD');
      },
    },
    {
      dataField: "first_name",
      text: "Account Status",
      formatter: (cell, row) => {
        return (
          <div class="d-flex justify-content-between align-items-baseline">
            {
              row?.is_active && (
                <Badge
                  className={[
                    styles.statusBadge,
                    styles.statusBadgeActive,
                  ].join(" ")}
                  title={"Active"}
                >
                  {row?.is_admin && <FontAwesomeIcon
                    icon={faUserShield}
                    size="1x"
                    style={{ marginRight: "4px" }}
                  />}
                  {!row?.is_admin && <FontAwesomeIcon
                    icon={faUser}
                    size="1x"
                    style={{ marginRight: "6px", marginLeft: "2px" }}
                  />}
                  Active
                </Badge>
              )
            }

            {
              !row?.is_active && (
                <Badge
                  className={[
                    styles.statusBadge,
                    styles.statusBadgeInactive,
                  ].join(" ")}
                  title={"Inactive"}
                >
                  {row?.is_admin && <FontAwesomeIcon
                    icon={faUserShield}
                    size="1x"
                    style={{ marginRight: "4px" }}
                  />}
                  {!row?.is_admin && <FontAwesomeIcon
                    icon={faUser}
                    size="1x"
                    style={{ marginRight: "6px", marginLeft: "2px" }}
                  />}
                  Inactive
                </Badge>
              )
            }
          </div>
        );
      },
    },
  ];

  const roleDataColumns = [
    {
      dataField: "rowNo",
      text: "#",
      formatter: (cell, row, rowIndex, extraData) => (
        <div>
          <span>{rowIndex + 1}</span>
        </div>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => {
        return (
          <div class="d-flex justify-content-between align-items-baseline">
            {canManageUsers && <a
              href="javascript:void(0)"
              className={styles.spaceLink}
              onClick={(e) =>
                history.push("/organisation/role/" + row.id)
              }
            >{row?.name}</a>}
            {!canManageUsers && <span>{row?.name}</span>}
          </div>
        );
      },
    },
    {
      dataField: "permissions",
      text: "Permissions",
      formatter: (cell, row) => {
        return (
          <>
            <div>
              <span>{row?.permissions.map(p => p.name).map(v => {
                return <><div class="badge badge-pill badge-secondary">{v}</div>&nbsp;</>
              })}</span>
            </div></>);
      },
    },
  ];

  const noDataForTable = () => {
    return (
      <div className={styles.webSpaceNoDataForFilter}>
        There is no data available
      </div>
    );
  };

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };

    return (
      <li className="page-item" style={{ float: "right" }}>
        <a
          href="#"
          onClick={handleClick}
          style={{ margin: "2px" }}
          className={
            "btn " + (active ? "btn-secondary" : "btn-outline-secondary")
          }
        >
          {page}
        </a>
      </li>
    );
  };

  const options = {
    pageButtonRenderer,
  };

  return (
    <>
      <Container className="p-0">
        <Form>
          <Row className="mb-3">
            <Col>
              <div className="rounded py-3 d-flex">
                <StyledTitle className="d-inline-block">
                  <h3 className="font-weight-bold">Team Members</h3>
                  <hr />
                </StyledTitle>
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div className="d-flex align-items-center justify-content-end py-2">
              <Form.Group className={styles.search_bar}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={filters.searchTerm}
                    style={session?.theme?.components?.form_control}
                    onChange={e => {
                      handleFilterChange({ searchTerm: e.target.value });
                    }}
                    placeholder="Search Users"
                  />
                  <InputGroup.Text className={styles.search_icon}>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {canInviteEmployees && <StyledButton
                className="float-right"
                onClick={() => history.push('/users/invite')}
              >
                Add New User
              </StyledButton>}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <div>
                <BootstrapTable
                  keyField="rowNo"
                  headerWrapperClasses={styles.styled_table_header}
                  data={filteredUsers}
                  columns={userDataColumns}
                  pagination={paginationFactory(options)}
                  noDataIndication={noDataForTable}
                />
              </div>
            </Col>
          </Row>
          <Row>
              <Col>
                <div className="rounded p-3 d-flex">
                  <StyledDiv
                    className="d-inline-block"
                  >
                    <h3>Available Roles</h3>
                  </StyledDiv>
                </div></Col>
            </Row>
            <Row>
              <Col>
                <form class="form-inline float-right" onSubmit={(e) => e.preventDefault()}>
                  {canManageUsers && <Button variant="outline-secondary" className={"mb-2"} key={"down"}
                    id={`dropdown-button-drop-right`}
                    onClick={(e) => setCreateRoleModal(true)}>Create new role</Button>}
                </form>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <div>
                  <BootstrapTable
                    keyField="rowNo"
                    headerWrapperClasses={""}
                    data={rolesList}
                    columns={roleDataColumns}
                    pagination={paginationFactory(options)}
                    noDataIndication={noDataForTable}
                  />
                </div>
              </Col>
            </Row>
        </Form>
        <Modal
            show={createRoleModal}
            onHide={handleCreateRoleModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div class="text-secondary">Create new role</div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={(e) => handleCreateRoleSubmit(e)}>
                <Row className="mb-2">
                  <Col lg="6">
                    <Form.Group>
                      <Form.Label>Name:</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          value={rolePermissionsForm?.name}
                          style={session?.theme?.components?.form_control}
                          onChange={e => setRolePermissionsForm({ ...rolePermissionsForm, name: e.target.value })}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PermissionsTable data={allPermissions} value={rolePermissionsForm.permissions} title="Permissions" onChange={d => {
                      let p = rolePermissionsForm.permissions;
                      p = p.filter(p => p.id !== d.id)
                      p = p.concat(d);
                      setRolePermissionsForm({ ...rolePermissionsForm, ...{ permissions: p } })
                    }} />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Button
                      variant="primary"
                      className="mr-auto px-3"
                      type="submit"
                      size="md"
                      disabled={isSavingDetails}
                    >
                      <span className="small d-flex">
                        {isSavingDetails && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                          />
                        )}
                        <span>Save</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {msg && !Array.isArray(msg.body) && (
                      <Alert className="mt-3" variant={msg.type}>
                        {msg.body}
                      </Alert>
                    )}
                    {msg && Array.isArray(msg.body) && (
                      <Alert className="mt-3" variant={msg.type}>
                        {msg.body.map((key, i) =>
                          <li>{msg.body[i]}</li>
                        )}
                      </Alert>
                    )}
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
      </Container>
    </>
  );
};

export default OrganisationUserManagement;