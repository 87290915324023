import {
    faArrowDown, faArrowUp
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components'

const CollapseContainer = styled.div`
    display: ${props => props.display ? 'block' : 'none'};
    background-color: #f8f9fa;
`

const ToggleContainer = styled.div`
    background-color: #f8f9fa;
    cursor: pointer;
    display: ${props => props.display ? 'inline' : 'none'};
`

const CollapseableTooltipBox = ({ label, children }) => {
    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return <div className="mb-4">
        <CollapseContainer
            className="px-3 py-1 rounded-bottom"
            display={open}
        >
            {children}
            <ToggleContainer
                className="small float-right px-2 mr-2 rounded-bottom"
                onClick={handleClose}
                display
            >
                <span className="mr-2">{label}</span>
                <FontAwesomeIcon
                    icon={faArrowUp}
                    size="xs" />
            </ToggleContainer>
        </CollapseContainer>
        <ToggleContainer
            className="small float-right px-2 mr-2 rounded-bottom"
            onClick={handleOpen}
            display={!open}
        >
            <span className="mr-2">{label}</span>
            <FontAwesomeIcon
                icon={faArrowDown}
                size="xs" />
        </ToggleContainer>
    </div>
}

export default CollapseableTooltipBox
