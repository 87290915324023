import { faGlobeAsia } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectSession } from '../../../session/sessionSlice'
import styles from './facebook_boosted.module.scss'
import { selectOwnerOrganisation } from '../../orderFormSlice';
import { faBoltLightning } from '@fortawesome/pro-regular-svg-icons'

const ImgPreview = ({ src }) => (
    <img src={src} alt="" className={styles.ad_media} />
)

const VideoPreview = ({ src }) => (
    <video width="100%" controls>
        <source src={src} />
    </video>
)

const FacebookBoosted = ({
    headline,
    bodycopy,
    link,
    linkdesc,
    assetType,
    src,
    cta,
    isPreviewMode = true
}) => {
    const session = useSelector(selectSession)
    const isValidHttpUrl = (string) => {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return true;
    }

    let media = ''
    if (assetType === 'image') {
        media = <ImgPreview src={src} />
    } else if (assetType === 'video') {
        media = <VideoPreview src={src} />
    }

    const callToAction = session.ad_types.find(adType => adType.control_id === 'facebook_display')?.fields?.find(
        f => f.id === 'cta'
    )?.options[cta]
    const fallback_logo = "/ad_preview/logo.png";
    const ownerOrganisation = useSelector(selectOwnerOrganisation);
    return (
        <>
            <div className={styles.ad_boosted}>
                <FontAwesomeIcon icon={faBoltLightning} /> Boosted Post
            </div>
            {!isPreviewMode && <div>{media}</div>}
            {isPreviewMode && <div id="ad_fb" className={`${styles.facebook_preview} mx-auto`}>
                <div style={{ color: "#808080", fontSize: "10px", textAlign: "center", width: "100%" }}>Not actual display of the ad on the device</div>
                <div className={styles.ad_header}>
                    <img src={(ownerOrganisation?.settings?.logo?.url || ownerOrganisation?.logo) ?? fallback_logo} alt="" className={styles.ad_logo} />
                    <div className={styles.ad_titles}>
                        <div className={styles.ad_title_one}>
                            <a href="#">{ownerOrganisation.name}</a>
                        </div>
                        <div className={styles.ad_title_two}>
                            <a href="#">Sponsored</a> ·{' '}
                            <FontAwesomeIcon icon={faGlobeAsia} />
                        </div>
                    </div>
                </div>
                <div id="ad_body" className={styles.ad_body}>
                    {bodycopy}
                </div>
                <div className={styles.ad_base}>
                    <div id="ad_media" className={(isPreviewMode) ? styles.ad_media_preview : styles.ad_media}>
                        {media}
                    </div>
                    <div className={styles.ad_footer}>
                        <div>
                            <div id="ad_link" className={styles.ad_link}>
                                {isValidHttpUrl(link) ? new URL(link).protocol + "//" + new URL(link).hostname : link}
                            </div>
                            <div id="ad_headline" className={styles.ad_headline}>
                                {headline}
                            </div>
                            <div id="ad_link_title" className={styles.ad_link_title}>
                                {linkdesc}
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    src="/ad_preview/reactions.png"
                    style={{ width: "100%", display: "block", marginLeft: "auto", marginRight: "auto" }}
                    alt=""
                    className={styles.ad_reactions}
                />
            </div>}
        </>
    )
}

FacebookBoosted.propTypes = {
    headline: PropTypes.string.isRequired,
    bodycopy: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    linkdesc: PropTypes.string.isRequired,
    assetType: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
}

export default FacebookBoosted
