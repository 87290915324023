import {faAd, faIndustry, faReceipt, faTachometerAlt, faUser, faWallet} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import React, {useCallback, useState} from "react";
import {Nav, Navbar} from "react-bootstrap";
import {useSelector} from "react-redux";
import styled from "styled-components";
import OrganisationSelectionModal from "../../features/order_form/OrganistionSelectionModal";
import {selectSession, selectUser} from "../../features/session/sessionSlice";
import {CREATE_ORDERS, INVITE_EMPLOYEE, INVITE_SUPPLIERS} from "../../lib/permissions";
import {SidebarAddButton} from "./SidebarComponents/SidebarAddButton";
import styles from "./sidebar.module.scss";
import {SidebarLink} from "./SidebarComponents/SidebarLink";
import {faBoxesStacked} from "@fortawesome/pro-regular-svg-icons";

const StyledNavBar = styled(Navbar)`
  & li:last-child {
    margin-bottom: auto !important;
  }
`;

const Sidebar = ({visible = false}) => {
    const session = useSelector(selectSession);
    const user = useSelector(selectUser);
    const userPermissions = user?.role?.permissions?.map(p => p.name);
    const hasWallets = user?.active_organisation?.has_wallets;
    const isRetailer = user.active_organisation?.is_retailer;
    const hasInventoryManagementEnabled = session?.inventory_management_enabled;

    const [showOrganisationsModal, setOrganisationsModal] = useState(false);

    const openOrganisationModal = useCallback((e) => {
        e.preventDefault();
        setOrganisationsModal(true);
    }, []);

    let actions = [];

    if (userPermissions.includes(CREATE_ORDERS)) {
        actions.push({icon: faAd, label: "Order", onClick: openOrganisationModal});
    }

    if (isRetailer && userPermissions.includes(INVITE_SUPPLIERS)) {
        actions.push({
            icon: faIndustry, label: "Supplier", to: "/organisations/invite",
        });
    }

    if (userPermissions.includes(INVITE_EMPLOYEE)) {
        actions.push({icon: faUser, label: "Employee", to: "/users/invite"});
    }

    if (!visible) {
        return <div></div>;
    }

    return (<>
        <StyledNavBar
            className={`align-items-center flex-column text-center ${styles.sidebar_nav}`}
            role="navigation"
            expand="lg"
            style={session.theme?.components?.sidebar}
        >
            <Nav as="ul" className="h-100 d-flex flex-column align-items-center justify-content-between">
                {actions.length && <SidebarAddButton actions={actions}/>}
                <SidebarLink to="/" icon={faTachometerAlt} label="Home"/>
                {hasWallets && <SidebarLink to="/wallets" icon={faWallet} label="Wallets"/>}
                <SidebarLink to="/orders" icon={faReceipt} label="Orders"/>
                {isRetailer && hasInventoryManagementEnabled &&
                    <SidebarLink to="/inventory/home" icon={faBoxesStacked} label="Inventory"/>}
            </Nav>
        </StyledNavBar>

        <OrganisationSelectionModal
            show={showOrganisationsModal}
            onHide={() => setOrganisationsModal(false)}
        />
    </>);
};

Sidebar.propTypes = {
    visible: PropTypes.bool, // onToggle: PropTypes.func,
};

export default Sidebar;
