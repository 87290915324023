import axios from '../../axios'
import FormData from 'form-data'
import {filterKeys} from "../util/objects";

export async function postAsset(file, { onUploadProgress } = { onUploadProgress: () => { } }) {
    let form = new FormData()

    form.append('mediafile', file)

    return axios.post(`/orders/assets`, form, {
        onUploadProgress: onUploadProgress,
        timeout: 0,
    })
}

export async function postFacebookAsset(file, { onUploadProgress, placements, automatic, ratio } = { onUploadProgress: () => { } }) {
    let params = new URLSearchParams()
    let form = new FormData()

    if (automatic || automatic === undefined) {
        form.append('automatic', 1)
    }

    // Need to put placements in form since it can return a 414 error.
    if (placements !== undefined) {
        form.append('placements', JSON.stringify(placements))
    }

    if (ratio !== undefined) {
        form.append('ratio', ratio)
    }

    form.append('mediafile', file)

    return axios.post(`/orders/assets/facebook?${params.toString()}`, form, {
        onUploadProgress: onUploadProgress,
        timeout: 0,
    })
}

export async function deleteAsset(fid) {
    return axios.delete(`/orders/assets/${fid}`);
}

const facebookPreviewKeys = [
    'link',
    'body',
    'headline',
    'linkdesc',
    'cta',
    'media',
    'asset_ratios',
    'created_at',
];

export async function adPreview(owner, ad, placement, {cancelSignal}) {
    let previewFields = filterKeys(ad, facebookPreviewKeys);

    let params = new URLSearchParams();
    Object.entries(previewFields).forEach(([key, value]) => params.set(key, encodeURIComponent(value)))

    if (placement) {
        params.set('placement', placement);
    }

    return axios.get(`/orders/preview/${owner}?${params.toString()}`, {
        signal: cancelSignal,
        timeout: 60000,
    });
}
