import { faGlobeAsia } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { selectSession } from "../../../session/sessionSlice";
import styles from "./facebook_cta.module.scss";
import { selectOwnerOrganisation} from '../../orderFormSlice';

const FacebookCTACarousel = ({
  bodycopy,
  cta,
  assets,
  isPreviewMode = true,
}) => {
  const session = useSelector(selectSession);
  const callToAction = session.ad_types
    .find((adType) => adType.control_id === "facebook_online_conversion_dpa")
    ?.fields?.find((f) => f.id === "cta")?.options[cta];
  const fallback_logo = "/ad_preview/logo.png";
  const ownerOrganisation = useSelector(selectOwnerOrganisation);
  return (
    <div id="ad_fb" className={styles.facebook_preview}>
      <div style={{ color: "#808080", fontSize: "10px", textAlign: "center", width: "100%" }}>Not actual display of the ad on the device</div>
      <div className={styles.ad_header}>
      <img src={(ownerOrganisation?.settings?.logo?.url || ownerOrganisation?.logo) ?? fallback_logo} alt="" className={styles.ad_logo} />
        <div className={styles.ad_titles}>
          <div className={styles.ad_title_one}>
            <a href="#">{ownerOrganisation.name}</a>
          </div>
          <div className={styles.ad_title_two}>
            <a href="#">Sponsored</a> ·{" "}
            <FontAwesomeIcon icon={faGlobeAsia} />
          </div>
        </div>
      </div>
      <div id="ad_body" className={styles.ad_body}>
        {bodycopy}
      </div>
      <div>
        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode
          className="w-100"
          afterChange={(previousSlide, { currentSlide, onMove }) => { }}
          containerClass=""
          dotListClass=""
          draggable
          focusOnSelect={true}
          itemClass={styles.ad_item}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {assets.map((media, i) => (
            <div key={media.id} style={{ margin: "5px" }}>
              <Card
                className={("card", "d-flex", "text-left", styles.ad_base)}
                style={{
                  padding: "0px",
                  height: "400px",
                  maxHeight: "400px",
                  marginBottom: "15px",
                }}
                key={i}
              >
                <div
                  className="card-block text-center"
                  style={{ width: "100%" }}
                >
                  <Card.Img
                    variant="top"
                    style={{
                      maxHeight: "350px",
                      padding: "0",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    className={isPreviewMode ? styles.ad_media_preview : ""}
                    src={media.image_url}
                  />
                </div>
                <Card.Body style={{ padding: "0px", margin: "5px" }}>
                  <div style={{ fontSize: "12px" }}>
                    <div
                      id="ad_link_title"
                      className={("md-6", styles.ad_link_title)}
                    >
                      <strong>
                        {media.name && media.name.length > 24
                          ? media.name.substring(0, 24 - 3) + "..."
                          : media.name}
                      </strong>
                      <div>
                        {media.description && media.description.length > 27
                          ? media.description.substring(0, 27 - 3) + "..."
                          : media.name}
                      </div>
                    </div>
                    <div className={("md-6", styles.ad_cta_container)}>
                      {cta !== "NO_BUTTON" && (
                        <span id="ad_link_cta" className={styles.ad_link_cta}>
                          {callToAction}
                        </span>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
      <img
        src="/ad_preview/reactions.png"
        style={{ width: "50%", display: "block", marginLeft: "auto", marginRight: "auto" }}
        alt=""
        className={styles.ad_reactions}
      />
    </div>
  );
};

FacebookCTACarousel.propTypes = {
  bodycopy: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
};

export default FacebookCTACarousel;