import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const WalletStyledSpinner = styled(Spinner)`
  margin-left: 12px;
  width: 20px;
  height: 20px;
`;

export default WalletStyledSpinner;