import {faDollarSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// @ts-ignore
import React, {useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import Errors from "../Errors";

// TODO: Might be better to use string { formatNumber } function. Not sure if they are compatible.
const formatNumberString: (string) => string = budget => budget.replace(/[^\d.]|\.(?=.*\.)/g, '')

type MoneyProps = {
    controlId: string,
    label: JSX.Element | string,
    readOnly: boolean,
    required: boolean,
    value?: number,
    placeholder: string,
    onChange: (arg0?: number) => void,
    errors: string[],
};

type BudgetState = [string, React.Dispatch<string>];

type MoneyLabelProps = {
    label: string,
};

const MoneyLabel = ({label}: MoneyLabelProps) => <div className="d-flex">
    <Form.Label className="font-weight-bold">
        {label}
    </Form.Label>
</div>;

const createLabel = (label: JSX.Element | string) => () => {
    if (typeof label === 'string') {
        return <MoneyLabel label={label}/>;
    }

    return label;
}

const Money = ({
                   controlId,
                   readOnly = false,
                   required = true,
                   label = "Budget",
                   value,
                   placeholder = "",
                   onChange = () => {
                   },
                   errors = [],
               }: MoneyProps) => {
    const [budget, setBudget]: BudgetState = useState(value ? value.toString() : '');

    const handleBudgetChange = (e) => {
        let val = formatNumberString(e.target.value);
        if (val === "") {
            onChange(null);
            setBudget("")
            return;
        }

        let num = Number(val);
        if (!isNaN(num)) {
            onChange(num);
            setBudget(num.toLocaleString('en', {maximumFractionDigits: 2}))
        }
    };

    const Label = createLabel(label);

    return (<Form.Group controlId={controlId}>
        <Label/>
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faDollarSign}/>
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                type="text"
                className="rounded-right"
                maxLength={9}
                min={0}
                required={required}
                readOnly={readOnly}
                value={budget}
                placeholder={placeholder}
                isInvalid={errors.length > 0}
                onBlur={handleBudgetChange}
                onChange={e => setBudget(formatNumberString(e.target.value))}
            />
        </InputGroup>
        <Form.Control.Feedback type="invalid" className={`${errors.length > 0 && 'd-block'}`}>
            <Errors errors={errors}/>
        </Form.Control.Feedback>
    </Form.Group>);
};

export default Money;
