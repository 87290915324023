import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import RegisterForm from '../features/session/RegisterForm'
import { getBrandingAssets, registerUserOrganisation } from '../lib/api'
import Footer from './layout/Footer'
import styles from './login.module.scss'
import { registrationSuccess } from '../features/session/sessionSlice'

const Register = () => {
  let history = useHistory()
  let location = useLocation()
  let params = new URLSearchParams(location.search)
  const formRef = useRef(null)
  let names = params.get('name') ?? '' 
  names = names.split(' ', 2)
  const first_name = names[0];
  const last_name = names[1] ?? '';

  const [form, setForm] = useState({
    token: params.get('token') ?? '',
    organisation: params.get('organisation') ?? '',
    first_name: params.get('first_name') ?? '',
    last_name: params.get('last_name') ?? '',
    jobtitle: params.get('jobtitle') ?? '',
    email: params.get('email') ?? '',
    password: '',
    password_confirmation: '',
  })

  const [errors, setErrors] = useState({})
  const [theme, setTheme] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.has('id')) {
      getBrandingAssets(params.get('id')).then(resp => {
        if (resp?.data?.data) {
          setTheme(resp.data.data)
        }
      })
    }
  }, [])

  const status = useSelector(state => state.session.status)
  const registerState = useSelector(state => state.session.registration)
  if (status === 'authenticated') {
    return <Redirect to="/" />
  }
  if(registerState?.outcome === 'success'){
    return <Redirect to="/" />
  }

  return (
    <>
      <Helmet>
        <title>
          Register - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <main className="py-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card>
                <Card.Body>
                  <div className={styles.header}>
                    {theme?.logo?.url && <img src={theme.logo.url} alt="" />}
                    <h4 className="text-center my-4">Register</h4>
                  </div>
                  <RegisterForm
                    ref={formRef}
                    form={form}
                    onChange={newForm => setForm(newForm)}
                    errors={errors}
                    onSubmit={e => {
                      e.preventDefault()
                      // Submit registration
                      registerUserOrganisation(
                        form.first_name,
                        form.last_name,
                        form.jobtitle,
                        form.email,
                        form.password,
                        form.password_confirmation,
                        form.token,
                        form.organisation
                      )
                        .then(resp => {
                          setErrors({
                            _success: [
                              'Successfully Registered',
                            ],
                          })
                          dispatch(registrationSuccess())
                          const form = formRef.current
                            form.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'nearest',
                            }) 
                        })
                        .catch(err => {
                          if (err.response?.status === 403) {
                            setErrors({
                              _error: [
                                'Invalild registration code: please request a new invite.',
                              ],
                            })
                            const form = formRef.current
                            form.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                              inline: 'nearest',
                            })
                          } else if (err.response) {
                            let respError = err.response.data
                            setErrors(respError)

                            const form = formRef.current
                            for (let i = 0; i < form.length; i++) {
                              if (respError[form[i].id]?.length > 0) {
                                form[i].scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'center',
                                  inline: 'nearest',
                                })
                                break
                              }
                            }
                          }
                        })
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default Register
