import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Form, Nav, Row, Spinner, Tab} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Controller, FormProvider, useForm} from "react-hook-form";
import Chrome from "../app/layout/Chrome";
import {
  clearSession,
  refreshFacebookData,
  refreshGoogleData,
  refreshSession,
  selectAdTypes,
  selectSession,
  selectUser,
} from "../features/session/sessionSlice";
import {updateOrganisationSettings} from "../lib/api";
import OrganisationList from "../features/organisation_settings/components/List";
import {DevTool} from "@hookform/devtools";
import AdSettings from "../features/organisation_settings/components/AdSettings";
import MetricPermissions from "../features/organisation_settings/components/MetricPermissions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import DefaultFacebookCustomAudiences
  from "../features/organisation_settings/containers/DefaultFacebookCustomAudiences";
import DefaultFacebookLookalikeAudiences
  from "../features/organisation_settings/containers/DefaultFacebookLookalikeAudiences";
import DefaultFacebookSavedAudiences from "../features/organisation_settings/containers/DefaultFacebookSavedAudiences";
import DefaultGoogleRemarketingAudiences
  from "../features/organisation_settings/containers/DefaultGoogleRemarketingAudiences";
import Breadcrumbs from "./components/Breadcrumbs";
import styles from './settings.module.scss';
import {reloadAllOrders} from "../features/orders/ordersSlice";
import styled from "styled-components";
import {CHANNELS_MANAGEMENT_FACEBOOK, CHANNELS_MANAGEMENT_GOOGLE, MANAGE_SUPPLIER_SETTINGS} from "../lib/permissions";
import {Throbber} from "../features/common/Throbber";
import {cachedFetchBrands, selectAllBrands, selectStatus} from "../features/brands/brandsSlice";

const breadcrumbItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/settings/suppliers",
    title: "Supplier Settings",
    active: true,
  },
];

const StyledSync = styled(Button)`
  padding: 0;
  font-size: 12px;
  height: 36px;
  width: 100px;
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection{
    background-color: #7155FF !important;
    border-color: #7155FF !important;
  }
`;

const StyledSave = styled(Button)`
  & ,
  &:hover,
  &:focus,
  &:active,
  &::selection{
    border-color: #644BF8 !important;
    background-color: #644BF8 !important;
  }
`;

export const METRICS_FORM_KEYS = {

  METRICS_CLICKS: 'clicks',
  METRICS_LINK_CLICKS: 'inline_link_clicks',
  METRICS_VIEW_RATE: 'view_rate',
  METRICS_VIEWS: 'views',
  METRICS_IMPRESSIONS: 'impressions',
  METRICS_REACH: 'reach',
  METRICS_ONLINE_SALES: 'online_sales',
  METRICS_OFFLINE_SALES: 'offline_sales',

  METRICS_CPC: 'cpc',
  METRICS_CPC_LINK: 'cost_per_inline_link_click',
  METRICS_CPM: 'cpm',
  METRICS_CPV: 'cpv',
  METRICS_CTR: 'ctr',
  METRICS_SPEND: 'spend',
  METRICS_LIFETIME_BUDGET: 'lifetime_budget',
  METRICS_DAILY_BUDGET: 'daily_budget',
  METRICS_TOTAL_ROAS: 'total_roas',
  METRICS_TOTAL_CONVERSION_VALUE: 'total_conversion_value',

  METRICS_WEBADS_DIRECT_CONVERSIONS: 'direct_conversions_value',
  METRICS_WEBADS_ASSOCIATED_CONVERSIONS: 'associated_conversions_value',
  METRICS_WEBADS_TOTAL_CONVERSIONS: 'webads_total_conversions_value',
}

const PERMISSIONS_FORM_KEYS = {
  PERMISSIONS_BID_AMOUNT: 'bid_amount',
  PERMISSIONS_BUDGET: 'budget',
  PERMISSIONS_SKEW_METRICS: 'skew_metrics',
  PERMISSIONS_SKEW_METRICS_RETAILER: 'skew_metrics_retailer',
  PERMISSION_REQUIRE_ORDER_WALLET: "require_order_wallet",
}

const SuppliersGeneralSettings = () => {
  let history = useHistory();
  const session = useSelector(selectSession);
  const suppliers = useSelector(selectAllBrands);
  const hasSuppliers = suppliers.length > 0;
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState(session.user.active_organisation);
  const permissions = organisation?.permissions;
  // const settings = organisation?.settings;
  const adSettings = organisation?.settings?.ad_settings;
  const [msg, setMsg] = useState(null);
  const adTypes = useSelector(selectAdTypes);
  const [isRefreshingFacebookData, setIsRefreshingFacebookData] = useState(false);
  const [isRefreshingGoogleData, setIsRefreshingGoogleData] = useState(false);
  const [isSaving, setIsSaving] = useState();
  const isLoading = useSelector(state => selectStatus(state) === 'loading');

  const user = useSelector(selectUser);
  const userPermissions = user?.role?.permissions?.map(p => p.name);

  const methods = useForm({
    mode: 'onSubmit',
  });

  const {
    setError,
    getValues,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    register,
    trigger,
    formState,
  } = methods;

  const { touchedFields, isSubmitted, errors } = formState;

  const updateAllSettings = (key) => {
    const currentValues = getValues();
    let selectedKey = null;
    if (key === 'all_margin') {
      selectedKey = 'margin';
    } else if (key === 'all_min_budget') {
      selectedKey = 'min_budget';
    } else {
      return;
    }

    adTypes.forEach(adType => {
      if (currentValues?.[adType?.control_id]) {
        setValue(`${adType.control_id}.${selectedKey}`, currentValues[key], {
          shouldValidate: true,
        });
      }
    });
    setValue(key, '');
  }

  const onSubmit = async data => {

    setIsSaving(true);
    setMsg(null);

    const adSettings = {
      margin: {},
      min_budget: {},
      enabled: {},
      facebook_custom_audiences: data.facebook_custom_audiences || [],
      facebook_lookalike_audiences: data.facebook_lookalike_audiences || [],
      facebook_saved_audiences: data.facebook_saved_audiences || [],
      google_remarketing_audiences: data.google_remarketing_audiences || [],
    };

    adTypes.forEach(adType => {
      if (data?.[adType?.control_id]) {
        if(adType.platform !== 'web'){
          adSettings.margin[adType.control_id] = parseFloat(data[adType.control_id].margin);
          if (data[adType.control_id].min_budget) {
            adSettings.min_budget[adType.control_id] = parseFloat(data[adType.control_id].min_budget);
          }
        }
        adSettings.enabled[adType.control_id] = data[adType.control_id].enabled;
      }
    });

    const updatedPermissions = {
      metrics: {
        insights: {},
      },
    };
    Object.values(METRICS_FORM_KEYS).forEach(key => {
      updatedPermissions.metrics.insights[key] = data?.[key];
    })
    Object.values(PERMISSIONS_FORM_KEYS).forEach(key => {
      updatedPermissions.metrics[key] = data?.[key];
    });

    const settings = { ...(organisation.settings || {}), ad_settings: adSettings, platform_webad_tracking: data.platform_webad_tracking };
    const permissions = { ...(organisation.permissions || {}), ...updatedPermissions };

    try {
      const res = await updateOrganisationSettings(organisation.id, {
        settings,
        permissions: updatedPermissions,
      });
      setOrganisation(res.data.data);
      dispatch(refreshSession());
      dispatch(reloadAllOrders());
      setMsg({
        type: 'success',
        body: 'Settings have been saved successfully.',
      });
    } catch (e) {
      if (e.response.status === 403) {
        dispatch(clearSession());
        history.push("/login");
      } else {
        const errors = e?.response?.data?.errors;
        const keys = Object.keys(errors || {});
        if (errors || keys.length) {
          setMsg({
            type: "danger",
            body: "Could not save the settings, please try again.",
          });
          keys.forEach((errorKey) => {
            const key = errorKey.replace('settings.ad_settings.', '');
            const splitKey = key.split('.');
            if (splitKey.length === 2) {
              setError(`${splitKey[1]}.${splitKey[0]}`, {
                type: "manual",
                message: errors[`settings.ad_settings.${key}`][0],
              });
            }
          });
        }
      }

    } finally {
      setIsSaving(false);
    }
  };

  const handleFacebookRefreshData = async () => {
    setIsRefreshingFacebookData(true);
    try {
      await dispatch(refreshFacebookData());
      setIsRefreshingFacebookData(false);
    } catch (e) {
      setIsRefreshingFacebookData(false);
    }
  }

  const handleGoogleRefreshData = async () => {
    setIsRefreshingGoogleData(true);
    try {
      await dispatch(refreshGoogleData());
      setIsRefreshingGoogleData(false);
    } catch (e) {
      setIsRefreshingGoogleData(false);
    }
  }

  useEffect(() => {
    register('facebook_custom_audiences')
    register('facebook_lookalike_audiences')
    register('facebook_saved_audiences')
    register('google_remarketing_audiences')

    dispatch(cachedFetchBrands());
  }, [])

  // Tab Stuff
  const [tabKey, setTabKey] = useState('global-settings');

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === "true" && (
        <DevTool control={control} />
      )}
      <Helmet>
        <title>
          Organisation Settings for {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className="pt-4">
          <FormProvider {...methods}>
            <Breadcrumbs items={breadcrumbItems} />
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Col>
                  <div className="rounded pb-3 d-flex">

                    <div className="d-inline-block w-100">
                      <h3>Supplier Settings</h3>
                    </div>
                  </div>
                </Col>
              </Row>

              {msg && <Alert className="mt-3" variant={msg.type}>{msg.body}</Alert>}

              <Tab.Container id="left-tabs-example" activeKey={tabKey} onSelect={key => setTabKey(key)}>
                <Row>
                  <Col md={12} lg={6}>
                    <Nav variant="pills" className="flex-row">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="global-settings"
                          className={`${tabKey==="global-settings"?styles.navpills_active:styles.navpills} ${styles.left}`}>
                            Global Settings
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="supplier-settings"
                          className={`${tabKey==="supplier-settings"?styles.navpills_active:styles.navpills} ${styles.right}`}>
                          My Suppliers
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>

                  {tabKey === "global-settings" && <Col md={12} lg={6}>
                    <StyledSave
                      variant="primary"
                      className="mr-auto px-5 py-2 float-right"
                      type="submit"
                      size="md"
                      disabled={isSaving}>
                      <span className="small d-flex">
                        {isSaving &&
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                          />}<span>Save Changes</span>
                      </span>
                    </StyledSave>
                  </Col>}

                  <Col md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="global-settings" className={styles.tab_panel}>

                        <Row className="mb-3">
                          <div className="rounded pl-3 d-flex">
                            To change settings for a specific supplier, click on the edit button next to the supplier’s name under Manage Supplier below.
                          </div>
                        </Row>

                        <Row className="mb-4">
                          <Col lg="12" xs="12">

                            <MetricPermissions isGeneralSettings={true} permissionsData={permissions} />

                          </Col>
                        </Row>

                        <Row>
                          {userPermissions.includes(CHANNELS_MANAGEMENT_FACEBOOK) && <Col>
                            <div className="rounded">
                              <div className="d-flex align-items-center mb-2">
                                <h5 className="flex-grow-1 mb-0">Facebook Permissions</h5>
                                <StyledSync onClick={handleFacebookRefreshData}>
                                  <FontAwesomeIcon
                                    className={isRefreshingFacebookData ? 'fa-spin' : ''}
                                    icon={faSync} size="1x"
                                  /> Sync
                                </StyledSync>

                              </div>
                              <Controller
                                name="facebook_custom_audiences"
                                control={control}
                                defaultValue={adSettings.facebook_custom_audiences}
                                render={({ field, formState }) => (
                                  <DefaultFacebookCustomAudiences
                                    controlId={`${organisation.id}-default-facebook-custom-audiences`}
                                    value={field.value}
                                    onChange={field.onChange}
                                  />

                                )}
                              />

                              <Controller
                                name="facebook_lookalike_audiences"
                                control={control}
                                defaultValue={adSettings.facebook_lookalike_audiences}
                                render={({ field, formState }) => (
                                  <DefaultFacebookLookalikeAudiences
                                    controlId={`${organisation.id}-default-facebook-lookalike-audiences`}
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />

                              <Controller
                                name="facebook_saved_audiences"
                                control={control}
                                defaultValue={adSettings.facebook_saved_audiences}
                                render={({ field, formState }) => (
                                  <DefaultFacebookSavedAudiences
                                    controlId={`${organisation.id}-default-facebook-saved-audiences`}
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </div>
                          </Col>}
                          {userPermissions.includes(CHANNELS_MANAGEMENT_GOOGLE) && <Col>
                            <div className="rounded">
                              <div className="d-flex align-items-center mb-2">
                                <h5 className="flex-grow-1 mb-0">Google Permissions</h5>
                                <StyledSync onClick={handleGoogleRefreshData}>
                                  <FontAwesomeIcon
                                    className={isRefreshingGoogleData ? 'fa-spin' : ''}
                                    icon={faSync} size="1x"
                                  /> Sync
                                </StyledSync>

                              </div>

                              <Controller
                                name="google_remarketing_audiences"
                                control={control}
                                defaultValue={adSettings.google_remarketing_audiences}
                                render={({ field, formState }) => (
                                  <DefaultGoogleRemarketingAudiences
                                    controlId={`${organisation.id}-default-google-remarketing-audiences`}
                                    value={field.value}
                                    onChange={field.onChange}
                                  />
                                )}
                              />
                            </div>
                          </Col>}
                        </Row>

                        <Row className="mb-2">
                          <Col lg="12" xs="12">
                            <div className="d-sm-flex justify-content-between align-items-center my-3">
                              <h5 className="text-dark mb-0">Ad Types</h5>
                            </div>
                            <hr />

                            <AdSettings adTypes={adTypes} updateAllSettings={updateAllSettings} adSettings={adSettings} />

                          </Col>
                        </Row>

                      </Tab.Pane>
                      <Tab.Pane eventKey="supplier-settings" className={styles.tab_panel}>

                        <Row className="mb-4">
                          <Col lg="12">
                            {isLoading && <Throbber/>}
                            {hasSuppliers && !isLoading ?
                                <>
                                  <div className="mb-3">
                                    <h5 className="text-dark mb-0">View and edit my suppliers.</h5>
                                  </div>
                                  <OrganisationList
                                      organisations={suppliers}
                                      onClick={(organisation) => {
                                        if (userPermissions.includes(MANAGE_SUPPLIER_SETTINGS)) {
                                          history.push(`/settings/suppliers/${organisation.id}`);
                                        }
                                      }}
                                  />
                                </>
                                :
                                <Button
                                    variant="secondary"
                                    onClick={() => history.push('/organisations/invite')}
                                >
                                  Invite supplier
                                </Button>
                            }

                          </Col>
                        </Row>

                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

            </Form>
          </FormProvider>
        </Container>
      </Chrome>
    </>
  );
};

export default SuppliersGeneralSettings;
