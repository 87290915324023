import { faMinus, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Form, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import styled from "styled-components";
import styles from './bespokeFields.module.scss'
import PropTypes from "prop-types";

const Remove = styled(Button)`
    padding: 2px 4px;
    margin: 0;
    line-height: 0;
    position: absolute;
    border-radius: 100%;
    background: red;
    border-color: red;
    animation: grow 0.3s 1;
    z-index: 1;
    &:hover,
    &:focus {
        background: darkred !important;
        border-color: darkred !important;
        color: #fff;
    }
    &:active,
    &::selection {
        background: darkred !important;
        border-color: darkred !important;
    }
    svg {
        font-size: 10px;
    }
    @keyframes grow {
        from { scale: 0;}
        to {scale: 1;}
    }
`

const Plus = styled(Button)`
    border: 1px solid #33363F;
    color: #33363F;
    background: transparent;
    border-radius: 10px;
    display: inline-block;
    padding: 5px 10px;
    transition: 0.3s;
    &:hover {
        background: #33363F;
        color: #fff;
    }
    &:active,
    &::selection {
        background: #6a7083;
    }
`

const ColorField = (props) => {

    const {
        field,
        index,
        updateLayoutData,
        layoutData,
        idHelper,
        errors,
    } = props

    // Populate array with colors from redux state
    const [colorArr, setColorArr] = useState(Object.keys(field.options??[]).map(col => field.options[col])??[]);
    const [colorAmmount, setColorAmmount] = useState(Object.keys(field.options??[]).length??1);

    const updateColourAmmount = (val, keyindex, remove = false, plus = false) => {
        let newColorArr = [...colorArr];
        if (remove) {newColorArr.splice(keyindex, remove)} 
        else if (plus) {newColorArr.splice(keyindex, remove, val)}
        else {
            newColorArr[keyindex] = val;
        }

        let newColorObj = {}
        newColorArr.map((color, index) => 
            newColorObj = {...newColorObj, ['color_'+(index+1)]: color}   
        )
        setColorArr(newColorArr)
        setColorAmmount(newColorArr.length);
        updateLayoutData({ options: newColorObj }, index)
    }

    return (
        <Row>
            <Col md={6}>
                <Form.Group className={styles.group_style} controlId={field.type +index + '-Name'}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={layoutData[index]?.title ?? ""}
                        onChange={(e) => updateLayoutData({ id: idHelper(e.target.value),title: e.target.value}, index)}
                        isInvalid={!layoutData[index]?.title && errors.length > 0}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type +index + '-Default'}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        value={layoutData[index]?.notice ?? ""}
                        onChange={(e) => updateLayoutData({ notice: e.target.value}, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type +index + '-Info'}>
                    <Form.Label>Info</Form.Label>
                    <Form.Control
                        type="text"
                        value={layoutData[index]?.info ?? ""}
                        onChange={(e) => updateLayoutData({ info: e.target.value}, index)}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Color ammount={colorAmmount} colorArr={colorArr} updateColourAmmount={updateColourAmmount} {...props} />
                <div className={styles.addition}>
                    <Plus onClick={() => updateColourAmmount("#000000", colorArr.length+1, false, true )}>
                        <FontAwesomeIcon icon={faPlus} />
                    </Plus>
                </div>
            </Col>
        </Row>
    );
}

const Color = ({
    ammount = 0,
    index,
    field,
    layoutData,
    updateLayoutData,
    colorArr,
    updateColourAmmount,
}) => {
    let inputs = [];
    for (let i = 1; i <= (ammount); i++) {
        inputs.push(
            <Form.Group className={styles.color_style} controlId={field.type +"-"+index + '-color'+i}>
                <Remove onClick={() => updateColourAmmount(null,i-1, true)}>
                    <FontAwesomeIcon icon={faTimes} />
                </Remove>
                <OverlayTrigger
                    trigger="hover"
                    rootClose
                    placement="right"
                    overlay={
                        <Tooltip id={colorArr[i - 1] + "-popup"}>
                            {colorArr[i-1]?? "Select a color"}
                        </Tooltip>
                    }
                >
                    <Form.Control
                        type="color"
                        value={colorArr[i-1]}
                        onChange={(e) => updateColourAmmount(e.target.value, i-1)
                        }
                    />
                </OverlayTrigger>
            </Form.Group>
        )
    }

    return inputs
}

ColorField.propTypes = {
    field: PropTypes.object,
    index: PropTypes.number,
    updateLayoutData: PropTypes.func,
    layoutData: PropTypes.func,
    idHelper: PropTypes.func,
    errors: PropTypes.array,
}

export default ColorField