import React from 'react'
import { Form } from 'react-bootstrap'


const EnableChannel = ({ is_app, is_web, onAppChannelChange = () =>{} , onWebChannelChange = () => {}}) => {
   
    return (
      <article>
        <div className="mt-3">
          <p className="mb-0">
            <b>&nbsp;</b>
          </p>
          <small className="mb-2">&nbsp;</small>
        </div>
        <div className="border rounded border-secondary p-3 m-3">
          <div>
            <small>
              <b>Enabled Channels</b>
            </small>
            <hr />

            <Form.Group>
              <Form.Check
                name="is_app"
                type="checkbox"
                label="App Enabled"
                className="mb-2"
                checked={is_app}
                onChange={(e) => onAppChannelChange(e.target.checked)}
              />
              <Form.Check
                name="is_web"
                type="checkbox"
                label="Web Enabled"
                className="mb-2"
                checked={is_web}
                onChange={(e) => onWebChannelChange(e.target.checked)}
              />
            </Form.Group>
          </div>
        </div>
      </article>
    );
}

EnableChannel.propTypes = {

}

export default EnableChannel
