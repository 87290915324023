import { connect } from 'react-redux'
import FeedView from '../../components/generic/FeedView'
import { selectGoogleProductGroup } from '../../orderFormSlice'

const mapStateToProps = state => ({
    group: selectGoogleProductGroup(state)?.group,
    products: selectGoogleProductGroup(state)?.products,
})


export default connect(mapStateToProps)(FeedView)
