import React from 'react'
import { Subheader, SubheaderWrapper, Subsection } from './AdGrid'


const PreviewSection = ({ children, header }) =>
  <Subsection>
    <SubheaderWrapper>
      <Subheader>Preview</Subheader>
      <span className="ml-auto">
        {header}
      </span>
    </SubheaderWrapper>
    <div className="p-3 h-100">
      {children}
    </div>
  </Subsection>

export default PreviewSection
