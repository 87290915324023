import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateOwnedSpace as updateOwnedSpaceRequest, setActiveStateOfOwnedSpace, createOwnedSpace as createOwnedSpaceRequest } from '../../lib/api/ownedSpaces';
import { refreshSession, selectUser } from '../session/sessionSlice';

const initialState = {
    owned_space: {
        id: '',
        name: '',
        organisation_id: '',
        owned_categories_id: '',
        owned_templates: [],
        owned_urls: [],
        format: 'banners',
        subformat: 'static',
        slots: 1,
        placement: 'embedded',
        placement_script: "{}",
        slot_pricing_mode: 'bid',
        bidmode: 'cpm',
        slot_price: '',
        availability_mode: 'always',
        availability_data: {},
        scroll_behaviour: 'auto',
        allowed_organisations: { type: "whitelist", data: [] },
        is_active: true,
        is_web: false,
        is_app: false
    },
    status: 'idle',
    request: 'none',
    request_errors: {},
    read_only: true,
    errors: {},
};


export const editOwnedSpace = createAsyncThunk('owner_space_form/editOwnedSpace', async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
        const response = await updateOwnedSpaceRequest(selectOwnedSpace(getState()), selectOwnedSpace(getState()).id)
        dispatch(ownedSpaceFormUpdated(response.data.data))
        dispatch(refreshSession())
        return response.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        dispatch(errorsUpdate(err.response.data.errors))
        return rejectWithValue(err.response.data)
    }
})

export const createOwnedSpace = createAsyncThunk('owner_space_form/createOwnedSpace', async (arg, { getState, dispatch, rejectWithValue }) => {
    try {
        const response = await createOwnedSpaceRequest(selectOwnedSpace(getState()), selectOwnedSpace(getState()).id)
        dispatch(ownedSpaceFormUpdated(response.data.data))
        dispatch(refreshSession())
        return response.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        dispatch(errorsUpdate(err.response.data.errors))
        return rejectWithValue(err.response.data)
    }
})

export const toggleOwnedSpaceState = createAsyncThunk('owner_space_form/editOwnedSpace', async (arg, { getState, dispatch, rejectWithValue }) => {
    const ownedSpace = selectOwnedSpace(getState())
    let data = { organisation_id: ownedSpace.organisation_id, is_active: ownedSpace.is_active ? false : true }
    try {
        const response = await setActiveStateOfOwnedSpace(data, selectOwnedSpace(getState()).id)
        dispatch(ownedSpaceFormUpdated(response.data.data))
        dispatch(refreshSession())
        return response.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        dispatch(errorsUpdate(err.response.data.errors))
        return rejectWithValue(err.response.data)
    }
})

const ownedSpaceFormSlice = createSlice({
    name: 'owned_space_form',
    initialState,
    reducers: {
        ownedSpaceFormUpdated(state, action) {
            state.owned_space = action.payload
        },
        ownedSpaceFieldUpdated(state, action) {
            state.owned_space[action.payload.field] = action.payload.value
        },
        updateName(state, action) {
            state.owned_space.name = action.payload
        },
        updateAllowedOrganisations(state, action) {
            state.owned_space.allowed_organisations = action.payload
        },
        updateTemplate(state, action) {
            state.owned_space.owned_templates = action.payload
        },
        updateSlots(state, action) {
            state.owned_space.slots = action.payload
        },
        updateOwnedCategoriesID(state, action) {
            state.owned_space.owned_categories_id = action.payload
        },
        updateOwnedUrls(state, action) {
            state.owned_space.owned_urls = action.payload
        },
        updateWebChannel(state,action){
            state.owned_space.is_web=action.payload
        },
        updateAppChannel(state,action){   
            state.owned_space.is_app=action.payload
        },
        errorsUpdate(state, action) {
            state.errors = action.payload
        },
        clearOwnedSpace: () => initialState,
    },
    extraReducers: {
    },
})

export const selectUserOrganisation = state => selectUser(state).active_organisation
export const selectOwnedSpace = state => state.owned_space_form.owned_space;
export const selectOwnedSpaceErrors = state => state.owned_space_form.errors

export const {
    ownedSpaceFormUpdated,
    ownedSpaceFieldUpdated,
    updateName,
    updateTemplate,
    updateAllowedOrganisations,
    updateSlots,
    updateOwnedCategoriesID,
    updateOwnedUrls,
    errorsUpdate,
    clearOwnedSpace,
    updateWebChannel,
    updateAppChannel
} = ownedSpaceFormSlice.actions

export default ownedSpaceFormSlice.reducer
