import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Card, Col, Form, InputGroup, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {selectTheme} from '../session/sessionSlice'
import {fetchOrders} from './ordersSlice'

const OrderFilter = ({
                       filterFields = {},
                       organisations = [],
                       platforms = [],
                       statuses = [],
                       adTypes = [],
                       sortBy = [],
                       onlyArchived = false,
                       onChange = () => {
  },
}) => {
  const theme = useSelector(selectTheme)
  const dispatch = useDispatch()
  const [filters, setFilters] = useState(filterFields);
  //const onlyArchived = useSelector(state => state.orders?.archived_only)

  let allStatuses = [
    ...statuses,
    { id: 'campaign_live', label: 'Campaign Live' },
  ]

  useEffect(() => {
    dispatch(fetchOrders({filters: filters, onlyArchived: onlyArchived}))
  }, [filters])

  const handleChange = (field) => {
    setFilters({ ...filterFields, ...field });
    onChange({ ...filterFields, ...field })
  }

  return (
    <Form>
      <Row className="mb-4">
        <Col lg="4">
          <InputGroup style={{ position: 'relative' }}>
            <InputGroup.Prepend>
              <InputGroup.Text style={theme.components?.input_group_text}>
                Search:
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className="text-dark"
              value={filterFields.text}
              style={theme.components?.form_control}
              onChange={e => handleChange({ text: e.target.value })}
            />
            {filterFields.text !== '' &&
              <span style={{ position: 'absolute', right: '8px', top: '7px', zIndex: 9999, cursor: 'pointer' }} onClick={e => handleChange({ text: '' })}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>}
          </InputGroup>
        </Col>
        <Col lg="4"></Col>
        <Col lg="4">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text style={theme.components?.input_group_text}>
                Sort:
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                as="select"
                style={theme.components?.form_control}
                onChange={e => handleChange({sort_by: e.target.value})}
            >
              {sortBy.map(({label, value}) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>
      </Row>

      <Card className="p-4 mb-4" style={theme.components?.filter_card}>
        <h5>Filters</h5>
        <Row>
          <Col>
            <Form.Control
              as="select"
              style={theme.components?.form_control}
              onChange={e => handleChange({ organisation: e.target.value })}
            >
              <option value="">All Organisations</option>
              {organisations.map(organisation => (
                <option key={organisation.id} value={organisation.id}>
                  {organisation.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as="select"
              style={theme.components?.form_control}
              onChange={e => handleChange({ order_status: e.target.value })}
            >
              <option value="">All Statuses</option>
              {allStatuses.map(status => (
                <option key={status.id} value={status.id}>
                  {status.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as="select"
              style={theme.components?.form_control}
              onChange={e => handleChange({ format: e.target.value })}
            >
              <option value="">All Formats</option>
              {adTypes.map(adType => (
                <option key={adType.control_id} value={adType.control_id}>
                  {adType.label}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Control
              as="select"
              style={theme.components?.form_control}
              onChange={e => handleChange({ platform: e.target.value })}
            >
              <option value="">All Platforms</option>
              {platforms.map(platform => (
                <option key={platform.id} value={platform.id}>
                  {platform.label}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

OrderFilter.propType = {
  organisations: PropTypes.array,
  filterFields: PropTypes.shape({
    text: PropTypes.string,
    organisation: PropTypes.string,
    order_status: PropTypes.string,
    format: PropTypes.string,
    platform: PropTypes.string,
    sort_by: PropTypes.string,
  }),
  adTypes: PropTypes.array.isRequired,
  platforms: PropTypes.array,
  statuses: PropTypes.array,
  objectives: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  sortBy: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }))
}

export default OrderFilter
