import { Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Breadcrumb,
  Spinner,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import OwnedSpaceAllowedOrganisations from "../owned_space_form/containers/OwnedSpaceAllowedOrganisations";
import OwnedSpaceCategory from "../owned_space_form/containers/OwnedSpaceCategory";
import OwnedSpaceMonetisation from "../owned_space_form/containers/OwnedSpaceMonetisation";
import OwnedSpaceName from "../owned_space_form/containers/OwnedSpaceName";
import OwnedSpaceSlots from "../owned_space_form/containers/OwnedSpaceSlots";
import OwnedTemplates from "../owned_space_form/containers/OwnedTemplates";
import OwnedUrls from "../owned_space_form/containers/OwnedUrls";
import {
  clearOwnedSpace,
  editOwnedSpace,
  errorsUpdate,
  ownedSpaceFormUpdated,
  updateAllowedOrganisations,
  selectOwnedSpace,
  toggleOwnedSpaceState,
} from "../owned_space_form/ownedSpaceFormSlice";
import {
  clearSession,
  fetchWebSpaces,
  refreshSession,
  selectSession,
  selectUser,
} from "../session/sessionSlice";
import { getEmbeddableTag, getInventorySpaceDetails } from "../../lib/api/ownedSpaces";
import Breadcrumbs from "../../app/components/Breadcrumbs";
import Chrome from "../../app/layout/Chrome";
import styled from 'styled-components'
import InventorySpaceDetails from "../owned_space_form/components/InventorySpaceDetails";
import InventorySpacePerformance from "../owned_space_form/components/InventorySpacePerformance";
import InventorySpaceActions from "../owned_space_form/components/InventorySpaceActions";
import InventorySpaceBookingSchedule from "../owned_space_form/components/InventorySpaceBookingSchedule";

const SearchSpaceBadge = styled(Badge)`
background-color: rgb(254, 242, 192);
`

const OrganisationInventoryWebspaceDetails = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { usage_id } = useParams();
  const [ownedSpaceForm, setOwnedSpaceForm] = useState({});
  const [submitMessages, setSubmitMessages] = useState(null);
  const ownedSpace = useSelector(selectOwnedSpace);
  const [filterByWebSpaceDateRange, setFilterByWebSpaceDateRange] = useState("last_30_days");

  const breadcrumbItems = [
    {
      path: "/inventory/home",
      title: "Your Inventory",
    },
    {
      path: `/inventory/ownedspace/usage/${usage_id}`,
      title: ownedSpace?.details?.name,
      active: true,
    },
  ];

  useEffect(() => {
    getInventorySpaceDetails(user.active_organisation.id, usage_id, filterByWebSpaceDateRange).then(res => {
      var data = res.data?.data;
      if (data) {
        setOwnedSpaceForm(data);
        dispatch(ownedSpaceFormUpdated(data));
      }
    }).catch(err => {

    });
  }, [filterByWebSpaceDateRange]);

  const filterWebSpaceByDateRange = (selection) => {
    setFilterByWebSpaceDateRange(selection);
  };

  if (!ownedSpace) {
    return <Redirect to="/organisation/websettings" />;
  }
  return (
    <>
      <Helmet>
        <title>
          Edit Virtual Space - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className="pt-4">
          <Breadcrumbs items={breadcrumbItems} />
          <Row>
            <Col>
              <Form>
                {submitMessages && submitMessages?.type === 'danger' && (
                  <Alert className="mt-3" variant={submitMessages.type}>
                    {submitMessages.body}
                  </Alert>
                )}
                {submitMessages && submitMessages?.type === 'list' && (
                  <Alert className="mt-3" variant="danger">
                    <ul>{submitMessages.body.map((m) => (<li>{m}</li>))}</ul>
                  </Alert>
                )}
                <div className="mb-3 d-flex">
                  <Link to={breadcrumbItems[0].path}>
                    <FontAwesomeIcon icon={faChevronLeft} /> Back to Your Inventory
                  </Link>
                  <div style={{marginLeft: "auto"}}><select
                    class="custom-select"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => filterWebSpaceByDateRange(e.target.value)}>
                    <option value="last_14_days">Last 14 Days</option>
                    <option value="last_30_days" selected>Last 30 Days</option>
                    <option value="last_90_days">Last 90 Days</option>
                  </select>
                  </div>
                </div>
                <div className="mb-5 d-flex">
                  <div>
                    <div className="d-flex align-items-center mb-2">
                      <h3>{ownedSpace?.details?.name || "Your Space"}</h3>{" "}
                    </div>
                  </div>
                </div>
                <Container fluid>
                  <Row>
                    <Col className="w-50"><InventorySpaceDetails ownedSpace={ownedSpace?.details} /></Col>
                    <Col className="w-50"><InventorySpacePerformance ownedSpace={ownedSpace?.performance} /></Col>
                  </Row>
                  <Row>
                    <Col>&nbsp;</Col>
                    <Col>&nbsp;</Col>
                  </Row>
                  <Row>
                    <Col className="w-50"><InventorySpaceActions ownedSpace={usage_id} /></Col>
                    <Col className="w-50"><InventorySpaceBookingSchedule orders={ownedSpace?.schedule} /></Col>
                  </Row>
                </Container>
              </Form>
            </Col>
          </Row>
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationInventoryWebspaceDetails;
