import React from "react";
import propTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { Form } from "react-bootstrap";
import PopoverHint from "./PopoverHint";

const WalletOverdraw = () => {
  const methods = useFormContext();
  const { control } = methods;

  return (
    <Form.Group>
      <Form.Label>Allow Overdraw</Form.Label>
      <Controller
        name="allow_overdraw"
        control={control}
        render={({ field }) => (
          <Form.Check
            className="d-inline-block ml-2"
            type="checkbox"
            {...field}
            checked={field.value}
          />
        )}
      />
      <PopoverHint popoverText="This will allow suppliers to place orders even if the order budget is greater than the available budget in the wallet." />
    </Form.Group>
  );
};

export default WalletOverdraw;
