import {connect} from 'react-redux'
import {selectSession} from '../session/sessionSlice'
import OrderFilter from './OrderFilter'
import {filterUpdated, selectFilterFields} from './ordersSlice'

const sortFields = [
    {
        value: 'status',
        label: 'Status',
    },
    {
        value: 'name',
        label: 'Name',
    },
    {
        value: 'start_date',
        label: 'Start Date',
    },
    {
        value: 'end_date',
        label: 'End Date',
    },
    {
        value: 'created_date',
        label: 'Created Date',
    },
    {
        value: 'last_updated',
        label: 'Last Updated',
    },
    {
        value: 'retailer',
        label: 'Retailer',
    },
    {
        value: 'supplier',
        label: 'Supplier',
    },
]

const mapStateToProps = state => {
    const session = selectSession(state)
    const filter = selectFilterFields(state)
    const sortBy = sortFields

    return {
        filterFields: filter,
        platforms: session.platforms,
        statuses: session.order_statuses,
        adTypes: session.ad_types,
        sortBy,
        archivedOnly: false,
    }
}

const mapDispatchToProps = dispatch => ({
    onChange: filterFields => dispatch(filterUpdated(filterFields)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderFilter)
