import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Errors from "./Errors";
import { useDispatch, useSelector } from "react-redux";
import { assetsUpdated } from "../../../order_form/orderFormSlice";
import { updateImage } from '../../packageFormSlice'
import { Button, Form, FormGroup, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import FileDropzone from "../../../order_form/components/FileDropzone";
import { selectSession } from "../../../session/sessionSlice";
import styled from "styled-components";
import { DescriptionContainer, ErrorFeedback, LabelText } from "./Common";
import { PLATFORM_NATIVE } from "../../../../lib/platforms";

const StyledImg = styled.img`
    object-fit: cover;
    height: 100px;
`;

const ImgContainer = styled.div`
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
`

const StyledButton = styled(Button)`
    margin-top: 5px;
`;

const AssetChanges = ({
    assetValues,
    readOnly = false,
    accept = [],
    onChange = () => { },
}) => {
    const [assetModal, setAssetModal] = useState(false);
    const dispatch = useDispatch();
    const [files, setFiles] = useState(assetValues);

    return (
        <div>
            <StyledButton
                disabled={readOnly}
                variant="outline-secondary"
                className="w-100"
                onClick={(e) => setAssetModal(true)}
            >
                <div>Upload Media</div>
            </StyledButton>

            <Modal
                show={assetModal}
                onHide={(e) => {
                    setFiles(assetValues);
                    setAssetModal(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select media to add to the order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileDropzone
                        ad={{ platform: PLATFORM_NATIVE }}
                        max={1}
                        onChange={(fs) => setFiles(fs)}
                        onDelete={(file) => {
                            setFiles([]);
                        }}
                        readOnly={readOnly}
                        accept={accept}
                        value={files}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            if (files && files.length > 0) {
                                let filePath = files.map((file) => file.url);
                                onChange(filePath[0]);

                                let assets = [...assetValues];

                                files.forEach((file) => {
                                    if (!assets.some((asset) => asset.id === file.id)) {
                                        assets.push(file);
                                    }
                                });
                                let asset = assets.find(asset => asset.url);
                                dispatch(updateImage(asset.url));
                            }

                            setAssetModal(false);
                        }}
                    >
                        Insert
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const ImgMedia = ({ src, alt }) => (
    <ImgContainer>
        <StyledImg
            key={src}
            className="w-100 rounded"
            alt={alt}
            src={src}
        />
    </ImgContainer>
);

const EditableAsset = ({
    controlId,
    label,
    description,
    readOnly = false,
    required = false,
    value,
    onChange = () => { },
    errors = [],
}) => {
    const session = useSelector(selectSession);

    return (
        <Form.Group controlId={controlId}>
            <LabelText className="font-weight-bold">
                {label} {required && "*"}
            </LabelText>
            <DescriptionContainer>
                {description}
            </DescriptionContainer>
            <Form.Control type="hidden" />
            {value &&
                <ImgMedia src={value} />
            }
            <div>
                <AssetChanges
                    assetValues={[]}
                    onChange={onChange}
                    readOnly={readOnly}
                    accept={session.file_formats.images}
                />
            </div>

            <ErrorFeedback type="invalid" isInvalid={errors.length > 0}>
                <Errors errors={errors} />
            </ErrorFeedback>
        </Form.Group>
    );
};

EditableAsset.propTypes = {
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
};

export default EditableAsset;
