import React, {useEffect, useState} from "react";
import styled from "styled-components";

const rows = 4;
const cols = 4;
const aliveRate = 0.6;

const createGrid = () => {

    const grid = Array(rows).fill(undefined)
        .map(() => Array(cols).fill(false));

    // Randomly set the grid cells to black or white
    for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
            grid[i][j] = Math.random() < aliveRate;
        }
    }

    return grid;
}

const ThrobberGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

const ThrobberRow = styled.div`
  display: flex;
`;

const ThrobberCell = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  background-color: ${props => props.alive ? '#fff' : '#000'};
  transition: background-color 0.2s;
`;

const FancyThrobber = () => {
    const [grid, setGrid] = useState(createGrid());

    useEffect(() => {
        const interval = setInterval(() => {
            setGrid(createGrid());
        }, 250);

        return () => clearInterval(interval);
    }, []);

    return (
        <ThrobberGrid>
            {grid.map((row, rowIndex) => (
                <ThrobberRow key={rowIndex}>
                    {row.map((cell, colIndex) => (
                        <ThrobberCell
                            key={colIndex}
                            alive={cell}
                        ></ThrobberCell>
                    ))}
                </ThrobberRow>
            ))}
        </ThrobberGrid>
    );
};

export default FancyThrobber;