import devices from '../../../lib/devices'
import styled from 'styled-components'

export const Subsection = styled.div`
    border: 1px solid #f8f9fa;
    background-color: #fff;
    border-radius: 5px;
    margin: 0.75rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
`

export const SubheaderWrapper = styled.div`
    background: #ededed;
    padding: 1rem;
    display: flex;
    justify-content: left;
    align-items: center;
    height: 64px;
    padding: 24px;
`

export const Subheader = styled.h4`
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    margin: 0;
`

const AdGrid = styled.div`
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: grid;
    grid-template-columns: 68% auto;

    @media screen and (max-width: 1790px) {
      grid-template-columns: 60% auto;
    }

    @media screen and (max-width: ${devices.laptopL}) {
      grid-template-columns: auto 505px;
    }

    @media screen and (max-width: ${devices.tablet}) {
      grid-template-columns: 1fr
    }
`

export default AdGrid
