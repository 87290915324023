import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import LogRocket from 'logrocket'
import ordersSlice from '../features/orders/ordersSlice'
import sessionSlice from '../features/session/sessionSlice'
import orderFormSlice from '../features/order_form/orderFormSlice'
import organisationSettingsSlice from '../features/organisation_settings/organisationSettingsSlice'
import appSlice from "./appSlice";
import ownedSpaceFormSlice from '../features/owned_space_form/ownedSpaceFormSlice'
import packageFormSlice from '../features/packages/packageFormSlice'
import packagesSlice from '../features/packages/packagesSlice'
import bespokeFormSlice from '../features/bespoke/bespokeFormSlice'
import bespokeSlice from '../features/bespoke/bespokeSlice'
import brandsSlice from "../features/brands/brandsSlice";
import retailersSlice from "../features/retailers/retailersSlice";

export default configureStore({
    reducer: {
        app: appSlice,
        organisation_settings: organisationSettingsSlice,
        session: sessionSlice,
        orders: ordersSlice,
        order_form: orderFormSlice,
        owned_space_form: ownedSpaceFormSlice,
        packages: packagesSlice,
        package_form: packageFormSlice,
        bespoke_products: bespokeSlice,
        bespoke_product_form: bespokeFormSlice,
        brands: brandsSlice,
        retailers: retailersSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [...getDefaultMiddleware(), LogRocket.reduxMiddleware({
        stateSanitizer: state => {
            const sessionData = state.session?.data;
            return {
              ...state,
                session: {
                    data: {
                        ...sessionData,
                        ...(!!Object.keys(sessionData).length && {token: '*', auth_id: '*'})
                    }
                }
            };
          },
          actionSanitizer: action => {
            const type = action.type ? action.type.split('/'): [];
            // TODO: add better catch and check for sensitive data
            if(type[0] === 'session' && /login/i.test(type[1])){
                return {
                    ...action,
                    meta: {
                        args: {
                            ...action?.meta?.args,
                            email: '*',
                            password: '*',
                        },
                    },
                    payload: {
                        ...action?.payload,
                        ...(!!Object.keys(action?.payload).length && {token: '*', auth_id: '*'})
                    }
                }
            }

            return {
              ...action,
            };
          },
    })]
})
