import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import propTypes from "prop-types";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const StyledTableWrapper = styled.div`
  .table thead th {
    border: none;
    background: #ededef;
    vertical-align: middle;
  }
  .table tbody td {
    border-right: none;
    border-left: none;
    vertical-align: middle;
  }

  ${({ hideHeaders }) =>
    hideHeaders &&
    `
    .table thead {
      display: none;
    }
  `}
`;

const DataTable = ({
  data,
  columns,
  noDataIndication,
  hideHeaders = false,
  alwaysShowPagination = false,
  rowEvents = {},
}) => {
  
  const handleNextPage = ({ page, onPageChange }) => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => {
    onPageChange(page - 1);
  };

  return (
    <StyledTableWrapper hideHeaders={hideHeaders}>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          totalSize: data.length,
        })}
      >
        {({ paginationProps, paginationTableProps }) => {
          const { totalSize, page, sizePerPage } = paginationProps;
          const maxPageNumber = Math.ceil(totalSize / sizePerPage);
          return (
            <div>
              <BootstrapTable
                keyField="id"
                data={data}
                columns={columns}
                rowEvents={rowEvents}
                hover
                {...paginationTableProps}
                noDataIndication={noDataIndication}
              />
              {totalSize > 0 && (alwaysShowPagination || maxPageNumber !== 1) && (
                <div>
                  <div class="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon
                      role="button"
                      icon={faChevronLeft}
                      size="md"
                      className="mr-3"
                      onClick={() => page !== 1 && handlePrevPage(paginationProps)}
                    />
                    <span>
                      {page} of {maxPageNumber}
                    </span>
                    <FontAwesomeIcon
                      role="button"
                      icon={faChevronRight}
                      size="md"
                      className="ml-3"
                      onClick={() => page !== maxPageNumber && handleNextPage(paginationProps)}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </PaginationProvider>
    </StyledTableWrapper>
  );
};

DataTable.propTypes = {
  data: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  noDataIndication: propTypes.func,
  hideHeaders: propTypes.bool,
  rowEvents: propTypes.object,
  alwaysShowPagination: propTypes.bool,
};

export default DataTable;
