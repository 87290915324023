import { connect } from "react-redux";
import { selectSession } from "../../session/sessionSlice";
import CustomAudiences from "../components/CustomAudiences";
import { facebookLookalikeAudienceUpdate, selectReadPermissions } from "../organisationSettingsSlice";


const mapStateToProps = state => ({
    title: 'Lookalike Audiences',
    audiences: selectSession(state).facebook_lookalike_audiences,
})

export default connect(mapStateToProps)(CustomAudiences)
