import {useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect, useParams} from 'react-router-dom'
import Chrome from '../app/layout/Chrome'
import {selectOrderById} from '../features/orders/ordersSlice'
import ConnectedOrderFormChrome from '../features/order_form/ConnectedOrderFormChrome'
import OrderAdForm from '../features/order_form/containers/OrderAdForm'
import {
    availableAdFormats,
    availableMercatoDevices,
    availableWebSpaces,
    clearOrder,
    hasLiveOrderBeingViewed,
    orderFormEditable,
    orderPackages,
    orderUpdated,
    selectOrder,
    switchLiveOrderToEdited,
    updateOrderFormOrganisations
} from '../features/order_form/orderFormSlice'
import {isEditingState} from '../lib/orders'

const EditOrder = () => {
    const dispatch = useDispatch()
    const { order_id } = useParams()
    const orderFromOrders = useSelector(state => selectOrderById(state, order_id))
    const order = useSelector(selectOrder)
    const liveOrderViewed = useSelector(hasLiveOrderBeingViewed)

    const canEdit = isEditingState(order) || liveOrderViewed

    useEffect(() => {
        dispatch(clearOrder())
        dispatch(orderUpdated(orderFromOrders))
        dispatch(orderFormEditable())
        dispatch(switchLiveOrderToEdited())
    }, [])

    useEffect(() => {
        dispatch(updateOrderFormOrganisations())
        dispatch(availableAdFormats())
        dispatch(availableMercatoDevices())
        dispatch(availableWebSpaces())
        dispatch(orderPackages())
    }, [dispatch, order?.owner_id, order?.supplier_id])

    if (!canEdit) {
        return <Redirect to={`/order/${order_id}`} />
    }

    return (
        <>
            <Helmet>
                <title>
                    Edit Order - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container fluid className="pt-4">
                    <Row>
                        <Col>
                            <ConnectedOrderFormChrome>
                                <OrderAdForm />
                            </ConnectedOrderFormChrome>
                        </Col>
                    </Row>
                </Container>
            </Chrome>
        </>
    )
}

export default EditOrder
