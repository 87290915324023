import { faAngleDown, faAngleUp, faCalendar, faCheckCircle, faClipboard, faCross, faImage, faPalette, faPlus, faTimes, faVideo } from "@fortawesome/free-solid-svg-icons";
import { faDown, faPage, faSymbols, faTimeline, faUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { OverlayTrigger, Popover, Button, Card, Form, Row, Col} from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Errors from "../../order_form/components/Errors";
import { selectBespokeProduct, selectErrors, selectRequestType, selectStatus } from "../bespokeFormSlice";
import BespokeFields from "./fields";
import PropTypes from "prop-types";

const StyledContainer = styled.div`
    text-align: center;
`;

const StyledPlus = styled.div`
    border: 1px solid #33363F;
    border-radius: 10px;
    display: inline-block;
    padding: 10px 15px;
    transition: 0.3s;
    &:hover {
        background: #33363F;
        color: #fff;
    }
    &:active,
    &::selection {
        background: #6a7083;
    }
`;

const StyledPopMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    border-radius: 0 !important;
    Button {
        text-align: left;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0 !important;
        &:nth-last-child(1){
            border-bottom: 0;
        }
    }
`;

const getIcon = (icon) => {
    switch (icon) {
        case 'Text':
            return faPage
        case 'Y/N':
            return faCheckCircle;
        case 'Image':
            return faImage;
        case 'Video':
            return faVideo;
        case 'List':
            return faClipboard;
        case 'Color':
            return faPalette;
        case 'Date':
            return faCalendar;
        case 'Range':
            return faTimeline;
        default:
            return faPage;
    }
}

const Plus = ({
    updateLayoutData,
    index = 0,
    show,
    setShow,
}) => {
    const update = (text) => { updateLayoutData(text, index, false, true) }

    return (
        <>
            <OverlayTrigger
                trigger="click"
                rootClose
                placement="right"
                overlay={
                    <Popover
                        id="add-popover-sidebar"
                        className="rounded-0"
                    >
                        <Popover.Content className="p-0">
                            <StyledPopMessage>
                                <Button key="field-text" variant="light"
                                    onClick={() => update({ type: "text" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faSymbols} /> Text</div>
                                </Button>
                                <Button key="field-yes-no" variant="light"
                                    onClick={() => update({ type: "select_one", options: { yes: "Yes", no: "No" }, variant: "radio" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faCheckCircle} /> Y/N</div>
                                </Button>
                                <Button key="field-image" variant="light"
                                    onClick={() => update({ type: "asset" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faImage} /> Image</div>
                                </Button>
                                <Button key="field-video" variant="light"
                                    onClick={() => update({ type: "video" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faVideo} /> Video</div>
                                </Button>
                                <Button key="field-video" variant="light"
                                    onClick={() => update({ type: "document" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faPage} /> Asset</div>
                                </Button>
                                <Button key="field-list" variant="light"
                                    onClick={() => update({
                                        type: "list", options: {
                                            list_item_1: ''
                                        }
                                    })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faClipboard} /> List</div>
                                </Button>
                                <Button key="field-color" variant="light"
                                    onClick={() => update({ type: "color", options: {
                                        color_1: '#000000'
                                    }})}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faPalette} /> Color</div>
                                </Button>
                                <Button key="field-date" variant="light"
                                    onClick={() => update({ type: "date" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faCalendar} /> Date</div>
                                </Button>
                                <Button key="field-range" variant="light"
                                    onClick={() => update({ type: "range" })}
                                >
                                    <div><FontAwesomeIcon className='fa-fw' icon={faTimeline} /> Range</div>
                                </Button>
                            </StyledPopMessage>
                        </Popover.Content>
                    </Popover>
                }
            >

                <StyledPlus>
                    <FontAwesomeIcon icon={faPlus} />
                </StyledPlus>

            </OverlayTrigger>
        </>
    )
}

const BespokeEditor = ({
    fields = [],
}) => {
    const [show, setShow] = useState(false)
    const bespErrors = useSelector(selectErrors)
    const [layoutData, setLayoutData] = useState(fields)

    useEffect(() => {
        setLayoutData(fields)
    }, [fields])

    const moveLayoutData = (index, direction) => {
        let newlayoutData = [...layoutData];
        if (index + direction >= 0 && index + direction < newlayoutData.length) {
            [newlayoutData[index], newlayoutData[index + direction]] = [newlayoutData[index + direction], newlayoutData[index]];
            setLayoutData(newlayoutData);
        }
    }

    const updateLayoutData = (data, index, remove = false, add = false) => {
        let newlayoutData = [...layoutData];
        if (remove){
            newlayoutData.splice(index, remove)
        }
        else {
            if (add) {
                newlayoutData.splice(index, remove, data )
            }
            else {
                newlayoutData[index] = remove ? { ...newlayoutData[index], ...{} } : { ...newlayoutData[index], ...data };
            }
        }
        setLayoutData(newlayoutData);
    }

    return(
        <>
            <StyledContainer>
                {layoutData.length <= 0 && 
                    <>
                        <p className="pb-1">Select a field</p>
                        <Form.Control.Feedback type="invalid" className={bespErrors?.fields ? "d-block text-center pb-2" : ""}>
                            <Errors errors={bespErrors?.fields} />
                        </Form.Control.Feedback>
                    </>
                }
                <Plus
                    updateLayoutData={updateLayoutData}
                    show={show}
                    setShow={setShow}
                />
                {layoutData.length > 0 && layoutData.map((field, index) => 
                    <>
                        <BespokeFields 
                            field={field}
                            updateLayoutData={updateLayoutData}
                            index={index}
                            layoutData={layoutData}
                            setLayoutData={setLayoutData}
                            moveLayoutData={moveLayoutData}
                            errors={bespErrors}
                        />
                        <Plus
                            index={index+1}
                            updateLayoutData={updateLayoutData}
                            show={show}
                            setShow={setShow}
                        />
                        <Form.Control.Feedback type="invalid" className={bespErrors?.fields ? "d-block text-center pb-2" : ""}>
                            <Errors errors={bespErrors?.fields} />
                        </Form.Control.Feedback>
                    </> 
                )}
            </StyledContainer>
        </>
    );
}

BespokeEditor.propTypes = {
    field: PropTypes.object,
}

export default BespokeEditor