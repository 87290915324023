import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {selectSession} from "../../../session/sessionSlice";
import {Col, Form, Row} from "react-bootstrap";
import Errors from "../Errors";
import LocationRow from "./LocationRow";
import {selectOwnerOrganisation} from "../../orderFormSlice";

const Location = ({
                    readOnly = false,
                    required = false,
                    platform,
                    value = [],
                    errors = [],
                    onChange = () => {
                    },
}) => {
  const session = useSelector(selectSession);
  const ownerOrganisation = useSelector(selectOwnerOrganisation)
  const dispatch = useDispatch();
  const [newField, setNewField] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);

  const getUnique = (array, key) => {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  };

  const handleChange = (i, obj) => {
    let arr = value.slice();
    arr.push(obj);
    //arr[i] = obj;
    arr = arr.filter((e) => e && !Array.isArray(e));
    onChange(getUnique(arr, "name"));
  };

  useEffect(() => {
    if (platform === "facebook") {
      setLocationTypes(session.facebook_audiences_location_fields);
      let countryIdx = session.facebook_audiences_location_fields.findIndex(e => e.type === 'country');

      if((!value || value.length === 0) && countryIdx >= 0) {
        if (ownerOrganisation?.settings?.defaultLocations?.facebook?.key) {
          let loc = ownerOrganisation.settings.defaultLocations.facebook;
          handleChange(countryIdx, {
            country_code: loc.country_code,
            country_name: loc.country_name,
            type: "country",
            key: loc.key,
            label: loc.country_name,
            name: loc.country_name
          })
        }
      }
    } else if (platform === "google" || platform === "youtube") {
      setLocationTypes(session.google_audiences_location_fields);
    }
  }, []);

  return (
    <Form.Group controlId="location-group">
      <Form.Label style={{ margin: "0", padding: "0" }}>Location</Form.Label>
      <div>
        <small>Note: regions includes states and provinces.</small>
      </div>
      <Form.Control.Feedback
        type="invalid"
        className={errors.length > 0 ? "d-block" : ""}
      >
        <Errors errors={errors} />
      </Form.Control.Feedback>
      <Row>
        {locationTypes.map((location, i) => (
          <Col className={`col-sm-6`}>
            <LocationRow
              id={`location-row-${i}`}
              readOnly={readOnly}
              required={required}
              platform={platform}
              key={location}
              value={location}
              onChange={(val) => handleChange(i, val)}
            />
          </Col>
        ))}
      </Row>
    </Form.Group>
  );
};

Location.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.any, type: PropTypes.string })
  ),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default Location;
