import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Button,
  Col,
  Container, Row
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { FACEBOOK_DISPLAY } from "../../../../../lib/webads";
import { selectFacebookPlacements } from "../../../../session/sessionSlice";
import FacebookDisplayPlacements from "../../../containers/FacebookDisplayPlacements";
import {assetsUpdated, isOwnerOrganisation, selectOwnerOrganisation} from "../../../orderFormSlice";
import Accordian from "../../common/Accordian";
import CollapseableTooltipBox from "../../common/CollapseableTooltipBox";
import { PaneSection } from '../../common/Common';
import AssetModal from "./AssetModal";
import AssetRatiosSelector from "./AssetRatiosSelector";
import MediaFileView from "./MediaFileView";


const PLACEMENTS_SECTION = 'placements'

const SuccessIcon = styled.span`
  color: #7ee79b;
  margin-right: 10px;
`;

const filterOutRightAssetRatios = (original, filterCond) =>
  original.filter(ass => !filterCond.some(fc => fc.asset_id === ass.asset_id))

const AssetsPane = ({
  adFormat = {},
  readOnly = true,
  ad = {},
  fieldNames = [],
  value = [],
  onChange = () => { },
  assetFieldStructures = [],
  errors = {},
}) => {
  const organisation = useSelector(selectOwnerOrganisation);
  const isOwner = useSelector(isOwnerOrganisation)
  const [showModal, setShowModal] = useState(false)
  const [accordian, setAccordian] = useState([])
  const dispatch = useDispatch()
  let placements = useSelector(selectFacebookPlacements).display
  const instagramEnabled = Boolean(organisation?.settings?.['facebookDefaultInstagramActorID'])

  if (assetFieldStructures.length === 0) {
    return <div></div>
  }

  if (!instagramEnabled) {
    placements = placements.filter(placement => placement.network !== 'Instagram' || ad?.placements?.includes(placement.id))
  }

  let ratioPlacements = placements
  if (!ad.placements_automatic && ad.placements && ad.placements.length > 0) {
    ratioPlacements = placements.filter(placement => ad.placements.includes(placement.id))
    if (!instagramEnabled) {
      ratioPlacements = ratioPlacements.filter(placement => placement.network !== 'Instagram')
    }
  }

  const handleShowModal = () => setShowModal(true)
  const handleHideModal = () => setShowModal(false)

  const isPlacementOpen = accordian.includes(PLACEMENTS_SECTION)
  const handlePlacementCollapse = () => isPlacementOpen
    ? setAccordian(accordian.filter(x => x !== PLACEMENTS_SECTION))
    : setAccordian([...accordian, PLACEMENTS_SECTION])

  const handleDelete = (file) => {
    let newAssets = value.filter((asset) => asset.id !== file.id);
    onChange({
      [fieldNames[0]]: newAssets.map((asset) => asset.id),
    });
    dispatch(assetsUpdated(newAssets));
  }

  const handleAssetRatiosDelete = (assetRatios) => {
    const newAssetRatioValues = filterOutRightAssetRatios((ad.asset_ratios || []), assetRatios)
    onChange({ asset_ratios: newAssetRatioValues })
  }

  const handleAssetRatiosChange = (asset, assetRatios) => {
    const newAssetRatioValues = (ad.asset_ratios || []).filter(ar => ar.asset_id !== asset.id)

    onChange({ asset_ratios: [...newAssetRatioValues, ...assetRatios] })
  }

  return <PaneSection>
    <Container>
      {adFormat?.asset_requirements && (
        <Row className="mb-3">
          <Col sm="12">
            <CollapseableTooltipBox label="Creative Guidelines">
              <div
                dangerouslySetInnerHTML={{
                  __html: adFormat.asset_requirements,
                }}
              ></div>
            </CollapseableTooltipBox>
          </Col>
        </Row>)}
      {ad.type === FACEBOOK_DISPLAY &&
        <Row className="mb-3">
          <Col sm="12">
            <Accordian
              onCollapse={handlePlacementCollapse}
              open={isPlacementOpen}
              header={
                <span>
                  <SuccessIcon>
                    <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                  </SuccessIcon>
                  <span>
                    1. Placements: {ad.placements_automatic ? 'Automatic' : 'Manual'}
                  </span>
                </span>
              }
            >
              <FacebookDisplayPlacements
                controlId="facebook-placements"
                readOnly={readOnly}
                instagramEnabled={instagramEnabled}
                isOwnerOrganisation={isOwner}
                availablePlacements={placements}
                automatic={ad.placements_automatic}
                value={ad.placements}
                onChange={({ automatic, placements }) => onChange({ placements_automatic: automatic, placements })}
              />
            </Accordian>
          </Col>
        </Row>}
      <Row className="mb-3">
        <Col sm="12">

          <div>
            <Button
              variant="outline-secondary"
              className={`py-3 mb-2 w-100 ${errors.length > 0 ? "border-danger" : ""}`}
              onClick={handleShowModal}
              disabled={readOnly}
            >
              <FontAwesomeIcon icon={faPlus} size="4x" />
              <br />
              <h6 className="mt-1">Attach Media</h6>
            </Button>

            {ad.type === FACEBOOK_DISPLAY
              ?
              value.map(file => (
                <AssetRatiosSelector
                  key={file.id}
                  asset={file}
                  readOnly={readOnly}
                  value={ad.asset_ratios?.filter(ratioAsset => ratioAsset.asset_id === file.id) || []}
                  placements={ratioPlacements}
                  automaticPlacements={Boolean(ad.placements_automatic)}
                  onPlacementDelete={asset_ratios => handleAssetRatiosDelete(asset_ratios)}
                  onChange={asset_ratios => handleAssetRatiosChange(file, asset_ratios)}
                />
              ))
              : value.map((file) => (
                <MediaFileView
                  key={file.id}
                  file={file}
                  readOnly={readOnly}
                  onDelete={handleDelete}
                />
              ))}

          </div>
        </Col>
      </Row>
    </Container>
    <AssetModal
      ad={ad}
      fieldNames={fieldNames}
      value={value}
      onChange={onChange}
      fieldStructure={assetFieldStructures[0]}
      errors={errors}
      onHide={handleHideModal}
      show={showModal}
      readOnly={readOnly}
    />
  </PaneSection>
}

export default AssetsPane
