import {
  faInfoCircle,
  faInfo,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import { faHeartCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Card, Button, CardGroup, Row, Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { selectSession } from "../../../session/sessionSlice";
import styled from 'styled-components';
import devices from "../../../../lib/devices";

const StyledCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
  flex: 0 0 100%;

  @media(min-width: ${devices.mobileL}) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media(min-width: ${devices.tablet}) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Special case media query breakpoint, likely to not be used anywhere else */
  @media(min-width: 960px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* Special case media query breakpoint, likely to not be used anywhere else */
  @media(min-width: 1100px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  /* Special case media query breakpoint, likely to not be used anywhere else */
  @media(min-width: 1300px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  /* Special case media query breakpoint, likely to not be used anywhere else */
  @media(min-width: 1650px) {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  
`

const SponsoredProductsCarousel = ({
  enablementText,
  ad,
  assets,
  itemsToPreview = 4,
  highlightPositions = [],
}) => {
  const session = useSelector(selectSession);

  return (
    <div>
      { enablementText && <div style={{ color: "#5995ED", textAlign: "center", margin: "20px" }}>
        <FontAwesomeIcon icon={faInfoCircle} style={{marginRight: "5px"}} /> {enablementText}
      </div>
      }
      <Row>
        {/* <CardGroup style={{backgroundColor: "#fff"}}> */}
          {new Array(itemsToPreview).fill().map((item, index) => (
            <StyledCol>
              <Card className="border-0" style={{ margin: "15px", backgroundColor: "#fff" }}>
                <div style={{width: "100%", height: "100px", backgroundColor: highlightPositions.includes(index) ? '#EFC352': "#EDEDED"}}></div>
                <Card.Body className="px-0" style={{height: "130px" }}>
                  <Card.Title>
                    <div style={{ height: "25px" }}>
                      <div style={{ width: "70%", height: "25px", backgroundColor: "#EDEDED", display: "inline-block", float: "left" }}></div>
                      <FontAwesomeIcon icon={faHeart} style={{display: "inline-block", float: "right", color: "#EDEDED"}} />
                    </div>
                  </Card.Title>
                  <div style={{ width: "100%", height: "25px", backgroundColor: "#EDEDED" }}></div>
                  <div style={{ width: "100%", height: "25px", backgroundColor: "#EDEDED", marginTop: "5px" }}></div>
                </Card.Body>
             </Card>
            </StyledCol>
          ))}

        {/* </CardGroup> */}
      </Row>
    </div>
  );
};

SponsoredProductsCarousel.propTypes = {
  enablementText: PropTypes.string,
  assets: PropTypes.object.isRequired,
  ad: PropTypes.object,
  itemsToPreview: PropTypes.number,
  highlightPositions: PropTypes.array
};

export default SponsoredProductsCarousel;
