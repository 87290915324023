import { connect } from "react-redux";
import { selectSession } from "../../session/sessionSlice";
import  {selectAllBrands } from "../../brands/brandsSlice";
import Suppliers from "../components/Suppliers";
import {
  selectOwnedSpace,
  ownedSpaceFieldUpdated,
  selectOwnedSpaceErrors,
  updateAllowedOrganisations,
} from "../ownedSpaceFormSlice";

const mapStateToProps = (state) => ({
  suppliers: selectAllBrands(state),
  value: selectOwnedSpace(state).allowed_organisations,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (value) => dispatch(updateAllowedOrganisations(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
