import { faPlus, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import Errors from "../Errors";
import BehaviourRow from "./BehaviourRow";

// TODO: remove undefined field when interest is null
const Behaviours = ({
  readOnly = false,
  required = false,
  adType,
  platform,
  value = [],
  errors = [],
  onChange = () => { },
}) => {
  const [newField, setNewField] = useState(false);

  const handleChange = (i, obj) => {
    let arr = value.slice();
    arr[i] = obj;
    onChange(arr);
  };
  const handleDelete = (val, id) =>
    onChange(value.filter((_, i) => `behaviour-row-${i}` !== id));

  const label =
    platform === "google" || platform === "youtube"
      ? "Affinity audiences"
      : "Behaviours";

  return (
    <Form.Group
      controlId="behaviour-group"
      className="col-md-6"
      style={{
        display: "inline-block",
        float: "left",
        width: "100%",
        "padding-right": "0",
        "margin-right": "0",
      }}
    >
      <div className="d-flex">
        <Form.Label>{label}</Form.Label>
        <a
          className="ml-2"
          target="_blank"
          href={
            platform === "facebook"
              ? "https://www.zitcha.com/en/knowledge/hc/en-us/articles/4402350882841-about-facebook-s-interest-audiences"
              : "https://www.zitcha.com/en/knowledge/hc/en-us/articles/4402394892953-which-google-audiences-are-available-in-zitcha"
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </a>
      </div>
      <Form.Control.Feedback
        type="invalid"
        className={errors.length > 0 ? "d-block" : ""}
      >
        <Errors errors={errors} />
      </Form.Control.Feedback>
      <BehaviourRow
        id={`behaviour-row-${value.length}`}
        platform={platform}
        adType={adType}
        readOnly={readOnly}
        required={required}
        onAddClick={(val) => {
          handleChange(value.length, val);
        }}
        onDelete={(e) => {
          handleDelete(e);
        }}
      />
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Behaviours.propTypes = {
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  adType: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.any, type: PropTypes.string })
  ),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default Behaviours;
