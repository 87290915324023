import {
  faArrowCircleRight,
  faArrowCircleLeft,
  faGlobeAsia,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Card, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { selectSession } from "../../../session/sessionSlice";
import styles from "./mercato_devices_mixed_carousel.module.scss";

const MercatoDevicesMixedCarousel = ({
  bodycopy,
  cta,
  ad,
  assets,
  isPreviewMode = true,
  onRightMove = () => { },
  onLeftMove = () => { },
}) => {
  const session = useSelector(selectSession);

  return (
    <div id="ad_fb" className={styles.device_preview}>
      <div>
        <Carousel
          additionalTransfrom={0}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode
          className="w-100"
          afterChange={(previousSlide, { currentSlide, onMove }) => { }}
          containerClass=""
          dotListClass=""
          draggable
          focusOnSelect={true}
          itemClass={styles.ad_item}
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {assets.map((media, i) => (
            <div key={media.id} style={{ margin: "5px" }}>
              <div style={{ textAlign: "center" }}>
                {i > 0 && (
                  <Button
                    onClick={(e) => onLeftMove(media, i)}
                    variant={"outline-secondary"}
                    style={{ margin: "2px", padding: "2px", border: "none" }}
                  >
                    <FontAwesomeIcon icon={faArrowCircleLeft} />
                  </Button>
                )}
                {i < assets.length - 1 && (
                  <Button
                    onClick={(e) => onRightMove(media, i)}
                    variant={"outline-secondary"}
                    style={{
                      margin: "2px",
                      padding: "2px",
                      border: "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faArrowCircleRight} />
                  </Button>
                )}
              </div>
              <Card
                className={("card", "d-flex", "text-left", styles.ad_base)}
                style={{
                  padding: "0px",
                  height: "400px",
                  maxHeight: "400px",
                  marginBottom: "15px",
                }}
                key={i}
              >
                <div
                  className="card-block text-center"
                  style={{
                    height: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  {media.assettype == "image" && (
                    <Card.Img
                      variant="top"
                      style={{
                        maxHeight: "350px",
                        padding: "0",
                        height: "350px",
                        objectFit: "cover",
                      }}
                      className={isPreviewMode ? styles.ad_media_preview : ""}
                      src={media.url}
                    />
                  )}
                  {media.assettype == "video" && (
                    <div style={{ height: "100%", objectFit: "cover" }}>
                      <video
                        className={styles.ad_video}
                        key={media.url}
                        controls
                        style={{ backgroundColor: "white", objectFit: "cover" }}
                      >
                        <source src={media.url} />
                      </video>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

MercatoDevicesMixedCarousel.propTypes = {
  bodycopy: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
};

export default MercatoDevicesMixedCarousel;