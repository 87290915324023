import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { calculateRoas } from '../../../lib/financial'
import { financialMetricKeys } from '../../organisation_settings/components/MetricPermissions'

const StatisticsListTable = styled.div`
    tr:nth-child(even) {
        background-color: #f6f5ff;
    };
    tr{
        height: 50px;
        width: 100%;
    }
    td{
        width: 100%;
        padding: 10px;
        font-size: 17px;
    }
`

const StyledSelection = ({value, onClick = () => {}, selected = false}) => {
    return (<a href="javascript:void(0)" onClick={e => onClick(e)} className="font-weight-bold" style={{ color: selected ? '#a28eff' : '#575868' }}>{value}</a>);
}

const formattedCurrency = (body, unit, currency = 'AUD') => {
    try {
        return new Intl.NumberFormat(undefined, { style: 'currency', minimumFractionDigits: 0, currency, maximumFractionDigits: 4, currencyDisplay: 'narrowSymbol' }).format(body)
    } catch (e) {
        if (e.name !== 'RangeError') {
            throw e
        }
        return new Intl.NumberFormat(undefined, { style: 'currency', minimumFractionDigits: 0, currency, maximumFractionDigits: 4, currencyDisplay: 'symbol' }).format(body)
    }
}

const formattedNumber = (body) => new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(body)

const formatPercentage = (body) => new Intl.NumberFormat('en-US', { notation: 'compact', compactDisplay: 'short' }).format(body) + "%"

const InsightsView = ({ insightSchema = [], insights = {}, currency = 'AUD' }) => {
    const fields = insightSchema.filter(field => insights[field.name] !== undefined && insights[field.name] !== null)
    const sortedFields = sortBy(fields, (field) => financialMetricKeys.includes(field.name) ? 1 : 0)

    const updatedRoasInsights = { ...insights, total_roas: insights?.total_roas ? insights.total_roas.toFixed(2) : calculateRoas(insights.total_conversion_value ?? 0, insights.spend ?? 0) }

    const [selection, setSelection] = useState('select-all');
    const [metrics, setMetrics] = useState(sortedFields);

    useEffect(() => {
        if (selection === 'financial') {
            setMetrics(sortedFields.filter(f => f.unit === 'currency'));
        } else if (selection === 'performance') {
            setMetrics(sortedFields.filter(f => f.unit !== 'currency'));
        } else {
            setMetrics(sortedFields);
        }
    }, [selection]);

    return (
      <>
        <>
          <div style={{ textAlign: "center", margin: "10px" }}>
            <span style={{ padding: "15px" }}>
              <StyledSelection
                value={"See All"}
                onClick={(e) => setSelection("select-all")}
                style={selection === "select-all"}
              />
            </span>
            <span style={{ padding: "15px" }}>
              <StyledSelection
                value={"Financial"}
                onClick={(e) => setSelection("financial")}
                style={selection === "financial"}
              />
            </span>
            <span style={{ padding: "15px" }}>
              <StyledSelection
                value={"Performance"}
                onClick={(e) => setSelection("performance")}
                style={selection === "performance"}
              />
            </span>
          </div>
          {metrics.length > 0 && (
            <StatisticsListTable class="table">
              <table>
                <tbody>
                  {metrics.map((field) => (
                    <tr key={field.name}>
                      <td style={{ textAlign: "left" }}>{field.title}</td>
                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                        {field.unit && field.unit === "currency"
                          ? formattedCurrency(
                              updatedRoasInsights[field.name],
                              field.unit
                            )
                          : field.unit && field.unit === "percentage"
                          ? field.name !== "total_roas"
                            ? formatPercentage(
                                updatedRoasInsights[field.name] * 100
                              )
                            : formatPercentage(updatedRoasInsights[field.name])
                          : formattedNumber(updatedRoasInsights[field.name])}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </StatisticsListTable>
          )}
        </>
        {metrics.length === 0 && (
          <div className="text-center" style={{ paddingTop: "10px" }}>
            No Metrics available.
          </div>
        )}
      </>
    );
}

InsightsView.propTypes = {
    insightSchema: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        })
    ),
    insights: PropTypes.object,
    currency: PropTypes.string,
}

export default InsightsView
