import React, { useState, useMemo, useCallback } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
  Spinner,
  InputGroup,
  Alert,
  Figure,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Chrome from "./layout/Chrome";
import {
  clearSession,
  refreshSession,
  selectSession,
} from "../features/session/sessionSlice";
import $styles from "./organisation_channels.module.scss";
import { updateOrganisationSettings, uploadOrganisationLogo } from "../lib/api";
import FacebookDefaultPageID from "../features/organisation_settings/components/FacebookDefaultPage";
import OrganisationList from "../features/organisation_settings/components/List";
import OrganisationAdSettings from "../features/organisation_settings/components/OrganisationAdSettings";
import { DevTool } from "@hookform/devtools";
import { selectSettings } from "../features/organisation_settings/organisationSettingsSlice";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "./components/Breadcrumbs";

const percLoaded = (p) => parseInt((p.loaded / p.total) * 100);

const breadcrumbItems = [
  {
    path: "/organisation/settings",
    title: "Settings",
  },
  {
    path: "/organisation/channels",
    title: "Owned Channels",
    active: true,
  },
];

const ChannelItem = ({ logo, title, settingsPath, description }) => {
  const history = useHistory();

  return (
    <div className="d-flex">
      <Figure>
        <Figure.Image className="img-responsive" src={logo} rounded />
      </Figure>
      <div className={$styles.channelItemDiv}>
        <h4 className={$styles.channelItemTitle}>{title}</h4>
        <p className={$styles.channelItemDesc}>{description}</p>
        <button
          className={$styles.settingsBtn}
          onClick={() => history.push(settingsPath)}
        >
          Settings
        </button>
      </div>
    </div>
  );
};

const OrganisationChannels = () => {
  const session = useSelector(selectSession);
  const [organisation, setOrganisation] = useState(
    session.user.active_organisation
  );
  const settings = organisation?.settings;

  const methods = useForm({
    defaultValues: useMemo(() => {
      const def = {
        facebookDefaultPageID: settings?.facebookDefaultPageID,
      };
      return def;
    }, [settings]),
    mode: "onSubmit",
  });

  const {
    setError,
    getValues,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    trigger,
    formState,
  } = methods;

  const { touchedFields, isSubmitted, errors } = formState;

  const adTypes = useSelector(selectSession).ad_types;

  const getAdTypeLogo = useCallback(
    (type) => {
      const adType = adTypes.find((e) => e.control_id === type);
      return adType ? adType.placeholder_images?.large : null;
    },
    [adTypes]
  );

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === "true" && (
        <DevTool control={control} />
      )}
      <Helmet>
        <title>
          Owned Organisation Channels for {organisation.name} - {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className="pt-4">
          <Breadcrumbs items={breadcrumbItems} />

          <Form>
            <Row className="mb-3">
              <Col>
                <div className="d-flex">
                  <h3 className={$styles.topHeading}>Owned Channels</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="6" xs="12">
                <ChannelItem
                  logo={getAdTypeLogo("web_ads")}
                  title="Web Ads"
                  description="Set up ads on your organisation’s own website across desktop, tablet and mobile environment."
                  settingsPath="/organisation/websettings"
                />
              </Col>
              <Col sm="6" xs="12">
                <ChannelItem
                  logo={getAdTypeLogo("bespoke_product")}
                  title="Bespoke Product"
                  description="Set up Ads on your organisation’s own website across desktop, tablet and mobile environment."
                  settingsPath="/organisation/bespokesettings"
                />
              </Col>
            </Row>

            {/* {session?.channels && session.channels.length > 0 && (
              <Row className="mb-2">
                <Col lg="6">
                  <Form.Group>
                    <ListGroup variant="flush">
                      {session.channels.map((channel, i) => (
                        <ListGroup.Item
                          className={
                            "d-flex justify-content-between align-items-center"
                          }
                        >
                          <a
                            href="#"
                            onClick={(e) =>
                              history.push(`/organisation/websettings`)
                            }
                          >
                            {channel.name}
                          </a>
                          <Button
                            variant="outline-secondary"
                            onClick={() => {}}
                          >
                            <FontAwesomeIcon icon={faLock} /> Unlock
                          </Button>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Form.Group>
                </Col>
              </Row>
            )} */}
          </Form>
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationChannels;
