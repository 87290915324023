import { connect } from 'react-redux'
import { postFacebookAsset } from '../../../lib/api'
import { selectSession } from '../../session/sessionSlice'
import FileDropzone from '../components/FileDropzone'
import { selectReadOnly } from '../orderFormSlice'

const mapStateToProps = state => ({
    postRequest: postFacebookAsset,
})


export default connect(mapStateToProps)(FileDropzone)
