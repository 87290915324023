import { faAngleDown, faAngleRight, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import _ from 'lodash';
import React, { useState } from 'react'
import { Accordion, Card, Form, useAccordionToggle, InputGroup } from 'react-bootstrap'
import { selectSession } from '../../session/sessionSlice';
import { useSelector } from 'react-redux'
import styles from './audiences.module.scss';

const CustomAudiences = ({
    controlId,
    title,
    value = [],
    preselectedValues = [],
    audiences = [],
    onChange = () => { },
}) => {
    const [panelIndex, setPanelIndex] = useState()
    const session = useSelector(selectSession)
    const [filters, setFilters] = useState({ searchTerm: null });
    const handleFilterChange = filterType => setFilters({ ...filters, ...filterType });
    const CustomToggle = ({ children, eventKey }) => {
        const customOnClick = useAccordionToggle(eventKey, () => {
            setPanelIndex(eventKey === panelIndex ? null : eventKey)
        })

        const arrow =
            eventKey === panelIndex ? (
                <FontAwesomeIcon icon={faAngleUp} fixedWidth />
            ) : (
                    <FontAwesomeIcon icon={faAngleDown} fixedWidth />
            )

        return (
            <Card.Header
                className='bg-white'
                onClick={customOnClick}
            >
                {children} <span className='float-right'>{arrow}</span>
            </Card.Header>
        )
    }

    const applyFilters = (audience, filter) => {
        return audience.filter(a => !filter.searchTerm || a.name.toLowerCase().includes(filter.searchTerm.toLowerCase()));
    }

    const handleChange = (audience, checked) => {
        let v = value.filter(x => x.id !== audience.id)
        if (checked) {
            v.push(audience)
        }
        onChange(v)
    }

    const sortedAudiences = applyFilters(_.orderBy(audiences, 'name', 'asc'), filters);

    return (
        <section>
            <Accordion className="mb-2">
                <Card className={styles.card_border}>
                    <CustomToggle eventKey={controlId} >
                        {title}
                    </CustomToggle>
                    <Accordion.Collapse eventKey={controlId}>
                        <Card.Body className={styles.card_body}>
                            <div className="px-1 py-1">
                                <Form.Group className={styles.search_bar}>
                                    <InputGroup>
                                        <InputGroup.Text className={styles.search_icon}>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={filters.searchTerm}
                                            style={session?.theme?.components?.form_control}
                                            onChange={e => handleFilterChange({ searchTerm: e.target.value })}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            {sortedAudiences.length > 0 ? (
                                sortedAudiences.map(audience => (
                                    <Form.Group controlId={`${controlId}-${audience.id}`} key={audience.id}>
                                        <div className="d-flex justify-contents-center">
                                            <Form.Check
                                                inline
                                                id={`${controlId}-${audience.id}`}
                                                className="mr-2"
                                                checked={value.some(x => x.id === audience.id) || preselectedValues.some(x => x.id === audience.id)}
                                                disabled={preselectedValues.some(x => x.id === audience.id)}
                                                onChange={e => handleChange(audience, e.target.checked)}
                                            />
                                            <Form.Label className="mb-0">{audience.name}</Form.Label>
                                        </div>
                                    </Form.Group>
                                ))
                            ) : (
                                <span>
                                    Sorry, there haven't been any created audiences.
                                </span>
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>

        </section>
    )
}

CustomAudiences.propTypes = {
    controlId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.array,
    audiences: PropTypes.array,
    onChange: PropTypes.func,
}

export default CustomAudiences
