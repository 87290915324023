import React from 'react'
import { Figure, Row, Col, Carousel } from 'react-bootstrap'
import { faFacebook, faYoutube, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectSession } from '../../../features/session/sessionSlice'
import { faChartLine, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { data } from 'jquery'
import { discountDisplay } from '../../../lib/financial'
import { truncate } from '../../../lib/strings'

const CardContainer = styled.article`
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 16px;
    width: 24rem;
    margin-bottom: 20px;
`

const PackageImage = styled(Figure.Image)`
    text-align: center;
    max-height: 200px;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
`

const BudgetStyled = styled.span`
        color:#000;
        position: relative;
        margin-top: -40px;
        margin-right: 10px;
        float: right;
        background: #2DEA40;
        padding: 2px 8px;
        border-radius: 5px;
        font-size: 12px;
`

const OrganisationHeader = styled.h6`
    color: #421CFF;
    font-size: 14px;
    margin-bottom: 1rem;
    font-weight: 500;
    text-align: right;
`

const PackageHeader = styled.h5`
    color: #3A3B4F;
    font-size: 20px;
    word-break: break-word;
`

const PackageDiscount = styled.p`
    color: #000000;
    font-weight: 200;
    font-size: 14px;
    margin-bottom: 4px;
`

const PackageDescription = styled.p`
    color: #000000;
    font-size: 20px;
    font-weight: 200;
    line-height: 1.2;
    word-break: break-word;
`

const PackageOutcomes = styled.div`
    color: #0000CA;
    background: #D0C6FF;
    padding: 5px 12px;
    border-radius: 5px;
    word-break: break-word;
`

const AddOrderButton = styled.button`
    border: 1px solid #7155FF;
    background: #ffffff;
    border-radius: 4px;
    padding: 10px 18px 10px 18px;
    color: #7155FF;
    font-size: 16px;

    display: flex;
    margin-left: auto;
`

const StyledAdIcon = styled(FontAwesomeIcon)`
    color: #fff;
    background-color: #B9B9C0;
    border-radius: 100%;
    padding: 6px;
    width: 30px !important;
    height: 30px !important;
    margin-right: 0.5rem !important;
`;

const StyedAdTitle = styled.p`
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 2px;
`;
const StyedAdDesc = styled.p`
    font-size: 10px;
    span {
        display: block;
    }
`;

const CarouselAd = styled.div`
    min-height: 90px;
`;

const StyledCarousel = styled(Carousel)`
    .carousel-indicators {
        li:not(.active) {
            background-color: #333;
        }
        bottom: -20px; 
    }
    .carousel-control-prev,
    .carousel-control-next {
        bottom: -30px;
    }
`

const maxBudget = (ads) => {
    let budget = 0;
    ads.map(ad => budget += ad.max_budget);
    return budget;
}

const PackageCard = ({ image, organisationLabel, header, description, outcomes, pkg, ads = [], showButton = true, onAdd = () => { } }) => {
    let ad = {}
    const session = useSelector(selectSession);
    const defCurrency = session.user?.active_organisation?.settings?.['defaultCurrency'];
    const currency = (session.currencies).find(s => s.code === defCurrency)?.symbol;
    const adType = session.ad_types;

    const countAds = (ads = []) => {
        let adTypes = {};
        ads.map(ad => {
            adTypes[ad.adformat_control_id] ? adTypes[ad.adformat_control_id] += 1 : adTypes[ad.adformat_control_id] = 1;
        })
        return adTypes;
    }

    const budget = maxBudget(ads);

    return <CardContainer>
        <Figure className="w-100">
            <PackageImage
                src={image || "https://upload.wikimedia.org/wikipedia/commons/5/51/Annie_Spratt_2016-03-06_%28Unsplash_reU05EIlNCQ%29.jpg"}
                budget={"20"}
            />
            {budget > 0 && <BudgetStyled>Budget: {currency}{budget}</BudgetStyled>}
        </Figure>
        <Row>
            <Col md="6">
                <PackageHeader>
                    {truncate(header, 30)} ({ads.length} Ad{ads.length > 1 ? 's' : ''})
                </PackageHeader>
                <PackageDiscount>
                    {discountDisplay(ads, currency)}
                </PackageDiscount>
            </Col>
            <Col md="6">
                <OrganisationHeader>
                    {organisationLabel}
                </OrganisationHeader>
            </Col>
        </Row>
        <PackageDescription>
            {truncate(description, 82)}
        </PackageDescription>
        {outcomes && <PackageOutcomes>
            <FontAwesomeIcon className='fa-fw' icon={faChartLine} /> {truncate(outcomes, 82)}
        </PackageOutcomes>}
        {ads.length > 0 &&
        <>
            <hr />

            <StyledCarousel
                nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
                prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                indicators={ads.length > 1}
                controls={ads.length > 1}
            >   
                {ads.map(ad =>
                    <Carousel.Item>
                        <PackageAds
                            key={ad}
                            ad={ad}
                            adType={adType}
                            currency={currency}
                        />
                    </Carousel.Item>
                )}
            </StyledCarousel>   
        </>
        }

        {showButton && <AddOrderButton onClick={onAdd}>
            Add to Order
        </AddOrderButton>}
    </CardContainer>
}

const budgetDisplay = (min, max, currency = "$") => {
    if (min && max) {
        return `${currency}${min} to ${currency}${max}`
    }
    else if (min) {
        return min ? `from ${currency}${min}` : `to ${currency}${max}`
    }
    else if (max) {
        return `up to ${currency}${max}`
    }
}

const PackageAds = ({
    ad,
    adType,
    currency,
}) => {
    const currentAd = adType.find(type => type.control_id === ad.adformat_control_id);
    const iconLookup = (name) => {
        switch (name) {
            case 'facebook':
                return <StyledAdIcon icon={faFacebook} />
            case 'youtube':
                return <StyledAdIcon icon={faYoutube} />
            case 'google':
                return <StyledAdIcon icon={faGoogle} />
            default:
                break;
        }
    }

    return (
        <CarouselAd className='d-flex'>
            <div> {iconLookup(currentAd?.platform)}</div>
            <div>
                <StyedAdTitle>{currentAd?.label}</StyedAdTitle>
                <StyedAdDesc>
                    {(ad?.min_budget || ad?.max_budget) &&
                        <span>Budget: {budgetDisplay(ad?.min_budget, ad?.max_budget, currency)}</span>}
                    {(ad?.percentage_discount > 0 || ad?.flat_discount > 0) &&
                        <span>Discount: {ad?.percentage_discount ? `${ad?.percentage_discount}%` : `$${ad?.flat_discount}`}</span>}
                    {(ad.start_date || ad.end_date) &&
                        <span>{ad?.start_date || ""} - {ad?.end_date || ""}</span>}
                </StyedAdDesc>
            </div>
        </CarouselAd>
    );
}

export default PackageCard
