import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { ownedBespokeProducts } from '../../lib/api'

export const fetchBespokeProducts = createAsyncThunk('bespoke/fetchBespokeProducts', async (_arg, { getState, dispatch, rejectWithValue }) => {
    try {
        const response = await ownedBespokeProducts()
        dispatch(bespokeProductsUpdated(response.data.data))
        return response.data.data
    } catch (err) {
        if (!err.response?.data) {
            throw err
        }
        return rejectWithValue(err.response.data)
    }
})

const bespokeProductsAdapter = createEntityAdapter({
    // Sort chronologically
    sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
})

export const {
    selectAll: selectAllBespokeProducts,
    selectIds: selectBespokeProductIds,
    selectById: selectBespokeProductsById,
} = bespokeProductsAdapter.getSelectors((state) => state.bespoke_products)

const bespokeSlice = createSlice({
    name: 'bespoke_products',
    initialState: bespokeProductsAdapter.getInitialState({
        bespoke_products: [],
        status: 'idle',
        error: {},
    }),
    reducers: {
        bespokeProductsUpdated(state, action) {
            state.bespoke_products = action.payload
        },
        bespokeProductUpdated(state, action) {
            bespokeProductsAdapter.upsertOne(state, action.payload)
        },
        bespokeProductDeleted: bespokeProductsAdapter.removeOne,
        bespokeProductsCleared: bespokeProductsAdapter.removeAll,
    },
    extraReducers: {
        [fetchBespokeProducts.pending]: (state, action) => {
            state.status = 'loading'
            state.error = {}
        },
        [fetchBespokeProducts.fulfilled]: (state, action) => {
            if (state.status === 'loading') {
                bespokeProductsAdapter.upsertMany(state, action)
                state.status = 'succeeded'
            }
        },
        [fetchBespokeProducts.rejected]: (state, action) => {
            if (state.status === 'loading') {
                state.status = 'failed'
                state.error = action.payload
            }
        },
    },
})

export const selectBespokeRequestStatus = state => state.bespoke_products.status

export const {
    bespokeProductsUpdated,
    bespokeProductUpdated,
    bespokeProductDeleted,
    bespokeProductsCleared,
} = bespokeSlice.actions

export default bespokeSlice.reducer