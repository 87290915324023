import React, {useEffect, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSync} from '@fortawesome/free-solid-svg-icons'
import {DevTool} from "@hookform/devtools";
import {FormProvider, useForm} from "react-hook-form";
import {Alert, Button, Col, Container, Form, Nav, Row, Spinner, Tab} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import {useDispatch, useSelector} from 'react-redux'
import {Link, Redirect, useHistory, useParams} from 'react-router-dom'
import Chrome from '../app/layout/Chrome'
import {updateSettings} from "../lib/api";
import FacebookCustomAudiences from '../features/organisation_settings/containers/FacebookCustomAudiences'
import FacebookProductPermissions from '../features/organisation_settings/containers/FacebookProductPermissions'
import GoogleProductPermissions from '../features/organisation_settings/containers/GoogleProductPermissions'
import GoogleRemarketingAudiences from '../features/organisation_settings/containers/GoogleRemarketingAudiences'
import OrganisationWallets from '../features/wallets/OrganisationWallets'
import {
    selectOrganisation,
    selectReadPermissions,
    selectStatus,
    updateOrganisation
} from '../features/organisation_settings/organisationSettingsSlice'
import {
    clearSession,
    refreshFacebookData,
    refreshGoogleData,
    selectAdTypes,
    selectUser
} from '../features/session/sessionSlice'
import FacebookSavedAudiences from '../features/organisation_settings/containers/FacebookSavedAudiences'
import FacebookLookalikeAudiences from '../features/organisation_settings/containers/FacebookLookalikeAudiences'
import MetricPermissions from "../features/organisation_settings/components/MetricPermissions";
import AdSettings from '../features/organisation_settings/components/AdSettings';
import Breadcrumbs from "./components/Breadcrumbs";
import {reloadAllOrders} from '../features/orders/ordersSlice';
import styled from 'styled-components';
import {
    CHANNELS_MANAGEMENT_FACEBOOK,
    CHANNELS_MANAGEMENT_GOOGLE,
    MANAGE_SUPPLIER_SETTINGS,
    WALLET_MANAGEMENT
} from '../lib/permissions';

const tabActive = {
    color: '#000',
    fontSize: '18px',
    padding: 0,
    margin: '0.5rem 1rem 0.5rem 0',
    borderBottom: '2px solid #7155FF'
}
const tabStyle = {
    color: '#000',
    fontSize: '18px',
    padding: 0,
    margin: '0.5rem 1rem 0.5rem 0',
}

const StyledImage = styled.img`
    width: 100%;
    max-width: 300px;
    display: none;
`;

const StyledLightText = styled.p`
    color: #3A3B4F;
`;

const StyledSync = styled(Button)`
  padding: 0;
  font-size: 12px;
  height: 36px;
  width: 100px;
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection{
    background-color: #7155FF !important;
    border-color: #7155FF !important;
  }
`;

const StyledSave = styled(Button)`
  & ,
  &:hover,
  &:focus,
  &:active,
  &::selection{
    background: #7155FF;
    border-color: #644BF8 !important;
  }
`;

export const METRICS_FORM_KEYS = {
    METRICS_CLICKS: 'clicks',
    METRICS_VIEW_RATE: 'view_rate',
    METRICS_VIEWS: 'views',
    METRICS_IMPRESSIONS: 'impressions',
    METRICS_REACH: 'reach',

    METRICS_CPC: 'cpc',
    METRICS_CPM: 'cpm',
    METRICS_CPV: 'cpv',
    METRICS_CTR: 'ctr',
    METRICS_SPEND: 'spend',
    METRICS_PURCHASE_ROAS: 'total_roas',
    METRICS_CONVERSION_VALUE: 'total_conversion_value',
    METRICS_LIFETIME_BUDGET: 'lifetime_budget',
    METRICS_DAILY_BUDGET: 'daily_budget',

    METRICS_ONLINE_SALES: 'online_sales',
    METRICS_OFFLINE_SALES: 'offline_sales',

    METRICS_WEBADS_TOTAL_CONVERSIONS: 'webads_total_conversions_value',
    METRICS_WEBADS_ASSOCIATED_CONVERSIONS: 'associated_conversions_value',
    METRICS_WEBADS_DIRECT_CONVERSIONS: 'direct_conversions_value',
}

const PERMISSIONS_FORM_KEYS = {
    PERMISSIONS_BID_AMOUNT: 'bid_amount',
    PERMISSIONS_BUDGET: 'budget',
    PERMISSIONS_SKEW_METRICS: 'skew_metrics',
    PERMISSIONS_SKEW_METRICS_RETAILER: 'skew_metrics_retailer',
}

const SupplierSettings = () => {
    const { organisation_id } = useParams()
    let history = useHistory()
    const status = useSelector(selectStatus)
    const dispatch = useDispatch()
    const organisation = useSelector(selectOrganisation)
    const adSettings = organisation?.settings;
    const permissions = organisation?.read_permissions;
    const read_permissions = useSelector(selectReadPermissions)
    const [msg, setMsg] = useState(null);
    const [isSaving, setIsSaving] = useState()
    const [isRefreshingFacebookData, setIsRefreshingFacebookData] = useState(false);
    const [isRefreshingGoogleData, setIsRefreshingGoogleData] = useState(false);
    const adTypes = useSelector(selectAdTypes)
    const user = useSelector(selectUser);
    const userPermissions = user?.role?.permissions ? user?.role?.permissions?.map(p => p.name) : [];
    const canManageChannelFacebook = userPermissions.includes(CHANNELS_MANAGEMENT_FACEBOOK);
    const canManageChannelGoogle = userPermissions.includes(CHANNELS_MANAGEMENT_GOOGLE);
    const canManageWallets = userPermissions.includes(WALLET_MANAGEMENT);


    const [tabKey, setTabKey] = useState('permissions');

    useEffect(() => {
        dispatch(updateOrganisation(organisation_id))
    }, [dispatch, organisation_id]);

    const methods = useForm({
        mode: 'onSubmit',
    });

    if ((status === 'loading' || status === 'idle') && !organisation.id) {
        return <Chrome>
            <Container className="pt-4">
                <div className="text-center">
                <Spinner animation="border" />&nbsp; Loading organisation, please wait...
                </div>
            </Container>
        </Chrome>
    }

    if (!userPermissions.includes(MANAGE_SUPPLIER_SETTINGS)) {
        return <Redirect to="/organisation/settings" />
    }

    const breadcrumbItems = [
        {
            path: "/settings/suppliers",
            title: "Supplier Settings",
        },
        {
            path: `/settings/suppliers/${organisation_id}`,
            title: `${organisation.name}`,
            active: true,
        },
    ];

    const {
        setError,
        getValues,
        handleSubmit,
        clearErrors,
        control,
        setValue,
        register,
        trigger,
        formState,
    } = methods;

    const { touchedFields, isSubmitted , errors } = formState;

    const updateAllSettings = (key) => {
        const currentValues = getValues();
        let selectedKey = null;
        if(key === 'all_margin') {
          selectedKey = 'margin';
        }else if(key === 'all_min_budget') {
          selectedKey = 'min_budget';
        }else {
          return;
        }

        adTypes.forEach(adType => {
          if(currentValues?.[adType?.control_id]) {
            setValue(`${adType.control_id}.${selectedKey}`, currentValues[key], {
              shouldValidate: true,
            });
          }
        });
        setValue(key, '');
    }

    // const handleSave = e => {
    //     e.preventDefault()
    //     dispatch(updateSettings(organisation_id))
    //     history.push('/organisation/settings')
    // }

    const onSubmit = async data => {

        setIsSaving(true);
        setMsg(null);

        const adSettings = {
            margin: {},
            min_budget: {},
            enabled_ads: {},
        };

        adTypes.forEach(adType => {
            if(data?.[adType?.control_id]) {
            adSettings.margin[adType.control_id] = data[adType.control_id].margin;
            if(data[adType.control_id].min_budget){
                adSettings.min_budget[adType.control_id] = data[adType.control_id].min_budget;
            }
            adSettings.enabled_ads[adType.control_id] = data[adType.control_id].enabled;
            }
        });

        const updatedPermissions = {
            metrics: {
                insights: {},
            },
        };
        Object.values(METRICS_FORM_KEYS).forEach( key => {
            updatedPermissions.metrics.insights[key] = data?.[key];
        })
        Object.values(PERMISSIONS_FORM_KEYS).forEach(key => {
            updatedPermissions.metrics[key] = data?.[key];
        });

        const settings = { ...(organisation.settings || {}), ...adSettings };
        const updatedReadPermissions = { ...(read_permissions || {}), ...updatedPermissions };

        try {
            const res = await updateSettings(organisation.id, {
                settings,
                read_permissions: updatedReadPermissions,
            });
            setMsg({
                type: 'success',
                body: 'Settings have been saved successfully.',
            });
            dispatch(reloadAllOrders());

        } catch (e) {
            if (e.response.status === 403) {
            dispatch(clearSession());
            history.push("/login");
            } else {
            const errors = e?.response?.data?.errors;
            const keys = Object.keys(errors || {});
            if (!errors || !keys.length) {
                setMsg({
                type: "danger",
                body: "Could not save the settings, please try again.",
                });
            } else {
                keys.forEach((errorKey) => {
                const key = errorKey.replace('settings.', '');
                const splitKey = key.split('.');
                if(splitKey.length === 1) {
                    setError(splitKey[0], {
                    type: "manual",
                    message: errors[`settings.${key}`][0],
                    });
                }
                });
            }
            }

        } finally {
            setIsSaving(false);
        }
    };

    const handleFacebookRefreshData = async () => {
        setIsRefreshingFacebookData(true);
        try {
            await dispatch(refreshFacebookData());
            setIsRefreshingFacebookData(false);
        } catch (e) {
            setIsRefreshingFacebookData(false);
        }
    }

    const handleGoogleRefreshData = async () => {
        setIsRefreshingGoogleData(true);
        try {
            await dispatch(refreshGoogleData());
            setIsRefreshingGoogleData(false);
        } catch (e) {
            setIsRefreshingGoogleData(false);
        }
    }

    return (
        <>
            {process.env.REACT_APP_DEBUG_MODE === "true" && (
                <DevTool control={control} />
            )}
            <Helmet>
                <title>
                    Manage {organisation.name} - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container className="pt-4">
                        <FormProvider {...methods}>
                            <Breadcrumbs items={breadcrumbItems}/>
                            <Link
                                as='span'
                                key='organistion_settings'
                                variant="light"
                                to='/settings/suppliers'
                            >&#8592; Back</Link>
                            <Form onSubmit={handleSubmit(onSubmit)}>

                                {msg && <Alert className="mt-3" variant={msg.type}>{msg.body}</Alert>}

                            <Row className="mb-3">
                                <Col>
                                    <div className="d-flex pt-3 pb-3 justify-content-between">
                                        <div>
                                            <h3>Supplier Settings: {organisation.name}</h3>
                                        </div>
                                            {organisation.logo && <div className="d-flex">
                                                <StyledImage
                                                    src={organisation.logo}
                                                    alt={`${organisation.name} logo`}
                                                />
                                            </div>}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="my-3 align-items-center">
                                <Col>
                                    <StyledLightText className='m-0'>Customise settings for {organisation.name}</StyledLightText>
                                </Col>
                                <Col>
                                    <StyledSave
                                        variant="primary"
                                        className="mr-auto px-5 py-2 float-right" 
                                        type="submit"
                                        size="md" 
                                        disabled={isSaving || !!Object.keys(errors).length}>
                                        <span className="small d-flex">
                                            {isSaving &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="mr-2"
                                                />}<span>Save Changes</span>
                                        </span>
                                    </StyledSave>
                                </Col>
                            </Row>

                            <Tab.Container id="left-tabs-example" activeKey={tabKey} onSelect={key => setTabKey(key)}>
                                <Row>
                                    <Col sm={12}>
                                        <Nav className='mb-3'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="permissions" style={tabKey==='permissions'?tabActive:tabStyle} >Permissions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="ad-type" style={tabKey === 'ad-type' ? tabActive : tabStyle} >Ad Type</Nav.Link>
                                            </Nav.Item>
                                            {canManageChannelFacebook && <Nav.Item>
                                                <Nav.Link eventKey="facebook" style={tabKey==='facebook'?tabActive:tabStyle} >Facebook</Nav.Link>
                                            </Nav.Item>}
                                            {canManageChannelGoogle && <Nav.Item>
                                                <Nav.Link eventKey="google" style={tabKey==='google'?tabActive:tabStyle} >Google</Nav.Link>
                                            </Nav.Item>}
                                            <Nav.Item>
                                                <Nav.Link eventKey="wallets" style={tabKey==='wallets'?tabActive:tabStyle} >Wallets</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="permissions">
                                                <Row className="mb-3">
                                                    <Col>
                                                        <div className="rounded">
                                                            <MetricPermissions permissionsData={permissions} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="ad-type">
                                                <Row className="mb-3">
                                                    <Col>
                                                        <div className="rounded">
                                                            <h4>Extension Channels</h4>
                                                            <p>Pro tip: Any value that you override for the supplier in this table, will have a blue border around it.</p>
                                                            <AdSettings adTypes={adTypes} adSettings={adSettings} updateAllSettings={updateAllSettings} isSupplierSettingsPage={true} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            {canManageChannelFacebook && <Tab.Pane eventKey="facebook">
                                                <Row>
                                                    <Col>
                                                        <div className="rounded">
                                                            <div className="d-flex align-items-center">
                                                                <h3 className="flex-grow-1">Facebook Permissions</h3>
                                                                <StyledSync onClick={handleFacebookRefreshData}>
                                                                    <FontAwesomeIcon
                                                                        className={isRefreshingFacebookData ? 'fa-spin' : ''}
                                                                        icon={faSync} size="1x"
                                                                    /> Sync
                                                                </StyledSync>
                                                                
                                                            </div>
                                                            <FacebookCustomAudiences
                                                                controlId={`${organisation.id}-facebook-custom-audiences`}
                                                            />
                                                            <FacebookLookalikeAudiences
                                                                controlId={`${organisation.id}-facebook-lookalike-audiences`}
                                                            />
                                                            <FacebookSavedAudiences
                                                                controlId={`${organisation.id}-facebook-saved-audiences`}
                                                            />
                                                            <FacebookProductPermissions
                                                                controlId={`${organisation.id}-facebook-products`}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>}
                                            {canManageChannelGoogle && <Tab.Pane eventKey="google">
                                                <Row>
                                                    <Col>
                                                        <div className="rounded">
                                                            <div className="d-flex align-items-center">
                                                                <h3 className="flex-grow-1">Google Permissions</h3>
                                                                <StyledSync onClick={handleGoogleRefreshData}>
                                                                    <FontAwesomeIcon
                                                                        className={isRefreshingGoogleData ? 'fa-spin' : ''}
                                                                        icon={faSync} size="1x"
                                                                    /> Sync
                                                                </StyledSync>
                                                                
                                                            </div>
                                                            <GoogleRemarketingAudiences
                                                                controlId={`${organisation.id}-google-remarketing-audiences`}
                                                            />
                                                            <GoogleProductPermissions
                                                                controlId={`${organisation.id}-google-products`}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>}
                                            {canManageWallets && <Tab.Pane eventKey="wallets">
                                                <Row className="mb-3">
                                                    <Col>
                                                        <div className="rounded">
                                                            <OrganisationWallets organisation={organisation} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>}
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Form>
                    </FormProvider>
                </Container>
            </Chrome>
        </>
    )
}

export default SupplierSettings
