import axios from '../../axios'


export async function getAllOrders(args) {
    let params = new URLSearchParams()
    if(args){
        Object.keys(args).map((key) => {
            if(key === 'filters'){
                Object.keys(args[key]).map((subkey) => {
                    params.set(subkey, encodeURIComponent(args[key][subkey]))
                })
            } else {
                params.set(key, args[key])
            }
        })
    }

    return axios.get(`/orders/all?${params.toString()}`, {
        timeout: 120000,
    });
}

export async function getOwnedOrders(session) {
    return axios.get(`/orders/owned`);
}

export async function getLinkedOrders(session) {
    return axios.get(`/orders/linked`);
}

export async function getOrder(order_id) {
    return axios.get(`/order/${order_id}`);
}


export async function createOrder(form, organisation_id) {
    return axios.post(`/orders/organisations/${organisation_id}`, form);
}

export async function updateOrder( form, order_id) {
    return axios.put(`/orders/${order_id}`, form);
}

export async function updateLiveOrder( form, order_id) {
    return axios.patch(`/order/${order_id}/live`, form);
}

export async function approveOrder(order_id, liveEdit=false) {
    if (liveEdit) {
        return axios.get(`/order/${order_id}/approve/live`);
    }

    return axios.get(`/order/${order_id}/approve`);
}

export async function rejectOrder(order_id, arg) {
    return axios.post(`/order/${order_id}/reject`, arg);
}

export async function getRequestReviewChanges(order_id) {
    return axios.get(`/order/${order_id}/requestreview/changes`)
}

export async function requestOrderReview(order_id, reviewee_id, message) {
    return axios.post(`/order/${order_id}/requestreview/${reviewee_id}`, { message });
}

export async function publishOrder(order_id) {
    return axios.get(`/order/${order_id}/publish`)
}

export async function deleteOrder(order_id) {
    return axios.delete(`/order/${order_id}`)
}

export async function archiveOrder(order_id) {
    return axios.get(`/order/${order_id}/archive`)
}

export async function unarchiveOrder(order_id) {
    return axios.get(`/order/${order_id}/unarchive`)
}

export async function duplicateOrder(order_id) {
    return axios.get(`/order/${order_id}/duplicate`);
}


export async function getProducts(productSetId, query = '', after = undefined, limit = 25) {
    let params = new URLSearchParams()
    params.set('product_set_id', productSetId);
    params.set('query', query)
    params.set('limit', limit);

    if (after) {
        params.set('after', JSON.stringify(after))
    }

    return axios.get(`/products?${params.toString()}`);
}


export async function getCampaignInsightsBreakdown(platform, order_id, ad_id) {
	return axios.get(`/campaign/${platform}/${order_id}/${ad_id}/metrics`);
}

export async function checkAvailableDateSlotsForWebSpace(space_id, supplier_id, start_date,
    stop_date, adId, { onUploadProgress } = { onUploadProgress: () => { } }) {
    const form = new FormData()
    form.set('space_id', space_id)
    form.set('supplier_id', supplier_id)
    form.set('start_date', start_date)
    form.set('end_date', stop_date)
    form.set('order_ad_id', adId)
    return axios.post(`/collab-web/get-unavailable-dates`, form, {})
}

export async function createStandardAudienceTemplate(name, platform, audience) {
    return axios.post(`/standard-audience-template`, {
        name,
        platform,
        audience,
    });
}
