import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { selectSession } from '../session/sessionSlice'
import { inviteUser } from '../../lib/api'
import Alert from 'react-bootstrap/Alert'
import { toast } from "react-toastify";
import Select from "react-select";

const selectStyles = {
  option: (styles) => ({
    ...styles,
    backgroundColor: "while",
    color: "black",
    overflow: "wrap",
  }),
};

const InviteForm = () => {
  let history = useHistory()
  const session = useSelector(selectSession)
  const [userRoles, setUserRoles] = useState(session?.available_roles.map(r => {return {value: r.id, label: r.name}}));
  const [form, setForm] = useState({
    email: '',
    first_name: '',
    last_name: '',
    jobtitle: '',
    role: ''
  })
  const [selectedUserRole, setSelectedUserRole] = useState(userRoles.find(r => r.value === form?.role?.id));
  const [info, setInfo] = useState([]);

  return (
    <Form>
      {info.length > 0 && (
        <Alert variant="danger">{info.join('\n')}</Alert>
      )}
      <Form.Group controlId="name">
        <Form.Label>Employee first name</Form.Label>
        <Form.Control
          type="text"
          required
          onChange={e => setForm({ ...form, first_name: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="name">
        <Form.Label>Employee last name</Form.Label>
        <Form.Control
          type="text"
          required
          onChange={e => setForm({ ...form, last_name: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="name">
        <Form.Label>Employee job title</Form.Label>
        <Form.Control
          type="text"
          required
          onChange={e => setForm({ ...form, jobtitle: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="role">
        <Form.Label>Employee system role</Form.Label>
        <Select
          placeholder="Select User Role"
          styles={selectStyles}
          value={selectedUserRole}
          onChange={e => setForm({ ...form, role: e.value })}
          options={userRoles}
        />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          required
          onChange={e => setForm({ ...form, email: e.target.value })}
        />
      </Form.Group>

      <Form.Group>
        <Button
          style={session?.theme?.components?.button}
          onClick={e => {
            e.preventDefault()
            setInfo([]);
            if(e.target.form === undefined) {return}
            if (!e.target.form.reportValidity()) {
              return
            }
            if(form.role === undefined || form.role === null || form.role === ''){
              setInfo(["Please select a valid system role."]);
              return;
            }

            inviteUser(form.first_name, form.last_name, form.email, form.role, form.jobtitle).then(resp => {
              if (resp.data.data.connected) {
                if (resp.data.data?.info) {
                  setInfo([resp.data.data.info]);
                }
              } else {
                toast.success("The invitation has been sent out to the employee");
                history.push("/");
              }
            })
          }}
        >
          <FontAwesomeIcon icon={faPaperPlane} /> Send Invite
        </Button>
      </Form.Group>
    </Form>
  )
}

export default InviteForm
