import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Button, Form, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { postFacebookAsset } from '../../../../../../lib/api'
import Errors from '../../../Errors'
import withSingleFileUpload from '../withSingleFileUploader'

const RatioContainer = styled.div`
    margin-bottom: 32px;
    width: 100%;
`

const CheckContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`

const MediaContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 0.5rem;
`

const MediaWrapper = styled.div`
    cursor: pointer;
`

const Spacer = styled.div`
    margin-right: 44px;
`

const ProgressContainer = styled.div`
    ${props => props.visible ? 'display: flex;' : 'display: none;'}
    width: 110px;
    margin-left: auto;
    margin-right: auto;
`

const Header = styled.h6`
    font-weight: bold;
    background-color: #fafafa;
    border-radius: 4px;
    padding: 3px 7px;
    text-align: center;
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: start;
    align-items: start;
    flex-direction: column;
`

const AssetActionButton = styled(Button)`
    background-color: #f2f2f2;
    color: #1c1e21;
    border: 0;
    display: inline;

    &:active,
    &:focus {
        background-color: #f2f2f2;
        filter: brightness(0.90)
        border: 0;
        color: #1c1e21;
    }

    &:disabled {
        background-color: #f2f2f2;
        filter: brightness(1.10)
        border: 0;
        color: #1c1e21;
        cursor: not-allowed;
    }
`

const UploadButton = styled(AssetActionButton)`
    max-width: 150px;
`

const SingleUploadButton = withSingleFileUpload(UploadButton)

/** A selector that selects the asset ratio file.
 * @param {string} controlId Unique form string associated with the element.
 * @param {bool} readOnly Should the component be modifiable?
 * @param {string} header The label for the asset ratio.
 * @param {array} options The list of options
 * @param {element} mediaComponent
 * @param {} value
 * @param {} onChange
*/
const RatioSelect = ({
    controlId,
    readOnly = true,
    header,
    ratio,
    options = [],
    mediaComponent,
    value = {},
    onChange,
    onDelete,
    errors = [],
    placements = [],
    automaticPlacements = false,
    accept,
}) => {
    const [uploadError, setUploadError] = useState([])
    const [uploadProgress, setUploadProgress] = useState(null);
    const handleDelete = e => onDelete(value)
    const fileRef = useRef(null);

    const handleRatioChange = ratio => onChange({ ratio })
    const handleAssetChange = asset => {
        setUploadProgress(0)
        postFacebookAsset(asset, {
            placements: placements.map(placement => placement.id),
            automaticPlacements,
            ratio,
            onUploadProgress: (p) => setUploadProgress((p.loaded / p.total) * 100),
        })
            .then(resp => {
                onChange(resp.data.data)
                setUploadError([])
            })
            .catch(e => {
                if (e.response.status === 400) {
                    setUploadError(e.response.data.error)
                } else {
                    setUploadError('Something bad happened. Please try again later.')
                }

            })
            .finally(() => setUploadProgress(null))
    }

    const allErrors = [...errors, ...uploadError];

    return <RatioContainer>
        <Form.Group as="section">
            <Header>{header}</Header>
            <CheckContainer>
                {options.map(option =>
                    <Form.Check
                        key={option.id}
                        id={`${controlId}-${option.id}`}
                        type="radio"
                        inline
                        label={option.label}
                        readOnly={readOnly || value.id}
                        disabled={readOnly || value.id}
                        checked={option.value === value.ratio}
                        onChange={() => handleRatioChange(option.value)}
                    />)}
            </CheckContainer>
            <MediaContainer>
                <Spacer></Spacer>
                <MediaWrapper onClick={() => fileRef.current.click()}>
                    {mediaComponent}
                </MediaWrapper>
                <ButtonContainer>
                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                        <AssetActionButton onClick={handleDelete} disabled={readOnly || !value.id}>
                            <FontAwesomeIcon fixedWidth icon={faTimes} />
                        </AssetActionButton>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={<Tooltip>Replace</Tooltip>}>
                        <SingleUploadButton
                            ref={fileRef}
                            readOnly={readOnly}
                            disabled={readOnly}
                            onChange={handleAssetChange}
                            value={value}
                            accept={accept}
                        >
                            <FontAwesomeIcon fixedWidth icon={faArrowRightArrowLeft} />
                        </SingleUploadButton>
                    </OverlayTrigger>

                </ButtonContainer>
            </MediaContainer>
            <ProgressContainer visible={uploadProgress !== null}>
                <ProgressBar
                    className="mb-2"
                    variant={uploadProgress === 100 ? "success" : "primary"}
                    animated
                    now={uploadProgress}
                    style={{ width: '150px' }}
                />
            </ProgressContainer>
            <Form.Control.Feedback
                className={allErrors.length > 0 ? "d-block text-center" : "d-none"}
                type="invalid"
            >
                <Errors errors={allErrors} />
            </Form.Control.Feedback>
        </Form.Group>
    </RatioContainer >
}

RatioSelect.propTypes = {
    controlId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    header: PropTypes.string,
    options: PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    }),
    mediaComponent: PropTypes.element,
    value: PropTypes.bool,
    onChange: PropTypes.func,
}

export default RatioSelect
