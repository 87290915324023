import { faGlobeAsia } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './bespoke_product.module.scss'
import { selectSession } from '../../../session/sessionSlice'
import { useSelector } from 'react-redux'

const BespokeProduct = ({
    headline,
    bodycopy,
    media,
}) => {
    const session = useSelector(selectSession)
    return (
    <div id="ad_bp" className={styles.ad_container}>
        <div id="ad_media" className={styles.ad_media}>
            <img src={media} alt="" />
        </div>
        <hr className='my-3' />
        <div id="ad_headline" className={styles.ad_name}>
            <p className='font-bold'>Name: {headline}</p>
        </div>
        <div id="ad_description" className={styles.ad_description}>
            <p>Description: {bodycopy}</p>
        </div>
    </div>
    );
}

export default BespokeProduct