import React, {useEffect, useRef} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import {useDispatch, useSelector} from 'react-redux'
import ConnectedOrderFilter from '../features/orders/ConnectedOrderFilter'
import ConnectedArchivedOrdersGrid from '../features/orders/ConnectedArchivedOrdersGrid'
import {fetchOrders, resetFilter} from '../features/orders/ordersSlice'
import Chrome from './layout/Chrome'
import OrderPagination from '../features/orders/components/OrderPagination'
import {cachedFetchBrands, selectAllBrands} from "../features/brands/brandsSlice";
import {cacheFetchRetailers, selectAllRetailers} from "../features/retailers/retailersSlice";

const ArchivedOrders = () => {
    const status = useSelector(state => state.orders.status)
    const dispatch = useDispatch()
    const ordersTotal = useSelector(state => state.orders?.total)
    const ordersPerPage = useSelector(state => state.orders?.per_page)
    const state = useSelector(state => state)
    const pages = useSelector(state => state.orders?.pages)
    const currentPage = useSelector(state => state.orders?.current_page)
    const listTopRef = useRef(null)
    const orderFilters = useSelector(state => state.orders?.filterFields)

    const retailers = useSelector(selectAllRetailers);
    const suppliers = useSelector(selectAllBrands);
    const organisations = [
        ...retailers.map(retailer => ({...retailer, label: `${retailer.name} (Retailer)`})),
        ...suppliers.map(supplier => ({...supplier, label: `${supplier.name} (Supplier)`})),
    ]

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchOrders({page: currentPage, onlyArchived: true}))
        }
        dispatch(resetFilter())
    }, [status, dispatch])

    useEffect(() => {
        dispatch(resetFilter())
    }, [currentPage])

    useEffect(() => {
        dispatch(cachedFetchBrands());
        dispatch(cacheFetchRetailers());
    }, []);

    const handlePaginationClick = async (val) => {
        if(status !== 'loading' && val && val !== currentPage){
            dispatch(fetchOrders({page: val, filters: orderFilters, onlyArchived: true}));
            listTopRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            }) 
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Archived Orders - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>

            <Chrome>
                <Container className="pt-4">
                    <Row>
                        <Col>
                            <h3>Archived Orders</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ConnectedOrderFilter onlyArchived={true} organisations={organisations} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <ConnectedArchivedOrdersGrid />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col><OrderPagination pages={pages} currentPage={currentPage} onClick={v => handlePaginationClick(v)} disabled={status === 'loading'} /></Col>
                    </Row>
                </Container>
            </Chrome>
        </>
    )
}

export default ArchivedOrders
