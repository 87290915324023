import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import LoginForm from '../features/session/LoginForm'
import { loginUser } from '../features/session/sessionSlice'
import { getBrandingAssets } from '../lib/api'
import Footer from './layout/Footer'
import styles from './login.module.scss'
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch()
  const status = useSelector(state => state.session.status)
  const errors = useSelector(state => state.session.errors)
  const registrationState = useSelector(state => state.session?.registration)

  let location = useLocation()
  let params = new URLSearchParams(location.search)
  const [theme, setTheme] = useState({})

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  })

  useEffect(() => {
    if (params.has('id')) {
      getBrandingAssets(params.get('id')).then(resp => {
        if (resp?.data?.data) {
          setTheme(resp.data.data)
        }
      })
    }
  }, [])

  useEffect(() => {
    if(registrationState?.outcome == 'success') {
      toast.success(`Successfully registered, please use the login form to start using ${process.env.REACT_APP_NAME}`);
    }
  }, [registrationState])

  const login = async e => {
    e.preventDefault()

    dispatch(
      loginUser({
        email: credentials.email,
        password: credentials.password,
      })
    )
  }

  if (status === 'authenticated') {
    return <Redirect to="/" />
  }

  return (
    <>
      <Helmet>
        <title>{`Login - ${process.env.REACT_APP_NAME}`}</title>
      </Helmet>

      <main
        className="py-4 mt-4"
        style={{ backgroundColor: theme?.styles?.background }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              <Card>
                <Card.Body>
                  <div className={styles.header}>
                    {theme?.logo?.url && <img src={theme.logo.url} alt="" />}
                    <h4 className="text-center my-4">User login</h4>
                  </div>
                  <LoginForm
                    credentials={credentials}
                    onChange={credentials => setCredentials(credentials)}
                    onSubmit={login}
                    errors={errors}
                  />
                  {status === 'loading' && (
                    <Row className="text-center d-flex align-items-center justify-content-center">
                      <Spinner animation="border" />
                    </Row>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default Login
