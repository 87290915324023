import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap"
import PropTypes from "prop-types";
import styles from './bespokeFields.module.scss'

const ImageField = ({
    field,
    index,
    updateLayoutData,
    layoutData,
    idHelper,
    errors,
}) => {

    return (
        <Row>
            <Col md={6}>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Name'}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={layoutData[index]?.title ?? ""}
                        onChange={(e) => updateLayoutData({ id: idHelper('image_'+e.target.value), title: e.target.value}, index)}
                        isInvalid={!layoutData[index]?.title && errors.length > 0}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Default'}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        type="text"
                        value={layoutData[index]?.notice ?? ""}
                        onChange={(e) => updateLayoutData({notice: e.target.value}, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Height'}>
                    <Form.Label>Min Height (px)</Form.Label>
                    <Form.Control
                        type="number"
                        value={layoutData[index]?.min_height ?? ""}
                        onChange={(e) => updateLayoutData({ min_height: parseInt(e.target.value) }, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Height'}>
                    <Form.Label>Max Height (px)</Form.Label>
                    <Form.Control
                        type="number"
                        value={layoutData[index]?.max_height ?? ""}
                        onChange={(e) => updateLayoutData({ max_height: parseInt(e.target.value) }, index)}
                    />
                </Form.Group>
            </Col>
            <Col md={6}>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Min-Width'}>
                    <Form.Label>Min Width (px)</Form.Label>
                    <Form.Control
                        type="number"
                        value={layoutData[index]?.min_width ?? ""}
                        onChange={(e) => updateLayoutData({min_width: parseInt(e.target.value)}, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Max-Width'}>
                    <Form.Label>Max Width (px)</Form.Label>
                    <Form.Control
                        type="number"
                        value={layoutData[index]?.max_width ?? ""}
                        onChange={(e) => updateLayoutData({max_width: parseInt(e.target.value)}, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-Info'}>
                    <Form.Label>Info</Form.Label>
                    <Form.Control
                        type="text"
                        value={layoutData[index]?.info ?? ""}
                        onChange={(e) => updateLayoutData({ info: e.target.value }, index)}
                    />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={field.type + index + '-multiline'}>
                    <Form.Label>Number of attachments</Form.Label>
                    <Form.Control
                        type="number"
                        min="1"
                        value={layoutData[index]?.max_images ?? ""}
                        onChange={(e) => updateLayoutData({ 
                            max_images: parseInt(e.target.value), 
                            max_videos: parseInt(e.target.value),
                            max_attachments: parseInt(e.target.value)
                        }, index)}
                    />
                </Form.Group>

                {/* <p>Aspect Ratio</p>
                <Form.Group className={styles.group_style} controlId={type + index + '-multiline'}>
                    <Form.Label>X:</Form.Label>
                    <Form.Check className={styles.form_check} type="radio" label="'1.1'" name={type + index + '-ar-X'} />
                    <Form.Check className={styles.form_check} type="radio" label="'1.9:1'" name={type + index + '-ar-X'} />
                </Form.Group>
                <Form.Group className={styles.group_style} controlId={type + index + '-multiline'}>
                    <Form.Label>Y:</Form.Label>
                    <Form.Check className={styles.form_check} type="radio" label="'1.1'" name={type + index + '-ar-Y'} />
                    <Form.Check className={styles.form_check} type="radio" label="'1.9:1'" name={type + index + '-ar-Y'} />
                </Form.Group> */}
            </Col>
        </Row>
    );
}

ImageField.propTypes = {
    field: PropTypes.object,
    index: PropTypes.number,
    updateLayoutData: PropTypes.func,
    layoutData: PropTypes.func,
    idHelper: PropTypes.func,
    errors: PropTypes.array,
}

export default ImageField