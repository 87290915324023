import axios from "axios";
import LogRocket from "logrocket";
import { showDialog } from "../app/appSlice";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";
const instance = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  headers: {
    common: {
      "Content-Type": "multipart/form-data",
    }
  },
});

// This is to setup an already existing instance, the reason for this function is to avoid infinite loop
// when importing axios instance in /api files which in turn getting imported in each redux slice file
// TODO: refactor this once we have the session id or token stored in cookies instead of the "store"
export const axiosSetUp = (instance, store) => {
  // each index represents the timeout for each try: [2,3] means 2s for first try and 3s for the second one, etc.
  const retryTimeouts = [2,3];
  const errorCodes = [500, 501, 502, 503, 504];

  instance.interceptors.request.use((config) => {
    const token = store.getState().session?.authToken;

    if (!!token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(null, async (error) => {

    const { code, config, response: res } = error;

    // if it's not timeout error and it's not one of the supported http response codes for retry. then reject and do nothing
    if (!res || code != 'ECONNABORTED' && !errorCodes.includes(res.status)) {
      return Promise.reject(error);
    }

    if (config.retryCount != retryTimeouts.length) {
      // TODO: change condition if required
      // TODO: Check if we need to prevent retry based on external value while calling instance.method
      const delay =
        retryTimeouts[!config.retryCount ? 0 : config.retryCount] * 1000;

      await new Promise((resolve) => setTimeout(resolve, delay));

      config.retryCount = !config.retryCount ? 1 : config.retryCount + 1;

      return instance(error.config);
    } else {
      // TODO: Change the message or handle each message type based on status inside the dialog after checking if it's axios error
      store.dispatch(
        showDialog({
          type: "error",
          title: "Something went wrong",
          body: (error?.message) ? error.message : "An unrecognized error occured. Please try again after a few minutes.",
          refId: error?.refId
        })
      );

      return Promise.reject(error);
    }
  });
};

export default instance;
