import { useState } from 'react'
import { useEffect } from 'react'
import { Col, Container, Row, Button, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import BespokeEditor from '../features/bespoke/components/BespokeEditor'
import BespokeForm from '../features/bespoke/components/BespokeForm'
import { clearBespokeProduct, selectBespokeProduct, selectErrors, upsertBespokeProduct } from '../features/bespoke/bespokeFormSlice'
import Chrome from './layout/Chrome'
import { useHistory } from 'react-router-dom'
import { clearOrder } from '../features/order_form/orderFormSlice'
import { useSelector } from 'react-redux'
import { refreshSession } from '../features/session/sessionSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons'

const Header = styled.h1`
    margin-bottom: 70px;
`

const ButtonContainer = styled.div`
    text-align: right;
    display: flex;
    gap: 8px;
`

const TextContainer = styled.section`
    border: 1px solid #ECECEC;
    border-radius: 8px;
    background-color: #F2F2F2;
    padding: 20px;
    margin-bottom: 1rem;
    p {
        margin-bottom: 0;
    }
`

const PrimaryButton = styled(Button)`
    background-color: #5995ED;
    border: 1px solid #5995ED;
    padding: 12px 50px;
`

const SecondaryButton = styled(Button)`
    margin-left: auto;
    border-color: #777785;
    color: #777785;
    background-color: #fff;
    padding: 12px 45px;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      background-color: #777785;
      color: #fff;
      border: 1px solid #777785 !important;
    }
    &:disabled {
        opacity: 0.5;
        background-color: transparent !important;
        border: 1px solid #777785 !important;
        color: #777785 !important;
    }
`

const PreviewButton = styled(SecondaryButton)`
    color: #5995ED;
    border-color: #5995ED;
    margin-left: 0;
`

const StyledModal = styled(Modal)`
    .modal-header {
        background-color: #F8F8F8;
        border-bottom: 0;
        .modal-title {font-size: 18px;}
    }
    .modal-body {
        .title {text-align: center;}
        article {
            margin: auto;
        }
    }
    .modal-footer {
        button {margin: 10px auto;}
        border: 0;
    }
`;

const StyledButton = styled(Button)`
    margin-left: auto;
    border-color: #777785;
    color: #777785;
    background-color: #fff;

    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
      background-color: #777785;
      color: #fff;
      border: 1px solid #777785 !important;
    }
    &:disabled {
        opacity: 0.5;
        background-color: transparent !important;
        border: 1px solid #777785 !important;
        color: #777785 !important;
    }
`

const BespokeFormContainer = styled(Row)`
    ${props => props.errors && `
        outline: 1px red solid;
        outline-offset: 10px;
        border-radius: 5px;
    `}
`

const Preview = styled.div`
    border-radius: 4px;
    background: #F8F8F8;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    transition: 0.3s;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    .img-container {
        width: 100%;
        height: 192px;
        text-align: center;
        background: #fff;
        padding: 10px;
        overflow: hidden;
        img {
            height: 100%;
        }
    }
    .bespoke-title {
        font-weight: bold;
        padding-top: 5px;
    }
    p {
        font-size: 15px;
    }
    hr {
        border-top: 1px solid #B9B9C0;
        width: 100%;
    }
    .descriptor {
        display: flex;
        svg {
            margin: 0 10px;
            color: #B9B9C0;
        }
        strong {
            font-size: 14px;
        }
        p {
            font-size: 12px;
        }
    }
`

const CreateBespokeProduct = ({
    edit = false,
}) => {
    let history = useHistory();
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const bespoke = useSelector(selectBespokeProduct);
    const errors = useSelector(selectErrors);
    useEffect(() => {
        dispatch(clearBespokeProduct())
        dispatch(clearOrder())
    }, [])

    const truncateString = (str, num) => {
        if (str.length > num) {
          return str.slice(0, num) + "...";
        } else {
          return str;
        }
    };

    return <>
        <Helmet>
            <title>
                New Bespoke Product - {process.env.REACT_APP_NAME}
            </title>
        </Helmet>

        <Chrome>
            <Container className="pt-4 px-5" fluid>
                <Row className="mb-3">
                    <Col>
                        <Header>Bespoke Products Editor</Header>
                    </Col>
                </Row>
                <BespokeFormContainer className="mb-3" errors={(Object.keys(errors)).length > 0}>
                    <Col md={3}>
                        <BespokeForm />
                    </Col>
                    <Col md={9}>
                        <TextContainer>
                            <ButtonContainer>
                                <SecondaryButton onClick={() => {
                                    dispatch(clearBespokeProduct());
                                    history.push('/organisation/bespokesettings')
                                }}>
                                    Cancel
                                </SecondaryButton>
                                <PreviewButton onClick={() => setShow(!show)}>
                                    Preview Listing
                                </PreviewButton>
                                <PrimaryButton
                                    onClick={() => {
                                        dispatch(upsertBespokeProduct())
                                            .then((action) => {
                                                if (!action.meta.rejectedWithValue) {
                                                    dispatch(refreshSession());
                                                    history.push('/organisation/bespokesettings')
                                                }
                                            })
                                    }}
                                >
                                    Save
                                </PrimaryButton>
                            </ButtonContainer>
                        </TextContainer>
                        <TextContainer>
                            <strong>Custom Properties</strong>
                            <p>Create the card for each property which supplier can answer the questions.</p>
                        </TextContainer>
                        <TextContainer>
                            <BespokeEditor fields={bespoke.fields} />
                        </TextContainer>
                    </Col>
                </BespokeFormContainer>

                <StyledModal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview Your Bespoke Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='title'>This is how your bespoke product will be advertised to supplier</p>
                        <Preview>
                            <div className='img-container'>
                                <img src={bespoke?.placeholder_images?.large || "https://upload.wikimedia.org/wikipedia/commons/5/51/Annie_Spratt_2016-03-06_%28Unsplash_reU05EIlNCQ%29.jpg"} alt="" />
                            </div>
                            <p className='bespoke-title'>{bespoke?.label ? truncateString(bespoke?.label, 50) : "Title"}</p>
                            <p><div style={{display: "inline-block", maxWidth: "450px", wordBreak: "break-all"}}>{bespoke?.description ? truncateString(bespoke.description, 82) : "Description"}</div></p>
                            <hr />
                            <Row>
                                <Col md="6">
                                    <div className="descriptor">
                                        <div>
                                            <FontAwesomeIcon icon={faClipboardListCheck} />
                                        </div>
                                        <div>
                                            <strong>Max. Media Size</strong>
                                            <p>Image no more than 30MB</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="descriptor">
                                        <div>
                                            <FontAwesomeIcon icon={faClipboardListCheck} />
                                        </div>
                                        <div>
                                            <strong>Media Format</strong>
                                            <p>MP4, MOV or GIF</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="descriptor">
                                        <div>
                                            <FontAwesomeIcon icon={faClipboardListCheck} />
                                        </div>
                                        <div>
                                            <strong>Budget</strong>
                                            <p>Fixed Price</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="descriptor">
                                        <div>
                                            <FontAwesomeIcon icon={faClipboardListCheck} />
                                        </div>
                                        <div>
                                            <strong>Objective</strong>
                                            <p>-</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Preview>
                    </Modal.Body>
                    <Modal.Footer>
                        <StyledButton variant="secondary" onClick={handleClose}>
                            Close Preview
                        </StyledButton>
                    </Modal.Footer>
                </StyledModal>
            </Container>
        </Chrome>
    </>
}

export default CreateBespokeProduct
