import moment from "moment"

export function titleCase(string) {
    return string.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
}

export function snakeCaseToTitleCase(string) {
    return titleCase(string.replaceAll('_', ' '))
}

export function formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function trimEllipses(string, len) {
    if (string.length <= len) {
        return string
    }

    if (typeof string === 'number') {
        return string.toString()
    }

    return string.toString().slice(0, len) + '…'
}

export function strcmp(str1, str2) {
    return ((str1 == str2) ? 0 : ((str1 > str2) ? 1 : -1))
}

export function lastEditedText(date) {
    const momentDate = moment.utc(date);
    const momentNow = moment()
    const duration = moment.duration(momentDate.diff(momentNow))
    return duration.humanize(true)
}

export function truncate(text, len) {
    return text.length > len ? text.slice(0, len) + "..." : text
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
