import React from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const StyleLoader = styled(FontAwesomeIcon)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// TODO: make this global reusable component
const QueryResult = ({ loading, error, children }) => {
  if (error) {
    return <p>ERROR: {error.message}</p>;
  }
  if (loading) {
    return <StyleLoader className="fa-spin my-3" icon={faSpinner} size="2x" />;
  }

  return children;
};

QueryResult.propTypes = {
  loading: propTypes.bool.isRequired,
  error: propTypes.string,
  children: propTypes.node.isRequired,
};

export default QueryResult;
