import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {Card, Col, Container, Form, InputGroup, Modal, Row} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {selectSession} from '../session/sessionSlice'
import styles from './ad_selection_modal.module.scss'
import {clearOrder, orderFieldUpdated, orderFormEditable} from './orderFormSlice'
import $style from './organisation_selection_modal.module.scss'
import {STATUS_PENDING_APPROVAL} from '../../lib/orders'
import {cachedFetchBrands, selectAllBrands} from "../brands/brandsSlice";
import {cacheFetchRetailers, selectAllRetailers} from "../retailers/retailersSlice";

const applyFilters = (organisation, filter) => {
    return organisation.filter(org => !filter.searchTerm || org.name.toLowerCase().includes(filter.searchTerm.toLowerCase()));
}

const OrganisationCard = ({
                              name,
                              logo,
                              onClick,
                          }) => {

  return (
    <Card
      onClick={onClick}
      className={$style.selectionCard}
    >
      {
        logo ?
          <Card.Img
            src={logo}
            alt={`${name} logo`}
            className={$style.selectionCardLogo}
            variant="top"
            />
          :
          <div className={$style.selectionCardDiv}></div>
      }

      <Card.Body className="p-2">
        <h6>{name}</h6>
      </Card.Body>
    </Card>
  )
}

const OrganisationSelectionModal = ({ show = false, onHide = () => { } }) => {
    const session = useSelector(selectSession);
    const history = useHistory();
    const dispatch = useDispatch()
    const user = session?.user;
    const userOrganisation = user.active_organisation || {};
    const [filters, setFilters] = useState({
        searchTerm: ''
    })

    const retailers = useSelector(selectAllRetailers);
    const suppliers = useSelector(selectAllBrands);

    useEffect(() => {
        dispatch(cachedFetchBrands());
        dispatch(cacheFetchRetailers());
    }, []);

    const [filteredOrganisations, setFilteredOrganisations] = useState({
        retailers: [],
        suppliers: [],
    });

    useEffect(() => {
        const nretailers = _.orderBy(retailers.map(e => ({...e, type: 'retailer'})), 'name', 'asc');
        const nsuppliers = _.orderBy(suppliers.map(e => ({...e, type: 'supplier'})), 'name', 'asc');
        const filteredOrgs = applyFilters([...nretailers, ...nsuppliers], filters);
        setFilteredOrganisations({
            retailers: filteredOrgs.filter(org => org.type === 'retailer'),
            suppliers: filteredOrgs.filter(org => org.type === 'supplier'),
        });
    }, [retailers, suppliers, filters])

    const handleFilterChange = filterType => setFilters({ ...filters, ...filterType })

    const handleOrganisationClick = (organisation) => {

      const type = organisation.type;

      if(!type) return;

      let orderDetails = {
        owner_id: organisation.id,
        supplier_id: userOrganisation.id,
      };

      if(type === 'supplier') {
        orderDetails = {
          owner_id: userOrganisation.id,
          supplier_id: organisation.id,
        }
      }

      dispatch(clearOrder())
      dispatch(orderFieldUpdated({ field: "owner_id", value: orderDetails.owner_id }));
      dispatch(orderFieldUpdated({ field: "supplier_id", value: orderDetails.supplier_id }));
      dispatch(orderFieldUpdated({ field: "status", value: STATUS_PENDING_APPROVAL }));
      dispatch(orderFormEditable());

      history.push('/order/create');
      onHide()

    }

    return (
        <Modal dialogClassName={styles.modal65w} show={show} onHide={onHide}>
            <Modal.Header closeButton>Select an organisation</Modal.Header>
            <Modal.Body>
                <Container className="container-fluid">
                  <div>
                    <Form.Group>
                      <Form.Label>Search</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          value={filters.searchTerm}
                          style={session?.theme?.components?.form_control}
                          onChange={e => handleFilterChange({ searchTerm: e.target.value })}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                  {filteredOrganisations.retailers.length > 0 &&
                    <div className="my-3">
                      <h6>Your Retailers: </h6>
                      <Row className="center-block">
                          {filteredOrganisations.retailers.map(org => {
                              return (
                                  <Col xs={12} md={4} key={org.id} className="center-block my-2">
                                      <OrganisationCard
                                        name={org.name}
                                        logo={org.logo}
                                        onClick={() => handleOrganisationClick(org)}
                                      />
                                  </Col>
                              )
                          })
                        }
                      </Row>
                    </div>
                  }
                  {filteredOrganisations.suppliers.length > 0 &&
                    <div className="my-3">
                      <h6>Your Suppliers: </h6>
                      <Row className="center-block">
                          {filteredOrganisations.suppliers.map(org => {
                              return (
                                <Col xs={12} md={4} key={org.id} className="center-block my-2">
                                      <OrganisationCard
                                        name={org.name}
                                        logo={org.logo}
                                        onClick={() => handleOrganisationClick(org)}
                                      />
                                  </Col>
                              )
                          })
                        }
                      </Row>
                    </div>
                  }
                </Container>
            </Modal.Body>

        </Modal >
    )
}

OrganisationSelectionModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
}

export default OrganisationSelectionModal
