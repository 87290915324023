import React from "react";
import { Col, Modal, ModalFooter, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from "react-redux";
import { showDialog } from "../app/appSlice";
import styles from './dialog.module.scss'

const Dialog = ({ dialog }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(showDialog(null));
  };

  const { title, body, refId = '' } = dialog;

  // TODO: Consider this for now just to error, and refactor later for other type
  return (
    <Modal
      show={!!dialog}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      dialogClassName={styles.modalTop}
    >
      <Modal.Header closeButton className={styles.modalheader}>
      <Modal.Title>
        <div class="text-danger">{title}</div>
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
        {refId != '' && (
          <div>Error reference code is {refId}</div>
        )}
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}><small>If this error persists, please reach out to support at <a href="https://collab-hub.thepistol.app/hc/en-us" target="__blank">{process.env.REACT_APP_NAME} Helpdesk</a></small></Modal.Footer>
    </Modal>
  );
};

export default Dialog;