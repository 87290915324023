import { connect } from "react-redux";
import { selectSession, selectUserOrganisation } from "../../session/sessionSlice";
import CustomAudiences from "../components/CustomAudiences";
import { googleRemarketingAudienceUpdate, selectReadPermissions } from "../organisationSettingsSlice";


const mapStateToProps = state => ({
    title: 'Remarketing Audiences',
    audiences: selectSession(state).google_remarketing_audiences,
})

export default connect(mapStateToProps)(CustomAudiences)
