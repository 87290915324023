import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { PLATFORM_BESPOKE_PRODUCT, PLATFORM_FACEBOOK, PLATFORM_GOOGLE, PLATFORM_MERCATO, PLATFORM_YOUTUBE } from "../../../../lib/platforms";
import { selectSession } from "../../../session/sessionSlice";
import { selectAvailableAdTypes } from "../../orderFormSlice";
import FacebookCTACarousel from "./FacebookCTACarousel";
import FacebookDisplay from "./FacebookDisplay";
import FacebookBoosted from "./FacebookBoosted";
import FacebookMixedCarousel from "./FacebookMixedCarousel";
import GoogleExpandedText from "./GoogleExpandedText";
import GoogleProductCarousel from "./GoogleProductCarousel";
import GoogleTextCarousel from "./GoogleTextCarousel";
import YoutubeVideo from "./YoutubeVideo";
import MercatoDevicesMixedCarousel from "./MercatoDevicesMixedCarousel";
import GoogleShortVideo from "./GoogleShortVideo";
import BespokeProduct from './BespokeProduct';
import { FACEBOOK_BOOSTED, FACEBOOK_CAROUSEL, FACEBOOK_ONLINE_CONVERSION_DPA, GOOGLE_EXPANDED_TEXT, GOOGLE_RESPONSIVE_SEARCH, GOOGLE_SHORT_VIDEO, GOOGLE_SMART_SHOPPING } from "../../../../lib/webads";

const Preview = ({
  type,
  ad = {},
  assets = [],
  feed = {},
  newPrev,
  onAssetsChange = () => { },
}) => {
  const dispatch = useDispatch();
  const availableAdTypes = useSelector(selectAvailableAdTypes);
  const adTypes = useSelector(selectSession).ad_types.filter((adType) =>
    availableAdTypes.includes(adType.control_id)
  );

  if (ad.platform === PLATFORM_FACEBOOK) {
    if (ad.type === FACEBOOK_BOOSTED) {
      const adProps = adTypes.filter((adType) => adType.control_id === ad.type);
      const asset = ad.media
        ?.map((mediaId) => assets.find((a) => a.id === mediaId))
        ?.filter((asset) => asset !== undefined)
        ?.find((a) => a.assettype === "image" || a.assettype === "video");
      const previewImg = newPrev
        ? adProps[0]?.placeholder_images?.large
        : "/ad_preview/no_preview.png";

      return (
        <FacebookDisplay
          headline={ad?.headline || "Headline Goes Here"}
          bodycopy={ad?.body || "Body copy goes here"}
          link={ad?.link || "https://www.example.com/product"}
          linkdesc={ad?.linkdesc || "Link title goes here"}
          assetType={asset?.assettype || "image"}
          src={asset?.url || previewImg}
          cta={ad?.cta}
          isPreviewMode={asset === undefined}
        />
      );
    } else if (ad.type === FACEBOOK_BOOSTED) {
      const adProps = adTypes.filter((adType) => adType.control_id === ad.type);
      const asset = ad.media
        ?.map((mediaId) => assets.find((a) => a.id === mediaId))
        ?.filter((asset) => asset !== undefined)
        ?.find((a) => a.assettype === "image" || a.assettype === "video");
      const previewImg = newPrev
        ? adProps[0]?.placeholder_images?.large
        : "/ad_preview/no_preview.png";

      return (
        <FacebookBoosted
          headline={ad?.headline || "Headline Goes Here"}
          bodycopy={ad?.body || "Body copy goes here"}
          link={ad?.link || "https://www.example.com/product"}
          linkdesc={ad?.linkdesc || "Link title goes here"}
          assetType={asset?.assettype || "image"}
          src={asset?.url || previewImg}
          cta={ad?.cta}
          isPreviewMode={asset === undefined}
        />
      );
    } else if (ad.type === FACEBOOK_CAROUSEL) {
      const adProps = adTypes.filter((adType) => adType.control_id === ad.type);
      const previewAssets = ad.media
        ?.map((mediaId) => assets.find((a) => a.id === mediaId))
        ?.filter((asset) => asset !== undefined)
        ?.filter((a) => a.assettype === "image" || a.assettype === "video");

      const onLeftMove = (media, i) => {
        if (ad?.media && ad.media.length > 0 && i > 0) {
          const arr = [...ad.media];
          const thisVal = ad.media[i];
          arr[i] = ad.media[--i];
          arr[i] = thisVal;

          onAssetsChange(arr);
        }
      };

      const onRightMove = (media, i) => {
        if (ad?.media && ad.media.length > 0 && i < ad.media.length - 1) {
          const arr = [...ad.media];
          const thisVal = ad.media[i];
          arr[i] = ad.media[++i];
          arr[i] = thisVal;

          onAssetsChange(arr);
        }
      };

      const previewImg = newPrev
        ? {
          assettype: "image",
          url: adProps[0]?.placeholder_images?.large,
          headline: "Headline Goes Here",
          body: "Body copy goes here",
        }
        : {
          assettype: "image",
          url: "/ad_preview/no_preview.png",
          headline: "Headline Goes Here",
          body: "Body copy goes here",
        };

      const facebookMixedAssets = previewAssets || [previewImg, previewImg];

      return (
        <FacebookMixedCarousel
          headline={ad?.headline || "Headline Goes Here"}
          bodycopy={ad?.body || "Body copy goes here"}
          link={ad?.link || "https://www.example.com/product"}
          linkdesc={ad?.linkdesc || "Link title goes here"}
          cta={ad?.cta || "shop_now"}
          ad={ad}
          assets={facebookMixedAssets}
          isPreviewMode={previewAssets === undefined}
          onRightMove={(media, i) => onRightMove(media, i)}
          onLeftMove={(media, i) => onLeftMove(media, i)}
        />
      );
    } else if (ad.type === FACEBOOK_ONLINE_CONVERSION_DPA) {
      const adProps = adTypes.filter((adType) => adType.control_id === ad.type);
      const productAssets =
        feed?.products?.length > 0
          ? feed.products
          : undefined;
      const previewImg = newPrev
        ? { image_url: adProps[0]?.placeholder_images?.large }
        : { image_url: "/ad_preview/no_preview.png" };

      return (
        <FacebookCTACarousel
          headline={ad?.headline || "Headline Goes Here"}
          bodycopy={ad?.body || "Body copy goes here"}
          link={ad?.link || "https://www.example.com/product"}
          linkdesc={ad?.linkdesc || "Link title goes here"}
          cta={ad?.cta || "shop_now"}
          assets={productAssets || [previewImg, previewImg]}
          isPreviewMode={productAssets === undefined}
        />
      );
    } else {
      //Default handler to fill empty space
      const asset = ad.media
        ?.map((mediaId) => assets.find((a) => a.id === mediaId))
        ?.filter((asset) => asset !== undefined)
        ?.find((a) => a.assettype === "image" || a.assettype === "video");
      const previewImg = newPrev
        ? "/ad_preview/no_preview.png"
        : "/ad_preview/no_preview.png";

      return (
        <FacebookDisplay
          headline={ad?.headline || "Headline Goes Here"}
          bodycopy={ad?.body || "Body copy goes here"}
          link={ad?.link || "https://www.example.com/product"}
          linkdesc={ad?.linkdesc || "Link title goes here"}
          assetType={asset?.assettype || "image"}
          src={asset?.url || previewImg}
          cta={ad?.cta || "shop_now"}
          isPreviewMode={asset === undefined}
        />
      );
    }
  }

  if (ad.platform === PLATFORM_MERCATO) {
    const adProps = adTypes.filter((adType) => adType.control_id === ad.type);
    const previewAssets = ad.media
      ?.map((mediaId) => assets.find((a) => a.id === mediaId))
      ?.filter((asset) => asset !== undefined)
      ?.filter((a) => a.assettype === "image" || a.assettype === "video");

    const onLeftMove = (media, i) => {
      if (ad?.media && ad.media.length > 0 && i > 0) {
        const arr = [...ad.media];
        const thisVal = ad.media[i];
        arr[i] = ad.media[--i];
        arr[i] = thisVal;

        onAssetsChange(arr);
      }
    };

    const onRightMove = (media, i) => {
      if (ad?.media && ad.media.length > 0 && i < ad.media.length - 1) {
        const arr = [...ad.media];
        const thisVal = ad.media[i];
        arr[i] = ad.media[++i];
        arr[i] = thisVal;

        onAssetsChange(arr);
      }
    };

    const previewImg = newPrev
      ? {
        assettype: "image",
        url: adProps[0]?.placeholder_images?.large,
        headline: "Headline Goes Here",
        body: "Body copy goes here",
      }
      : {
        assettype: "image",
        url: "/ad_preview/no_preview.png",
        headline: "Headline Goes Here",
        body: "Body copy goes here",
      };

    return (
      <MercatoDevicesMixedCarousel
        headline={ad?.headline || "Headline Goes Here"}
        bodycopy={ad?.body || "Body copy goes here"}
        link={ad?.link || "https://www.example.com/product"}
        linkdesc={ad?.linkdesc || "Link title goes here"}
        cta={ad?.cta || "shop_now"}
        ad={ad}
        assets={previewAssets || [previewImg, previewImg]}
        isPreviewMode={previewAssets === undefined}
        onRightMove={(media, i) => onRightMove(media, i)}
        onLeftMove={(media, i) => onLeftMove(media, i)}
      />
    );
  }

  if (ad.platform === PLATFORM_YOUTUBE) {
    const asset = ad?.["media-files"]
      ?.map((mediaId) => assets.find((a) => a.id === mediaId))
      ?.filter((asset) => asset !== undefined)
      ?.find((a) => a.assettype === "video");

    return (
      <YoutubeVideo
        src={asset?.url || ""}
        link={ad?.display_link || "example.com"}
        header={ad?.headline || "Example Header"}
        channelName={ad?.name || "Channel Name"}
        callToAction={ad?.["youtube-cta"] || "Button"}
      />
    );
  }

  if (ad.platform === PLATFORM_GOOGLE) {
    if (ad.type === GOOGLE_SMART_SHOPPING) {
      const adProps = adTypes.filter((adType) => adType.control_id === ad.type);
      const productAssets =
        feed?.products?.length > 0 ? feed.products : undefined;
      const previewImg = newPrev
        ? { image_url: adProps[0]?.placeholder_images?.large }
        : { image_url: "/ad_preview/no_preview.png" };

      const assetLogo = ad.media
        ?.map((mediaId) => assets.find((a) => a.id === mediaId))
        ?.filter((asset) => asset !== undefined)
        ?.find((a) => a.assettype === "image");
      return (
        <GoogleProductCarousel
          assets={
            productAssets || [previewImg, previewImg, previewImg, previewImg]
          }
          isPreviewMode={productAssets === undefined}
          logo={assetLogo}
        />
      );
    } else if (ad.type === GOOGLE_RESPONSIVE_SEARCH) {
      const keys = Object.keys(ad);
      const headlines = keys
        .filter((k) => k.match(/headline\d+/))
        .map((k) => ad[k]);
      const descriptions = keys
        .filter((k) => k.match(/description\d+/))
        .map((k) => ad[k]);
      let link;
      try {
        let url = new URL(ad.link);
        link =
          url.hostname + "/" + [ad.path1, ad.path2].filter((x) => x).join("/");
      } catch (e) {
        link = undefined;
      }

      return (
        <GoogleTextCarousel
          link={link || "https://www.example.com/your-product"}
          headlines={headlines.length > 0 ? headlines : ["Example Header"]}
          descriptions={
            descriptions.length > 0 ? descriptions : ["Example Description"]
          }
        />
      );
    } else if (ad.type === GOOGLE_EXPANDED_TEXT) {
      const header = [ad.headline1, ad.headline2, ad.headline3]
        .filter((a) => a)
        .join(" | ");
      const descriptions = [ad.description1, ad.description2].filter((d) => d);
      let link;
      try {
        const url = ad.link
          ? new URL(ad.link)
          : new URL("https://www.example.com");
        link =
          url.hostname + "/" + [ad.path1, ad.path2].filter((x) => x).join("/");
      } catch (e) {
        link = undefined;
      }

      return (
        <GoogleExpandedText
          header={header || "Example Headline"}
          descriptions={
            descriptions.length > 0 ? descriptions : ["Example Description"]
          }
          link={link || "www.example.com"}
        />
      );
    } else if (ad.type === GOOGLE_SHORT_VIDEO) {
      const asset = ad?.["media-files"]
        ?.map((mediaId) => assets.find((a) => a.id === mediaId))
        ?.filter((asset) => asset !== undefined)
        ?.find((a) => a.assettype === "video");

        return (
          <GoogleShortVideo
            src={asset?.url || ""}
            link={ad?.display_link || "example.com"}
            header={ad?.headline || "Example Header"}
            channelName={ad?.name || "Channel Name"}
            callToAction={ad?.["youtube-cta"] || "Button"}
          />
        )
    }
  }

  if (ad.platform === PLATFORM_BESPOKE_PRODUCT) {
    return (  
      <BespokeProduct
        headline={ad?.headline || "Headline Goes Here"}
        bodycopy={ad?.body || "Body copy goes here"}
        media={assets || "/ad_preview/no_preview.png"}
      />
    );
  }

  return "";
};

Preview.propTypes = {
  ad: PropTypes.object.isRequired,
  newPrev: PropTypes.bool,
};

export default Preview;
