import {connect} from 'react-redux'
import Feed from '../components/Feed'
import {selectOrderFormPermissions, selectReadOnly} from '../orderFormSlice'

const mapStateToProps = state => {
  const perms = selectOrderFormPermissions(state)

  const productSets = perms?.product_sets?.filter(set => set.platform === 'facebook')
  const productCatalogs = perms?.product_catalogs?.filter(catalog => catalog.platform === 'facebook')

  return {
    controlId: 'facebook-catalogs',
    required: false,
    readOnly: selectReadOnly(state),
    productSets: productSets,
    productCatalogs: productCatalogs,
  }
}

export default connect(mapStateToProps)(Feed)
