import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import OrderSquare from "./OrderSquare";
import styles from "./orders_grid.module.scss";

const StyledLoaderWrapper = styled.div`
  text-align: center;
  width: 100%;
  p {
    font-size: 1.2em;
  }
`;

const OrdersGrid = ({ orders, loading = false }) => {

  if (loading) {
    return (
      <>
      {loading && <div className="mb-4 text-center d-flex align-items-center justify-content-center">
        <Spinner animation="border" />&nbsp; Loading orders, please wait...
      </div>}
      {orders.length > 0 && <div className={styles.grid}>
        {orders.map((order) => (
          <OrderSquare key={order.id} order={order} />
        ))}
      </div>}
      </>
    );
  }

  return orders.length > 0 ? (
      <div className={styles.grid}>
        {orders.map((order) => (
          <OrderSquare key={order.id} order={order} />
        ))}
      </div>
  ) : (
    <div className="text-center">
      <p>Sorry, but it doesn't seem like there are currently any orders.</p>
    </div>
  );
};

export default OrdersGrid;
