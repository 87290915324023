import React, {useEffect, useState} from "react";
import {Col, Container, Row, Spinner,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Link, useHistory} from "react-router-dom";
import Chrome from "./layout/Chrome";
import {useDispatch, useSelector} from "react-redux";
import {selectSession,} from "../features/session/sessionSlice";
import {useForm} from "react-hook-form";
import {DevTool} from "@hookform/devtools";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faPlus} from '@fortawesome/pro-light-svg-icons'
import Breadcrumbs from "./components/Breadcrumbs";
import styled from "styled-components";
import {
  fetchBespokeProducts,
  selectAllBespokeProducts,
  selectBespokeRequestStatus
} from "../features/bespoke/bespokeSlice";
import moment from "moment/moment";
import {capitalizeFirstLetter} from "../lib/strings";
import {BESPOKE_DIGITAL, BESPOKE_PHYSICAL} from "../lib/constants";
import {formatToCurrency} from "../lib/financial";

const Header = styled.h1`
    margin-bottom: 70px;
`

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h6 {
      font-weight: 500;
    }
`

const StyledBespokeContainer = styled.article`
    border: 1px solid #D4CCFF;
    border-radius: 8px;
    padding: 32px;
    ${props => props.active &&
    'background-color: #F3F0FF;'}
    margin-bottom: 20px;
    display: flex;
`

const BespokeLink = styled(Link)`
    text-decoration: none;
    margin-left: auto;
    color: unset;

    &:hover {
        text-decoration: none;
        color: unset;
    }
`

const AddPackageButton = styled.button`
    border: 1px solid #7155FF;
    background: #ffffff;
    border-radius: 4px;
    padding: 10px 18px 10px 18px;
    color: #7155FF;
    font-size: 16px;

    display: flex;
    align-items: center;
    margin-left: auto;

    &:hover,
    a:hover {
        background: #7155FF;
        color: #ffffff;
    }
`

const StyledEmptyBespoke = styled.div`
    border-radius: 8px;
    border: 1px solid #EDEDEF;
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      text-align: center;
      max-width: 18.75rem;
    }
`

const InfoContainer = styled.div`
    border-radius: 8px;
    background-color: #F8F8F8;
    padding: 16px;
    ${props => props.active &&
    'background-color: #ffffff;'}
`

const InfoGroup = styled.span`
    margin-right: 20px;
    font-weight: 300;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
`

const InfoLabel = styled.span`
    font-weight: 700;
    margin-right: 8px;
    font-size: 14px;
`

const ImageContainer = styled.div`
  width: 20%;
  max-height: 100px;
  overflow: hidden;
  padding-right: 20px;
  img {
    width: 100%;
    object-fit: cover;
  }
`

const BespokeInfo = styled.div`
  width: 80%;
`

const breadcrumbItems = [
  {
    path: "/organisation/settings",
    title: "Settings",
  },
  {
    path: "/organisation/channels",
    title: "Owned Channels",
  },
  {
    path: "/organisation/bespokesettings",
    title: "Bespoke Product Settings",
    active: true,
  },
];

const OrganisationBespokeProducts = () => {
  let history = useHistory();
  const methods = useForm({});
  const dispatch = useDispatch()
  const { control, formState } = methods;
  const session = useSelector(selectSession);
  const [organisation, setOrganisation] = useState(session.user.organisation);
  const bespoke = useSelector(selectAllBespokeProducts)
  const status = useSelector(selectBespokeRequestStatus)
  const user = session.user.active_organisation;

  useEffect(() => {
    dispatch(fetchBespokeProducts())
  }, [dispatch])

  return (
    <>
      {process.env.REACT_APP_DEBUG_MODE === "true" && (
        <DevTool control={control} />
      )}
      <Helmet>
        <title>
          Bespoke Products 
          {" "}{organisation?.name ?? session.user?.active_organisation?.name} -
          {" "}{process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <Chrome>
        <Container className="pt-4">
          <Breadcrumbs items={breadcrumbItems} />
          <Row className="mb-3">
            <Col md={12}>
              <Header>Bespoke Products</Header>
            </Col>
          </Row>
          {status === 'loading' && <Row className="text-center d-flex align-items-center justify-content-center">
            <Spinner animation="border" />&nbsp;
          </Row>}
          {status === 'succeeded' && <BespokeProductsList bespoke={bespoke} />}
        </Container>
      </Chrome>
    </>
  );
};

export default OrganisationBespokeProducts;


const BespokeProductsList = ({ bespoke = []}) => {
  const session = useSelector(selectSession);
  const currency = session.user?.active_organisation?.settings?.['defaultCurrency'] ?? 'AUD';
  if (bespoke.length === 0) {
    return <BespokeProductsEmpty />
  }

  return (
    <section>
      <HeaderContainer>
        <h6>All Bespoke Products ({bespoke.length})</h6>
        <BespokeLink to="/organisation/bespokesettings/create">
          <AddPackageButton>
            <FontAwesomeIcon icon={faPlus} className="mr-3" /> New Bespoke Product
          </AddPackageButton>
        </BespokeLink>
      </HeaderContainer>
      <div>
        {bespoke.map(bsp => <>
          <BespokeRow
              active={bsp.active}
              image={bsp.placeholder_images?.large}
              key={bsp.id}
              header={bsp.label}
              currency={currency}
              product={bsp}
              actions={<div>
              <BespokeLink to={`/organisation/bespokesettings/${bsp.id}/edit`}>
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </BespokeLink>
            </div>}
          />
        </>
        )}
      </div>

    </section>
  );
}

const BespokeProductsEmpty = () => {
  return (
    <section>
      <StyledEmptyBespoke>
        <div>
          <h2>No Bespoke Product</h2>
          <p>Create a bespoke product to promote a collection of ad channels to your suppliers</p>
          <BespokeLink to="/organisation/bespokesettings/create">
            <AddPackageButton className="mr-auto">
              <FontAwesomeIcon icon={faPlus} className="mr-3" /> New Bespoke Product
            </AddPackageButton>
          </BespokeLink>
        </div>
      </StyledEmptyBespoke>
    </section>
  );
}

const BespokeRow = ({ image, header, active = false, currency, product, actions }) => {
  const moq = product.custom_fields?.moq ? product.custom_fields.moq: "0";
  const budget = product.custom_fields?.budget ? formatToCurrency(currency, product.custom_fields.budget): "0";
  const minSpend= product.custom_fields?.price ? formatToCurrency(currency, product.custom_fields.price): "0";
  return (<StyledBespokeContainer active={active}>
    <ImageContainer>
      <img src={image} alt="" />
    </ImageContainer>
    <BespokeInfo>
      <HeaderContainer>
        <strong>{header}</strong>
        <span className="ml-auto">
          {actions}
        </span>
      </HeaderContainer>
      <InfoContainer active={active}>
        {(product.availability_start && product.availability_end) && <InfoGroup><InfoLabel>Availability</InfoLabel>{moment(product.availability_start).format('DD/MM/YYYY hh:mm A') ?? '—'} to {moment(product.availability_end).format('DD/MM/YYYY hh:mm A') ?? '—'}</InfoGroup>}
        {(product.availability_start && !product.availability_end) && <InfoGroup><InfoLabel>Availability starts</InfoLabel>{moment(product.availability_start).format('DD/MM/YYYY hh:mm A') ?? '—'}</InfoGroup>}
        {(!product.availability_start && product.availability_end) && <InfoGroup><InfoLabel>Availability ends</InfoLabel>{moment(product.availability_end).format('DD/MM/YYYY hh:mm A') ?? '—'}</InfoGroup>}
        {(product.custom_fields.campaign_start && product.custom_fields.campaign_end) && <InfoGroup><InfoLabel>Campaign period</InfoLabel>{moment(product.custom_fields.campaign_start).format('DD/MM/YYYY hh:mm A') ?? '—'} to {moment(product.custom_fields.campaign_end).format('DD/MM/YYYY hh:mm A') ?? '—'}</InfoGroup>}
        {(product.custom_fields.campaign_start && !product.custom_fields.campaign_end) && <InfoGroup><InfoLabel>Campaign starts</InfoLabel>{moment(product.custom_fields.campaign_start).format('DD/MM/YYYY hh:mm A') ?? '—'}</InfoGroup>}
        {(!product.custom_fields.campaign_start && product.custom_fields.campaign_end) && <InfoGroup><InfoLabel>Campaign ends</InfoLabel>{moment(product.custom_fields.campaign_end).format('DD/MM/YYYY hh:mm A') ?? '—'}</InfoGroup>}
        <InfoGroup><InfoLabel>Classification</InfoLabel>{capitalizeFirstLetter(product.custom_fields.classification)}</InfoGroup>
        {product.custom_fields.classification === BESPOKE_DIGITAL && <><InfoGroup><InfoLabel>Budget</InfoLabel>{budget}</InfoGroup></>}
        {product.custom_fields.classification === BESPOKE_PHYSICAL && <><InfoGroup><InfoLabel>MOQ</InfoLabel>{moq ?? '—'}</InfoGroup><InfoGroup><InfoLabel>Min. Spend</InfoLabel>{minSpend}</InfoGroup></>}
      </InfoContainer>
      {!active && <div className="m-2">This Ad is currently disabled, enable it <Link to='/settings/suppliers'>here</Link></div>}
    </BespokeInfo>
  </StyledBespokeContainer>
  )}