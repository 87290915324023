import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../session/sessionSlice";
import Errors from "../Errors";
import { SketchPicker } from "react-color";
import { useEffect } from "react";
import { useState } from "react";

const ColorPicker = ({
  header,
  controlId,
  readOnly = false,
  required = false,
  value = "",
  onChange = () => {},
  errors = [],
  notice,
  info,
}) => {
  const theme = useSelector(selectTheme);
  const handleChangeComplete = (color) => {
    onChange(color?.hex);
  };
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="d-flex align-self-center align-items-center font-weight-bold">
        <span>
          {header} {required && "*"}{" "}
          {info && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id={`${controlId}-info-popover`}>{info}</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
            </OverlayTrigger>
          )}
        </span>
      </Form.Label>
      {notice && (
        <Form.Control.Feedback className="d-block text-body mb-2">
          {notice}
        </Form.Control.Feedback>
      )}
      <div>
        <div
          style={{
            padding: "5px",
            backgroundColor: "#fff",
            borderRadius: "3px",
            display: "inline-block",
            cursor: "pointer",
            width: "125px",
            border: "1px solid #b3b3b3",
          }}
          onClick={(e) => setShowColorPicker(!showColorPicker)}
        >
          <span style={{ float: "left", color: "#808080" }}>{value}</span>
          <div
            style={{
              width: "40px",
              height: "25px",
              borderRadius: "2px",
              backgroundColor: value,
              float: "right",
            }}
          />
        </div>
        {showColorPicker && (
          <div
          // style={{
          //   zIndex: "2",
          // }}
          >
            <SketchPicker
              color={value}
              onChangeComplete={(e) => handleChangeComplete(e)}
            />
          </div>
        )}
      </div>
      <Form.Control.Feedback type="invalid">
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

ColorPicker.propTypes = {
  header: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  notice: PropTypes.string,
  info: PropTypes.string,
};

export default ColorPicker;
