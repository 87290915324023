import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Nav, Tab } from "react-bootstrap";
import styled from 'styled-components';

const TabNav = styled(Nav)`
  padding: 0 8px;
  overflow-x: auto;
  flex-wrap: nowrap;
`

const TabTitle = styled.h6`
  font-size: 12px;
  margin: 4px 0px;
`

const TabNavItem = styled(Nav.Item)`
  text-align: center;
  background-color: #dedede;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 2px;
  font-size: 12px;
  height: 53px;
  flex: 0 0 70px;

  ${props => props.isActive && `
    background-color: #fff !important;
    border-bottom: 0;
  `}

  ${props =>
        props.completed && `
    border-bottom: 2px solid #87F5C0;
    `}

  ${props => props.invalid && `border-bottom: 2px solid #EF767A;`}
`

const TabNavLink = styled(Nav.Link)`
    color: #595959;
    font-weight: normal;
    padding: 0 !important;
    position: relative;
    text-align: center;
`

const TabNavContainer = styled.div`
    background-color: #F8F8F8;
`


const TabContainer = ({ tabs = [], children }) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const handleTabSelect = key => setActiveTab(tabs.find(tab => tab.id === key))

    return <Tab.Container
        id="tab"
        activeKey={activeTab.id}
        onSelect={handleTabSelect}
    >
        <TabNavContainer>
            <TabNav>
                {tabs.map((tab) =>
                    <TabNavItem key={tab.id} isActive={tab.id === activeTab.id}>
                        <TabNavLink eventKey={tab.id}>
                            <FontAwesomeIcon icon={tab.icon} size="lg" color="#5995ED" />
                            <TabTitle>
                                {tab.title}
                            </TabTitle>
                        </TabNavLink>
                    </TabNavItem>
                )}
            </TabNav>
        </TabNavContainer>
        <Tab.Content>
            {children}
        </Tab.Content>
    </Tab.Container>
}

TabContainer.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.object.isRequired,
    }))
}

export default TabContainer
