import { Col, Container, Form, Row, FormCheck, OverlayTrigger, Popover } from "react-bootstrap";
import Text from '../../order_form/components/generic/Text';
import TextArea from '../../order_form/components/generic/TextArea';
import Checkbox from '../../order_form/components/generic/Checkbox';
import Asset from '../../order_form/components/generic/Asset';
import { useState } from "react";
import EditableAsset from "../../order_form/components/generic/EditableAsset";
import TimeSelect from "../../organisation_settings/components/TimeSelect";
import DateRange from "../../order_form/components/DateRange";
import Datetime from 'react-datetime'
import { selectSession } from "../../session/sessionSlice";
import { useSelector } from "react-redux";
import moment from "moment";
import { selectAssets } from "../../order_form/orderFormSlice";
import styled from "styled-components";
import { TextInput } from "../../packages/components/form_elements/Common";
import { selectErrors } from "../bespokeFormSlice";
import Errors from "../../order_form/components/Errors";
import { indexOf } from "lodash";
import PropTypes from "prop-types";
import { BESPOKE_DIGITAL, BESPOKE_PHYSICAL } from "../../../lib/constants";

const FancyCheckbox = styled.input`
    accent-color: #7155FF;
    width: 15px;
    height: 15px;
`

const SelectCheckbox = styled(FancyCheckbox)`
    left: 35px;
    top: 7px;
`

const BespokeFormEditor = ({
    onChange = () => { },
    bespoke = [],
}) => {
    const [errors, setErrors] = useState([])

    const bespErrors = useSelector(selectErrors)
    const handleChange = (val) => {
        onChange({ ...bespoke, ...val });
    };

    const updateCustom = (key, val) => {
        let newCustom = { ...bespoke.custom_fields };
        newCustom[key] = val;
        handleChange({ custom_fields: newCustom })
    }

    const validAvailabilityStart = (current) => {
        if (bespoke.availability_end && current.isAfter(moment(bespoke.availability_end))) {
            return false
        }

        return current.isAfter(moment().subtract(1, 'day'))
    }

    const validAvailabilityEnd = (current) => {
        if (bespoke.availability_start && current.isBefore(moment(bespoke.availability_start))) {
            return false;
        }

        return current.isAfter(moment())
    }

    const validCampaignStart = (current) => {
        if (bespoke.campaign_end && current.isAfter(moment(bespoke.campaign_end))) {
            return false
        }

        return current.isAfter(moment().subtract(1, 'day'))
    }

    const validCampaignEnd = (current) => {
        if (bespoke.campaign_start && current.isBefore(moment(bespoke.campaign_start))) {
            return false;
        }

        return current.isAfter(moment())
    }

    return (
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="form-name">
                    <Form.Label className="font-weight-bold">Name *</Form.Label>
                    <Form.Text className="text-muted">
                        Values over 30 characters may be truncated.
                    </Form.Text>
                    <Form.Control
                        type="text"
                        required={true}
                        value={bespoke.label}
                        error={bespErrors.label}
                        onChange={(e) => handleChange({ label: e.target.value })}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors?.label ? "d-block" : ""}>
                        <Errors errors={bespErrors?.label} />
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="form-description">
                    <Form.Label className="font-weight-bold">Description *</Form.Label>
                    <Form.Text className="text-muted">
                        Text over 82 characters may be truncated.
                    </Form.Text>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        maxLength={82}
                        required={true}
                        value={bespoke?.description}
                        onChange={(e) => handleChange({ description: e.target.value })}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors?.description ? "d-block" : ""}>
                        <Errors errors={bespErrors?.description} />
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="form-availability-start">
                    <Form.Label className="font-weight-bold">Availability Start</Form.Label>
                    <Datetime
                        errors={errors}
                        value={bespoke.availability_start && moment(bespoke.availability_start).isValid() ? moment(bespoke.availability_start): ""}
                        dateFormat="YYYY-MM-DD"
                        isValidDate={ validAvailabilityStart }
                        onChange={(value) => {
                            let time = moment.isMoment(value) ? moment(value.toString()).format("YYYY-MM-DD hh:mm A") : value;
                            handleChange({ availability_start: time });
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors?.availability_start ? "d-block" : ""}>
                        <Errors errors={bespErrors?.availability_start} />
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form-availability-end">
                    <Form.Label className="font-weight-bold">Availability End</Form.Label>
                    <Datetime
                        errors={errors}
                        value={bespoke.availability_end && moment(bespoke.availability_end).isValid() ? moment(bespoke.availability_end): ""}
                        dateFormat="YYYY-MM-DD"
                        isValidDate={ validAvailabilityEnd }
                        onChange={(value) => {
                            let time = moment.isMoment(value) ? moment(value.toString()).format("YYYY-MM-DD hh:mm A") : value;
                            handleChange({ availability_end: time });
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors?.availability_end ? "d-block" : ""}>
                        <Errors errors={bespErrors?.availability_end} />
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form-campaign-end">
                    <Form.Label className="font-weight-bold">Campaign Period Starts</Form.Label>
                    <Datetime
                        errors={errors}
                        value={bespoke.custom_fields.campaign_start && moment(bespoke.custom_fields.campaign_start).isValid() ? moment(bespoke.custom_fields.campaign_start): ""}
                        dateFormat="YYYY-MM-DD"
                        isValidDate={ validCampaignStart }
                        onChange={(value) => {
                            let time = moment.isMoment(value) ?  moment(value.toString()).format("YYYY-MM-DD hh:mm A") : value;
                            updateCustom('campaign_start', time);
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.campaign_start'] ? "d-block" : ""}>
                        <Errors errors={bespErrors['custom_fields.campaign_start']} />
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="form-campaign-end">
                    <Form.Label className="font-weight-bold">Campaign Period Ends</Form.Label>
                    <Datetime
                        errors={errors}
                        value={bespoke.custom_fields.campaign_end && moment(bespoke.custom_fields.campaign_end).isValid() ? moment(bespoke.custom_fields.campaign_end): ""}
                        dateFormat="YYYY-MM-DD"
                        isValidDate={ validCampaignEnd }
                        onChange={(value) => {
                            let time = moment.isMoment(value) ? moment(value.toString()).format("YYYY-MM-DD hh:mm A") : value;
                            updateCustom('campaign_end', time);
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.campaign_end'] ? "d-block" : ""}>
                        <Errors errors={bespErrors['custom_fields.campaign_end']} />
                    </Form.Control.Feedback>
                </Form.Group>
                <EditableAsset
                    type="image"
                    controlId="bespoke-image"
                    header="Bespoke product image"
                    errors={bespErrors?.placeholder_images}
                    field={{ type: 'image' }}
                    ad={{ platform: "web" }}
                    value={bespoke?.placeholder_images?.large}
                    onChange={asset => handleChange({ placeholder_images: { large: asset } })}
                />
                <Form.Group className="mb-3" controlId="form-classification">
                    <p className="font-weight-bold">Classification *</p>
                    <Form.Check
                        name="classification"
                        id="check-physical"
                        type="radio"
                        required={true}
                        label="Physical"
                        value="physical"
                        checked={bespoke.custom_fields.classification === BESPOKE_PHYSICAL}
                        onChange={(e) => updateCustom("classification", e.target.value)}
                    />
                    <Form.Check
                        name="classification"
                        id="check-digital"
                        type="radio"
                        required={true}
                        label="Digital"
                        value="digital"
                        checked={bespoke.custom_fields.classification === BESPOKE_DIGITAL}
                        onChange={(e) => updateCustom("classification", e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.classification'] ? "d-block" : ""}>
                        <Errors errors={bespErrors['custom_fields.classification']} />
                    </Form.Control.Feedback>
                </Form.Group>
                {bespoke.custom_fields.classification !== BESPOKE_DIGITAL
                    && <Form.Group className="mb-3" controlId="form-stock-level">
                        <Form.Label className="font-weight-bold">Stock Level</Form.Label>
                        <Form.Text className="text-muted">
                            The maximum number of stock you want to offer.
                        </Form.Text>
                        <Form.Control
                            type="number"
                            required={true}
                            min="0"
                            value={bespoke.custom_fields.stock_level}
                            onChange={(e) => updateCustom("stock_level", parseInt(e.target.value))}
                        />
                        <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.stock_level'] ? "d-block" : ""}>
                            <Errors errors={bespErrors['custom_fields.stock_level']} />
                        </Form.Control.Feedback>
                    </Form.Group>}
                {bespoke.custom_fields.classification === 'digital'
                    && <><Form.Group className="mb-3" controlId="form-stock-budget">
                        <Form.Label className="font-weight-bold">Budget</Form.Label>
                        <Form.Control
                            type="text"
                            required={true}
                            value={bespoke.custom_fields.budget}
                            onChange={(e) => updateCustom("budget", e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.budget'] ? "d-block" : ""}>
                            <Errors errors={bespErrors['custom_fields.budget']} />
                        </Form.Control.Feedback>
                    </Form.Group>
                        <Form.Group className="mb-3" controlId="form-stock-budget-period">
                            <p className="font-weight-bold">Budget Period</p>
                            <Form.Check
                                name="budget_period"
                                id="check-budget-period-daily"
                                type="radio"
                                required={true}
                                label="Daily"
                                value="daily"
                                checked={bespoke.custom_fields.budget_period === 'daily'}
                                onChange={(e) => updateCustom("budget_period", e.target.value)}
                            />
                            <Form.Check
                                name="budget_period"
                                id="check-budget-period-lifetime"
                                type="radio"
                                required={true}
                                label="Lifetime"
                                value="lifetime"
                                checked={bespoke.custom_fields.budget_period === 'lifetime'}
                                onChange={(e) => updateCustom("budget_period", e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.budget_period'] ? "d-block" : ""}>
                                <Errors errors={bespErrors['custom_fields.budget_period']} />
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="form-stock-budget-supplier-options">
                            <Form.Check
                                name="budget_set_by_supplier"
                                id="check-budget_set_by_supplier"
                                type="checkbox"
                                label={"Let brands increase the budget"}
                                checked={bespoke.custom_fields.budget_set_by_supplier || bespoke.custom_fields.budget_set_by_supplier === 'true'}
                                onChange={(e) => updateCustom("budget_set_by_supplier", e.target.checked)}
                            />
                            <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.budget_set_by_supplier'] ? "d-block" : ""}>
                                <Errors errors={bespErrors['custom_fields.budget_set_by_supplier']} />
                            </Form.Control.Feedback>
                        </Form.Group></>}
                {bespoke.custom_fields.classification !== BESPOKE_DIGITAL
                    && <><Form.Group className="mb-3" controlId="form-batch-size">
                        <Form.Label className="font-weight-bold">Batch Size</Form.Label>
                        <Form.Text className="text-muted">
                            The maximum number of batch you want to offer.
                        </Form.Text>
                        <Form.Control
                            type="number"
                            required={true}
                            min="0"
                            value={bespoke.custom_fields.batch_size}
                            onChange={(e) => updateCustom("batch_size", parseInt(e.target.value))}
                        />
                        <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.batch_size'] ? "d-block" : ""}>
                            <Errors errors={bespErrors['custom_fields.batch_size']} />
                        </Form.Control.Feedback>
                    </Form.Group>
                        <Form.Group className="mb-3" controlId="form-moq">
                            <Form.Label className="font-weight-bold">Minimum Order Quantity (MOQ)</Form.Label>
                            <Form.Control
                                type="number"
                                required={true}
                                min="0"
                                value={bespoke.custom_fields.moq}
                                onChange={(e) => updateCustom("moq", parseInt(e.target.value))}
                            />
                            <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.moq'] ? "d-block" : ""}>
                                <Errors errors={bespErrors['custom_fields.moq']} />
                            </Form.Control.Feedback>
                        </Form.Group></>}
                <Form.Group className="mb-3" controlId="form-stock-level">
                    <Form.Label className="font-weight-bold">Lead time</Form.Label>
                    <Form.Text className="text-muted">
                        Lead time required in days (0 - 999).
                    </Form.Text>
                    <Form.Control
                        type="number"
                        required={true}
                        min="0"
                        value={bespoke.custom_fields.lead_time}
                        onChange={(e) => updateCustom("lead_time", parseInt(e.target.value))}
                    />
                    <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.lead_time'] ? "d-block" : ""}>
                        <Errors errors={bespErrors['custom_fields.lead_time']} />
                    </Form.Control.Feedback>
                </Form.Group>
                {bespoke.custom_fields.classification !== BESPOKE_DIGITAL
                    && <Form.Group className="mb-3" controlId="form-price">
                        <Form.Label className="font-weight-bold">Price / Item</Form.Label>
                        <Form.Control
                            type="text"
                            required={true}
                            value={bespoke.custom_fields.price}
                            onChange={(e) => updateCustom("price", e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid" className={bespErrors['custom_fields.price'] ? "d-block" : ""}>
                            <Errors errors={bespErrors['custom_fields.price']} />
                        </Form.Control.Feedback>
                    </Form.Group>}
            </Col>
        </Row>
    );
}

BespokeFormEditor.propTypes = {
    bespoke: PropTypes.object,
    errors: PropTypes.array,
    onChange: PropTypes.func,
}

export default BespokeFormEditor;