import { connect } from "react-redux"
import { selectSession, selectUser } from "../../session/sessionSlice"
import InsightsView from "./InsightsView"


const mapStateToProps = state => ({
    insightSchema: selectSession(state).metric_read_permissions.find(p => p.name === 'insights')?.fields,
})


export default connect(mapStateToProps)(InsightsView)
