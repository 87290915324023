import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { selectSession, selectUserOrganisation } from "../../session/sessionSlice";
import CustomAudiences from "../components/CustomAudiences";
import { facebookCustomAudienceUpdate, selectReadPermissions } from "../organisationSettingsSlice";


const mapStateToProps = state => ({
    title: 'Custom Audiences',
    audiences: selectSession(state).facebook_custom_audiences,
})

export default connect(mapStateToProps)(CustomAudiences)
