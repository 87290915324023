import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AllOrders from "./app/AllOrders";
import { selectDialog } from "./app/appSlice";
import { ToastContainer, toast } from "react-toastify";
import ArchivedOrders from "./app/ArchivedOrders";
import CreateOrder from "./app/CreateOrder";
import Dashboard from "./app/Dashboard";
import AppDialog from "./app/Dialog";
import EditOrder from "./app/EditOrder";
import InviteSupplier from "./app/InviteSupplier";
import InviteUser from "./app/InviteUser";
import LinkOrg from "./app/LinkOrg";
import LinkUser from "./app/LinkUser";
import Login from "./app/Login";
import LoginAdminAs from "./app/LoginAdminAs";
import ProtectedRoutes from "./app/ProtectedRoutes";
import EditOwnedSpace from "./app/EditOwnedSpace";
import CreateOwnedSpace from "./app/CreateOwnedSpace";
import Register from "./app/Register";
import RegisterUser from "./app/RegisterUser";
import ResetPassword from "./app/ResetPassword";
import ResetPasswordConfirm from "./app/ResetPasswordConfirm";
import SupplierSettings from "./app/SupplierSettings";
import SuppliersGeneralSettings from "./app/SuppliersGeneralSettings";
import ViewOrder from "./app/ViewOrder";
import { randomRange } from "./lib/random";
import OrganisationSettings from "./app/OrganisationSettings";
import OrganisationChannels from "./app/OrganisationChannels";
import OrganisationWebSettings from "./app/OrganisationWebSettings";
import OrganisationBespokeSettings from './app/OrganisationBespokeSettings'
import CreateBespokeProduct from "./app/CreateBespokeProduct";
import EditBespokeProduct from "./app/EditBespokeProduct";
import {
  clearSession,
  isLoggedIn,
  refreshSession,
} from "./features/session/sessionSlice";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "react-toastify/dist/ReactToastify.css";
import OrganisationUserEdit from "./app/OrganisationUserEdit";
import ListPackages from "./app/ListPackages";
import CreatePackage from "./app/CreatePackage";
import OrganisationRoleEdit from "./app/OrganisationRoleEdit";
import RetailerWallets from "./app/RetailerWallets";
import SingleWalletPage from "./app/SingleWalletPage";
import EditPackage from "./app/EditPackage";
import OrganisationInventoryHome from "./features/inventory/OrganisationInventoryHome";
import OrganisationInventoryWebspaceDetails from "./features/inventory/OrganisationInventoryWebspaceDetails";
import OrganisationInventoryAudienceDetails from "./features/inventory/OrganisationInventoryAudienceDetails";

const LogOut = () => {
  const dispatch = useDispatch();
  dispatch(clearSession());
  return <Redirect to="/login" />;
};

const Routes = () => {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoggedIn(session)) {
        dispatch(refreshSession());
      }
    }, randomRange(3, 5) * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [session]);

  return (
    <Router>
      <Switch>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/admin-login-as" exact>
          <LoginAdminAs />
        </Route>
        <Route path="/register" exact>
          <Register />
        </Route>
        <Route path="/register/user" exact>
          <RegisterUser />
        </Route>
        <Route path="/organisation/link" exact>
          <LinkOrg />
        </Route>
        <Route path="/user/link" exact>
          <LinkUser />
        </Route>
        <Route path="/reset" exact>
          <ResetPassword />
        </Route>
        <Route path="/reset/password/:token" exact>
          <ResetPasswordConfirm />
        </Route>
        <ProtectedRoutes>
          <Switch>
            <Route path="/" exact>
              <Dashboard />
            </Route>
            <Route path="/orders" exact>
              <AllOrders />
            </Route>
            <Route path="/orders/archive" exact>
              <ArchivedOrders />
            </Route>
            <Route path="/order/create" exact>
              <CreateOrder />
            </Route>
            <Route path="/order/:order_id" exact>
              <ViewOrder />
            </Route>
            <Route path="/order/edit/:order_id" exact>
              <EditOrder />
            </Route>
            <Route path="/organisations/invite" exact>
              <InviteSupplier />
            </Route>
            <Route path="/inventory/home" exact>
              <OrganisationInventoryHome />
            </Route>
            <Route path="/inventory/ownedspace/usage/:usage_id" exact>
              <OrganisationInventoryWebspaceDetails />
            </Route>
            <Route path="/inventory/audience/usage/:audience_id" exact>
              <OrganisationInventoryAudienceDetails />
            </Route>
            <Route path="/organisation/settings" exact>
              <OrganisationSettings />
            </Route>
            <Route path="/organisation/settings/:id" exact>
              <OrganisationUserEdit />
            </Route>
            <Route path="/organisation/channels" exact>
              <OrganisationChannels />
            </Route>
            <Route path="/organisation/websettings" exact>
              <OrganisationWebSettings />
            </Route>
            <Route path="/organisation/bespokesettings" exact>
              <OrganisationBespokeSettings />
            </Route>
            <Route path="/organisation/role/:id" exact>
              <OrganisationRoleEdit />
            </Route>
            <Route path="/wallets" exact>
              <RetailerWallets />
            </Route>
            <Route path="/wallet/:wallet_id" exact>
              <SingleWalletPage />
            </Route>
            <Route path="/organisation/websettings/edit/:owned_space_id" exact>
              <EditOwnedSpace />
            </Route>
            <Route
              path="/organisation/websettings/create/:placement/:format/:subformat"
              exact
            >
              <CreateOwnedSpace />
            </Route>
            <Route path="/settings/suppliers" exact>
              <SuppliersGeneralSettings />
            </Route>
            <Route path="/packages" exact>
              <ListPackages />
            </Route>
            <Route path="/packages/create" exact>
              <CreatePackage />
            </Route>
            <Route path="/packages/:package_id/edit" exact>
              <EditPackage />
            </Route>
            <Route path="/organisation/bespokesettings/create" exact>
              <CreateBespokeProduct />
            </Route>
            <Route path="/organisation/bespokesettings/:bespoke_id/edit" exact>
              <EditBespokeProduct />
            </Route>
            <Route path="/settings/suppliers/:organisation_id" exact>
              <SupplierSettings />
            </Route>
            <Route path="/users/invite" exact>
              <InviteUser />
            </Route>
            <Route path="/logout" exact>
              <LogOut />
            </Route>
          </Switch>
        </ProtectedRoutes>
      </Switch>
    </Router>
  );
};

const App = () => {
  const dialog = useSelector(selectDialog);
  return (
    <>
      <Routes />
      {!!dialog && <AppDialog dialog={dialog} />}
      <ToastContainer autoClose={5000} />
    </>
  );
};

export default App;
