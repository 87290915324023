import {useDispatch, useSelector} from "react-redux";
import {
    adInsert,
    selectFacebookProductGroup,
    selectGoogleProductGroup,
    selectImageAssets,
    selectOwnerOrganisation,
    selectVideoAssets
} from "../../orderFormSlice";
import React, {useState} from "react";
import {createBespokeProductAd, createOwnedSpaceAd, createStandardAd, strToIcon} from "./helper";
import {Button, Col, Row} from "react-bootstrap";
import styles from "./ad_selection_modal.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export const AdBox = ({
    title,
    description,
    recommended,
    imageURL,
    imageAlt,
    requirements,
    adtype,
    states,
    keyID,
    isBespoke = false,
    disabled = false,
}) => {
    const organisation = useSelector(selectOwnerOrganisation)
    const facebookProducts = useSelector(selectFacebookProductGroup);
    const googleProducts = useSelector(selectGoogleProductGroup);
    const imageAssets = useSelector(selectImageAssets);
    const videoAssets = useSelector(selectVideoAssets);
    const [adSelection, setAdSelection] = useState({});
    const dispatch = useDispatch();

    const handleInsert = (sectiontype, advalue, fields = {}) => {
        setAdSelection({type: sectiontype, value: advalue.id})
        let ad;
        if (sectiontype === "standard_ad") {
            ad = createStandardAd(
                advalue,
                facebookProducts,
                googleProducts,
                imageAssets,
                videoAssets,
                organisation.settings,
            );
        } else if (sectiontype === "owned_space") {
            ad = createOwnedSpaceAd(advalue.id);
        } else if (sectiontype === "bespoke_product") {
            ad = createBespokeProductAd(advalue);
        }

        ad = {...ad, ...fields}

        dispatch(adInsert(ad));
        states(true);
    };

    return (
        <Col xl={6} lg={12} key={adtype.id}>
            <div
                className={`${styles.ad_objective} ${recommended ? styles.recommended : ""} ${adtype.id === adSelection?.value?.id
                    ? styles.selectedType
                    : styles.modalAdBox}
        ${disabled ? styles.disabled : ''}`}
                onDoubleClick={() => !disabled && handleInsert(isBespoke ? 'bespoke_product' : 'standard_ad', adtype)}>
                <div className={styles.ad_objective_image_section}>
                    <img src={imageURL} alt={imageAlt}/>
                </div>
                <p className={styles.ad_objective_title}>{title}</p>
                <p>{description}</p>
                <hr/>
                <Row className={styles.ad_objective_row}>
                    {Array.isArray(requirements) && requirements.map(requirement =>
                        <Col md={6} className='p-0'>
                            <div className={styles.ad_objective_info}>
                                <div>
                                    <FontAwesomeIcon
                                        icon={strToIcon(requirement.icon)}
                                        size="2x"
                                    />
                                </div>
                                <div className={styles.ad_objective_info_text}>
                                    <strong>{requirement.title}</strong>
                                    <p>{requirement.description}</p>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
                <div style={{textAlign: 'right'}}>
                    <Button
                        disabled={disabled}
                        onClick={() => !disabled && handleInsert(isBespoke ? 'bespoke_product' : 'standard_ad', adtype)}
                    >Add to Order</Button>
                </div>
            </div>
        </Col>
    )
}

AdBox.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    recommended: PropTypes.bool,
    imageURL: PropTypes.string,
    imageAlt: PropTypes.string,
    requirements: PropTypes.array,
    adtype: PropTypes.object,
    states: PropTypes.func,
    keyID: PropTypes.string,
}
