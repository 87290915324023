import {DevTool} from "@hookform/devtools";
import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Col, Container, Form, InputGroup, Nav, ProgressBar, Row, Spinner, Tab,} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Select from "react-select";
import Chrome from "../../app/layout/Chrome";
import OrganisationList from "../../features/organisation_settings/components/List";
import MinimumReviewTime from "../../features/organisation_settings/components/MinimumReviewTime";
import TimeSelect from "../../features/organisation_settings/components/TimeSelect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {clearSession, refreshSession, selectSession, selectUser} from "../../features/session/sessionSlice";
import {updateOrganisationSettings, uploadOrganisationLogo} from "../../lib/api";
import styled from 'styled-components'
import Reporting from "./Reporting";
import OrganisationUserManagement from '../OrganisationUserManagement';
import {MANAGE_ORGANISATION_SETTINGS, USER_MANAGEMENT} from "../../lib/permissions";
import {cacheFetchRetailers, selectAllRetailers} from "../../features/retailers/retailersSlice";
import {cachedFetchBrands, selectAllBrands} from "../../features/brands/brandsSlice";

const StyledNav = styled(Nav.Link)`
  transition: 0.3s;
  border: 1px solid #7155FF !important;
  border-radius: 5px !important;
  padding: 6px 40px !important;
  color: ${props => props.active ? "#fff" : "#7155FF"} !important;
  background: ${props => props.active ? "#421CFF" : "#fff"} !important;
`;

const StyledNavLeft = styled(StyledNav)`
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0;
`;

const StyledNavRight = styled(StyledNav)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0;
`;

const StyledTitle = styled.div`
  font-weight: bold;

  h3 {
    font-size: 22px;
  }

  color: #000;
  text-align: left;
  width: 100%;
`;

const StyledSave = styled(Button)`
  &,
  &:hover,
  &:focus,
  &:active,
  &::selection {
    background: #7155FF;
    border-color: #644BF8 !important;
  }
`;

const StyledImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    width: 100%;
    max-height: 320px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: #B9B9C0;
    y
  }

  ${props => !props.logo &&
          `
    border: 2px dashed #ddd;
    height 40px;
    `
  };
`;


const percLoaded = (p) => parseInt((p.loaded / p.total) * 100);


const OrganisationSettings = () => {
    let history = useHistory();
    const session = useSelector(selectSession);
    const user = useSelector(selectUser);
    const retailers = useSelector(selectAllRetailers);
    const suppliers = useSelector(selectAllBrands);
    const isRetailer = user.active_organisation.is_retailer;
    const hasRetailers = retailers.length > 0;
    const hasSuppliers = suppliers.length > 0;

    const dispatch = useDispatch();
    const [submitErrors, setSubmitErrors] = useState({});
    const [organisation, setOrganisation] = useState(
        session.user.active_organisation
    );
    const currencyList = session?.currencies ?? [];
    const settings = organisation?.settings;
    const [msg, setMsg] = useState(null);
    const [isSaving, setIsSaving] = useState();
    const [timezoneOptions, setTimezoneOptions] = useState(
        session?.timezones && session.timezones.length > 0
            ? session.timezones.map((timezone) => {
                return {label: timezone, value: timezone};
            })
            : []
    );
    const [selectedTimezone, setSelectedTimezone] = useState(
        organisation.settings?.timezone
            ? {
                label: organisation.settings.timezone,
                value: organisation.settings.timezone,
            }
            : {}
    );
    const currencyOptions = useMemo(() => {
        if (!currencyList || !currencyList?.length) {
            return [];
        }
        return currencyList?.map(currency => ({label: currency?.name, value: currency?.code}))
    }, [currencyList]);

    const [selectedCurrency, setSelectedCurrency] = useState(organisation.settings?.defaultCurrency ?? "");

    const userPermissions = user?.role?.permissions ? user?.role?.permissions.map(p => p.name) : [];
    const canManageUsers = userPermissions.includes(MANAGE_ORGANISATION_SETTINGS) && userPermissions.includes(USER_MANAGEMENT);

    const [logoProg, setLogoProg] = useState(null);

    const methods = useForm({
        defaultValues: useMemo(() => {
            const def = {
                facebookDefaultPageID: settings?.facebookDefaultPageID,
                facebookPixelID: settings?.facebookPixelID,
                defaultLocations: settings?.defaultLocations,
                timezone: settings?.timezone,
                defaultCurrency: settings?.defaultCurrency,
                facebookDefaultAudiences: settings?.facebookDefaultAudiences,
                googleDefaultAudiences: settings?.googleDefaultAudiences,
                maxSpendPacingDifference: settings?.maxSpendPacingDifference,
                webadsAttributionEnabled: settings?.webadsAttributionEnabled,
                webadsAttributionWindow: settings?.webadsAttributionWindow ?? 1,
                webadsAttributionTracking: settings?.webadsAttributionTracking,
            };
            return def;
        }, [settings]),
        mode: "onSubmit",
    });

    const {
        setError,
        getValues,
        handleSubmit,
        clearErrors,
        control,
        setValue,
        trigger,
        formState,
    } = methods;

    const {touchedFields, isSubmitted, errors} = formState;

    const [tabKey, setTabKey] = useState(canManageUsers ? 'global-settings' : userPermissions.includes(MANAGE_ORGANISATION_SETTINGS) ? 'global-settings' : 'supplier-settings');

    const onSubmit = async (data) => {
        setIsSaving(true);
        setMsg(null);

        const updatedData = {
            facebookDefaultPageID: data.facebookDefaultPageID,
            facebookPixelID: data.facebookPixelID,
            timezone: selectedTimezone?.value,
            defaultLocations: data.defaultLocations,
            defaultCurrency: selectedCurrency,
            facebookDefaultAudiences: data.facebookDefaultAudiences,
            googleDefaultAudiences: data.googleDefaultAudiences,
            maxSpendPacingDifference: data.maxSpendPacingDifference,
            webadsAttributionEnabled: data?.webadsAttributionEnabled,
            webadsAttributionWindow: data.webadsAttributionWindow,
            webadsAttributionTracking: data.webadsAttributionTracking,
        };
        const settings = {...(organisation.settings || {}), ...updatedData};
        setOrganisation({
            ...organisation,
            settings,
        });

        try {
            const res = await updateOrganisationSettings(organisation.id, {
                settings,
            });
            setOrganisation(res.data.data);
            // setErrors({});
            dispatch(refreshSession());
            setMsg({
                type: "success",
                body: "Settings have been saved successfully.",
            });
        } catch (e) {
            if (e.response.status === 403) {
                dispatch(clearSession());
                history.push("/login");
            } else {
                const errors = e?.response?.data?.errors;
                const keys = Object.keys(errors || {});
                if (!errors || !keys.length) {
                    setMsg({
                        type: "danger",
                        body: "Could not save the settings, please try again.",
                    });
                } else {
                    keys.forEach((errorKey) => {
                        const key = errorKey.replace("settings.", "");
                        const splitKey = key.split(".");
                        if (splitKey.length === 1) {
                            setError(splitKey[0], {
                                type: "manual",
                                message: errors[`settings.${key}`][0],
                            });
                        }
                    });
                }
            }
        } finally {
            setIsSaving(false);
        }
    };

    const webAdsTrackingEvents = session?.webads_events || [];

    const handleSettingChange = (setting, value) => {
        const settings = {...(organisation.settings || {}), [setting]: value};
        setOrganisation({
            ...organisation,
            settings,
        });

        updateOrganisationSettings(organisation.id, {
            settings,
        })
            .then((resp) => {
                setOrganisation(resp.data.data);
                setSubmitErrors({});
                dispatch(refreshSession());
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    dispatch(clearSession());
                    history.push("/login");
                } else if (err.response.status === 422) {
                    setSubmitErrors(err.response.data.errors);
                }
            });
    };

    const selectedCurrencyLocked = useMemo(() => {
        if (organisation.settings?.defaultCurrency?.length > 0) {
            return true;
        }
        if (msg?.type === "success" && selectedCurrency?.length > 0) {
            return true;
        }

        return false;
    }, [selectedCurrency, msg, organisation.settings?.defaultCurrency])

    useEffect(() => {
        dispatch(cachedFetchBrands());
        dispatch(cacheFetchRetailers());
    }, []);

    return (
        <>
            {process.env.REACT_APP_DEBUG_MODE === "true" && (
                <DevTool control={control}/>
            )}
            <Helmet>
                <title>
                    Organisation Settings for {organisation.name} - {process.env.REACT_APP_NAME}
                </title>
            </Helmet>
            <Chrome>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <div className="rounded p-3 d-flex">
                                <div
                                    className="d-inline-block w-100"
                                >
                                    <h3>Organisation Settings</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Tab.Container id="left-tabs-example" activeKey={tabKey} onSelect={key => setTabKey(key)}>
                        <Row>
                            {canManageUsers && <Col md={12}>
                                <Nav variant="pills" className="flex-row mb-3">
                                    <Nav.Item>
                                        <StyledNavLeft eventKey="global-settings" active={tabKey === "global-settings"}>My
                                            Organisation</StyledNavLeft>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <StyledNavRight eventKey="supplier-settings"
                                                        active={tabKey === "supplier-settings"}>Team
                                            Members</StyledNavRight>
                                    </Nav.Item>
                                </Nav>
                            </Col>}

                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="global-settings">
                                        <Row>
                                            <Col>
                                                <div className="rounded py-3 d-flex">
                                                    <StyledTitle className="d-inline-block">
                                                        <h3 className="font-weight-bold">Basic Information</h3>
                                                        <hr/>
                                                    </StyledTitle>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Col md={12}>
                                                    <h4 className="text-dark">{organisation?.name}</h4>
                                                </Col>
                                                <Col lg={3} className="pt-2">
                                                    <StyledImage logo={organisation.settings?.logo?.url}>
                                                        {organisation.settings?.logo?.url ?
                                                            <img src={organisation.settings?.logo?.url} alt=""/> :
                                                            <p>Your logo here</p>}
                                                    </StyledImage>
                                                </Col>
                                                <Col lg={3} className="pt-2">
                                                    <Form.Group className="mb-0">
                                                        <Form.File
                                                            id="logo"
                                                            label={
                                                                organisation.settings?.logo?.name || "Select an image"
                                                            }
                                                            errors={errors["logo"]}
                                                            onChange={(e) => {
                                                                uploadOrganisationLogo(
                                                                    organisation.id,
                                                                    e.target.files[0],
                                                                    {
                                                                        onUploadProgress: (p) => setLogoProg(percLoaded(p)),
                                                                    }
                                                                ).then((resp) => {
                                                                    setOrganisation(resp.data.data);
                                                                    setLogoProg(null);
                                                                    dispatch(refreshSession());
                                                                });
                                                            }}
                                                            custom
                                                        />
                                                        {logoProg !== null && (
                                                            <ProgressBar className="mt-2" animated now={logoProg}/>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            {isRetailer && <><Row>
                                                <Col md={6} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label className="font-weight-bold text-dark">Business
                                                            Hours</Form.Label>
                                                        <p className="text-muted">Set your business’ regular opening and
                                                            closing hours.</p>
                                                        <div className="d-flex align-items-center">
                                                            <TimeSelect
                                                                controlId="openingTime"
                                                                value={organisation.settings?.openingTime}
                                                                onChange={handleSettingChange}
                                                                errors={submitErrors["settings.openingTime"]}
                                                            />
                                                            <span className="p-2 mb-3">-</span>
                                                            <TimeSelect
                                                                controlId="closingTime"
                                                                value={organisation.settings?.closingTime}
                                                                onChange={handleSettingChange}
                                                                errors={submitErrors["settings.closingTime"]}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label className="font-weight-bold text-dark">Minimum
                                                            Review Time</Form.Label>
                                                        <p className="text-muted">Adequate time for you to review the
                                                            orders before they go live.</p>
                                                        <MinimumReviewTime
                                                            controlId="minimumReviewTime"
                                                            required={false}
                                                            value={organisation.settings?.minimumReviewTime}
                                                            onChange={handleSettingChange}
                                                            errors={submitErrors["settings.minimumReviewTime"]}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label
                                                            className="font-weight-bold text-dark">Timezone</Form.Label>
                                                        <p className="text-muted">Select your Timezone.</p>
                                                        <Select
                                                            className="pt-4"
                                                            placeholder="Select Timezone"
                                                            value={selectedTimezone}
                                                            onChange={(e) => {
                                                                setSelectedTimezone(e);
                                                                handleSettingChange("timezone", e.value);
                                                            }}
                                                            options={timezoneOptions}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label className="font-weight-bold text-dark">Max Spend
                                                            Pacing Difference (2-20 %)</Form.Label>
                                                        <InputGroup>
                                                            <Controller
                                                                name="maxSpendPacingDifference"
                                                                control={control}
                                                                render={({field, fieldState: {invalid}}) => (
                                                                    <Form.Control
                                                                        type="number"
                                                                        max={20}
                                                                        min={2}
                                                                        aria-label="max-spend-pacing-difference-id"
                                                                        style={session?.theme?.components?.form_control}
                                                                        {...field}
                                                                        isInvalid={
                                                                            (isSubmitted ||
                                                                                touchedFields?.maxSpendPacingDifference) &&
                                                                            errors?.maxSpendPacingDifference?.message
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <Form.Group>
                                                        <Form.Label className="font-weight-bold text-dark">Currency
                                                            {selectedCurrencyLocked &&
                                                                <FontAwesomeIcon icon={faLock} color="#007bff"
                                                                                 title="Locked" size="sm"
                                                                                 className="ml-1"/>
                                                            }
                                                        </Form.Label>
                                                        {
                                                            selectedCurrencyLocked ?
                                                                <Form.Control
                                                                    type="text"
                                                                    disabled={true}
                                                                    value={selectedCurrency}
                                                                /> :
                                                                <Form.Control
                                                                    as="select"
                                                                    disabled={selectedCurrencyLocked}
                                                                    placeholder="Select Currency"
                                                                    value={selectedCurrency}
                                                                    onChange={e =>
                                                                        setSelectedCurrency(e?.target?.value)
                                                                    }
                                                                >
                                                                    <option hidden value=""/>
                                                                    {currencyOptions?.map(currency => (
                                                                        <option key={currency.value}
                                                                                value={currency?.value}>{currency?.label}</option>
                                                                    ))}

                                                                </Form.Control>
                                                        }
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                                <Row>
                                                    <Col>
                                                        <div className="rounded py-3 d-flex">
                                                            <StyledTitle className="d-inline-block">
                                                                <h3 className="font-weight-bold">Extension Channels</h3>
                                                                <hr/>
                                                            </StyledTitle>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="mb-2">
                                                    <Col lg="6">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Facebook page id (only required for Retailers)
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <Controller
                                                                    name="facebookDefaultPageID"
                                                                    control={control}
                                                                    // rules={validationRules.margin}
                                                                    render={({field, fieldState: {invalid}}) => (
                                                                        <Form.Control
                                                                            type="text"
                                                                            aria-label="facebook-default-page-id"
                                                                            style={session?.theme?.components?.form_control}
                                                                            {...field}
                                                                            isInvalid={
                                                                                (isSubmitted ||
                                                                                    touchedFields?.facebookDefaultPageID) &&
                                                                                errors?.facebookDefaultPageID?.message
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col lg="6">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Facebook Pixel
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <Controller
                                                                    name="facebookPixelID"
                                                                    control={control}
                                                                    render={({field, fieldState: {invalid}}) => (
                                                                        <Form.Control
                                                                            type="text"
                                                                            aria-label="facebook-pixel-id"
                                                                            style={session?.theme?.components?.form_control}
                                                                            {...field}
                                                                            isInvalid={
                                                                                (isSubmitted ||
                                                                                    touchedFields?.facebookPixelID) &&
                                                                                errors?.facebookPixelID?.message
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col lg="6">
                                                        <Form.Group>
                                                            <InputGroup>
                                                                <Controller
                                                                    name="webadsAttributionEnabled"
                                                                    control={control}
                                                                    render={({field, fieldState: {invalid}}) => (
                                                                        <div className='custom-control custom-switch'>
                                                                            <input
                                                                                type='checkbox'
                                                                                className='custom-control-input'
                                                                                id='webadsAttributionEnabled'
                                                                                value={true}
                                                                                {...field}
                                                                                checked={field?.value === true}
                                                                                onChange={e => {
                                                                                    field.onChange(e.target.checked);
                                                                                }}
                                                                            />
                                                                            <label className='custom-control-label'
                                                                                   htmlFor='webadsAttributionEnabled'>Enable
                                                                                Conversion attribution for web
                                                                                ads</label>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col lg="6">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Conversion attribution for webads window size (In days)
                                                                1-7 only
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <Controller
                                                                    name="webadsAttributionWindow"
                                                                    control={control}
                                                                    render={({field, fieldState: {invalid}}) => (
                                                                        <Form.Control
                                                                            type="number"
                                                                            min={1}
                                                                            max={7}
                                                                            aria-label="webads-attribution-window"
                                                                            style={session?.theme?.components?.form_control}
                                                                            {...field}
                                                                            isInvalid={
                                                                                (isSubmitted ||
                                                                                    touchedFields?.webadsAttributionWindow) &&
                                                                                errors?.webadsAttributionWindow?.message
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col lg="6">
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Conversion attribution for webads ad click tracking
                                                            </Form.Label>
                                                            <Controller
                                                                name="webadsAttributionTracking"
                                                                control={control}
                                                                render={({field, fieldState: {invalid}}) => (
                                                                    <div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input"
                                                                                   {...field}
                                                                                   id="webadsAttributionTrackingAll"
                                                                                   type="radio"
                                                                                   name="webadsAttributionTracking"
                                                                                   value="all"
                                                                                   checked={field?.value === 'all'}
                                                                                   onChange={e => {
                                                                                       field.onChange(e.target.value);
                                                                                   }}

                                                                            />
                                                                            <label class="form-check-label"
                                                                                   htmlFor='webadsAttributionTrackingAll'>All
                                                                                Ad clicks</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input"
                                                                                   {...field}
                                                                                   id="webadsAttributionTrackingLast"
                                                                                   type="radio"
                                                                                   name="webadsAttributionTracking"
                                                                                   value="last"
                                                                                   checked={field?.value === 'last'}
                                                                                   onChange={e => {
                                                                                       field.onChange(e.target.value);
                                                                                   }}
                                                                            />
                                                                            <label class="form-check-label"
                                                                                   htmlFor='webadsAttributionTrackingLast'>Last
                                                                                Ad click</label>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="rounded py-3 d-flex">
                                                            <StyledTitle className="d-inline-block">
                                                                <h3 className="font-weight-bold">Inventory
                                                                    Performance</h3>
                                                                <hr/>
                                                            </StyledTitle>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h5>Inventory Performance Ranges</h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <h5>Utilisation</h5>
                                                    </Col>
                                                    <Col>
                                                        <h5>Revenue</h5>
                                                    </Col>
                                                </Row>
                                            </>}

                                            {hasRetailers && (
                                                <>
                                                    <div
                                                        className="d-sm-flex justify-content-between align-items-center my-4">
                                                        <h5 className="text-dark mb-0">My Retailers</h5>
                                                    </div>

                                                    <Row className="mb-3">
                                                        <Col lg="6">
                                                            <OrganisationList
                                                                isSupplier={true}
                                                                organisations={session.retailers}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                            {hasSuppliers && (
                                                <>
                                                    <Row className="mb-5">
                                                        <Col>
                                                            <Reporting/>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {msg && (
                                                <Alert className="mt-3" variant={msg.type}>
                                                    {msg.body}
                                                </Alert>
                                            )}

                                            <Row className="mb-3">
                                                <Col>
                                                    <StyledSave
                                                        variant="primary"
                                                        className="mr-auto px-5 py-2 float-right"
                                                        type="submit"
                                                        size="md"
                                                        disabled={isSaving || !!Object.keys(errors).length}
                                                    >
                          <span className="small d-flex">
                            {isSaving && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-2"
                                />
                            )}
                              <span>Save Changes</span>
                          </span>
                                                    </StyledSave>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="supplier-settings">
                                        <OrganisationUserManagement/>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </Chrome>
        </>
    );
};

export default OrganisationSettings;
