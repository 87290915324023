import { connect } from 'react-redux'
import Asset from '../../components/generic/Asset'
import { selectImageAssets } from '../../orderFormSlice'

const mapStateToProps = state => ({
    type: 'image',
    files: selectImageAssets(state),
})


export default connect(mapStateToProps)(Asset)
