import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'


const DeleteButton = styled.button`
    top: 8px;
    right: 8px;
    position: absolute;
    line-height: 0;
    border-radius: 50%;
    background: #fff;
`

const NoMedia = ({ name }) =>
    <div className={`p-1 w-100 h-100 rounded`}>
        {name}
    </div>

const ImgMedia = ({ src, alt }) =>
    <img
        className={`p-1 w-100 h-100 rounded`}
        alt={alt}
        src={src}
    />

const VideoMedia = ({ src }) =>
    <video
        controls=""
        width="100%"
        className={`p-1 w-100 h-100 rounded`}
    >
        <source src={src} />
    </video>


/**
 * A small preview of a media file to view.
 * @param {file} param0 The file to render the preview.
 */
const MediaFileView = ({ file, onDelete = () => { }, readOnly = false }) => {
    let media = <NoMedia name={file.name} />

    if (file.assettype === 'image') {
        media = <ImgMedia src={file.url} alt={file.name} />
    } else if (file.assettype === 'video') {
        media = <VideoMedia src={file.url} />
    }

    return (
        <div className="m-3 position-relative">
            {media}
            {!readOnly && (
                <DeleteButton
                    className="border-0 p-0"
                    onClick={e => {
                        e.preventDefault()
                        onDelete(file)
                    }}
                >
                    <FontAwesomeIcon icon={faTimesCircle} size="1x" />
                </DeleteButton>
            )}
        </div>
    )
}

MediaFileView.propTypes = {
    file: PropTypes.shape({
        assettype: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }),
    readOnly: PropTypes.bool,
    onDelete: PropTypes.func,
}

export default React.memo(MediaFileView)
