
import { connect } from 'react-redux'
import { selectAdTypes } from '../../../session/sessionSlice'
import AdFormatForm from '../../components/PackageForm/AdFormatForm'
import { selectAdFormatTemplates, selectErrors, updateAdFormats } from '../../packageFormSlice'

const filterErrors = errors => {
    let arrayErrors = [];

    Object.keys(errors).forEach(key => {
        if (!key.startsWith('ad_format_templates.')) {
            return
        }
        const [_, index, field] = key.split('.')

        let errorsObj = arrayErrors[parseInt(index)] ?? {}
        errorsObj[field] = errors[key]
        arrayErrors[parseInt(index)] = errorsObj
    })

    return arrayErrors
}

const mapStateToProps = state => ({
    adFormats: selectAdTypes(state),
    value: selectAdFormatTemplates(state),
    errors: filterErrors(selectErrors(state))
})


const mapDispatchToProps = dispatch => ({
    onChange: adFormats => dispatch(updateAdFormats(adFormats)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdFormatForm)
