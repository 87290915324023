import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Errors from "../Errors";
import styles from "./asset.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { assetsUpdated } from "../../../order_form/orderFormSlice";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import FileDropzone from "../FileDropzone";
import { selectSession } from "../../../session/sessionSlice";

const AssetChanges = ({
  assetValues,
  readOnly = false,
  accept = [],
  onChange = () => {},
  ad,
  fields,
}) => {
  const [assetModal, setAssetModal] = useState(false);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(assetValues);

  return (
    <div>
      <Button
        disabled={readOnly}
        variant="outline-secondary"
        className="w-100"
        style={{ marginTop: "5px" }}
        onClick={(e) => setAssetModal(true)}
      >
        <div>Upload Media</div>
      </Button>

      <Modal
        show={assetModal}
        onHide={(e) => {
          setFiles(assetValues);
          setAssetModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select media to add to the order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileDropzone
            ad={ad}
            max={1}
            maxDuration={fields?.max_video_duration}
            onChange={(fs) => setFiles(fs)}
            onDelete={(file) => {
              setFiles([]);
            }}
            readOnly={readOnly}
            accept={accept}
            value={files}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              if (files && files.length > 0) {
                let filePath = files.map((file) => file.url);
                onChange(filePath[0]);

                let assets = [...assetValues];

                files.forEach((file) => {
                  if (!assets.some((asset) => asset.id === file.id)) {
                    assets.push(file);
                  }
                });

                dispatch(assetsUpdated(assets));
              }

              setAssetModal(false);
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ImgMedia = ({ src, alt }) => (
  <img
    key={src}
    className={`w-100 rounded ${styles.media_square}`}
    alt={alt}
    src={src}
  />
);

const VideoMedia = ({ src }) => (
  <>
    <video
      key={src}
      controls={true}
      width="100%"
      className={`w-100 h-100 rounded ${styles.media_square}`}
    >
      <source src={src} />
    </video>
  </>
);

const EditableAsset = ({
  controlId,
  readOnly = false,
  required = false,
  value = null,
  files = [],
  onChange = () => {},
  errors = [],
  header,
  type,
  max,
  field,
  notice,
  info,
  ad,
}) => {
  const assetFieldStructures = [
    {
      id: field.id,
      type: "asset",
      title: field?.label,
      default: true,
      required: field?.constraints?.required,
      min_width: field?.max_wdith ? field.max_wdith : 500,
      max_images: field.type === "image" ? 1 : 0,
      max_videos: field.type === "video" ? 1 : 0,
      min_height: field?.min_height ? field.min_height : 500,
      max_attachments: 1,
    },
  ];

  const session = useSelector(selectSession);

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="d-flex align-self-center align-items-center font-weight-bold">
        <span>
          {header} {required && "*"}{" "}
          {info && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id={`${controlId}-info-popover`}>{info}</Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} size="sm" />
            </OverlayTrigger>
          )}
        </span>
      </Form.Label>
      {notice && (
        <Form.Control.Feedback className="d-block text-body mb-2">
          {notice}
        </Form.Control.Feedback>
      )}
      <Form.Control type="hidden" />
      <div>
        {!value || value === "" ? (
          <div className="text-center" style={{ lineHeight: "4rem" }}>
            No {type} assets uploaded.
          </div>
        ) : (
          <div>
            {field?.constraints?.max_video_duration && (
              <p>
                Max video duration (in seconds):{" "}
                {field.constraints.max_video_duration}{" "}
              </p>
            )}
            <div className="d-flex flex-wrap">
              {field.type === "image" && (
                <ImgMedia src={value} alt={field.label} />
              )}
              {field.type === "video" && <VideoMedia src={value} />}
            </div>
          </div>
        )}
        <AssetChanges
          ad={ad}
          assetValues={files}
          onChange={onChange}
          readOnly={readOnly}
          fields={field}
          accept={
            field.type === "image"
              ? session.file_formats.images
              : field.type === "video"
              ? session.file_formats.videos
              : field.type === "document"
              ? session.file_formats.documents
              : []
          }
        />
      </div>

      <Form.Control.Feedback
        type="invalid"
        className={`${errors.length > 0 ? "d-block" : ""}`}
      >
        <Errors errors={errors} />
      </Form.Control.Feedback>
    </Form.Group>
  );
};

EditableAsset.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  files: PropTypes.array,
  max: PropTypes.number,
  field: PropTypes.object.isRequired,
  type: PropTypes.oneOf(["image", "video", "mixed"]).isRequired,
  maxVideoDuration: PropTypes.number,
  notice: PropTypes.string,
  ad: PropTypes.object.isRequired,
};

export default EditableAsset;
