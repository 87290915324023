import PropTypes from "prop-types";
import React from "react";
import PropType from "prop-types";
import { Alert, Form, InputGroup } from "react-bootstrap";
import Errors from "../../order_form/components/Errors";
import Select from "react-select";
import { useEffect } from "react";

const Monetisation = ({ ownedSpace, category, onChange, errors = {} }) => {
  const bidFormats = [
    { value: "cpm", label: "CPM" },
    { value: "cpc", label: "CPC" },
  ];
  const handleChange = (field) => onChange({ ...ownedSpace, ...field });

  useEffect(() => {
    if (
      ownedSpace.slot_pricing_mode === "bid" &&
      (!ownedSpace.bidmode || ownedSpace.bidmode === "none")
    ) {
      handleChange({ bidmode: "cpm" });
    }
  }, [ownedSpace.slot_pricing_mode]);

  return (
    <article>
      <div className="mb-2">
        <p className="mb-0">
          <b>Monetisation</b>
        </p>
        <small className="mb-2">
          Bring monetisation settings from existing Category or set a new one.
        </small>
      </div>
      <div className="border rounded border-secondary p-3">
        <div>
          <Form.Group controlId="bidding">
            {/* <Form.Check
              type="checkbox"
              isInvalid={errors.slot_pricing_mode?.length > 0}
              label="Inherit from Category"
              checked={ownedSpace.slot_pricing_mode === "inherit"}
              onChange={(e) =>
                handleChange({
                  slot_pricing_mode:
                    ownedSpace.slot_pricing_mode === "inherit" ? "" : "inherit",
                })
              }
            /> */}
            {/* {category && ownedSpace.slot_pricing_mode === "inherit" && (
              <Alert variant="info">Inherited from {category.name}</Alert>
            )} */}
            <Form.Check
              type="radio"
              name="bidding"
              label="Fixed"
              isInvalid={errors.slot_pricing_mode?.length > 0}
              disabled={ownedSpace.slot_pricing_mode === "inherit"}
              readOnly={ownedSpace.slot_pricing_mode === "inherit"}
              inline
              checked={ownedSpace.slot_pricing_mode === "fixed"}
              onChange={(e) => handleChange({ slot_pricing_mode: "fixed" })}
            />
            <Form.Check
              type="radio"
              name="bidding"
              label="Bidding"
              isInvalid={errors.slot_pricing_mode?.length > 0}
              disabled={ownedSpace.slot_pricing_mode === "inherit"}
              readOnly={ownedSpace.slot_pricing_mode === "inherit"}
              inline
              checked={ownedSpace.slot_pricing_mode === "bid"}
              onChange={(e) => handleChange({ slot_pricing_mode: "bid" })}
            />
            <Form.Control.Feedback
              type="invalid"
              className={`${
                errors.slot_pricing_mode?.length > 0 ? "d-block" : ""
              }`}
            >
              <Errors errors={errors.slot_pricing_mode} />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            {ownedSpace.slot_pricing_mode === "fixed" && (
              <div>
                <Form.Label>Rate</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    isInvalid={errors.slot_price?.length > 0}
                    value={ownedSpace.slot_price}
                    disabled={ownedSpace.slot_pricing_mode === "inherit"}
                    readOnly={ownedSpace.slot_pricing_mode === "inherit"}
                    onChange={(e) =>
                      handleChange({ slot_price: e.target.value })
                    }
                  />
                  <InputGroup.Text>/ Day</InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  className={`${
                    errors.slot_price?.length > 0 ? "d-block" : ""
                  }`}
                >
                  <Errors errors={errors.slot_price} />
                </Form.Control.Feedback>
              </div>
            )}
            {ownedSpace.slot_pricing_mode === "bid" && (
              <div>
                <Form.Label>Minimum bid</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type="number"
                    isInvalid={errors.slot_price?.length > 0}
                    value={ownedSpace.slot_price}
                    disabled={ownedSpace.slot_pricing_mode === "inherit"}
                    readOnly={ownedSpace.slot_pricing_mode === "inherit"}
                    onChange={(e) =>
                      handleChange({ slot_price: e.target.value })
                    }
                  />
                </InputGroup>
                <Form.Control.Feedback
                  type="invalid"
                  className={`${
                    errors.slot_price?.length > 0 ? "d-block" : ""
                  }`}
                >
                  <Errors errors={errors.slot_price} />
                </Form.Control.Feedback>
                <div style={{ marginTop: "15px" }}>
                  <Form.Label>Bid format</Form.Label>
                  <InputGroup>
                    {bidFormats.map((bidf) => (
                      <Form.Check
                        inline
                        checked={ownedSpace.bidmode === bidf.value}
                        name="bidformat"
                        label={bidf.label}
                        value={bidf.value}
                        type={"radio"}
                        onChange={(e) =>
                          handleChange({ bidmode: e.target.value })
                        }
                      />
                    ))}
                  </InputGroup>
                </div>
              </div>
            )}
          </Form.Group>
        </div>
      </div>
    </article>
  );
};

Monetisation.propTypes = {
  onChange: PropTypes.func,
  category: PropTypes.object,
  ownedSpace: PropTypes.objectOf({
    slot_pricing_mode: PropTypes.string,
    slot_price: PropTypes.string,
  }),
  errors: PropTypes.object,
};

export default Monetisation;
