import React from "react";
import propTypes from "prop-types";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { Form, InputGroup } from "react-bootstrap";
import PopoverHint from "./PopoverHint";

const WalletRemark = () => {
  const methods = useFormContext();
  const { control } = methods;
  const { errors, isSubmitted, touchedFields } = useFormState();

  return (
    <Form.Group>
      <Form.Label>
        <span>Additional Info</span>
        <PopoverHint popoverText="Additional info will be visible to the supplier" />
      </Form.Label>
      <Controller
        name="remark"
        control={control}
        render={({ field }) => (
          <Form.Control
            type="text"
            isInvalid={
              (isSubmitted || touchedFields.remark) && errors.remark?.message
            }
            {...field}
          />
        )}
      />
      {(isSubmitted || touchedFields.remark) && (
        <Form.Control.Feedback type="invalid">
          {errors.remark?.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default WalletRemark;
