import { connect } from 'react-redux'
import { selectMercatoDeviceGroups, selectWebSubformats } from '../../../session/sessionSlice'
import Placement from '../../components/MercatoDevice/Placement'

const mapStateToProps = state => ({
    mercatoDeviceGroups: selectMercatoDeviceGroups(state),
})


export default connect(mapStateToProps)(Placement)
